export const illustrationCard_style = {
  rootSx: {
    borderRadius: '8px',
    boxShadow: 'none',
  },
  headerSx: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  imageWithTextSx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    mt: 0.4
  },
  titleSx: {
    borderColor: 'PrimaryTints.A100',
    px: 2,
    fontWeight: 600,
    fontSize: '16px',
    mt: 1,
  },
  healthStatusContentSx: {
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: 500,
    px: 2,
    mt: 1,
  },
  cardContentSx: {
    px: 2.5,
    py: 2,
    pb: '20px!important',
  },
};
