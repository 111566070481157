/* eslint-disable consistent-return */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFLeaderboard = create((set, get) => ({
  mbfLeaderboardState: {
    member_data: [],
    user_data: {},
    user_rank: '',
  },
  loading: null,
  error: null,
  getLeaderboardData: async (payloadData) => {
    try {
      set({ loading: true });

      const payload = {
        user_id: payloadData?.user_id ?? '',
        organization_id: payloadData?.organization_id ?? 0,
        start_date: payloadData?.start_date ?? '',
        end_date: payloadData?.end_date ?? '',
      };

      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/mbf/leaderboard-clinical`,
        {
          ...payload,
        },
        true
      );
      if (response?.data?.status?.code === 200) {
        const { mbfLeaderboardState } = get();
        return set({
          mbfLeaderboardState: {
            ...mbfLeaderboardState,
            member_data: response?.data?.data?.response_data ?? [],
            user_data: response?.data?.data?.profile_data ?? {},
            user_rank: response?.data?.data?.profile_data?.user_rank?.[0]?.rank
              ? response?.data?.data?.profile_data?.user_rank?.[0]?.rank
              : Array.isArray(response?.data?.data?.response_data) &&
                response?.data?.data?.response_data?.length > 0 &&
                response?.data?.data?.response_data.find(
                  (v) => v?.is_you === true
                )?.rank
              ? response?.data?.data?.response_data.find(
                  (v) => v?.is_you === true
                )?.rank
              : undefined,
          },
          loading: false,
        });
      }
      return set({
        loading: false,
      });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
