import { envConfig } from '@hc/config';
import { httpRequest, queryClient } from '@hc/utils';
import {
  excludeLastFiveMinsAppointment,
  inculdeInLiveApp,
  inculdeInLiveAppointment,
} from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

// CONSTRUCT TODAY APPOINTMENT
export const constructTodayAppointmentData = (data) => {
  // SORT UPCOMING APPOINTMENT BY appointment_date_time
  if (Array.isArray(data) && data?.length > 0) {
    data.sort((a, b) => {
      const keyA = new Date(a.appointment_date_time);
      const keyB = new Date(b.appointment_date_time);
      // Compare the 2 appointment_date_time
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    const todayAppointmentsData = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        const res = excludeLastFiveMinsAppointment(val?.appointment_date_time);
        if (res) {
          todayAppointmentsData.push(val);
        }
      });
      // GET UPCOMING 3 APPOINTMENTS
      return todayAppointmentsData.length > 3
        ? todayAppointmentsData.slice(0, 3)
        : todayAppointmentsData;
    }
  }
  // GET UPCOMING 3 APPOINTMENTS
  return [];
};

// CONSTRUCT LIVE APPOINTMENTS
export const constructLiveAppointmentData = (data) => {
  // debugger;
  const liveAppointmentsData = [];
  // FILTER TIME LAPSED PASS APPOINTMENTS
  if (
    Array.isArray(data?.past_appointments) &&
    data?.past_appointments?.length > 0
  ) {
    data.past_appointments.map((val) => {
      if (
        val?.master_appointment_status?.appointment_status?.toLowerCase() ===
          'booked' ||
        val?.master_appointment_status?.appointment_status?.toLowerCase() ===
          'inprogress' ||
        val?.master_appointment_status?.appointment_status?.toLowerCase() ===
          'timelapsed'
      ) {
        const res = inculdeInLiveApp(val?.appointment_date_time);

        if (res?.type === 'live') {
          const obj = {
            ...val,
            appType: res,
          };
          liveAppointmentsData.push(obj);
        } else {
          const obj = {
            ...val,
            appType: res,
          };
          liveAppointmentsData.push(obj);
        }
      }
    });
  }
  // FILTER WITH IN 5 MINS START APPOINTMENT FROM UPCOMING APPOINTMENTS
  if (
    Array.isArray(data?.upcoming_appointments) &&
    data?.upcoming_appointments?.length > 0
  ) {
    data.upcoming_appointments.map((val) => {
      const res = inculdeInLiveAppointment(val?.appointment_date_time);

      if (res?.type === 'upcoming') {
        const obj = {
          ...val,
          appType: res,
        };
        liveAppointmentsData.push(obj);
      }
      // }
    });
  }
  return liveAppointmentsData;
};

export const useAppointment = create((set, get) => ({
  appointmentState: {
    past_appointments: [],
    search_past_appointments: [],
    upcoming_appointments: [],
    search_upcoming_appointments: [],
    today_appointments: [],
    follow_up_referral: [],
    live_appointments: [],
    payment_pending_appointments: [],
    docs: [
      {
        uri: '',
      },
    ],
    appointment_id: '',
    appointed_doctor_id: '',
    event_id: '',
    cancellationReason: '',
    index: '',
    appointment_data: {},
    patientStartTime: '',
    DoctorStartTime: '',
  },
  loading: null,
  error: null,
  clearAppointmentStore: () => {
    set({
      appointmentState: {
        past_appointments: [],
        search_past_appointments: [],
        upcoming_appointments: [],
        search_upcoming_appointments: [],
        today_appointments: [],
        follow_up_referral: [],
        live_appointments: [],
        payment_pending_appointments: [],
        docs: [
          {
            uri: '',
          },
        ],
        appointment_id: '',
        appointed_doctor_id: '',
        event_id: '',
        cancellationReason: '',
        index: '',
        appointment_data: {},
      },
    });
  },
  checkSlotIsAvilableOrNot: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/slot-availibity`,
        {
          ...payload,
        },
        true
      );
      set({ loading: false });
      return response;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  appointmentStatusChange: async (payload) => {
    try {
      set({ loading: true });

      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/status-update`,
        {
          ...payload,
        },
        true
      );
      set({ loading: false });
      set((state) => ({
        loading: false,
        appointmentState: {
          ...state.appointmentState,
          patientStartTime: response?.data?.data?.patient_joining_date_time,
          DoctorStartTime: response?.data?.data?.doctor_joining_date_time,
        },
      }));
      return response;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getPastUpcomingAppointmentList: async (id, isHome) => {
    try {
      set({ loading: true });
      // eslint-disable-next-line no-unused-vars
      const { data } = await queryClient.fetchQuery({
        queryKey: ['appointments'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/appointments/getAll`,
            { id: id ?? '' },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      const todayAppointments = await constructTodayAppointmentData(
        data?.upcoming_appointments
      );
      let liveAppointments = [];
      if (isHome) {
        liveAppointments = await constructLiveAppointmentData(data);
      }
      set((state) => ({
        loading: false,
        appointmentState: {
          ...state.appointmentState,
          ...data,
          today_appointments: todayAppointments,
          live_appointments: liveAppointments,
        },
      }));
      return data;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getFollowupReferralAppointmentList: async (id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      // eslint-disable-next-line no-unused-vars
      queryClient.invalidateQueries({
        queryKey: ['followUp-referral'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['followUp-referral'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/appointments/followUp-referral`,
            { id: id ?? '' },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        const follow_up_referral_copy = [];
        if (Array.isArray(data?.follow_up) && data?.follow_up?.length > 0) {
          data?.follow_up?.map((val) => {
            follow_up_referral_copy.push(val);
          });
        }
        if (Array.isArray(data?.referral) && data?.referral?.length > 0) {
          data?.referral?.map((val) => {
            follow_up_referral_copy.push(val);
          });
        }
        return set((state) => ({
          loading: false,
          appointmentState: {
            ...state.appointmentState,
            follow_up_referral: follow_up_referral_copy,
          },
        }));
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  cancelAppointment: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/cancel`,
        {
          ...payload,
        },
        true
      );
      // To invalidate the cache of performanceStats
      queryClient.refetchQueries({
        queryKey: ['appointments'],
      });
      set({ loading: false });
      return response;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  removeAppointment: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/followUp-referral/remove`,
        {
          ...payload,
        },
        true
      );
      // To invalidate the cache of performanceStats
      queryClient.refetchQueries({
        queryKey: ['appointments'],
      });
      queryClient.refetchQueries({
        queryKey: ['followUp-referral'],
      });

      set({ loading: false });
      return response;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  // getActivePrescription: async (id) => {
  //   try {
  //     set({ loading: true });
  //     const { status, data, error, isFetching } =
  //       await queryClient.fetchQuery([
  //         '/appointments/prescription-getAll',
  //         'post',
  //         { id: id ?? '' },
  //       ]);

  //     // if (data?.status?.code === 200) {
  //       return set((state) => ({
  //         loading: false,
  //         appointmentState: {
  //           ...state.appointmentState,
  //           active_prescription_data: data,
  //         },
  //       }));

  //     // }
  //     set({ loading: false });
  //   } catch (error) {
  //     set({ loading: false });
  //     return toast.error(
  //       error?.data?.status?.message ??
  //         error?.message ??
  //         'Something went wrong please try again!'
  //     );
  //   }
  // },
  // APPOINTMENT STATE UPDATE
  updateAppointments: (key, value) => {
    const { appointmentState } = get();
    set({
      appointmentState: {
        ...appointmentState,
        [key]: value,
      },
    });
  },
  // APPOINTMENT DRAWER DATA CLEAR
  appointmentDrawerDataClear: () => {
    const { appointmentState } = get();
    set({
      appointmentState: {
        ...appointmentState,
        appointment_id: '',
        appointed_doctor_id: '',
        event_id: '',
        cancellationReason: '',
        index: '',
        appointment_data: {},
      },
    });
  },
  // UPDATE APPOINTMENT DATA
  updateAppointmentsData: (
    index,
    app_id,
    doctor_id,
    event_id,
    appointment_data
  ) => {
    const { appointmentState } = get();
    if (app_id) {
      set({
        appointmentState: {
          ...appointmentState,
          index,
          appointment_id: app_id,
          appointed_doctor_id: doctor_id,
          event_id,
          appointment_data,
        },
      });
    } else {
      set({
        appointmentState: {
          ...appointmentState,
          appointment_id: '',
          appointed_doctor_id: '',
          event_id: '',
          cancellationReason: '',
          index: '',
          appointment_data: {},
        },
      });
    }
  },
  // APPOINTMENT STATE UPDATE
  appointmentStateUpdate: (key, value) => {
    const { appointmentState } = get();
    set({
      appointmentState: {
        ...appointmentState,
        [key]: value,
      },
    });
  },
  // DOCUMENT URL UPDATE
  handleDocsURL: (visit_report_url) => {
    const { appointmentState } = get();
    if (visit_report_url) {
      set({
        appointmentState: {
          ...appointmentState,
          docs: [
            {
              uri: visit_report_url,
            },
          ],
        },
      });
    } else {
      set({
        appointmentState: {
          ...appointmentState,
          docs: [
            {
              uri: '',
            },
          ],
        },
      });
    }
  },
}));
