import { getDateFormat } from '@hc/dayjs';
import { useMBFPerformance } from '@hc/store';
import { Box } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import { PerfomanceStatsMapping } from '../performance/performanceStatsMapping';
import { PreviousStatsNav } from '../previousStatsNav';
import { previousPerformanceStats_style } from './style';

function PreviousPerformanceStats(props) {
  const {
    className = '',
    stats,
    active,
    onNext = () => {},
    onBack = () => {},
    rootStyle = {},
    ...rest
  } = props;

  const { mbfPerformanceData, planStartDate, planEndDate } = useMBFPerformance(
    (state) => ({
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
      startEndDateUpdate: state.startEndDateUpdate,
      mbfPerformanceData: state.mbfPerformanceData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { mindStatus, bodyStatus, foodStatus } = mbfPerformanceData;

  return (
    <Box
      sx={{
        ...previousPerformanceStats_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box px={0.5} py={1.5}>
        <PreviousStatsNav
          onNext={onNext}
          onBack={onBack}
          plan={`Plan ${stats === 0 ? 1 : stats}`}
          startAndEndDate={`${getDateFormat(
            planStartDate,
            'MMM DD yyyy'
          )} - ${getDateFormat(planEndDate, 'MMM DD yyyy')}`}
          active={active}
        />
      </Box>
      <Box px={3} pb={2} sx={previousPerformanceStats_style.mindBodyRootCardSx}>
        <Box>
          <Box>
            <PerfomanceStatsMapping
              mindStatus={mindStatus}
              bodyStatus={bodyStatus}
              foodStatus={foodStatus}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PreviousPerformanceStats.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PreviousPerformanceStats };

