/* eslint-disable camelcase */
export const patient_report_card_style = {
  rootSx: {},
  outlinedBox: {
    border: '1px solid #DBEAE8',
    borderRadius: '8px',
  },
  activeOutlinedBox: {
    border: '1px solid ',
    borderColor: 'border.focus',
    borderRadius: '8px',
  },
  expandIconSx: {
    position: 'absolute',
  },
  patientTypo: {
    fontSize: '12px',
    color: '#888888',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  checkSecondDisabledStyle: {
    backgroundColor: '#F0F0F0',
    backgroundImage: '#F0F0F0',
    'input:hover ~ &': {
      backgroundColor: '#F0F0F0',
    },
  },
  ImageBoxSx: {
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    background: '#000',
  },
  adSx: {
    mt: 3,
    height: '48vh',
  },
  editIconSx: {
    pr: 1.5,
    cursor: 'pointer',
  },
};
