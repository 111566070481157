import { CloseIcon, InfoIcon } from '@hc/ui/atoms';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Contractexpiry_Illustration from '../../../assets/Contractexpiry_Illustration.svg';
import { contractExpiryModal_style } from './style';

function ContractExpiryModal(props) {
  const {
    addDrawerOpen,
    setAddDrawerOpen,
    className = '',
    rootStyle,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...contractExpiryModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {addDrawerOpen && (
          <Drawer
            height="auto"
            anchor="bottom"
            sx={contractExpiryModal_style.rootSx}
            open={addDrawerOpen}
            onClose={() => setAddDrawerOpen(false)}
          >
            <Stack>
              <Box sx={contractExpiryModal_style.closeiconwraperSx}>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  onClick={() => setAddDrawerOpen(false)}
                >
                  <CloseIcon rootStyle={{ width: '16px', height: '16px' }} />
                </IconButton>
              </Box>
              <Box sx={contractExpiryModal_style.imgcenterSx}>
                {' '}
                <img
                  src={Contractexpiry_Illustration}
                  alt="Contractexpiry_Illustration"
                />
              </Box>
              <Typography sx={contractExpiryModal_style.descriptionTextSx}>
                Your{` organization's `}contract for MBF is <br /> expired.
                Resume contract to continue <br /> your wellness goals
              </Typography>
              <Box sx={contractExpiryModal_style.infotextwrapSx}>
                <InfoIcon
                  rootStyle={{
                    color: '#888888',
                    width: '20px',
                    height: '20px',
                  }}
                />

                <Typography sx={contractExpiryModal_style.infotextSx}>
                  Contact your admin for more information
                </Typography>
              </Box>
            </Stack>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

ContractExpiryModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ContractExpiryModal };
