import Angry_Emoji from '@assets/angryEmoji.png';
import Tired_Emoji from '@assets/faceBandageEmoji.png';
import Stressed_Emoji from '@assets/faceWIthStreamEmoji.png';
import Neutral_Emoji from '@assets/neutralFaceEmoji.png';
import Sad_Emoji from '@assets/sleepyFaceEmoji.png';
import SmileEmoji from '@assets/smileEmoji.png';
import Energetic_Emoji from '@assets/smileWithStarEmoji.png';
import { getDateFormat } from '@hc/dayjs';
import { useMBFMoodStatsProgress, useMBFUserPlanData } from '@hc/store';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { CircularProgressWithLabel } from '../../../../atoms';
import { PreviousStatsNav } from '../previousStatsNav';
import { previousMoodStats_style } from './style';

function PreviousMoodStats(props) {
  const {
    className = '',
    stats,
    active,
    onNext = () => {},
    onBack = () => {},
    rootStyle = {},
    ...rest
  } = props;

  // GENERAL HOOKS
  const { mbfMoodStatsData, planStartDate, planEndDate } =
    useMBFMoodStatsProgress();

  const { mbfUserPlanState } = useMBFUserPlanData((state) => ({
    mbfUserPlanState: state.mbfUserPlanState,
  }));

  const { happy, neutral, sad, tired, energetic, angry, stressed } =
    mbfMoodStatsData;

  const data = [
    {
      value: happy,
      color: { mainColor: '#25C460', backgroundColor: '#D8F8E4' },
      moodState: 'Happy',
      image: SmileEmoji,
    },
    {
      value: neutral,
      color: { mainColor: '#2C9EE9', backgroundColor: '#DCEFFB' },
      moodState: 'Neutral',
      image: Neutral_Emoji,
    },
    {
      value: sad,
      color: { mainColor: '#FF980E', backgroundColor: '#FFEED7' },
      moodState: 'Sad',
      image: Sad_Emoji,
    },
    {
      value: tired,
      color: { mainColor: '#4F66D8', backgroundColor: '#DFE3F7' },
      moodState: 'Tired',
      image: Tired_Emoji,
    },
    {
      value: energetic,
      color: { mainColor: '#24C092', backgroundColor: '#D4F8E9' },
      moodState: 'Energetic',
      image: Energetic_Emoji,
    },
    {
      value: angry,
      color: { mainColor: '#F44F5A', backgroundColor: '#FEDDDF' },
      moodState: 'Angry',
      image: Angry_Emoji,
    },
    {
      value: stressed,
      color: { mainColor: '#FACC15', backgroundColor: '#FCF4D5' },
      moodState: 'Stressed',
      image: Stressed_Emoji,
    },
  ];

  return (
    <Box
      sx={{
        ...previousMoodStats_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box pb={2}>
        <PreviousStatsNav
          onNext={onNext}
          onBack={onBack}
          plan={`Plan ${mbfUserPlanState?.planNumber}`}
          startAndEndDate={`${getDateFormat(
            planStartDate,
            'MMM DD yyyy'
          )} - ${getDateFormat(planEndDate, 'MMM DD yyyy')}`}
          active={active}
        />
      </Box>
      <Box sx={previousMoodStats_style.ContainerSx}>
        <Grid
          container
          sx={previousMoodStats_style.progressContainerSx}
          justifyContent="center"
        >
          {data.map((progressData, i) => (
            <Grid
              key={i}
              item
              xs={6}
              pb={2}
              display="flex"
              justifyContent="center"
            >
              <CircularProgressWithLabel
                size={100}
                thickness={5.5}
                value={progressData.value}
                color={progressData.color}
                moodState={progressData.moodState}
                centerIcon
                isPercenteageValue
                isMoodState
                image={progressData.image}
                circularStyle={previousMoodStats_style.circularColorSx}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

PreviousMoodStats.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PreviousMoodStats };
