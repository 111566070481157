export const leaderBoardCard_style = {
  cardSx: {
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'purple.600',
    borderRadius: '8px',
    minHeight: '70px',
    mt: 1.5,
    cursor: 'pointer',
  },
  leaderBoardRankList: {
    bgcolor: '#FCF8E8',
    border: '1px solid #FBD74A',
    p: 0,
  },
  rankListSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  textWithStatusSx: {
    pl: '10px',
    pt: 0.5,
  },
  leaderNameSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'text.primary',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  activitySx: {
    display: 'flex',
    alignItems: 'center',
  },
  runningSx: {
    fontSize: '10px',
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
  },
  avatarSx: {
    height: '36px',
    width: '36px',
    marginLeft: '18px',
  },
  withCircular: {
    display: 'flex',
    alignItems: 'center',
    pt: 1,
    textAlign: 'center',
  },
};
