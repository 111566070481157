export const appointmentReports_style = {
  rootUploadSx: {
    px: 2.5,
  },
  uploadFileSizeSx: {
    height: '240px',
  },
  uploadIconCenterSx: {},
  buttonSx: {
    textTransform: 'none',
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
