export const bloodGroupSelection_style = {
  nameActiveSx: {
    fontWeight: 600,
    color: 'orangeTints.600',
  },
  nameInactiveSx: {
    fontWeight: 500,
  },
  bloodgroupactiveSx: {
    width: '35px',
    height: '35px',
    backgroundColor: 'orangeTints.50',
    border: '2px solid',
    borderColor: 'orangeTints.600',
    color: 'orangeTints.600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    cursor: 'pointer',
  },
  bloodgroupinactiveSx: {
    width: '35px',
    height: '35px',
    backgroundColor: 'orangeTints.50',
    border: '2px solid',
    borderColor: 'orangeTints.50',
    borderRadius: '6px',
    fontSize: '14px',
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};
