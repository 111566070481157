export const notification_style = {
  rootSx: {},

  rootSxx: {
    boxShadow: '0px 1px #dfe2e3',
    py: 2.1,
    px: 2.5,
  },
  iconBackgroundSx: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'primaryTints.100',
  },
  buttonSx: {
    textTranform: 'inherit',
  },
};
