/* eslint-disable camelcase */
export const view_plan_card_style = {
  rootSx: {},
  cardRootSx: {
    backgroundImage: 'linear-gradient(60deg,#D5CCFF,#E5E0FF)',
    height: 140,
    width: '100%',
    borderRadius: '8px',
  },
  headerTextSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    mt: 2,
  },
  cardHeaderTextSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 600,
  },
  subTextSx: {
    fontSize: '14px',
    color: 'text.hint',
    mt: 0.5,
  },
  footerSx: {
    display: 'flex',
    boxShadow: '0px 0px 10px #efefef',
    justifyContent: 'space-between',
    px: 2,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
  },
  buttonSx: {
    backgroundColor: 'mbf.main',
    textTransform: 'none',
    borderRadius: '8px',
    my: 3,
    '&:hover': {
      backgroundColor: 'mbf.dark',
    },
    '&:focus': {
      backgroundColor: 'mbf.dark',
    },
    '&:active': {
      backgroundColor: 'mbf.dark',
    },
    '& .MuiLoadingButton-loadingIndicator': {
      color: 'mbf.main',
    },
    '&.MuiLoadingButton-loading': {
      backgroundColor: 'mbf.light',
    },
  },
  planDurationImgSx: {
    ml: '13%',
    mt: '-44%',
  },
  evaluationsImgSx: {
    ml: '195%',
    mt: '-153%',
  },
  activityImgSx: {
    mt: '-39%',
    ml: '-1%',
  },
  progressJourneyImgSx: {
    mt: '-86%',
    ml: '80%',
  },
};
