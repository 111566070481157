export const planRenewalBanner_style = {
  bannerBox: { backgroundColor: '#EAE6FF', borderRadius: '10px' },
  descSx: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
  spanSx: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#6856cd',
  },
  imgContainerSx: {
    width: '180px',
  },
  getPlanBtn: {
    width: '124px',
    borderRadius: '4px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: '#6552CC',
    p: 1,
    '&:hover': { backgroundColor: '#6552CC' },
  },
  getPlanBtnText: {
    fontSize: '12px',
    color: '#fff',
    fontWeight: 500,
    textAlign: 'center',
  },
};
