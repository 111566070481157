/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useVitalMonitoring = create((set, get) => ({
  vitalMonitoringState: {
    vital: {
      blood_pressure: {
        SBP: '',
        DBP: '',
      },
      puls_bpm: '',
      temp_in_f: '',
      o2_level: '',
      height: '',
      weight: '',
    },
    vitalHistory: {
      chartData: [],
      historyData: [],
    },
    error: {
      blood_pressure: '',
      puls_bpm: '',
      temp_in_f: '',
      o2_level: '',
      height: '',
      weight: '',
    },
    vitalLastUpdateData: {},
    dataDrawerContent: {},
  },
  loading: null,
  error: null,
  getVitalLastUpdateHistoryData: async (key) => {
    try {
      const { vitalMonitoringState } = get();
      set({ loading: true });
      // For preventing duplicate request, we're using react-query

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['vitalLastUpdate'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/vitalHistory/get-last-updated-vital-data`,
            {
              vital_type_id: key,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === '200') {
        set({
          loading: false,
          vitalMonitoringState: {
            ...vitalMonitoringState,
            vitalLastUpdateData: data ?? {},
          },
        });
      }
      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  upserVitalData: async (key, vitalValue) => {
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['vitalLastUpsert'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/vitalHistory/upsert-vital-data`,
            {
              vital_type_id: key,
              value: vitalValue,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === '200') {
        set({ loading: false });
        toast.success(status?.message);
      }

      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getVitalHistoryData: async (payload) => {
    try {
      const { vitalMonitoringState } = get();
      const { dataDrawerContent, vitalHistory } = vitalMonitoringState;
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['vitalHistory'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/vitalHistory/get-vital-history`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === '200') {        
        let arr = [];
        let historyArr = [];
        set({ loading: false });
        if (dataDrawerContent?.keyValue === 1) {
          for (const obj of data) {
            const bloodPressure = obj?.value.split('/');
            const dateValue = `${new Date(obj?.updated_at)
              .toString()
              .slice(3, 10)}`;
            const year = new Date(obj?.updated_at).getFullYear();

            const object = {
              name: dateValue ?? '',
              SBP: bloodPressure[0],
              DBP: bloodPressure[1],
            };

            const hisObject = {
              value: obj?.value ?? '',
              date: `${dateValue} ,${year}`,
            };
            arr.push(object);
            historyArr.push(hisObject);
          }
        } else {
          for (const obj of data) {
            const dateValue = `${new Date(obj?.updated_at)
              .toString()
              .slice(3, 10)}`;
            const year = new Date(obj?.updated_at).getFullYear();

            const object = {
              name: dateValue ?? '',
              [dataDrawerContent?.key]: obj?.value,
            };
            const hisObject = {
              value: `${obj?.value} ${dataDrawerContent?.subType}`,
              date: `${dateValue} ,${year}`,
            };
            arr.push(object);
            historyArr.push(hisObject);
          }
        }
        set({
          vitalMonitoringState: {
            ...vitalMonitoringState,
            vitalHistory: {
              ...vitalHistory,
              chartData: arr,
              historyData: historyArr,
            },
          },
        });
      }

      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  updateStatevalue: (key, value) => {
    const { vitalMonitoringState } = get();

    set({
      vitalMonitoringState: {
        ...vitalMonitoringState,
        [key]: value,
      },
    });
  },
  updateVitalData: (key, value, subKey) => {
    const { vitalMonitoringState } = get();
    const { vital } = vitalMonitoringState;
    const { error } = vitalMonitoringState;
    const { blood_pressure } = vital;

    if (key === 'blood_pressure') {
      //   const data = value.split('/');
      set({
        vitalMonitoringState: {
          ...vitalMonitoringState,
          vital: {
            ...vital,
            blood_pressure: {
              ...blood_pressure,
              [subKey]: value,
            },
          },
        },
      });
    } else if (key === 'error') {
      set({
        vitalMonitoringState: {
          ...vitalMonitoringState,
          error: {
            ...error,
            [subKey]: value,
          },
        },
      });
    } else {
      set({
        vitalMonitoringState: {
          ...vitalMonitoringState,
          vital: {
            ...vital,
            [key]: value,
          },
        },
      });
    }
  },
}));
