import { clinicalRoutes } from '@hc/routes';
import { useFamilyMember } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { FamilyMemberAddEdit } from '@hc/ui/components/clinical/familyMember/addFamilyMember';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { isValidBloodPressure } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { addEditFamilyMember_style } from './style';

export default function EditFamilyMembers(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  // General Hooks
  const navigate = useNavigate();
  const {
    familyMember,
    upsertFamilyMember,
    updateFamilyMemberError,
    handleFamilyMemberChange,
    clearFamilyMemberState,
    existingFamilyMember,

    // loading,
  } = useFamilyMember(
    (state) => ({
      familyMember: state.familyMember,
      updateFamilyMemberError: state.updateFamilyMemberError,
      handleFamilyMemberChange: state.handleFamilyMemberChange,
      clearFamilyMemberState: state.clearFamilyMemberState,
      loading: state.loading,
      existingFamilyMember: state.existingFamilyMember,
      upsertFamilyMember: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const [useMyNumber, setUseMyNumber] = useState(false);
  const [saving, setSaving] = useState(false);

  const isIamValidToSave = async () => {
    const familyMemberCopy = JSON.parse(JSON.stringify(familyMember));

    let isValid = true;
    const error = familyMemberCopy?.error;

    // Checking name
    if (!familyMember?.name) {
      isValid = false;
      error.name = 'Please enter your name';
    } else {
      error.name = '';
    }

    // Checking relationship
    if (!familyMember?.relationship) {
      isValid = false;
      error.relationship = 'Please select relationship';
    } else {
      error.relationship = '';
    }

    // Checking gender
    if (!familyMember?.gender) {
      isValid = false;
      error.gender = 'Please enter your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!familyMember?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }

    // Checking o2_level pressure
    if (familyMember?.o2_level.length > 0) {
      if (familyMember.o2_level < 95 || familyMember.o2_level > 100) {
        isValid = false;
        error.o2_level = 'Range between 95 and 100';
      } else {
        error.o2_level = '';
      }
    } else {
      error.o2_level = '';
    }

    // Checking puls_bpm pressure
    if (familyMember?.puls_bpm.length > 0) {
      if (familyMember.puls_bpm < 60 || familyMember.puls_bpm > 100) {
        isValid = false;
        error.puls_bpm = 'Range between 60 and 100';
      } else {
        error.puls_bpm = '';
      }
    } else {
      error.puls_bpm = '';
    }

    // Checking temp_in_f pressure
    if (familyMember?.temp_in_f.length > 0) {
      if (familyMember.temp_in_f < 80 || familyMember.temp_in_f > 120) {
        isValid = false;
        error.temp_in_f = 'Range between 80 and 120';
      } else {
        error.temp_in_f = '';
      }
    } else {
      error.temp_in_f = '';
    }

    // Checking blood pressure
    if (familyMember?.blood_pressure.length > 0) {
      if (isValidBloodPressure(familyMember?.blood_pressure)) {
        if (familyMember?.blood_pressure.includes('/')) {
          if (
            familyMember?.blood_pressure?.split('/')[0] &&
            familyMember?.blood_pressure?.split('/')[1]
          ) {
            if (
              familyMember?.blood_pressure?.split('/')[0] < 90 ||
              familyMember?.blood_pressure?.split('/')[0] > 120 ||
              familyMember?.blood_pressure?.split('/')[1] < 60 ||
              familyMember?.blood_pressure?.split('/')[1] > 80
            ) {
              isValid = false;
              error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
            } else {
              error.blood_pressure = '';
            }
          } else {
            isValid = false;
            error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
          }
        } else {
          isValid = false;
          error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
        }
      } else {
        isValid = false;
        error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
      }
    }
    // Checking email_id
    const filter = /\S+@\S+\.\S+/;
    if (familyMember?.email_id?.length > 0) {
      if (!filter.test(familyMember?.email_id)) {
        isValid = false;
        error.email_id = 'Please enter the valid mail';
      } else {
        error.email_id = '';
      }
    } else {
      isValid = false;
      error.email_id = 'Please enter the mail';
    }

    // Checking mobile_no
    if (familyMember?.mobile_no?.length > 0) {
      if (familyMember?.mobile_no?.length !== 10) {
        isValid = false;
        error.mobile_no = 'Please enter your valid mobilenumber';
      } else {
        const data = await existingFamilyMember({
          mobile_no: familyMemberCopy?.mobile_no ?? '',
          country_code: familyMemberCopy?.country_code ?? '',
        });
        if (data) {
          if (data?.mobile_no) {
            isValid = false;
            toast('Mobile Number Already Exists as a User in Health circles', {
              duration: 2000,
            });
            setTimeout(() => {
              toast('Redirecting to adding existing member page...');
            }, 1000);
            updateFamilyMemberError({
              name: '',
              relationship: '',
              gender: '',
              age: '',
              mobile_no: '',
              email_id: '',
              blood_pressure: '',
            });
            setTimeout(() => {
              navigate(clinicalRoutes.addexistingmember);
            }, 2000);
          }
        }

        error.mobile_no = '';
      }
    } else {
      isValid = false;
      error.mobile_no = 'Please enter your mobilenumber';
    }

    // UPDATE FAMILY MEMBER ERROR
    updateFamilyMemberError(error);
    return isValid;
  };

  const handleCheckBox = (event) => {
    setUseMyNumber(event?.target?.checked);
    if (event?.target?.checked === true) {
      handleFamilyMemberChange('country_code', data?.country_code ?? '');
      handleFamilyMemberChange('mobile_no', data?.mobile_no ?? '');
    } else {
      handleFamilyMemberChange('country_code', '');
      handleFamilyMemberChange('mobile_no', '');
    }
  };

  const handleSave = async () => {
    setSaving(true);
    const result = await isIamValidToSave();
    if (result) {
      await upsertFamilyMember(
        familyMember,
        !familyMember?.family_member_id,
        useMyNumber,
      );
      // CLEAR FAMILY MEMBER STATE
      clearFamilyMemberState();
    }
    setSaving(false);
  };

  const familyMemberView = () => {
    navigate(clinicalRoutes.myFamily);
    // CLEAR FAMILY MEMBER STATE
    clearFamilyMemberState();
  };

  useEffect(() => {
    // CLEAR FAMILY MEMBER STATE
    clearFamilyMemberState();
  }, []);

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={addEditFamilyMember_style.boxRootSx}>
        <Screenlayout
          backRequired
          title={
            familyMember?.family_member_id
              ? 'Edit Family Member'
              : 'Add Family Member'
          }
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={familyMemberView}
                buttonStyle={addEditFamilyMember_style.buttonSx}
              >
                Cancel
              </Button>
              <Button loading={saving} onClick={handleSave}>
                Save
              </Button>
            </Box>
          }
        >
          <FamilyMemberAddEdit
            useMyNumber={useMyNumber}
            handleCheckBox={handleCheckBox}
          />
        </Screenlayout>
      </Box>
    </Box>
  );
}
