// import { WellnessCard } from '@hc/ui/components/clinical';
import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity, useMBFWellnessJourney } from '@hc/store';
import { Flag } from '@hc/ui/atoms';
import { PlanHistoryFooter, WellnessJourneyHistory } from '@hc/ui/components';
import { MbfChartCard, WellnessActivityCard } from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { queryClient } from '@hc/utils';
import { Box, CircularProgress, Typography } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { wellnessjourney_style } from './style';

export default function WellnessJourney(props) {
  const [stats, setStats] = useState(0);
  const [active, setActive] = useState(true);
  // Store Data

  const {
    mbfState,
    loading,
    getWellnessJourney,
    getPriviousWellnessJourney,
    planCount,
  } = useMBFWellnessJourney((state) => ({
    mbfState: state.mbfState,
    getWellnessJourney: state.getWellnessJourney,
    getPriviousWellnessJourney: state.getPriviousWellnessJourney,
    planCount: state.planCount,
    loading: state.loading,
  }));

  const [searchParams, setSearchParams] = useSearchParams();

  const planStatus = searchParams.get('status');

  const { mbfState: mbfStateCopy } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfStateCopy;
  const { status } = checkPlanData;

  const onNext = async () => {
    if (planCount > stats) {
      setActive(true);
      queryClient.invalidateQueries({
        queryKey: ['wellnessJourneyPrevious'],
      });
      await getPriviousWellnessJourney(stats + 1);
      setStats(stats + 1);
    } else {
      toast(
        `You have only ${planCount} previous ${
          planCount === 1 ? 'plan' : 'plans'
        }`,
        { icon: '⚠️' },
      );
    }
  };

  const onBack = async () => {
    if (stats >= 0) {
      if (stats - 1 !== 0) {
        setActive(false);
        queryClient.invalidateQueries({
          queryKey: ['wellnessJourneyPrevious'],
        });
        await getPriviousWellnessJourney(stats - 1);
        setStats(stats - 1);
      } else {
        toast(
          `You have only ${planCount} previous ${
            planCount === 1 ? 'plan' : 'plans'
          }`,
          { icon: '⚠️' },
        );
      }
    }
  };
  const onPrevious = async () => {
    if (planCount > 0) {
      setSearchParams({ status: 'Previous' });

      queryClient.invalidateQueries({
        queryKey: ['wellnessJourneyPrevious'],
      });
      await getPriviousWellnessJourney(1);
      setStats(1);
    } else {
      toast(`You have current plan only`, { icon: '⚠️' });
    }
  };

  const onCurrent = async () => {
    if (status === 4) {
      setSearchParams({ status: 'current' });

      queryClient.invalidateQueries({
        queryKey: ['wellnessJourney'],
      });
      await getWellnessJourney();
    } else {
      toast(`You have no current plan`, { icon: '⚠️' });
    }
  };

  const goToPrevious = async () => {
    setSearchParams({ status: 'Previous' });

    queryClient.invalidateQueries({
      queryKey: ['wellnessJourneyPrevious'],
    });
    await getPriviousWellnessJourney(1);
    setStats(1);
  };

  const initialData = async () => {
    if (searchParams.get('status')) {
      setSearchParams({ status: searchParams.get('status') });
    } else if (status === 4) {
      setSearchParams({ status: 'current' });
      await getWellnessJourney();
    } else {
      goToPrevious();
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box {...props}>
      <Screenlayout
        appBarStyle={wellnessjourney_style.appBarStyleSx}
        rootStyle={{ backgroundColor: 'purple.50' }}
        backRequired
        notshowFooter={false}
        title="Wellness Journey"
        backIconStyle={{ color: 'mbf.main' }}
        footer={
          planCount > 0 ? (
            <PlanHistoryFooter
              planStatus={planStatus}
              onPrevious={onPrevious}
              planCount={planCount}
              onCurrent={onCurrent}
            />
          ) : null
        }
        backRoute={clinicalRoutes?.mindbodyfood}
        footerStyle={{ borderTop: '0px', p: planCount > 0 ? 1 : 0 }}
        childrenStyle={wellnessjourney_style.childrenSx}
      >
        {planStatus === 'current' && !loading && (
          <Box>
            <Box sx={{ px: 2.5, py: 2 }}>
              {mbfState?.numberOfDaysCompleted && (
                <Box sx={wellnessjourney_style.headerSx}>
                  <Flag sx={wellnessjourney_style.avatarSx} />
                  <Typography sx={wellnessjourney_style.statusSx}>
                    Day{' '}
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {mbfState?.numberOfDaysCompleted}
                    </span>{' '}
                    InProgress
                  </Typography>
                </Box>
              )}
            </Box>
            {mbfState?.graphData &&
            mbfState?.graphData.length > 0 &&
            mbfState?.graphData[1]?.value ? (
              <MbfChartCard />
            ) : null}
            {mbfState?.activities && mbfState?.activities.length > 0 ? (
              <WellnessActivityCard />
            ) : (
              <Typography
                textAlign="center"
                py={15}
                color="#616161"
                fontWeight={600}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        )}

        {planStatus === 'Previous' && (
          <Box>
            <WellnessJourneyHistory
              onBack={onBack}
              active={active}
              stats={stats}
              onNext={onNext}
            />
          </Box>
        )}

        {loading && (
          <Box
            sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}
          >
            <CircularProgress sx={{ color: 'mbf.main' }} />
          </Box>
        )}
      </Screenlayout>
    </Box>
  );
}
