import { Box, Typography } from '@mui/material';
import { EditIcon, DropIcon, DropUpIcon } from '@atoms';
import PropTypes from 'prop-types';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appointmentChips_style } from './style';

export function AppointmentChips(props) {
  const {
    heading = '',
    isDoctor,
    editDownIconShown = true,
    icon = [],
    list = [],
    className = '',
    editOnclick,
    noDataFound = '',
    ...rest
  } = props;
  const res = useMediaQuery('(max-width:945px)');
  const IntialList = res ? list.slice(0, 1) : list.slice(0, 2);
  const [expand, setExpand] = useState(true);
  return (
    <Box
      sx={appointmentChips_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={appointmentChips_style.titleSx}>
          {icon}
          <Typography sx={appointmentChips_style.headerSx}>
            {heading}
          </Typography>
        </Box>
        {editDownIconShown && (
          <Box
            sx={{
              ...appointmentChips_style.titleSx,
              gap: 2,
              cursor: 'pointer',
            }}
          >
            {list.length > 0 && <EditIcon onClick={editOnclick} />}
            {list.length > 0 ? (
              expand ? (
                <DropIcon
                  onClick={() => setExpand(false)}
                  rootStyle={{ cursor: 'pointer' }}
                />
              ) : (
                <DropUpIcon
                  onClick={() => setExpand(true)}
                  rootStyle={{ cursor: 'pointer' }}
                />
              )
            ) : null}
          </Box>
        )}
      </Box>

      {expand ? (
        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          {IntialList && IntialList.length
            ? IntialList.map((val, index) => (
                <Box
                  key={index}
                  sx={{
                    ...appointmentChips_style.labelSx,
                    alignItems: 'center',
                  }}
                >
                  {val?.labelShow ? val?.labelShow : val?.label}
                </Box>
              ))
            : null}
          {list.length - IntialList.length > 0 && (
            <Box
              sx={{ ...appointmentChips_style.pointsSx, alignItems: 'center' }}
            >
              {`+${list.length - IntialList.length}`}
            </Box>
          )}
          {!list.length && (
            <Box>
              {!isDoctor ? (
                <Typography
                  sx={appointmentChips_style.addDetails}
                  onClick={editOnclick}
                  id={heading.replace(/ /g, '')}
                >{` +Add ${heading}`}</Typography>
              ) : (
                <Typography sx={appointmentChips_style.noDataFound}>
                  {noDataFound}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {list && list.length ? (
            list.map((val, index) => (
              <>
                {' '}
                <Box key={index} as="span" sx={appointmentChips_style.labelSx}>
                  {val?.labelShow ? val?.labelShow : val?.label}
                </Box>
              </>
            ))
          ) : (
            <Typography sx={appointmentChips_style.noDataFound}>
              {noDataFound}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

AppointmentChips.propTypes = {
  heading: PropTypes.string,
  icon: PropTypes.object,
  list: PropTypes.array,
  editOnclick: PropTypes.func,
  noDataFound: PropTypes.string,
  editDownIconShown: PropTypes.bool,
  className: PropTypes.node,
  sx: PropTypes.object,
  isEdit: PropTypes.bool,
  isDoctor: PropTypes.bool,
};
