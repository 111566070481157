/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useSymptoms = create((set, get) => ({
  searchSymptom: null,
  quickSymptomsData: [],
  symptomsData: [],
  loading: null,
  error: null,
  get: async (search) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        search?.length > 0
          ? `/master-symptoms/get-symptoms?limit=10&searchString=${search}`
          : '/master-symptoms/get-symptoms?limit=10',
        'get',
      ]);
      const symptomsDataDummy = [];
      const quickSymptomsDataDummy = [];
      if (Array.isArray(data?.symptomData) && data?.symptomData?.length > 0) {
        data?.symptomData?.map((val) => {
          symptomsDataDummy.push({
            value: val?.id ?? '',
            labelShow:val?.symptoms ?? '',
            label: val?.symptoms ?? '',
          });
        });
      }

      if (
        Array.isArray(data?.quick_suggestion_data) &&
        data?.quick_suggestion_data?.length > 0
      ) {
        data?.quick_suggestion_data?.map((val) => {
          quickSymptomsDataDummy.push({
            value: val?.symptom_id ?? '',
            labelShow:val?.symptoms ?? '',
            label: val?.symptoms ?? '',
          });
        });
      }

      return set(() => ({
        loading: false,
        symptomsData: symptomsDataDummy,
        quickSymptomsData: quickSymptomsDataDummy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
