export const editProfile_style = {
  // rootSx: {
  //   display: 'grid',
  //   gap: 2,
  //   minHeight: '100vh',
  // },

  // resRootSx:{
  //   display: 'grid',
  //   gap: 2,
  //   minHeight: '100vh',
  //   zoom:"83%"
  // },

  boxRootSx: {
    px: 2.5,
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
