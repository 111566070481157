import { IllustrationRightIcon } from '@atoms';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { illustrationCard_style } from './style';

export function IllustrationCard(props) {
  const {
    healthStatus = '',
    healthStatusContent = '',
    icon = [],
    rootStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...illustrationCard_style.rootSx, ...rootStyle }}>
        <Box sx={illustrationCard_style.cardContentSx}>
          <Box sx={illustrationCard_style.imageWithTextSx}>
           {icon}
            <Box>
              <Typography variant="body2" sx={illustrationCard_style.titleSx}>
                {healthStatus}
              </Typography>
              <Typography variant="body2" sx={illustrationCard_style.healthStatusContentSx}>
                {healthStatusContent}
              </Typography>
            </Box>
          </Box>
          <Box sx={illustrationCard_style.headerSx}>
            <IllustrationRightIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

IllustrationCard.propTypes = {
  healthStatus: PropTypes.string,
  healthStatusContent: PropTypes.string,
  src: PropTypes.object,
  className: PropTypes.node,
  sx: PropTypes.object,
};
