import { ResetIllustration } from '@hc/ui/atoms';
import { ClinicalResetPassword } from '@hc/ui/components';
import { Box } from '@mui/material';
import React from 'react';
import { resetPassword_style } from './style';

export default function ResetPassword(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={resetPassword_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={resetPassword_style.imgContSx}>
        <ResetIllustration rootStyle={resetPassword_style.imgSx} />
      </Box>
      <ClinicalResetPassword />
    </Box>
  );
}
