import { getDateFormat } from '@hc/dayjs';
import reportEmptyState from '@hc/ui/assets/reportEmptyState.svg';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { EditIcon } from '../../../atoms';
import { PatientReportCard } from '../reports/patientReportCard';
import { SkeletonCard } from '../skeletonCard';
import { reportCardList_style } from './style';

function ReportCardList(props) {
  const {
    className = '',
    rootStyle = {},
    uploadReports = [],
    caseSummaryReports = [],
    tabIndex,
    handleChangeFns,
    reportState,
    IsBookAppoinmentReport,
    deleteClick,
    loading,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...reportCardList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={reportCardList_style.reportBoxSX}>
        {loading &&
          Array(5)
            .fill(1)
            .map((i) => <SkeletonCard key={i} isCardDetailsRequired={false} />)}
      </Box>
      <Box sx={loading ? { display: 'none' } : { display: 'block' }}>
        {(uploadReports !== null && uploadReports.length > 0) ||
        (caseSummaryReports !== null && caseSummaryReports.length > 0) ? (
          <>
            {tabIndex === '1' && (
              <Box py={2}>
                {(Array.isArray(uploadReports) && uploadReports?.length > 0
                  ? uploadReports
                  : []
                ).map((data, i) => (
                  <Box px={2.5} key={i} pb={2}>
                    <PatientReportCard
                      disabled={reportState?.uploadedReportId?.some(
                        (val) => val.id === data?.id,
                      )}
                      reportState={reportState}
                      tabIndex={tabIndex}
                      handleChange={handleChangeFns}
                      reportId={data?.id ?? ''}
                      showUploadedBy
                      showReportType
                      showReportName
                      icon={<EditIcon />}
                      showCheckBox={!!IsBookAppoinmentReport}
                      reportName={data.report_name}
                      reportType={data?.master_report_type?.report_type ?? ''}
                      uploadedBy="by me"
                      uploadedFor={
                        !data?.is_self_report
                          ? data?.user_family_member?.name
                          : data.user_profile?.name ||
                            data.user_profile?.first_name
                      }
                      relationShip={
                        data?.is_self_report
                          ? 'Self'
                          : data?.user_family_member?.master_relationship
                              ?.releationship
                      }
                      uploadedData={
                        Array.isArray(data?.upload?.upload_data)
                          ? data?.upload?.upload_data
                          : typeof data?.upload?.upload_data === 'object' &&
                            !Array.isArray(data?.upload?.upload_data) &&
                            data?.upload?.upload_data !== null
                          ? [data?.upload?.upload_data]
                          : []
                      }
                      uploadeddate={
                        data?.report_date
                          ? getDateFormat(data?.report_date, 'DD-MM-YYYY')
                          : ''
                      }
                      objectData={data}
                      reportDelete={deleteClick}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {tabIndex === '2' && (
              <Box py={2}>
                {(Array.isArray(caseSummaryReports) &&
                caseSummaryReports?.length > 0
                  ? caseSummaryReports
                  : []
                ).map((data, i) => (
                  <Box px={2.5} key={i} pb={2}>
                    <PatientReportCard
                      reportState={reportState}
                      tabIndex={tabIndex}
                      handleChange={handleChangeFns}
                      reportId={data?.id ?? ''}
                      showCheckBox={!!IsBookAppoinmentReport}
                      icon={<EditIcon />}
                      doctorName={`Dr. ${
                        data?.assigned_doctor_profile?.first_name ?? ''
                      }`}
                      speciality={
                        Array.isArray(
                          data?.assigned_doctor_profile?.doctor_specialities,
                        ) &&
                        data?.assigned_doctor_profile?.doctor_specialities
                          ?.length > 0
                          ? data?.assigned_doctor_profile?.doctor_specialities
                          : []
                      }
                      relationShip={
                        data?.is_self_booking
                          ? 'Self'
                          : data?.appointment_members?.[0]?.user_family_member
                              ?.name
                          ? data?.appointment_members?.[0]?.user_family_member
                              ?.name
                          : 'Others'
                      }
                      uploadedFor={
                        data?.is_self_booking
                          ? data.user_profile?.name ||
                            data.user_profile?.first_name
                          : data?.user_profile?.master_relationships
                              ?.user_family_members?.[0]?.name
                      }
                      caseSummary
                      uploadedData={data?.visit_summaries ?? []}
                      uploadeddate={getDateFormat(
                        data?.visit_summaries?.[0]?.updatedAt,
                        'DD-MM-YYYY',
                      )}
                      visit_report_url={
                        data?.visit_summaries?.[0]?.visit_report_url ?? ''
                      }
                      objectData={data}
                      reportDelete={deleteClick}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        ) : null}

        {(tabIndex === '1' &&
          uploadReports !== null &&
          uploadReports?.length === 0) ||
        (tabIndex === '2' &&
          caseSummaryReports !== null &&
          caseSummaryReports?.length === 0) ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item mt={9}>
              <Box>
                <img src={reportEmptyState} alt="" />
              </Box>
            </Grid>
            <Grid item mt={1.6}>
              <Typography fontSize={16} fontWeight={600}>
                No Records Found
              </Typography>
            </Grid>
            <Grid item mt={1}>
              <Box width={250} textAlign="center">
                {tabIndex === '1' ? (
                  <Typography fontSize={12} color="#888888">
                    Records will appear once you upload your or family members
                    documents
                  </Typography>
                ) : (
                  <Typography fontSize={12} color="#888888">
                    Records will appear once your doctor submits your case
                    summary
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}

ReportCardList.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ReportCardList };
