// import Chat from '@assets/Chat.png';
import MobileInHand from '@assets/MobileInHand.png';
// import OnlineDoctor from '@assets/OnlineDoctor.png';
// import Selfhealth from '@assets/Selfhealth.png';
import TrackingWellness from '@assets/TrackingWellness.png';
import VitalMonitoring from '@assets/VitalMonitoring.png';
import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import { ArrowwithCircle, Button, HealthCircleIcon } from '@hc/ui/atoms';
import { ClinicalLandingPageSkeleton } from '@hc/ui/components/clinical/clinicalLandingPageSkeleton';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { clinical_landing_page_style } from './style';

export default function ClinicalLandingPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const media = useMediaQuery('(max-width:385px)');
  const [isViewAll, setIsViewAll] = useState(false);

  const { awarenessState, getAwarenessData, updateAwarenessState, loading } =
    useAwareness(
      (state) => ({
        updateAwarenessState: state.updateAwarenessState,
        awarenessState: state.awarenessState,
        getAwarenessData: state.getAwarenessData,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { awarenessData } = awarenessState;
  const { high_priority_awareness } = awarenessData;
  const { awareness } = awarenessData;

  const benifits = [
    { name: 'Vital Monitoring', img: VitalMonitoring, id: 0 },
    { name: 'Tracking Your Wellness', img: TrackingWellness, id: 1 },
    // { name: 'Online Doctor Consultation', img: OnlineDoctor, id: 2},
    // { name: 'Chat with healthbot', img: Chat, id: 3 },
    // { name: 'Self health Check-Ups', img: Selfhealth, id: 4 },
  ];

  const benefitsNavigation = (id) => {
    navigate(clinicalRoutes?.benefitsSlider, { state: { benifits: id } });
  };

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getAwarenessData'],
    });
    await getAwarenessData();
  };

  const onLoginClick = () => {
    navigate(clinicalRoutes?.login);
  };

  const onViewMoreClick = () => {
    setIsViewAll(!isViewAll);
  };

  // GET AWARENESS LIST DATA
  useEffect(() => {
    getInitialData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  // const settingForCatg = {
  //   dots: false,
  //   speed: 500,
  //   draggable: true,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   arrows: false,
  // };

  return (
    <Box>
      {loading ? (
        <Box p={2}>
          <ClinicalLandingPageSkeleton />
        </Box>
      ) : (
        <Box sx={clinical_landing_page_style.rootSx}>
          <Box sx={{ pt: 2 }}>
            {/* EmptyState Design */}
            <Box pl={2} mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <HealthCircleIcon rootStyle={{ width: '34px', height: '34px' }} />
              <Typography
                fontWeight={600}
                color="#007965"
                ml={1}
                fontSize="20px"
              >
                Health Circles
              </Typography>
            </Box>
            <Typography
              pl={2}
              my={1}
              mt={2.5}
              color="#0E1824"
              sx={{ fontSize: 16, fontWeight: 600 }}
            >
              Topic in Trends
            </Typography>
            <Typography
              ml={2}
              color="#888888"
              sx={{ fontSize: 12, fontWeight: 500 }}
            >
              What aspects of awareness are you curious about?
            </Typography>

            <Box mt={1}>
              <Slider {...settings}>
                {Array.isArray(high_priority_awareness) &&
                  high_priority_awareness?.length > 0 &&
                  high_priority_awareness.map((val, i) => (
                    <Box
                      sx={{ ml: -0.5 }}
                      key={i}
                      onClick={() => {
                        if (data?.user_id) {
                          navigate(clinicalRoutes?.awarenessCategory);
                        } else {
                          navigate('/awarenesscategory');
                        }
                        updateAwarenessState('awareness_id', val?.awareness_id);
                        updateAwarenessState(
                          'awareness_name',
                          val?.awareness_name,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: '10px',
                        }}
                      >
                        <img
                          src={val?.high_priority_awareness_image}
                          loading="lazy"
                          alt="Awarenesss"
                          style={{
                            borderRadius: '10px',
                            width: media ? '140px' : '190px',
                            height: '130px',
                            objectFit: 'fill',
                            marginBottom: '16px',
                            marginLeft: '22px',
                            alignSelf: 'center',
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          ...clinical_landing_page_style.titleSx,
                          marginTop: '-160px',
                          ml: 2.5,
                        }}
                      >
                        {val?.awareness_name}{' '}
                      </Typography>
                    </Box>
                  ))}
              </Slider>
            </Box>
            <Box sx={{ px: 2, pb: 2, mt: -1 }}>
              <Grid
                container
                sx={{
                  background: 'linear-gradient(to bottom, #2868BE,#6837C9)',
                  borderRadius: '8px',
                }}
                mt={1}
              >
                <Grid item xs={8} p={1}>
                  <Box>
                    <Typography
                      pl={2}
                      mt={1}
                      color="#fff"
                      sx={{ fontSize: 14, fontWeight: 600 }}
                    >
                      Elevate Your Health with Us
                    </Typography>
                    <Typography
                      pl={2}
                      py={0.5}
                      color="#fff"
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      We welcome you to our care!
                    </Typography>
                    <Box pl={1.5} pt={2}>
                      <Button
                        buttonStyle={{
                          width: '142px',
                          fontSize: '14px',
                          border: '1px solid',
                          borderColor: '#fff',
                          color: '#0E1824',
                          borderRadius: '8px',
                          bgcolor: '#fff',
                          '&:hover': {
                            bgcolor: '#fff',
                          },
                          padding: '6px 10px',
                          textTransform: 'capitalize',
                        }}
                        onClick={onLoginClick}
                      >
                        Get started
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item display="flex" justifyContent="end" xs={4} ml={-1.5}>
                  <img
                    src={MobileInHand}
                    alt="mobile"
                    width="150px"
                    style={{ display: 'block' }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography pl={2} mt={0.4} color="#0E1824" fontWeight={600}>
                Browse Topics by Category
              </Typography>

              {Array.isArray(awareness) && awareness?.length > 2 && (
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onViewMoreClick()}
                >
                  <Typography
                    id={isViewAll ? 'Hide' : 'View'}
                    color={isViewAll ? '#f00' : '#007965'}
                    pr={2}
                    mt={0.8}
                    fontSize={14}
                    fontWeight={600}
                  >
                    {isViewAll ? 'Show less' : 'Show more'}
                  </Typography>
                </Box>
              )}
            </Box>

            {!isViewAll && (
              <Box mt={1} width={400}>
                <Grid container>
                  {/* <Slider {...settingForCatg}> */}
                  {Array.isArray(awareness) && awareness?.length > 2
                    ? awareness?.slice(0, 2)?.map((val, i) => (
                        <Grid
                          key={i}
                          item
                          xs={6}
                          id={`awareCatog_${i}`}
                          onClick={() => {
                            if (data?.user_id) {
                              navigate(clinicalRoutes?.awarenessCategory);
                            } else {
                              navigate('/awarenesscategory');
                            }
                            updateAwarenessState(
                              'awareness_id',
                              val?.awareness_id,
                            );
                            updateAwarenessState(
                              'awareness_name',
                              val?.awareness_name,
                            );
                          }}
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '10px',
                            }}
                          >
                            <img
                              src={val?.awareness_image}
                              loading="lazy"
                              alt="Awarenesss"
                              style={{
                                borderRadius: '10px',
                                width: media ? '140px' : '190px',
                                height: '130px',
                                objectFit: 'fill',
                                marginBottom: '16px',
                                marginLeft: '22px',
                                alignSelf: 'center',
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              ...clinical_landing_page_style.titleSx,
                              marginTop: '-170px',
                              ml: 1,
                            }}
                          >
                            {val?.awareness_name}{' '}
                          </Typography>
                        </Grid>
                      ))
                    : Array.isArray(awareness) && awareness?.length < 3
                    ? awareness?.map((val, i) => (
                        <Grid
                          key={i}
                          item
                          xs={6}
                          id={`awareCatog_${i}`}
                          onClick={() => {
                            if (data?.user_id) {
                              navigate(clinicalRoutes?.awarenessCategory);
                            } else {
                              navigate('/awarenesscategory');
                            }
                            updateAwarenessState(
                              'awareness_id',
                              val?.awareness_id,
                            );
                            updateAwarenessState(
                              'awareness_name',
                              val?.awareness_name,
                            );
                          }}
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '10px',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={val?.awareness_image}
                              loading="lazy"
                              alt="Awarenesss"
                              style={{
                                borderRadius: '10px',
                                width: media ? '140px' : '190px',
                                height: '130px',
                                objectFit: 'fill',
                                marginBottom: '16px',
                                marginLeft: '22px',
                                alignSelf: 'center',
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              ...clinical_landing_page_style.titleSx,
                              marginTop: '-170px',
                              ml: 1,
                            }}
                          >
                            {val?.awareness_name}{' '}
                          </Typography>
                        </Grid>
                      ))
                    : null}
                  {/* </Slider> */}
                </Grid>
              </Box>
            )}

            {isViewAll && !loading && (
              <Box>
                <Grid container mt={2} width={400}>
                  {Array.isArray(awareness) && awareness?.length > 0 ? (
                    awareness.map((val, i) => (
                      <Grid
                        key={i}
                        item
                        xs={6}
                        onClick={() => {
                          if (data?.user_id) {
                            navigate(clinicalRoutes?.awarenessCategory);
                          } else {
                            navigate('/awarenesscategory');
                          }
                          updateAwarenessState(
                            'awareness_id',
                            val?.awareness_id,
                          );
                          updateAwarenessState(
                            'awareness_name',
                            val?.awareness_name,
                          );
                        }}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={val?.awareness_image}
                            loading="lazy"
                            alt="Awarenesss"
                            style={{
                              borderRadius: '10px',
                              width: media ? '140px' : '190px',
                              height: '130px',
                              objectFit: 'fill',
                              marginBottom: '16px',
                              marginLeft: '22px',
                              alignSelf: 'center',
                            }}
                          />
                          <Typography
                            sx={{
                              ...clinical_landing_page_style.titleSx,
                              marginTop: '-170px',
                              ml: 1,
                            }}
                          >
                            {val?.awareness_name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      textAlign="center"
                      py={15}
                      color="#616161"
                      fontWeight={600}
                    >
                      No Awareness Data Found
                    </Typography>
                  )}
                </Grid>
              </Box>
            )}
          </Box>
          <Box px={2} pb={2}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              Explore Other Benefits of Healthcircles
            </Typography>

            <Box
              sx={{
                display: 'inline-flex',
                gap: 2,
                overflow: 'scroll',
                width: '400px',
                my: 2,
              }}
            >
              {benifits.map((value, key) => (
                <Box
                  id={`benifits_${value?.id}`}
                  key={key}
                  onClick={() => benefitsNavigation(value.id)}
                  sx={{
                    borderRadius: '8px',
                    background:
                      key % 2 === 0
                        ? `url(${VitalMonitoring})`
                        : `url(${TrackingWellness})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                  }}
                  mr={0.2}
                >
                  <Box pl={2} pt={2} sx={{ width: '200px', height: '120px' }}>
                    {' '}
                    <Typography
                      sx={{ fontSize: '14px', fontWeight: 600, color: '#fff' }}
                    >
                      {value.name}
                    </Typography>{' '}
                    <Box pt={1}>
                      <ArrowwithCircle />
                    </Box>
                  </Box>
                  <Box>
                    {/* <img
                      src={value.img}
                      alt="mobile"
                      width="120px"
                      height="120px"
                      style={{
                        display: 'block',
                        objectFit: 'cover',
                        objectPosition: 'right',
                      }}
                    /> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
