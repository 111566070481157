export const appointmentCard_style = {
  rootSx: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
  },

  mainSx: {
    borderRadius: '14px',
    p: 1.5,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1.5px dashed',
    borderColor: 'grey.A500',
    position: 'relative',
    opacity: 0.8,
    boxShadow: '0px 7px 15px 0px #00000014',
  },

  dividerSx: {
    my: 1.5,
    borderStyle: 'dashed',
    borderColor: 'grey.A500',
    borderBottomWidth: '1px',
    opacity: 0.6,
  },

  arrow1Sx: {
    '&::after': {
      content: '""',
      borderBottom: '1.5px dashed',
      borderRight: '1.5px dashed',
      borderColor: 'grey.A500',
      backgroundColor: 'common.white',
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: '60px',
      left: '-3%',
      borderRadius: '15%',
      transform: 'rotate(-45deg)',
    },
  },
  arrow2Sx: {
    '&::after': {
      content: '""',
      borderBottom: '1.5px dashed',
      borderRight: '1.5px dashed',
      borderColor: 'grey.A500',
      backgroundColor: 'common.white',
      position: 'absolute',
      top: '60px',
      width: '20px',
      height: '20px',
      transform: 'rotate(133deg)',
      borderRadius: '15%',
      right: '-3%',
    },
  },
};
