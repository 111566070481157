export const appointmentShedule_style = {
  rootSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  generalIconSx: {
    backgroundColor: 'grey.A300',
    width: '28px',
    height: '28px',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  videoIconSx: {
    backgroundColor: 'grey.A300',
    width: '28px',
    height: '28px',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataTextSx: {
    fontSize: '12px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  dayTextSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'text.primary',
  },
};
