export const addEditFamilyMember_style = {

  PersonalTextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    pb:0.5
  },


  vitalsTextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
  },
  

  endAdornmentTextSx: {
    fontSize: '12px',
    color: 'text.disabled',
    opacity: 1,
  },
  inputWidthSx: {
    width: '100%',
    marginTop: '4px',
  },

  // inputFieldSx: {
  //   marginTop: '4px',
  // },
  rootVitalSx: {
    mt: 3,
  },
  bmiCalculationGroupSx: {
    border: '1px solid',
    borderColor: 'orangeTints.400',
    backgroundColor: 'orangeTints.200',
    borderRadius: '4px',
    padding: '10px',
    mt: 2,
  },

  bmiCalculationValueSx: {
    fontSize: 12,
    color: 'text.label',
    fontWeight: 100,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  }
};
