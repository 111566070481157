export const AwarenessCategory_style = {
  subHeadSx: {
    fontSize: '16px',
    color: 'text.primary',
    p: 5.5,
    fontWeight: '25px',
  },
  boxRootSx: {
    backgroundColor: '#fff',
    p: 1,
  },
  titleSx: {
    fontSize: '12px',
    p: 3,
    fontWeight: '600',
    textShadow: '0px 1px 6px #0000003D',
    color: '#0E1824',
  },
  navSx: {},
  backgroundImg: {},
};
