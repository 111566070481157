export const vitalMonitoringCard_style = {
  rootSx: {
    p: 1.5,
    borderRadius: 2,
    opacity: 1,
    bgcolor: '#fff',
    cursor: 'pointer',
  },
  buttonSx: {
    bgcolor: '#DCF5F1',
    color: 'primary.main',
    boxShadow: 0,
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 0,
      bgcolor: '#DCF5F1',
      color: 'primary.main',
    },
  },
};
