export const chatBotMessageBox_style = {
  textRootSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      borderRadius: '8px',
      // borderColor: 'border.hover',
      '& fieldset': {
        borderColor: 'border.hover',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        // borderColor: '#0cae95',
        borderColor: '#aeefe5',

        color: '#0E1824',
        borderRadius: '8px',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
      // borderColor: 'border.hover',
    },
    '& input::placeholder': {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
};
