import { CircularProgressWithLabel } from '@atoms/circularProgessBar';
import { timeConvertor } from '@hc/dayjs';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import DefaultImg from '@assets/DefaultImg.svg';
import MindImg from '@assets/MindImg.svg';
import BodyImg from '@assets/BodyImg.svg';
import FoodImg from '@assets/FoodImg.svg';
import { activityCard_style } from './style';

export function ActivityCard(props) {
  const {
    name = '',
    image = {},
    time = '',
    mbfStatus = '',
    timeShow = false,
    specificTime = false,
    specificFromTime = '',
    specificToTime = '',
    selected,
    setSelected,
    progressValue,
    className = '',
    index,
    ...rest
  } = props;

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={activityCard_style.cardSx}>
        <Grid container>
          <Grid item xs={2.5}>
            <img
              src={
                image && image.includes('http')
                  ? image
                  : mbfStatus === 'Mind'
                  ? MindImg
                  : mbfStatus === 'Body'
                  ? BodyImg
                  : mbfStatus === 'Food'
                  ? FoodImg
                  : DefaultImg
              }
              style={{
                ...activityCard_style.imgSx,
                objectFit:
                  image && image.includes('http') ? 'cover' : 'contain',
              }}
              alt=""
            />
          </Grid>
          <Grid item xs={7.5}>
            <Box
              mt={1.8}
              alignSelf="center"
              sx={activityCard_style.textWithStatusSx}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={activityCard_style.activityStateSx}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {specificTime ? (
                    <Typography sx={activityCard_style.timeWithDaySx}>
                      {`${time} (${timeConvertor(
                        specificFromTime,
                      )} - ${timeConvertor(specificToTime)})`}
                      &nbsp;
                      <span>
                        <RiCheckboxBlankCircleFill
                          style={{ fontSize: '6px', ml: 1, mr: 1 }}
                        />
                        &nbsp;
                        {mbfStatus}
                      </span>
                    </Typography>
                  ) : timeShow ? (
                    <Typography sx={activityCard_style.timeWithDaySx}>
                      {time}&nbsp;
                      <span>
                        <RiCheckboxBlankCircleFill
                          style={{ fontSize: '6px', ml: 1, mr: 1 }}
                        />
                        &nbsp;
                        {mbfStatus}
                      </span>
                    </Typography>
                  ) : (
                    <Typography sx={activityCard_style.timeWithDaySx}>
                      <span>
                        &nbsp;
                        {mbfStatus}
                      </span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={activityCard_style.iconWithCircular}>
              {/* {icon} */}
              <CircularProgressWithLabel
                value={progressValue}
                size={35}
                thickness={4}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
ActivityCard.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  mbfStatus: PropTypes.string,
  image: PropTypes.object,
  timeShow: PropTypes.bool,
  specificTime: PropTypes.bool,
  specificFromTime: PropTypes.string,
  specificToTime: PropTypes.string,
  selected: PropTypes.string,
  setSelected: PropTypes.string,
  progressValue: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.string,
};
