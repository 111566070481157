export const mbfGetStartedCard_style = {
  rootSx: {
    pt: 1,
    pb: 2,
    backgroundColor: '#F4F2FF',
    position: 'relative',
    zIndex: 1,
    height: '262px',
    overflow: 'hidden',
    borderRadius: '6px',
  },
};
