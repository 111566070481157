import { useAppointment, usePrescription } from '@hc/store';
import { useProfile } from '@hc/store/clinical/profile';
import { CancelAppointment } from '@hc/ui/components/clinical/appointmentBooking/cancelAppointment';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export function CancelAppointmentHome({
  setCancelDrawerOpen,
  cancelDrawerOpen,
}) {
  const { get } = useProfile(
    (state) => ({
      profile: state.profile,
      get: state.get,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    appointmentState,
    getPastUpcomingAppointmentList,
    getFollowupReferralAppointmentList,
    cancelAppointment,
    appointmentDrawerDataClear,
    updateAppointmentsData,
    appointmentStateUpdate,
    // loading,
  } = useAppointment(
    (state) => ({
      appointmentState: state.appointmentState,
      getPastUpcomingAppointmentList: state.getPastUpcomingAppointmentList,
      getFollowupReferralAppointmentList:
        state.getFollowupReferralAppointmentList,
      cancelAppointment: state.cancelAppointment,
      // getActivePrescription: state.getActivePrescription,
      appointmentDrawerDataClear: state.appointmentDrawerDataClear,
      updateAppointmentsData: state.updateAppointmentsData,
      appointmentStateUpdate: state.appointmentStateUpdate,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { getAllPrescriptionsData } = usePrescription(
    (state) => ({
      getAllPrescriptionsData: state.getAllPrescriptionsData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const handleDrawerCancel = (val) => {
    // APPOINTMENT DRAWER DATA CLEAR
    appointmentDrawerDataClear();
    setCancelDrawerOpen(val);
  };

  const getInitialData = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    await get();
    await getPastUpcomingAppointmentList(data?.user_id, true);
    await getFollowupReferralAppointmentList(data?.user_id);
    getAllPrescriptionsData();
  };

  const cancelAppointmentFnc = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    const payload = {
      appointed_doctor_id: appointmentState?.appointed_doctor_id ?? '',
      appointment_id: appointmentState?.appointment_id ?? '',
      cancellationReason: appointmentState?.cancellationReason ?? '',
      event_id: appointmentState?.event_id ?? '',
      canceledBy: data?.user_id,
    };
    // CANCEL APPOINTMENT SERVICE CALL
    const response = await cancelAppointment(payload);
    if (response?.data?.status?.code === 200) {
      toast.success(
        response?.data?.status?.message ?? `Appointment cancelled successfully.`
      );
      updateAppointmentsData();
      handleDrawerCancel(false);
      getInitialData();
    } else {
      return toast.error(
        response?.data?.status?.message ??
          response?.data?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  };

  const ReasonForCancellation = (event) => {
    // APPOINTMENT STATE UPDATE
    appointmentStateUpdate('cancellationReason', event?.target.value);
  };

  return (
    <CancelAppointment
      onNext={() => cancelAppointmentFnc(false)}
      onBack={() => handleDrawerCancel(false)}
      setDrawerOpen={handleDrawerCancel}
      drawerOpen={cancelDrawerOpen}
      ReasonForCancellation={ReasonForCancellation}
      value={appointmentState?.cancellationReason}
    />
  );
}
