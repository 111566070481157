import MbfHomeBanner from '@assets/mbfHomeBanner.svg';

export const mbfHomeBanner_style = {
  mbfHomeBannerSx: {
    backgroundColor: 'purple.600',
    borderRadius: '10px',
    backgroundImage: `url(${MbfHomeBanner})`,
    height: '100%',
    width: 'auto',
  },
  descSx: {
    fontWeight: 700,
    fontSize: '16px',
  },
  buttonSx: {
    textTransform: 'none',
    fontSize: '12px',
    backgroundColor: 'mbf.main',
    '&:hover': {
      bgcolor: 'mbf.main',
    },
  },
};
