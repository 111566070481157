import { useFamilyMember } from '@hc/store';
import { FamilyMemberCard } from '@hc/ui/components/clinical/familyProfile/familyMemberCard';
import { getFirstLetterOfName } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import React from 'react';
import isEqual from 'react-fast-compare';
import { myFamilyScreen_style } from './style';

export function FamilyMemberSelect(props) {
  const { selectFamilyMember, setSelectFamilyMember } = props;

  const { familyMembers } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  return (
    <Box>
      {Array.isArray(familyMembers) && familyMembers?.length > 0
        ? familyMembers.map((val, index) => (
            <FamilyMemberCard
              key={index}
              value={val}
              name={val?.name}
              relationship={val?.relationship_data?.releationship}
              gender={val?.gender}
              age={val?.age}
              profileName={getFirstLetterOfName(val?.name)}
              rootStyle={{
                ...myFamilyScreen_style.familySX,
                borderColor:
                  selectFamilyMember >= 0 && selectFamilyMember === index
                    ? '#007965'
                    : '#E2E2E2',
              }}
              selectable
              onSelected={() => setSelectFamilyMember(index)}
              selected={
                selectFamilyMember >= 0 ? selectFamilyMember === index : null
              }
            />
          ))
        : null}
    </Box>
  );
}
