/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ALittle, NotABit, Totally } from '../../../../atoms';
import { foodActivityUpdate_style } from './style';

function FoodActivityUpdate(props) {
  const {
    // onClickFn,
    onChange,
    value,
    className = '',
    rootStyle = {},
    ...rest
  } = props;

  const options = [
    {
      icon: <NotABit />,
      name: 'Not A bit',
      value: 'not_a_bit',
      percentage: 0,
    },
    {
      icon: <ALittle />,
      name: 'A little',
      value: 'a_little',
      percentage: 50,
    },
    {
      icon: <Totally />,
      name: 'Totally!',
      value: 'totally',
      percentage: 100,
    },
  ];

  const [active, setActive] = useState(value);
  const onClickFn = (percentage) => {
    setActive(percentage);
    onChange(percentage);
  };

  useEffect(() => {
    setActive(value);
  }, []);

  return (
    <Box
      sx={{
        ...foodActivityUpdate_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Typography
        sx={{ fontSize: '14px', color: 'text.primary', fontWeight: 500 }}
      >
        Did you complete this activity?
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
        {options?.map((val,i) => (
          <Box
          key={i}
            sx={{
              display: 'flex',
              gap: 1,
              ...(val.percentage === active
                ? foodActivityUpdate_style.activeSx
                : foodActivityUpdate_style.radioSx),
            }}
            onClick={() => onClickFn(val?.percentage)}
          >
            <Button
              sx={{
                ...(val.percentage === active
                  ? foodActivityUpdate_style.buttonactiveSx
                  : foodActivityUpdate_style.buttoninactiveSx),
              }}
            >
              {val?.icon}
              {val?.name && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    pl: 0.6,
                    ...(val.percentage === active
                      ? foodActivityUpdate_style.nameActiveSx
                      : foodActivityUpdate_style.nameInactiveSx),
                  }}
                >
                  {val.name}
                </Typography>
              )}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

FoodActivityUpdate.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  options: PropTypes.array,
  onClickFn: PropTypes.func,
  value: PropTypes.bool,
};

export { FoodActivityUpdate };

