import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store';
import { UseSeekHelp } from '@hc/store/clinical/seekHelp';
import {
  Button,
  CustomTextArea,
  Input,
  UploadCloseIcon,
  UploadIcon
} from '@hc/ui/atoms';
import { PatientReportUploadCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { handleUploadFile } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { seekHelp_style } from './style';

export default function SeekHelp() {
  const [sendBtn, setsendBtn] = useState(false);
  const navigate = useNavigate();

  // ----------- STORE ---------------------
  const {
    upsertSeekHelp,
    changeState,
    seekHelpState,
    isValid,
    seekHelpDelete,
    clearForm,
    attachmentURLList,
    loading,
  } = UseSeekHelp(
    (state) => ({
      upsertSeekHelp: state.upsertSeekHelp,
      changeState: state.changeState,
      seekHelpState: state.seekHelpState,
      loading: state.loading,
      isValid: state.isValid,
      seekHelpDelete: state.seekHelpDelete,
      attachmentURLList: state.attachmentURLList,
      clearForm: state.clearForm,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { emailId, description, attachmentURL, error } = seekHelpState;
  const { profile } = useProfile(
    (state) => ({
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const handleChange = (key, value) => {
    changeState(key, value);
    if (sendBtn) isValid();
  };

  const handleActivityPicUpload = async (e) => {
    const [res] = await handleUploadFile(e.target.files);
    if (res?.message === 'Upload Successfully!') {
      attachmentURLList(res);
    }
  };

  const handleSubmit = async () => {
    setsendBtn(true);
    const valid = isValid();
    if (valid) {
      const resCode = await upsertSeekHelp(profile);

      if (resCode === 200) {
        navigate(clinicalRoutes.profileView);
      }
    }
  };
  useEffect(() => {
    clearForm();
    changeState('emailId', profile?.email_id ?? '');
  }, []);

  return (
    <Screenlayout
      title="Seek Help"
      backRequired
      childrenStyle={seekHelp_style.childSx}
      notshowFooter={false}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            sx={{ borderRadius: '10px' }}
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Submit
          </Button>
        </Box>
      }
    >
      <Box sx={seekHelp_style.boxRootSx}>
        <Box pt={2}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, pb: 2 }}>
            Email ID
          </Typography>
          <Input
            placeholder="Type something"
            // textFieldStyle={seekHelp_style.customtextSx}
            value={emailId}
            onChange={(e) => handleChange('emailId', e.target.value)}
            id='email'
          />
          <Typography
            sx={seekHelp_style.errorText}
            display={{
              display: error.emailId ? 'block' : 'none',
            }}
          >
            {error.emailId}
          </Typography>
        </Box>
        <Box py={2}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, pb: 2 }}>
            Description
          </Typography>
          <CustomTextArea
          data_testId='description'
            placeholder="Type something"
            textFieldStyle={seekHelp_style.customtextSx}
            value={description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
          <Typography
            sx={seekHelp_style.errorText}
            display={{
              display: error.description ? 'block' : 'none',
            }}
          >
            {error.description}
          </Typography>
        </Box>
        <Box>
          {attachmentURL.length === 0 && (
            <Box sx={seekHelp_style.activeUploadFileSx}>
              <Box component="label" sx={seekHelp_style.uploadIconSx}>
                <UploadIcon />
                <input
                  hidden
                  name="img"
                  accept="image/*"
                  multiple="multiple"
                  type="file"
                  id="uplodebtnv"
                  onChange={handleActivityPicUpload}
                />
              </Box>
              <Box>
                <Typography sx={seekHelp_style.browseTextSx}>
                  Click to browse and upload
                </Typography>
              </Box>
            </Box>
          )}
          {attachmentURL && (
            <Box mb={1}>
              {Array.isArray(attachmentURL) &&
                attachmentURL.length > 0 &&
                attachmentURL.map((val, i) => (
                  <PatientReportUploadCard
                    key={i}
                    showReportName
                    icon={<UploadCloseIcon />}
                    isUploadCard
                    reportName={val?.filename ?? ''}
                    uploadedFor={val?.size ?? ''}
                    uploadedData={[{ file: `${val?.downloadUrl ?? ''}` }]}
                    IconOnClick={() => seekHelpDelete(i)}
                  />
                ))}
            </Box>
          )}
          {attachmentURL && attachmentURL.length > 0 && (
            <Box component="label" sx={{ cursor: 'pointer' }}>
              <Typography pt={2} sx={seekHelp_style.addMoreButtonSx}>
                + Add More
              </Typography>
              <input
                hidden
                name="img"
                accept="image/*"
                multiple
                type="file"
                onChange={handleActivityPicUpload}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Screenlayout>
  );
}
