/* eslint-disable react/style-prop-object */
import { Stethescope } from '@atoms/icons';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FreeConsultationCard_style } from './style';

export function FreeConsultationCard(props) {
  const {
    className = '',
    rootStyle = {},
    header = '',
    date = '',
    totalconsultation = '',
    freecosultation = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...FreeConsultationCard_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...FreeConsultationCard_style.rootBoxSx }}>
        <Grid container>
          <Grid item xs={8} sm={8} md={9.5} display="flex">
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#e2f7f4',
                  borderRadius: '50%',
                  padding: '12px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <Stethescope />
              </Box>
            </Box>
            <Box sx={{ p: 1.4 }}>
              <Box>
                <Typography
                  fontWeight={600}
                  sx={{ color: 'text.secondary', fontSize: '12px' }}
                >
                  {header}
                </Typography>
              </Box>
              <Box sx={{ ...FreeConsultationCard_style.dateparentSx }}>
                <Typography sx={{ ...FreeConsultationCard_style.dateSx }}>
                  Valid till{' '}
                  <span style={{ fontSize: '10px', color: '#007965' }}>
                    {date}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={2.5} display="flex">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Typography>
                <span
                  style={{
                    color: 'text.primary',
                    fontSize: '23px',
                    fontWeight: 600,
                  }}
                >
                  {freecosultation}
                </span>{' '}
                <span
                  style={{
                    color: '#888888',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  out of
                </span>{' '}
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#5C6266',
                  }}
                >
                  {totalconsultation}
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

FreeConsultationCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  header: PropTypes.string,
  date: PropTypes.string,
  totalconsultation: PropTypes.string,
  freecosultation: PropTypes.string,
};
