/* eslint-disable no-lonely-if */
import { envConfig } from '@hc/config';
import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import { AwrShareIcon, BackIcon, Loader } from '@hc/ui/atoms';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import Stories from 'react-insta-stories';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { constructStoryData, constructVideoStoryData } from './sliderUtils';
import { awarenessSlider_Style } from './style';

export default function AwarenessSlider() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const [searchParams] = useSearchParams();
  const cate_id = searchParams?.get('cate_id');
  const awareness_id = searchParams?.get('awareness_id');

  // const val = constructStoryData(notesData);

  const { awarenessState, getNotesDataByCatergoryId, updateAwarenessState } =
    useAwareness(
      (state) => ({
        updateAwarenessState: state.updateAwarenessState,
        awarenessState: state.awarenessState,
        getNotesDataByCatergoryId: state.getNotesDataByCatergoryId,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { notesData, categoryObjectData } = awarenessState;

  const getInitialData = async () => {
    if (data?.user_id) {
      await getNotesDataByCatergoryId(
        awarenessState?.cate_id ? awarenessState?.cate_id : cate_id,
      );
    } else {
      if (awarenessState?.cate_id) {
        await getNotesDataByCatergoryId(awarenessState?.cate_id);
      } else {
        await getNotesDataByCatergoryId(
          awarenessState?.cate_id ? awarenessState?.cate_id : cate_id,
        );
        updateAwarenessState(
          'awareness_id',
          categoryObjectData.awareness_id
            ? categoryObjectData.awareness_id
            : awareness_id,
        );
      }
    }
  };

  const onAllStoriesEndFnc = () => {
    if (data?.user_id) {
      navigate(clinicalRoutes?.awarenessCategory);
    } else {
      navigate('/awarenesscategory');
    }

    updateAwarenessState('awareness_id', categoryObjectData.awareness_id);
    updateAwarenessState('awareness_name', categoryObjectData.awareness_name);
  };

  const onBackNavigate = () => {
    if (data?.user_id) {
      navigate(-1);
    } else {
      navigate('/awarenesscategory');
    }
  };

  // GET NOTES LIST DATA
  useEffect(() => {
    if (awarenessState?.cate_id || cate_id) {
      getInitialData();
    } else {
      if (data?.user_id) {
        navigate(clinicalRoutes?.landingPage);
      } else {
        navigate(clinicalRoutes?.home);
      }
    }
  }, []);

  return (
    <Box sx={awarenessSlider_Style.rootSx}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1000,
          top: 9,
          ml: '3px',
          cursor: 'pointer',
        }}
        display="flex"
        height="50px"
      >
        <IconButton onClick={() => onBackNavigate()}>
          <BackIcon id='backBtn' rootStyle={{ color: '#000' }} />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            zIndex: 1000,
            position: 'fixed',
            top: 9,
            display: 'flex',
            justifyContent: 'center',
          }}
          display="flex"
          height="50px"
        >
          <Box alignSelf="center">
            {/* {categoryObjectData?.note_type === 'content' && data?.user_id?.length > 0 && } */}
            <Typography fontWeight={600} color="#fff">
              {categoryObjectData?.awareness_category_name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="end">
        <Box
          sx={{
            zIndex: 1000,
            position: 'fixed',
            top: 9,
            display: 'flex',
            justifyContent: 'end',
          }}
          display="flex"
          height="50px"
        >
          <RWebShare
            data={{
              text: 'Awareness share - ',
              url: `${envConfig?.awareness_share_domain}awarenesscategory/awarenessslide?cate_id=${awarenessState?.cate_id}&awareness_id=${awarenessState?.awareness_id}`,
              title: 'Share to',
            }}
          >
            <IconButton>
              <AwrShareIcon id='shareIcon' rootStyle={{ color: '#000' }} />
            </IconButton>
          </RWebShare>
        </Box>
      </Box>

      {Array.isArray(notesData) && notesData?.length > 0 ? (
        <Stories
          loop
          keyboardNavigation
          defaultInterval={8000}
          // isPaused
          stories={
            categoryObjectData?.note_type === 'content'
              ? constructStoryData(notesData)
              : categoryObjectData?.note_type === 'video'
              ? constructVideoStoryData(notesData)
              : []
          }
          width="100%"
          storyContainerStyles={{ paddingTop: 0 }}
          height="100vh"
          onAllStoriesEnd={() => onAllStoriesEndFnc()}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box alignSelf="center">
            <Loader />
          </Box>
        </Box>
      )}
    </Box>
  );
}
