/* eslint-disable no-unused-vars */

import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFMoodStats,
  useMBFMyWellnessPlanSlice
} from '@hc/store';
import { BackIcon } from '@hc/ui/atoms/icons';
import { PlanSummaryBackgroundImage } from '@hc/ui/atoms/illustration';
import { ViewPlanCard } from '@hc/ui/components/clinical/mindBodyFood/planSummary';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box } from '@mui/material';
import { lazy, React, Suspense, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { plan_summary_style } from './style';

export default function PlanSummary(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const [isGifLoading, setIsGifloading] = useState(true);

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const navigate = useNavigate();

  const LoadingGif = lazy(() =>
    import('@hc/ui/components/clinical/mindBodyFood/planSummary/gifLoader')
  );

  // Store Data
  const { checkPlanDataDetails, mbfState, levelUpdate } = useMBFActivity(
    (state) => ({
      checkPlanDataDetails: state.checkPlanDataDetails,
      mbfState: state.mbfState,
      levelUpdate: state.levelUpdate,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { checkPlanData } = mbfState;
  const { status } = checkPlanData;

  const { mbfMood } = useMBFMoodStats((state) => ({
    mbfMood: state.mbfMood,
  }));

  const { planActivityDataUpsert } = useMBFMyWellnessPlanSlice((state) => ({
    planActivityDataUpsert: state.planActivityDataUpsert,
  }));

  const GifLoader = (
    <Box px={2} pb={20} display="flex" justifyContent="center">
      <Box mt={8} height={300} width={200}>
        <Suspense>
          <LoadingGif />
        </Suspense>
      </Box>
    </Box>
  );

  const ViewPlanComponent = <ViewPlanCard />;

  const planActivityDataAddFnc = async () => {
    const planActivityDataUpsertRes = await planActivityDataUpsert();
    if (planActivityDataUpsertRes === 200) {
      setIsGifloading(false);
      await levelUpdate(4);
    } else {
      setIsGifloading(false);
      toast.error('Something went wrong please try again!');
      return navigate(clinicalRoutes?.getStarted);
    }
  };

  const initialData = async () => {
    // To get plan status value
    if (
      typeof checkPlanData === 'object' &&
      Object.keys(checkPlanData)?.length === 0
    ) {
      await checkPlanDataDetails();
    }
    // Check mood status selected for the day or not
    if (status) {
      if (status === 4) {
        if (!mbfMood || mbfMood === null) {
          navigate(clinicalRoutes?.moodforyou);
        } else {
          navigate(clinicalRoutes?.mindbodyfood);
        }
      } else if (status === 3) {
        planActivityDataAddFnc();
      }
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      sx={{ ...plan_summary_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={plan_summary_style.planSummaryImageBackgroundSx}>
        <Box sx={plan_summary_style.planSummaryImageSx}>
          <Box px={1} mt={1} sx={{ position: 'absolute', left: 12, top: 2 }}>
            <BackIcon
              fill="#6552CC"
              onClick={() => navigate(clinicalRoutes.getStarted)}
              rootStyle={{
                color: '#fff',
                cursor: 'pointer',
              }}
            />
          </Box>
          <PlanSummaryBackgroundImage />
        </Box>
      </Box>
      <Box px={2}>{isGifLoading ? GifLoader : ViewPlanComponent}</Box>
    </Box>
  );
}
