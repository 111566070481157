export const chatFeedbackModal_style = {
  titleSx: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#0E1824',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F0B11',
    textAlign: 'center',
    '& span': {
      fontWeight: 700,
    },
  },
  closebuttonSx: {
    mr: 2,
    textWrap: 'nowrap',
    textTransform: 'none',
    maxWidth: '120px',
    bgcolor: '#DCF5F1',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    // border: '2px solid #007965',
  },
  DeletebuttonSx: {
    ml: 2,
    textWrap: 'nowrap',
    textTransform: 'none',
    maxWidth: '120px',
    bgcolor: '#F44F5A',
    color: 'white',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
    '&:focus': {
      bgcolor: '#F44F5A',
    },
    // border: '2px solid #F44F5A',
  },

  totalModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 'auto', md: 400 },
    border: '0px solid',
    boxShadow: 24,
    backgrounColor: '#FFF',
    p: 3,
    outline: '0px',
  },
  totalBoxSx: {
    backgroundColor: 'common.white',
    px: 6,
    pt: 2,
    borderRadius: '8px',
    minWidth: { xs: 'auto', md: 400 },
    minHeight: '190px',
    margin: '0px auto',
  },

  centerIconSx: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
  boxRootSx: {
    px: 2.5,
  },
  customtextSx: {
    '& .MuiOutlinedInput-input': {
      height: '100px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      lineHeight: '25px',
      p: 0,
    },
  },
  emojiContainer: {
    padding: 1,
    borderRadius: '100%',
    display: 'flex',
    placeItems: 'center',
  },
  childSx: { minHeight: '60vh', pt: 0, backgroundColor: 'common.white' },
  errorText: { fontSize: '12px', mt: 1, color: '#f00' },
  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: 'primary.main',
    // opacity: '48%',
    height: '132px',
    // marginTop: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  browseTextSx: {
    fontSize: '14px',
    color: ' PrimaryTints.A100',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },
  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addMoreButtonSx: {
    fontSize: 14,
    color: 'primary.main',
    fontWeight: 'medium',
  },
};
