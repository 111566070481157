export const familyMemberCard_style = {
  rootSx: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none',
    backgroundColor: '#FFf',
    cursor: 'pointer',
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gobalIconSx: {
    display: 'flex',
    alignItems: 'center',
  },

  iconbtnSx: {
    p: 0.75,
    m: -1,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  profileSx: {
    backgroundColor: '#FFF6F0',
    color: 'secondary.main',
    fontSize: '24px',
    fontWeight: 600,
    width: '48px',
    height: '48px',
    borderRadius: '6px',
  },
  dividerSx: {
    width: '12px',
    height: '32px',
    mx: 1.5,
    color: 'grey.400',
  },
  relationSX: {
    px: 1,
  },
  linkSx: {
    color: 'inherit',
    textDecoration: 'none',
  },
  linkSxx: {
    color: 'inherit',
    textDecoration: 'none',
  },
  circleIconSx: {
    fontSize: '5px',
    color: 'text.hint',
    my: 0.75,
    mx: 1,
  },
  invitedSx: {
    fontSize: '12px',
    color: '#0F0B117A',
    bgcolor: '#18445714',
    px: 0.5,
    py: 0.3,
    borderRadius: '4px',
  },
};
