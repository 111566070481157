export const leaderTitleCard_style = {
  rankSx: {
    display: 'flex',
  },
  headerSx: {
    display: 'flex',
  },
  dateSx: {
    fontSize: '10px',
    color: 'text.secondary',
  },
  categorySx: {
    display: 'flex',
    color: 'text.secondary',
  },
  headerText: {
    color: 'text.secondary',
    fontSize: '12px',
    textAlign: 'center',
  },
  bodyText: {
    color: 'text.secondary',
    fontSize: '12px',
    textAlign: 'center',
  },
  foodText: {
    color: 'text.secondary',
    fontSize: '12px',
    textAlign: 'center',
  },
};
