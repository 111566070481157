/* eslint-disable react/prop-types */
import { CertificateIcon, HospitalIcon } from '@atoms';
import { differenceInYearsFnc } from '@hc/dayjs';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { doctorProfile_style } from './style';

export function DoctorAvailable(props) {
  const {
    src = {},
    doctorName = '',
    profilepicStyle = {},
    pricingRequired = null,
    // role = [],
    paymentType,
    hospital = [],
    tagStyle = {},
    apointmentCardstyle = {},
    nameStyle = {},
    className = '',
    servingFrom = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={doctorProfile_style.rootSx}>
        <Box sx={{ p: 0, pb: '0px!important',cursor:'pointer' }}>
          <Box
            sx={{
              ...doctorProfile_style.appointmentCardSx,
              ...apointmentCardstyle,
            }}
          >
            <Box sx={{ ...doctorProfile_style.doctornameSx, ...nameStyle }}>
              <Avatar
                src={src}
                sx={{ ...doctorProfile_style.profilepicSx, ...profilepicStyle }}
              />
              <Box sx={{ px: 1, width: '151px' }}>
                <Typography variant="body2" sx={{ fontWeight: '600' }}>
                  {`Dr. ${doctorName}`}
                </Typography>
                <>
                  <Box sx={doctorProfile_style.doctorSx}>
                    {servingFrom?.length > 0 && <CertificateIcon />}

                    <Typography sx={doctorProfile_style.assitantSx}>
                      {differenceInYearsFnc(servingFrom) ?? ''}
                    </Typography>
                  </Box>
                  <Box sx={doctorProfile_style.doctorSx}>
                    {Array.isArray(hospital) && hospital?.length > 0 && (
                      <HospitalIcon />
                    )}
                    {Array.isArray(hospital) &&
                      hospital?.length > 0 &&
                      hospital.map((val_, index_) => (
                        <>
                          {index_ > 0 ? (
                            <span style={doctorProfile_style.labelSxx}>
                              {', '}{' '}
                            </span>
                          ) : null}
                          <Typography
                            key={index_}
                            noWrap
                            sx={doctorProfile_style.assitantSx}
                          >
                            {val_}
                          </Typography>
                        </>
                      ))}
                  </Box>
                </>
              </Box>
            </Box>
            {/* Pricing Details */}
            <Box sx={{ ...doctorProfile_style.tagSx, ...tagStyle }}>
              {pricingRequired && (
                <Box>
                  <Box sx={doctorProfile_style.pricingBoxSx}>
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      {paymentType === 'free' ? 'Free' : pricingRequired}
                    </Typography>
                  </Box>
                  {paymentType === 'free' && (
                    <Box>
                      <Typography
                        variant="caption"
                        color="#888888"
                        sx={{ fontWeight: 500, textDecoration: 'line-through' }}
                      >
                        {pricingRequired}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

DoctorAvailable.propTypes = {
  src: PropTypes.object,
  doctorName: PropTypes.string,
  pricingRequired: PropTypes.string,
  role: PropTypes.array,
  hospital: PropTypes.array,
  className: PropTypes.node,
  sx: PropTypes.object,
  paymentType: PropTypes.string,
};
