export const ViewDoctorDetails_style = {
  topbar: {
    background: '#E1F2F0',
    height: '20vh',
    position: 'relative',
  },
  topSecondBar: {
    height: '20vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rootSx: {
    height: '100vh',
    bgcolor: 'white',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    // pb: 4,
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
  },
  doctornameSx: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },

  profilepicSx: {
    width: '130px',
    height: '130px',
    alignSelf: 'start',
    position: 'absolute',
    inset: '-50% 0 0 50%',
    border: '5px solid #fff',
    transform: 'translatex(-50%)',
  },
  assitantSx: {
    color: 'text.hint',
    fontSize: '12px !important',
    fontWeight: 'medium',
    ml: 1,
    // width: '100px',
  },
  profilepicSxassitantSx: {
    color: 'text.hint',
    fontSize: '12px!important',
    fontWeight: 'medium',
    ml: 1,
  },
  tagSx: {
    // display: 'flex',
    // alignItems: 'start',
  },
  doctorSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 0.5,
    gap: '5px',
  },
  patientSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 0.5,
  },
  pricingRequiredSx: {
    fontFamily: 'Roboto',
    padding: '0px 2px',
    fontSize: '12px',
  },
  labelSxx: {
    // backgroundColor: 'PrimaryTints.A100',
    p: 0.4,
    borderRadius: '4px',
    color: '#868484',
    fontSize: '12px',
    ml: 0.8,
    fontWeight: 600,
  },
  dividerSx: {
    my: 1.25,
    borderStyle: 'dashed',
    color: 'grey.400',
  },
  subText: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#888888',
    textTransform: 'capitalize',
  },
  titileText: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#18574CB7',
    textTransform: 'capitalize',
    py: 0.5,
  },
  iconText: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  highLightedTextSx: {
    background: '#18445714',
    borderRadius: '4px',
    width: 'max-content',
    p: 0.5,
    fontSize: '12px',
    fontWeight: 500,
    color: '#0F0B117A',
    textTransform: 'capitalize',
  },
  detailSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F0B11',
  },
  shareIcon: {
    background: '#D6F6F0',
    borderRadius: '16px',
    ml: 'auto',
    alignSelf: 'start',
    p: 0.3,
    display: 'flex',
    placeItem: 'center',
  },
  lightBorder: {
    borderColor: '#18445714',
  },
  iconsList: {
    border: '2px solid #FEC70F ',
    borderRadius: '50px',
    p: 1,
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenIconSx: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#E1F2F0',
    borderRadius: '50px',
  },
};
