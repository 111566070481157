export const passAppointment_style = {
  rootSx: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none',
    mt: 1.625,
  },
  labelSxx: {
    color: '#868484',
    fontSize: '12px',
    margin: '0px 2px',
    fontWeight: 600,
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
    mt: 0,
  },
  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 1.5,
    ml: 1.1,
  },
  selfSummarySx: {
    display: 'flex',
    alignItems: 'center',
    ml: 1.5,
  },
  typeSx: {
    mt: 2,
    mr: 1.5,
    // height: '17px',
    alignItems: 'center',
    overflow: 'hidden',
  },
  dateTimeSx: {
    display: 'flex',
    alignItems: 'center',
    ml: 1.5,
  },
  profilepicSx: {
    width: '44px',
    height: '44px',
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    pr: 0.8,
    pt: 0.3,
    pb: 0.3,
    pl: 1,
    mx: 0.75,
  },
  appointmentDocCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 1,
    mb: 1.5,
    mt: 1,
  },
  selfSx: {
    fontSize: '12px!important',
    color: 'text.secondary',
    pt: 0.3,
    pl: 0.77,
    fontWeight: 500,
  },
  viewSummaryTextSx: {
    fontSize: '12px',
    fontWeight: '600',
    color: 'primary.main',
    pt: 0.3,
    pl: 0.77,
  },
  amountSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'primary.main',
  },
  appointmentDateTimeSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    mt: 1.5,
  },
  dividerSx: {
    mt: 1.5,
    mb: 1.5,
    borderColor: '#F5F5F5',
  },
  amountTextSx: {
    display: 'flex',
    justifyContent: 'end',
    mr: 1.6,
    color: 'green.500',
    fontSize: '12px',
    fontWeight: 600,
    mb: 1.5,
    mt: 0.3,
  },

  professionalSx: {
    color: '#868484',
    fontSize: '12px',
    mt: 0.2,
  },
};
