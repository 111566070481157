import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store/clinical/profile';
import { Button } from '@hc/ui/atoms';
import { EditFamilyProfile } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { editProfile_style } from './style';

export default function EditProfile(props) {
  const { className = '', ...rest } = props;
  const media = useMediaQuery('(min-width:374px)');
  const navigate = useNavigate();
  const { profile, upsertProfile, updateProfileError, loading } = useProfile(
    (state) => ({
      profile: state.profile,
      updateProfileError: state.updateProfileError,
      upsertProfile: state.upsert,
      clearProfile: state.clearProfile,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const isIamValidToSave = () => {
    const profileCopy = JSON.parse(JSON.stringify(profile));

    let isValid = true;
    const error = profileCopy?.error;

    // Checking name
    if (!profile?.name) {
      isValid = false;
      error.name = 'Please enter your name.';
    } else {
      error.name = '';
    }




    // Checking gender
    if (!profile?.gender) {
      isValid = false;
      error.gender = 'Please enter your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!profile?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }
    // Checking email_id
    const filter = /\S+@\S+\.\S+/;
    if (profile?.email_id?.length > 0 && !filter.test(profile?.email_id)) {
      isValid = false;
      error.email_id = 'Please enter the valid mail';
    } else {
      error.email_id = '';
    }
    if (profile?.mobile_no?.length > 0 && profile?.mobile_no?.length !== 10) {
      isValid = false;
      error.mobile_no = 'Please enter your valid mobilenumber';
    } else {
      error.mobile_no = '';
    }



    if (!profile?.blood_group > 0 || profile?.blood_group === 'k') {
      isValid = false;
      error.blood_group = 'Please select your blood group';
    } else {
      error.blood_group = '';
    }
    // UPDATE PROFILE ERROR
    updateProfileError(error);
    return isValid;
  };

  const profileView = () => {
    navigate(clinicalRoutes.profileView);
  };

  const handleSave = async () => {
    const result = isIamValidToSave();
    if (result) {
      await upsertProfile(profile, false);
    }
  };

  return (
    <Box
      sx={media ? editProfile_style.rootSx : editProfile_style.resRootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={editProfile_style.boxRootSx}>
        <Screenlayout
          title="Edit Profile"
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={profileView}
                buttonStyle={editProfile_style.buttonSx}
              >
                Cancel
              </Button>
              <Button onClick={handleSave} loading={loading}>
                Save
              </Button>
            </Box>
          }
        >
          <EditFamilyProfile />
        </Screenlayout>
      </Box>
    </Box>
  );
}
