export const chatStatusCard_style = {
  rootSx: {
    padding: 2,
  },
  cardSx: {
    padding: 0.1,
  },
  rootCardSx: {
    mt: 6,
  },
  smsTextSx: {
    mt: 6,
  },
  profilepicSx: {
    mr: 0,
  },
};
