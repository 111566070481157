/* eslint-disable camelcase */
export const upload_toggle_Button_style = {
  activeSx: {
    border: '2px solid #007965',
    borderColor: 'orangeTints.600',
    backgroundColor: 'orangeTints.50',
    borderRadius: '8px',
    pt: 1,
    pb: 0.5,
    cursor: 'pointer',
  },
  radioSx: {
    border: '2px solid #DBEAE8',
    borderColor: ' #DBEAE8',
    borderRadius: '8px',
    pt: 1,
    pb: 0.5,
    cursor: 'pointer',
  },

  inActiveSx: {
    border: '2px solid  #B7B7B7',
    borderColor: ' #B7B7B7',
    borderRadius: '8px',
    pt: 1,
    pb: 0.5,
    cursor: 'pointer',
    opacity: 0.2,
  },
  iconActiveSx: {
    color: 'orangeTints.600',
  },
  iconInactiveSx: {
    color: '#000',
  },
  headerSx: {
    fontSize: '12px',
    fontWeight: 'medium',
    color: 'text.secondary',
    mb: 1,
    mt: 1.5,
  },
};
