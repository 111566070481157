export const homeBanner_style = {
  rootSx: {
    backgroundColor: 'primaryTints.400',
    borderRadius: '10px',
  },
  imgSx: {
    borderRadius: '0%',
    height: '95px',
    width: 'auto',
    maxWidth: 'fit-content',
  },
  componentsx: {
    borderRadius: '0%',
    // height: '95px',
    width: 'auto',
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  contSx: {
    display: 'flex',
    alignItems: 'center',
    p: 2,
    gap: 3,
  },

  descSx: {
    fontWeight: 600,
    fontSize: '18px',
  },

  buttonSx: {
    maxWidth: 'max-content',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  dayStatusSx: {
    fontSize: '12px',
    mt: 1,
    color: 'mbf.main',
    fontWeight: 600,
  },
  dayStatusSxx: {
    fontSize: '10px',
    mt: 1,
    color: 'mbf.main',
    fontWeight: 500,
  },
};
