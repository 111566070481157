export const linearProgressBar_style = {
  linearSx: {
    '&.MuiLinearProgress-root': {
      height: 6,
      backgroundColor: 'purple.700',
    },
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: 'purple.A100',
      borderRadius: '0px 6px 6px 0px',
    },
  },
};
