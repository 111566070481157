import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import React from 'react';

const PrettoSlider = styled(Slider)({
  color: '#6552CC',
  height: 35,
  marginLeft: 3,
  borderRadius: '150px 130px 130px 150px',
  '& .MuiSlider-track': {
    border: 'none',
    height: 20,
    marginLeft: 5,
  },
  '& .MuiSlider-thumb': {
    height: 28,
    width: 9,
    marginLeft: '1px',
    borderRadius: '9%',
    backgroundColor: 'currentColor',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#6552CC',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export function ProgressUpdation(props) {
  const {
    selected,
    className = '',
    editData,
    updateProgressStore,
    progressUpdate,
    ...rest
  } = props;

  const handleChange = (event, newValue) => {
    updateProgressStore(newValue);
  };

  return (
    <Box sx={{ width: 350 }} className={`${className}`} {...rest}>
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={selected?.user_updated_quantity}
        min={0}
        max={selected?.quantity}
        value={progressUpdate}
        onChange={handleChange}
      />
      <Stack direction="row" justifyContent="space-between" px={0.2}>
        <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
          {0} {selected?.unit_name}
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
          {selected?.quantity} {selected?.unit_name}
        </Typography>
      </Stack>
    </Box>
  );
}
