export const activePrescriptionCard_style = {
  rootSx: {
    backgroundColor: '#fff',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 1,
    mb:1
  },
  medicineSx: {
    fontSize: '12px',
    fontWeight: 500,
    mb:1,
    color:'text.primary'
  },
  directionSx: {
    fontSize: '12px',
    color: 'text.secondary',
  },
  dosageSx: {
    fontSize: '12px',
    fontWeight: 500,
    color:'orangeTints.600'
  },
};
