import { DropIcon, DropUpIcon, EditIcon } from '@atoms';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { appointmentDetails_style } from './style';

export function MedicationDetails(props) {
  const {
    heading = '',
    content = [],
    icon = {},
    className = '',
    editOnclick,
    noDataFound = '',
    ...rest
  } = props;
  const IntialList = content.slice(0, 1);
  const [expand, setExpand] = useState(true);

  return (
    <Box
      sx={appointmentDetails_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={appointmentDetails_style.titleSx}>
          {icon}
          <Typography sx={appointmentDetails_style.headerSx}>
            {heading}
          </Typography>
        </Box>
        <Box
          sx={{
            ...appointmentDetails_style.titleSx,
            gap: 2,
            cursor: 'pointer',
          }}
        >
          {content.length > 0 && <EditIcon onClick={editOnclick} />}
          {content.length > 0 ? (
            expand ? (
              <DropIcon
                onClick={() => setExpand(false)}
                rootStyle={{ cursor: 'pointer' }}
              />
            ) : (
              <DropUpIcon
                onClick={() => setExpand(true)}
                rootStyle={{ cursor: 'pointer' }}
              />
            )
          ) : null}
        </Box>
      </Box>
      {expand ? (
        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          {IntialList && Array.isArray(IntialList) && IntialList.length
            ? IntialList.map(
                (val, index) =>
                  val?.label && (
                    <Box key={index} sx={appointmentDetails_style.labelSx}>
                      {val?.labelShow ? val?.labelShow : val?.label}
                    </Box>
                  )
              )
            : null}
          {content.length - IntialList.length > 0 ? (
            <Box sx={appointmentDetails_style.pointsSx}>
              {`+${content.length - IntialList.length}`}
            </Box>
          ) : null}
          {!content.length && (
            <Typography
              sx={appointmentDetails_style.addDetails}
              onClick={editOnclick}
            >{` +Add ${heading}`}</Typography>
          )}
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>
          {content && Array.isArray(content) && content.length ? (
            content.map(
              (val, index) =>
                val?.label && (
                  <Box
                    key={index}
                    sx={{ ...appointmentDetails_style.labelSx, pb: '10px' }}
                  >
                    {val?.labelShow ? val?.labelShow : val?.label}
                  </Box>
                )
            )
          ) : (
            <Typography sx={appointmentDetails_style.noDataFound}>
              {noDataFound}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
