/* eslint-disable prefer-arrow-callback */
import { useViewPort } from '@hc/store';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { RiSendPlaneFill } from 'react-icons/ri';
import { AttachIcon, Button, Input } from '../../atoms';
import { chartListingInputBox_style } from './style';

function ChartListingInputBox(props) {
  const {
    className = '',
    isScroll,
    isLive,
    children,
    rootStyle = {},
    chatStatus = '',
    isPay,
    chatExtendBodyContent = '',
    buttonText = '',
    isDoctor,
    // isMobilePort,
    isChatExtendRequest,
    onMessageType = () => {},
    onSendMessage = () => {},
    onAttachFile = () => {},
    onRequestOrPayClick = () => {},
    onDelineRequest = () => {},
    onAcceptRequest = () => {},
    handleScroll = () => {},
    message = '',
    ...rest
  } = props;
  const scrollRef = useRef(null);

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  useEffect(() => {
    if (scrollRef.current) {
      if (isScroll) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [children]);

  // const input = document.getElementById('Input-message');

  // input?.addEventListener('keypress', async (event) => {
  //   // If the user presses the "Enter" key on the keyboard
  //   if (event.key === 'Enter') {
  //     // Cancel the default action, if needed
  //     event.preventDefault();
  //     // Trigger the button element with a click
  //     await onSendMessage();
  //   }
  // });

  return (
    <Box
      sx={{
        ...chartListingInputBox_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* <div > */}
          <div
            // py={1}
            ref={scrollRef}
            onScroll={handleScroll}
            style={{
              height: isPay ? '74vh' : '76vh',
              overflowY: 'auto',
              marginTop: isDoctor && !isMobilePort ? 0 : '56px',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
          >
            {children}
          </div>
          {/* </div> */}
          {isPay && (
            <Box px={2}>
              <Box sx={chartListingInputBox_style.chatPayCardSx}>
                <Typography alignSelf="center" fontSize={12} color="#EB6D13">
                  {chatExtendBodyContent}
                </Typography>
                <Button
                  sx={chartListingInputBox_style?.payButtonSx}
                  variant="contained"
                  onClick={() => onRequestOrPayClick()}
                >
                  {buttonText}
                </Button>
              </Box>
            </Box>
          )}
          {isChatExtendRequest && (
            <Box px={2}>
              <Box sx={chartListingInputBox_style.chatExtendCardSx}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={7}>
                    <Typography
                      alignSelf="center"
                      fontSize={12}
                      color="#239179"
                    >
                      Chat request is received
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6} display="flex">
                        <Button
                          sx={chartListingInputBox_style?.acceptButtonSx}
                          variant="contained"
                          onClick={() => onAcceptRequest()}
                        >
                          Accept
                        </Button>
                      </Grid>
                      <Grid item xs={6} display="flex">
                        <Button
                          sx={chartListingInputBox_style?.delineButtonSx}
                          variant="contained"
                          onClick={() => onDelineRequest()}
                        >
                          Deline
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {chatStatus === 'OnGoing' && (
            <Box
              sx={
                !isDoctor || isMobilePort
                  ? { ...chartListingInputBox_style.chartInputBoxSx }
                  : { ...chartListingInputBox_style.isDoctorAppInput }
              }
            >
              <Input
                textFieldStyle={chartListingInputBox_style.chartInputSx}
                id="Input-message"
                endAdornment={
                  <Box>
                    {message?.length > 0 ? (
                      <IconButton onClick={() => onSendMessage()}>
                        <RiSendPlaneFill height="10px" width="10px" />
                      </IconButton>
                    ) : (
                      <Box display="flex">
                        <IconButton onClick={() => onAttachFile()}>
                          <AttachIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                }
                placeholder="Type here"
                value={message ?? ''}
                onChange={(e) => onMessageType(e?.target?.value)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

ChartListingInputBox.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isScroll: PropTypes.bool,
  isPay: PropTypes.bool,
  chatExtendBodyContent: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.element,
  onMessageType: PropTypes.func,
  onSendMessage: PropTypes.func,
  onAttachFile: PropTypes.func,
  message: PropTypes.string,
  isDoctor: PropTypes.bool,
  chatStatus: PropTypes.string,
  isLive: PropTypes.bool,
  onRequestOrPayClick: PropTypes.func,
  isChatExtendRequest: PropTypes.bool,
  onAcceptRequest: PropTypes.func,
  onDelineRequest: PropTypes.func,
};
export { ChartListingInputBox };

