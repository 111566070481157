import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store';
import { Button, SqureCheckedIcon } from '@hc/ui/atoms';
import { Screenlayout } from '@hc/ui/components';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { MedicalConditions_style } from './style';

function Card(Condition) {
  return (
    Array.isArray(Condition) &&
    Condition.map((val, index) => (
      <Box sx={MedicalConditions_style.ListCardSx} key={index}>
        <Typography sx={MedicalConditions_style.cardFontSx}>{val}</Typography>
      </Box>
    ))
  );
}
function NoData(condition) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        sx={{ marginLeft: '-8px' }}
        icon={<SqureCheckedIcon rootStyle={{ color: '#1844577A' }} />}
      />
      <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
        {`No ${condition}`}
      </Typography>
    </Box>
  );
}

export default function ViewMedicalConditions() {
  const { profile } = useProfile(
    (state) => ({
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const navigate = useNavigate();
  const handleOnChangePage = () => {
    navigate(clinicalRoutes?.medicalConditions, {
      state: {
        isFromClinical: true,
      },
    });
  };

  return (
    <Screenlayout
      title="Medical Conditions"
      backRequired
      masterchildStyle={{ pb: 10 }}
      backIconStyle="primaryTints.900"
      appBarStyle="primaryTints.900"
      childrenStyle={{
        minHeight: '65vh',
        pt: {
          xs: 10,
        },
        backgroundColor: '#F7F7F7',
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={handleOnChangePage}>Edit</Button>{' '}
        </Box>
      }
    >
      {/* Allergies */}
      <Box p={1} pt={2}>
        <Stack sx={MedicalConditions_style.boxRootSx} gap={3}>
          <Stack sx={MedicalConditions_style.medicalCard}>
            <Typography sx={MedicalConditions_style.titileSx}>
              Allergies
            </Typography>
            {!(profile?.medical_allergies?.[0] === 'No Allergies') && (
              <Typography sx={MedicalConditions_style.requiredSx}>
                Allergies added
              </Typography>
            )}
            {!profile?.medical_allergies ||
            profile?.medical_allergies?.[0] === 'No Allergies'
              ? NoData('Allergies')
              : Card(profile?.medical_allergies)}
          </Stack>
          <Stack sx={MedicalConditions_style.medicalCard}>
            <Typography sx={MedicalConditions_style.titileSx}>
              Health problems
            </Typography>
            {!(
              profile?.medical_health_problems?.[0] === 'No Health Problems'
            ) && (
              <Typography sx={MedicalConditions_style.requiredSx}>
                Health problems added
              </Typography>
            )}
            {!profile?.medical_health_problems ||
            profile?.medical_health_problems?.[0] === 'No Health Problems'
              ? NoData('health problems')
              : Card(profile?.medical_health_problems)}
          </Stack>
          <Stack sx={MedicalConditions_style.medicalCard}>
            <Typography sx={MedicalConditions_style.titileSx}>
              Medications
            </Typography>

            {!(
              profile?.medical_active_medications?.[0] === 'No Medications'
            ) && (
              <Typography sx={MedicalConditions_style.requiredSx}>
                Medications added
              </Typography>
            )}
            {!profile?.medical_active_medications ||
            profile?.medical_active_medications?.[0] === 'No Medications'
              ? NoData('medications')
              : Card(profile?.medical_active_medications)}
          </Stack>
        </Stack>
      </Box>
    </Screenlayout>
  );
}
