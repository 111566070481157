import { useAllergy } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { AllergySelection, Screenlayout } from '@hc/ui/components';
import { Box } from '@mui/material';
import { useKeyPress } from 'ahooks';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';

import { allergies_style } from './style';

export default function Allergies(props) {
  const { onChange, className = '', ...rest } = props;

  const { get } = useAllergy(
    (state) => ({
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // const {
  //   clearPageData,
  // loading: loader,
  // } = useBookAppointment(
  //   (state) => ({
  //     bookAppointmentState: state?.bookAppointmentState,
  //     clearPageData: state.clearPageData,
  //   }),
  //   (prev, curr) => isEqual(prev, curr)
  // );

  // General Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // ADD ALLERGY DATA
  // const handleAdd = (e, option) => {
  //   addAllergies(e, option);
  //   // GET ALLERY MASTER DATA
  //   get();
  // };

  // GET ALLERY MASTER DATA BASED ON SEARCH
  // const onChangeText = async (e) => {
  //   await get(e?.target?.value ?? '');
  // };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  // CLEAR ALLERGY FUNCTION
  // const handleClear = () => {
  // CLEAR ALLERGY
  //   clearPageData('allergies');
  // };

  // SKIP FUNCTION
  const onSkip = () => {
    // handleClear();
    navigate(location?.state?.prevRoute);
  };

  // GETTING ALLERGY DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={onSkip} buttonStyle={allergies_style.buttonSx}>
            Go back
          </Button>
          <Button onClick={() => navigate(location?.state?.prevRoute)}>
            Save
          </Button>
        </Box>
      }
      {...rest}
    >
      {/* Allergies */}
      <Box>
        <Box sx={allergies_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <AllergySelection />
        </Box>
      </Box>
    </Screenlayout>
  );
}
