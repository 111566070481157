/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useChatBot = create((set, get) => ({
  chatBotState: {
    chatHistory: [],
    questioninitialData: [],
    chat_status_id: '',
    lastUpdatedTime: '',
    remainingChatCount: '',
    questionData: [],
    previousHistory: [],
    preDefineData: [
      {
        title: 'Hey !! What would you like to ask?',
        role: '',
        content: [
          'What lifestyle changes can help me better manage my chronic condition, such as diabetes or hypertension?',
          'What should I know about keeping my family healthy, including vaccinations and preventive measures?',
          "What are the key health considerations at different stages of a woman's life?",
          "What are the important health screenings and lifestyle choices for maintaining men's health as they age?",
          'How can I manage my dietary restrictions or food allergies while maintaining a balanced diet?',
        ],
      },
    ],
    resolvedQuentionData: {
      title: 'Is your concern addressed?',
      role: '',
      content: [
        "Yes, Sorted and I' m good to go!",
        "I've got more questions!",
        'No, My concern still persists!',
      ],
    },
  },
  loading: null,
  messageLoading: null,
  error: null,

  clearChatBotState: () => {
    const { chatBotState } = get();
    set({
      chatBotState: {
        ...chatBotState,
        chatHistory: [],
        questioninitialData: [],
        chat_status_id: '',
        lastUpdatedTime: '',
        questionData: [],
        previousHistory: [],
        preDefineData: [
          {
            title: 'Hey !! What would you like to ask?',
            role: '',
            content: [
              'What lifestyle changes can help me better manage my chronic condition, such as diabetes or hypertension?',
              'What should I know about keeping my family healthy, including vaccinations and preventive measures?',
              "What are the key health considerations at different stages of a woman's life?",
              "What are the important health screenings and lifestyle choices for maintaining men's health as they age?",
              'How can I manage my dietary restrictions or food allergies while maintaining a balanced diet?',
            ],
          },
        ],
        resolvedQuentionData: {
          title: 'Is your concern addressed?',
          role: '',
          content: [
            "Yes, Sorted and I' m good to go!",
            "I've got more questions!",
            'No, My concern still persists!',
          ],
        },
      },
    });
  },

  getHistoryData: async () => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chatHistory-get-chat-history'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatHistory/get-chat-history`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        const { chatBotState } = get();
        if (Object.keys(data).length > 0) {
          set({
            loading: false,
            chatBotState: {
              ...chatBotState,
              chatHistory: data?.history,
              lastUpdatedTime: data?.updated_at,
              chat_status_id: data?.chat_status_id,
            },
          });
        } else {
          set({
            loading: false,
            chatBotState: {
              ...chatBotState,
              chatHistory: chatBotState?.preDefineData,
              chat_status_id: 1,
            },
          });
        }
      }
      return Object.keys(data).length > 0 ? data?.chat_status_id : null;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getRemainingQuesCount: async () => {
    try {
      set({ loading: true });
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chatHistory-remaining-question-count'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${
              envConfig.api_url
            }/chatHistory/remaining-question-count/get?user_id=${
              tokenData?.user_id ?? ''
            }`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        const { chatBotState } = get();

        set({
          loading: false,
          chatBotState: {
            ...chatBotState,
            remainingChatCount: data?.remaining_count ?? '',
          },
        });
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getPreviousHistoryData: async () => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chatHistory-get-previous-chat-history'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatHistory/get-previous-chat-history`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        const { chatBotState } = get();

        set({
          loading: false,
          chatBotState: {
            ...chatBotState,
            previousHistory: data,
          },
        });
      }
      return Object.keys(data).length > 0 ? data?.chat_status_id : null;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  upsertQuestion: async () => {
    try {
      const { chatBotState } = get();
      set({ messageLoading: true });
      const payload = { messages: chatBotState?.questioninitialData };
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chatHistory-get-answer'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/chatHistory/get-answer`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      set({
        chatBotState: {
          ...chatBotState,
          questionData: {},
        },
      });

      if (status?.code === 200) {
        const { chatBotState } = get();
        const resArr = [];
        chatBotState?.chatHistory.forEach((val) => {
          if (val?.content?.length > 0) {
            resArr.push(val);
          }
        });
        resArr.push(data);
        if (resArr?.length === 3) {
          resArr.splice(0, 1);
        }

        const lastIndex = resArr?.slice(-1).pop();

        set({
          messageLoading: false,
          chatBotState: {
            ...chatBotState,
            chatHistory: resArr,
            lastUpdatedTime: lastIndex?.date,
          },
        });
      }
      return status?.code;
    } catch (error) {
      set({ messageLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  setUnResolvedQuestion: async (value) => {
    try {
      const { chatBotState } = get();
      set({ messageLoading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chatHistory-resolve-chat'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            value === 'resolved'
              ? `${envConfig.api_url}/chatHistory/resolve-chat`
              : `${envConfig.api_url}/chatHistory/un-resolve-chat`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200 && value === 'resolved') {
        const { chatBotState } = get();

        const resArr = chatBotState?.chatHistory;
        for (const object of data) {
          resArr.push(object);
        }

        set({
          messageLoading: false,
          chatBotState: {
            ...chatBotState,
            chatHistory: resArr,
          },
        });
      }
      return status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  updateChatBotData: (key, value) => {
    const { chatBotState } = get();

    set({
      chatBotState: {
        ...chatBotState,
        [key]: value,
      },
    });
  },
}));
