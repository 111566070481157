import upgradedProfile from '@assets/upgradedProfile.svg';
import { Box, Typography } from '@mui/material';
import { UpgradedCard_style } from './style';

export function UpgradedCard(props) {
  const { className = '', rootStyle = {}, organisation = '', ...rest } = props;

  return (
    <Box
      sx={{ ...UpgradedCard_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...UpgradedCard_style.rootBoxSx }}>
        <Box sx={UpgradedCard_style.cardheaderSx}>
          <img
            src={upgradedProfile}
            alt=""
            style={{
              padding: '32px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'block',
              margin: 'auto',
            }}
          />
        </Box>
        <Box sx={UpgradedCard_style.cardBodySx}>
          <Typography
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              p: 2,
              fontWeight: 600,
            }}
          >
            Account Upgraded
          </Typography>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', pb: 2 }}>
            Your account has been changed from <br />
            <b>Individual to Corporate account</b> <br />({organisation})
          </Typography>
          <Typography
            sx={{ fontSize: '14px', textAlign: 'center', color: '#888888' }}
          >
            Don&apos;t worry your data will not be affected
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
