import { Box } from '@mui/material';
import { Dropdown } from '@atoms';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { RiCloseLine } from 'react-icons/ri';
import { multiSelect_style } from './style';

export function MultiSelect(props) {
  const {
    rootStyle = {},
    multiSelectOption,
    helperText = '',
    isError,
    value = '',
    className = '',
    ChipText = [],
    onChange = () => {},
    onChipDelete = () => {},
    onChipClick = () => {},
    languageData,
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...multiSelect_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Dropdown
        selectOption={multiSelectOption}
        value={value}
        onChange={onChange}
        isError={isError}
        helperText={helperText}
      />
      {ChipText.length > 0 && (
        <Box>
          {ChipText?.map((item, index) => (
            <Chip
              size="small"
              key={index}
              sx={multiSelect_style.chipTotalSx}
              label={languageData?.filter((x) => x?.value === item)?.[0]?.label}
              onClick={onChipClick}
              onDelete={() => onChipDelete(item, index)}
              deleteIcon={<RiCloseLine sx={multiSelect_style.CloseSx} />}
              value={item}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
MultiSelect.propTypes = {
  rootStyle: PropTypes.object,
  helperText: PropTypes.string,
  className: PropTypes.string,
  isError: PropTypes.bool,
  languageData: PropTypes.any,
  value: PropTypes.string,
  ChipText: PropTypes.array,
  onChange: PropTypes.func,
  onChipDelete: PropTypes.func,
  onChipClick: PropTypes.func,
  multiSelectOption: PropTypes.object,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
