import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { httpRequest, localStorageKeys, queryClient } from '@hc/utils';
import { parseJwt, routeTo } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import create from 'zustand';
import { useRouting } from '../common/routing';

export const useOnboarding = create((set, get) => ({
  user: {
    password: '',
    confirmPassword: '',
    mobileNumber: '',
    countryCode: '',
    error: {
      password: '',
      confirmPassword: '',
      mobileNumber: '',
    },
  },
  otpVerified: false,
  loading: false,
  error: null,
  sendingOTP: false,
  setUser: (payload) => set({ user: payload }),
  sendOtp: async () => {
    try {
      set({ sendingOTP: true });
      await httpRequest('post', `${envConfig.auth_url}/resend-otp`, {}, true);
      set({ sendingOTP: false });
      return toast.success('OTP Sent Successfully');
    } catch (error) {
      set({ sendingOTP: false });
      // eslint-disable-next-line no-undef
      log('error', error);
      return toast.error('Error sending OTP, please try again!');
    }
  },
  verifyOtp: async (otp) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/verify-otp`,
        {
          otp,
        },
        true
      );
      if (response?.data?.status?.code === '400') {
        set({ loading: false });
        return toast.error('Please enter the correct OTP');
      }
      if (response?.data?.status?.code !== '200') {
        set({ loading: false });
        return toast.error(response?.data?.status?.message);
      }
      // toast.success(response?.data?.status?.message);
      set({ loading: false, otpVerified: true });
      const token = response?.data?.data?.token;
      const { user } = get();
      if (user?.type !== 'forgot-password') {
        localStorage.removeItem(localStorageKeys.authTempKey);
      }
      return localStorage.setItem(localStorageKeys.authToken, token);
    } catch (err) {
      set({ loading: false, otpVerified: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  signUp: async (payload, type) => {
    try {
      set({ loading: true });
      localStorage.removeItem(localStorageKeys.authTempKey);
      // Hitting the signup API
      const response = await httpRequest(
        'post',
        type === 'corporate' || type === 'familyMember'
          ? `${envConfig.auth_url}/user/member/signup`
          : type === 'addByDoctorOrAdmin'
          ? `${envConfig.auth_url}/user/patient/signup`
          : `${envConfig.auth_url}/signup`,
        {
          mobile_no: payload?.mobileNumber ?? '',
          country_code: payload?.countryCode ?? '',
          password: payload?.password ?? '',
        },
        !!(
          type === 'corporate' ||
          type === 'familyMember' ||
          type === 'addByDoctorOrAdmin'
        )
      );

      // If the user is new

      if (
        (response?.data?.data?.token && type !== 'addByDoctorOrAdmin') ||
        (response?.status === 200 &&
          response?.data?.data?.token &&
          type === 'addByDoctorOrAdmin')
      ) {
        const token = response?.data?.data?.token;
        const data = parseJwt(token);
        localStorage.setItem(localStorageKeys?.authTempKey, true);
        localStorage.setItem(localStorageKeys.authToken, token);
        set({
          user: {
            ...payload,
            ...data,
            type: data?.employee_membership_code
              ? 'corporate'
              : data?.family_member_id
              ? 'familyMember'
              : data?.user_id?.length > 0
              ? 'addByDoctorOrAdmin'
              : 'sign-up',
          },
          loading: false,
        });
        // For routing, the useRouting is used in the emptyLayout to handle the routing
        toast.success('OTP Sent Successfully');
        return routeTo(useRouting, clinicalRoutes.otp);
      }
      if (
        response?.data?.status?.code === '403' &&
        response?.data?.status?.message &&
        type !== 'addByDoctorOrAdmin'
      ) {
        // If the user is already exists
        set({
          user: {
            ...payload,
          },
          loading: false,
        });
        toast(response?.data?.status?.message, { icon: '⚠️' });
        localStorage.clear();
        // window.location.reload();
        return routeTo(useRouting, clinicalRoutes.login);
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  signIn: async (payload) => {
    try {
      localStorage.removeItem(localStorageKeys.authTempKey);
      set({ loading: true });
      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/signin`,
        {
          mobile_no: payload?.mobileNumber ?? '',
          country_code: payload?.countryCode ?? '',
          password: payload?.password ?? '',
        }
      );
      // If the user is exists
      if (response?.data?.data?.token) {
        const token = response?.data?.data?.token;
        const data = parseJwt(token);
        set({ user: { ...payload, type: 'sign-in' }, loading: false });
        if (
          data?.mobile_no &&
          data?.mobile_verified === true &&
          data?.user_name === null
        ) {
          localStorage.setItem(localStorageKeys.authToken, token);
          toast.success('Signed in successfully');
          toast('Complete your profile');
          return routeTo(useRouting, clinicalRoutes.addProfile);
        }
        if (
          data?.mobile_no &&
          data?.mobile_verified === true &&
          data?.user_name !== null
        ) {
          localStorage.setItem(localStorageKeys.authToken, token);
          toast.success('Signed in successfully');
          return routeTo(useRouting, clinicalRoutes.home);
        }
        localStorage.setItem(localStorageKeys.authToken, token);
        toast('Please verify your mobile number', { icon: '⚠️' });
        routeTo(useRouting, clinicalRoutes.otp);
        return get().sendOtp();
      }
      if (response?.data?.status?.message) {
        set({ user: payload, loading: false });
        return toast(response?.data?.status?.message, { icon: '⚠️' });
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      if (
        err?.response?.data?.status?.code === '404' &&
        err?.response?.data?.status?.message.toLowerCase().includes('not found')
      ) {
        set({
          user: payload,
        });
        toast('Please create your account. Redirecting to Sign up...');
        return routeTo(useRouting, clinicalRoutes.signup);
      }
      set({
        user: payload,
      });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  forgotPassword: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/reset-password`,
        {
          mobile_no: payload?.mobileNumber ?? '',
          country_code: payload?.countryCode ?? '',
        }
      );
      if (
        response?.data?.status?.code === '401' &&
        response?.data?.status?.message
      ) {
        set({ user: payload, loading: false });
        return toast(response?.data?.status?.message, { icon: '⚠️' });
      }
      if (response?.data?.data?.token) {
        const token = response?.data?.data?.token;
        localStorage.setItem(localStorageKeys?.authTempKey, true);
        localStorage.setItem(localStorageKeys.authToken, token);
        set({
          user: { ...payload, type: 'forgot-password' },
          loading: false,
        });
        toast.success(response?.data?.status?.message);
        return routeTo(useRouting, clinicalRoutes.otp);
      }
      return set({ loading: false });
    } catch (err) {
      set({ user: payload, loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  resetPassword: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/change-password`,
        {
          password: payload?.password ?? '',
        },
        true
      );
      if (response?.data?.status?.code !== '200') {
        set({ user: payload, loading: false });
        return toast.error(response?.data?.status?.message);
      }
      set({ user: payload, loading: false });
      toast.success(
        `${response?.data?.status?.message}. Please sign in to continue`
      );
      localStorage.clear();
      return routeTo(useRouting, clinicalRoutes.login);
    } catch (err) {
      set({ user: payload, loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  verifyOnboardUrl: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['verifyOnboardUrl'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.auth_url}/invite-link-expired-check`,
            {},
            true
          );
          return data;
        },
        staleTime: 30000,
      });
      set({ loading: false });
      if (data?.isExpired === true) {
        toast.success(
          `It looks like you've already signed up for Health Circles...`
        );
        localStorage.clear();
        routeTo(useRouting, clinicalRoutes.login);
      }
      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
