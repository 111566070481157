import OtpBg from '@assets/otpBg.svg';
import { LogoIcon, SplashScreenIcon } from '@hc/ui/atoms';
import { Box, Typography } from '@mui/material';
import { splashScreen_style } from './style';

export default function Splash(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={splashScreen_style.rootSx} className={`${className}`} {...rest}>
      <Box
        sx={{
          ...splashScreen_style.subrootSx,
          backgroundImage: `url(${OtpBg})`,
        }}
      >
        {/* Splash Design */}
        <Box sx={splashScreen_style.resetSx}>
          <LogoIcon  rootStyle={splashScreen_style.avatarSx} />
          <Typography variant="h5" sx={splashScreen_style.titleSx}>
            Health Circles
          </Typography>
          <Typography variant="body1" sx={splashScreen_style.subtitleSx}>
            Make
            <span style={{ fontWeight: 700, color: '#fff' }}> Health </span>
            and
            <span style={{ fontWeight: 700, color: '#fff' }}> Wellness </span>
            Your Top Priority
          </Typography>
          <Box sx={{ pt: 4.95, px: 4.3 }}>
            <SplashScreenIcon rootStyle={splashScreen_style.splashSx} />
            {/* <img src={SplashImg} alt="" style={splashScreen_style.splashSx} /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
