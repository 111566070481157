import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useAwareness = create((set, get) => ({
  awarenessState: {
    awarenessData: [],
    categoryData: [],
    categoryObjectData: {},
    notesData: [],
    awareness_id: '',
    awareness_name: '',
    cate_id: '',
    cate_name: '',
  },
  loading: false,
  error: false,

  getAwarenessData: async () => {
    try {
      const { awarenessState } = get();
      set({ loading: true });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAwarenessData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/awareness/get`,
            {},
            false,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          awarenessState: {
            ...awarenessState,
            awarenessData: data ?? [],
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  getCategoryDataByAwarenessId: async (awareness_id) => {
    try {
      const { awarenessState } = get();
      set({ loading: true });

      const response = await httpRequest(
        'get',
        `${envConfig.api_url}/awareness/get/category-by-awareness-id?awareness_id=${awareness_id}`,
        {},
        false,
      );

      if (response?.data?.status?.code === 200) {
        const arr = [];
        if (
          Array.isArray(response?.data?.data?.categoryData) &&
          response?.data?.data?.categoryData?.length > 0
        ) {
          // eslint-disable-next-line no-restricted-syntax
          for (const val of response?.data?.data?.categoryData) {
            const obj = {
              id: val?.id ?? '',
              awareness_category_name: val?.awareness_category_name ?? '',
              awareness_category_image: val?.awareness_category_image ?? '',
              note_type: val?.note_type ?? '',
            };
            arr.push(obj);
          }
        }

        set({
          awarenessState: {
            ...awarenessState,
            categoryData: arr ?? [],
            awareness_name:
              response?.data?.data?.awarenessData?.awareness_name ?? '',
          },
        });
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  getNotesDataByCatergoryId: async (category_id) => {
    try {
      const { awarenessState } = get();
      set({ loading: true });

      const response = await httpRequest(
        'get',
        `${envConfig.api_url}/awareness/get/notes-by-category-id?category_id=${category_id}`,
        {},
        false,
      );
      if (response?.data?.status?.code === 200) {
        set({
          awarenessState: {
            ...awarenessState,
            categoryObjectData: {
              ...response?.data?.data?.categoryData,
            },

            notesData:
              Array.isArray(response?.data?.data?.categoryNotesData) &&
              response?.data?.data?.categoryNotesData?.length > 0
                ? response?.data?.data?.categoryNotesData
                : [],
          },
        });
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  updateAwarenessState: (key, value) => {
    const { awarenessState } = get();
    set({
      awarenessState: {
        ...awarenessState,
        [key]: value,
      },
    });
  },
}));
