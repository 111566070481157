/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CheckBox, DialogModal } from '@atoms';
import {
  BillIcon,
  ReportUserIcon,
  ThreeDotsMenuIcon,
  ViewDocumentIcon,
} from '@atoms/icons';
import { clinicalRoutes } from '@hc/routes';
import { useReport } from '@hc/store';
import DocumentImage from '@hc/ui/assets/PDFimage.png';
import { NotificationPopup } from '@hc/ui/components/clinical';
import { getFileType } from '@hc/utils/helperFunctions';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import PropTypes from 'prop-types';
import { React, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { PdfViewer } from '../../../pdfviewer';
import { patient_report_card_style } from './style';

export function PatientReportCard(props) {
  const {
    className,
    rootStyle,
    reportName = '',
    doctorName = '',
    speciality = '',
    reportType = '',
    uploadedFor = '',
    relationShip = '',
    uploadeddate = '',
    uploadedData = [],
    showReportName,
    showReportType,
    showUploadedBy,
    showCheckBox,
    isUploadCard,
    disabled,
    selectAllCheckBox,
    isLoading,
    reportDelete = () => {},
    visit_report_url = '',
    caseSummary = false,
    objectData = {},
    tabIndex,
    reportId,
    handleChange,
    ...rest
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    reportState,
    constructReportEditData,
    clearReportState,
    updateReportStoreState,
    loading,
  } = useReport((state) => ({
    reportState: state.reportState,
    updateReportStoreState: state.updateReportStoreState,
    constructReportEditData: state.constructReportEditData,
    clearReportState: state.clearReportState,
    loading: state.loading,
  }));

  const fileTypeValues = caseSummary
    ? Array.isArray(uploadedData) && uploadedData?.length > 0
      ? uploadedData.map((data) => getFileType(data?.visit_report_url))
      : []
    : Array.isArray(uploadedData) && uploadedData?.length > 0
    ? uploadedData.map((data) => getFileType(data?.downloadUrl))
    : [];

  const fileType = fileTypeValues?.[0]?.toString() ?? '';
  const ExpandImage = caseSummary
    ? uploadedData?.[0]?.visit_report_url
      ? uploadedData?.[0]?.visit_report_url
      : ''
    : uploadedData?.[0]?.downloadUrl
    ? uploadedData?.[0]?.downloadUrl
    : '';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const DiaogBodyContent = (
    <>
      {fileType === 'pdf' && (
        <Box>
          {Array.isArray(uploadedData) && uploadedData?.length > 0
            ? uploadedData?.map((data) => (
                <div style={{ height: '50vh', width: '50vh' }}>
                  <PdfViewer
                    url={
                      caseSummary ? data?.visit_report_url : data?.downloadUrl
                    }
                  />
                </div>
              ))
            : null}
        </Box>
      )}

      {fileType !== 'pdf' && (
        <Box sx={patient_report_card_style.adSx}>
          <Slider {...settings}>
            {Array.isArray(uploadedData) && uploadedData?.length > 0
              ? uploadedData?.map((data, i) => (
                  <img
                    key={i}
                    style={{ objectFit: 'fill' }}
                    src={
                      caseSummary ? data?.visit_report_url : data?.downloadUrl
                    }
                    alt=""
                  />
                ))
              : null}
          </Slider>
        </Box>
      )}
    </>
  );

  const openPopUp = () => {
    setAlertOpen(true);
  };

  const onClose = () => {
    setAlertOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleChangef = async (e) => {
    await handleChange(e, tabIndex, reportId);
    await updateReportStoreState();
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const editClick = async (value) => {
    await clearReportState(true);
    await constructReportEditData(value);
    if (location.pathname === clinicalRoutes.uploadReports) {
      navigate(clinicalRoutes.reportsUpload, {
        state: {
          status: 'edit',
          isBookAppoinment: true,
        },
      });
    } else {
      navigate(clinicalRoutes.reportsUpload, {
        state: {
          status: 'edit',
          isBookAppoinment: false,
        },
      });
    }

    setAnchorEl(null);
  };

  const deleteClick = async () => {
    setAlertOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box
      sx={patient_report_card_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={
          showCheckBox
            ? tabIndex === '1'
              ? !reportState?.deselectedReports?.uploadedId?.includes(reportId)
                ? patient_report_card_style.activeOutlinedBox
                : patient_report_card_style.outlinedBox
              : tabIndex === '2'
              ? !reportState?.deselectedReports?.caseSummaryId?.includes(
                  reportId,
                )
                ? patient_report_card_style.activeOutlinedBox
                : patient_report_card_style.outlinedBox
              : ''
            : patient_report_card_style.outlinedBox
        }
      >
        <Grid container>
          <Grid
            item
            xs={3}
            sm={2.5}
            md={2.5}
            p={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                width: '66px',
                backgroundColor: '#fff',
                color: '#0E1824',
                transition:
                  ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderRadius: '4px',
                border: '1px solid #DBDBDB',
                overflow: 'hidden',
              }}
            >
              <Box sx={patient_report_card_style.ImageBoxSx}>
                {fileType === 'pdf' && (
                  <img
                    style={{ objectFit: 'fill' }}
                    src={DocumentImage}
                    alt=""
                    onClick={handleModalOpen}
                  />
                )}
                {fileType !== 'pdf' && (
                  <img
                    style={{
                      objectFit: 'cover',
                      width: '100vh',
                      opacity: '0.6',
                    }}
                    src={ExpandImage}
                    alt=""
                    onClick={handleModalOpen}
                  />
                )}

                <Box
                  sx={patient_report_card_style.expandIconSx}
                  alignSelf="center"
                >
                  {fileType !== 'pdf' && (
                    <Box>
                      {uploadedData?.length > 1 && (
                        <Box onClick={handleModalOpen}>
                          <Typography color="#fff" fontSize={14}>
                            +{uploadedData?.length - 1}
                          </Typography>
                        </Box>
                      )}
                      {uploadedData?.length === 1 && (
                        <Box onClick={handleModalOpen}>
                          <ViewDocumentIcon />
                        </Box>
                      )}
                    </Box>
                  )}

                  {showReportName && (
                    <DialogModal
                      isDialogOpened={isModalOpen}
                      handleCloseDialog={handleClose}
                      title={reportName}
                      content={DiaogBodyContent}
                    />
                  )}
                  {!showReportName && (
                    <DialogModal
                      isDialogOpened={isModalOpen}
                      handleCloseDialog={handleClose}
                      title={doctorName}
                      content={DiaogBodyContent}
                    />
                  )}
                </Box>
                <Box
                  sx={patient_report_card_style.expandIconSx}
                  alignSelf="end"
                >
                  <Typography fontSize="10px" color="white">
                    {uploadeddate}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            sm={9.5}
            md={9.5}
            py={1}
            pl={0.2}
            display="flex"
            justifyContent="space-between"
          >
            <Grid container>
              <Grid item xs={12} alignSelf="start">
                <Grid container>
                  <Grid item xs={10} sm={10} md={10}>
                    {showReportName && (
                      <Typography mt={0.6} fontSize="14px" fontWeight="600">
                        {reportName}
                      </Typography>
                    )}
                    {!showReportName && (
                      <Typography fontSize="14px" fontWeight="600">
                        {doctorName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="end">
                    {showCheckBox && (
                      <Box>
                        {tabIndex === '1' ? (
                          <CheckBox
                            checked={
                              !reportState?.deselectedReports?.uploadedId?.includes(
                                reportId,
                              )
                            }
                            disabled={disabled}
                            checkSecondStyle={
                              disabled
                                ? patient_report_card_style.checkSecondDisabledStyle
                                : ''
                            }
                            onChange={handleChangef}
                          />
                        ) : (
                          <CheckBox
                            checked={
                              !reportState?.deselectedReports?.caseSummaryId?.includes(
                                reportId,
                              )
                            }
                            onChange={handleChangef}
                          />
                        )}
                      </Box>
                    )}
                    {!showCheckBox && (
                      <Box sx={patient_report_card_style.editIconSx}>
                        {!caseSummary && (
                          <Box>
                            {' '}
                            <IconButton
                              disableRipple
                              onClick={handleClick}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={open ? 'fade-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                            >
                              <ThreeDotsMenuIcon id="more" />
                            </IconButton>
                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                'aria-labelledby': 'fade-button',
                              }}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: 'visible',
                                  filter:
                                    'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt: 1.5,
                                  '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                              }}
                              anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleOnClose}
                              TransitionComponent={Fade}
                            >
                              <MenuItem
                                id="Edit"
                                key="edit"
                                onClick={() => editClick(objectData)}
                                onClose={handleOnClose}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                id="delete"
                                key="Delete"
                                onClick={openPopUp}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                            <NotificationPopup
                              open={alertOpen}
                              subTitleStyle={{
                                fontWeight: 500,
                              }}
                              loading={loading}
                              subTitle="Are you sure, You want delete this report ?"
                              acceptButtonName="Delete"
                              declineButtonName="Cancel"
                              notifyBtnStyletwo={{
                                bgcolor: 'primaryTints.200',
                                color: 'primary.main',
                                '&:hover': {
                                  bgcolor: 'primaryTints.200',
                                },
                              }}
                              notifyBtnStyle={{
                                bgcolor: 'primary.main',
                                color: 'common.white',
                              }}
                              onDecline={onClose}
                              onAccpet={() => {
                                deleteClick();
                                reportDelete(objectData?.id, tabIndex);
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignSelf: 'center',
                  flexwrap: 'wrap',
                  flexFlow: 'wrap',
                }}
              >
                {!showUploadedBy && (
                  <Typography fontSize="12px" color="#888888">
                    {/* {speciality} */}
                    {Array.isArray(speciality) &&
                      speciality?.length > 0 &&
                      speciality.map((val, index) => (
                        <Typography
                          key={index}
                          component="span"
                          sx={{
                            color: 'text.hint',
                            fontSize: '14px',
                            fontWeight: 500,
                            py: 0.25,
                          }}
                        >
                          • {val?.master_speciality?.s ?? ''} &nbsp;
                        </Typography>
                      ))}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} alignSelf="end">
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="end"
                >
                  <Grid item display="flex" mb={1} xs={8}>
                    <Box display="flex">
                      {!isUploadCard && <ReportUserIcon />}
                    </Box>
                    <Typography sx={patient_report_card_style.patientTypo}>
                      {relationShip && <span>{`(${relationShip})`}</span>}
                      &nbsp; {uploadedFor}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    pr={1}
                    xs={4}
                    mb={1}
                    display="flex"
                    justifyContent="end"
                    alignItems="end"
                  >
                    {!isUploadCard && (
                      <Box>
                        {showReportType && (
                          <Typography fontSize="12px" color="#FF980E">
                            {reportType}
                          </Typography>
                        )}
                        {!showReportType && (
                          <Box>
                            {visit_report_url && (
                              <a
                                href={visit_report_url}
                                download
                                style={{ textDecoration: 'none' }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'end',
                                  }}
                                >
                                  <Box
                                    pr={0.8}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'end',
                                    }}
                                  >
                                    <BillIcon />
                                  </Box>
                                  <Typography fontSize="12px" color="#007965">
                                    Download
                                  </Typography>
                                </Box>
                              </a>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
PatientReportCard.propTypes = {
  className: PropTypes.object,
  rootStyle: PropTypes.object,
  reportName: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.string,
  reportType: PropTypes.string,
  uploadedBy: PropTypes.string,
  uploadedFor: PropTypes.string,
  relationShip: PropTypes.string,
  uploadeddate: PropTypes.string,
  uploadedData: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
    }),
  ),
  showReportName: PropTypes.bool,
  showReportType: PropTypes.bool,
  showUploadedBy: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  icon: PropTypes.object,
  isUploadCard: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  caseSummary: PropTypes.bool,
  objectData: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  reportDelete: PropTypes.func,
  visit_report_url: PropTypes.string,
  tabIndex: PropTypes.number,
  reportId: PropTypes.string,
  handleChange: PropTypes.func,
};
