import mbfTickGif from '@assets/mbfTickGif.gif';
import { clinicalRoutes } from '@hc/routes';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mbfSplash_style } from './style';

export default function MbfSplash(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location?.state;
  useEffect(() => {
    setTimeout(() => {
      if (from === 'Renewal') {
        navigate(clinicalRoutes.medicalConditions, {
          state: {
            fromPlan: 'Renewal',
          },
        });
      } else if (from === 'New') {
        navigate(clinicalRoutes.getStarted, { state: { from: 'NewFlow' } });
      }
    }, 2500);
  }, []);

  return (
    <Box
      sx={{
        ...mbfSplash_style.rootSx,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={mbfSplash_style.containerSx}
      >
        <Box>
          <img
            src={mbfTickGif}
            alt="Verified tick gif"
            style={mbfSplash_style.tickGifSx}
          />
        </Box>
        <Typography sx={mbfSplash_style.descTypoSx}>
          {' '}
          Your response has been <br /> submitted !!!
        </Typography>
      </Stack>
    </Box>
  );
}
