import { envConfig } from '@hc/config';
import { clinicalRoutes } from '@hc/routes';
import { useChatConsultation } from '@hc/store/clinical/chatConsultation';
import {
  ChatStatusCard,
  NotificationPopup,
  Screenlayout,
} from '@hc/ui/components';
import { useSocket } from '@hc/utils/socket';
import { Box } from '@mui/material';
import { razorpayCall } from 'crayond_payment_gateway';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { chat_status_style } from './style';

export default function ClinicalChatStatusPage(props) {
  const { className = '' } = props;
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [paymentLoading, setLoading] = useState(false);
  const [paymentLoadingState, setPaymentLoadingState] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();

  const { chatConsultationState, updateChatConsultationState } =
    useChatConsultation(
      (state) => ({
        chatConsultationState: state.chatConsultationState,
        updateChatConsultationState: state.updateChatConsultationState,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { chatStatusData } = chatConsultationState;

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    type = '',
    chatConsultationRequestsId = '',
    // chatConsultationRequestPaymentId = '',
  } = chatStatusData;

  const onCancelModalClickFnc = () => {
    setCancelModalOpen(true);
  };

  const onHomePageClickFnc = () => {
    navigate(clinicalRoutes?.chatListPage);
  };

  const callBackResponse = async (val) => {
    if (val?.statusCode === 200) {
      toast.success(val?.message ?? `Payment Success.`);
      // call socket for payment completed
      await socket.emit('chat_payment_completed_by_patient', {
        doctorProfileId,
        doctorUserId,
        doctorProfilePicture,
        doctorName,
        doctorMobileNo,
        doctorChatConsultationAmount,
        chatRequestedDate,
        chatConsultationDuration,
        patientUserId,
        patientProfileId,
        patientProfilePicture,
        patientName,
        patientMobileNo,
        type: 'chatPaymentCompletedByPatient',
        chatConsultationRequestsId,
        chatConsultationRequestPaymentId: localStorage.getItem(
          'chatConsultationRequestPaymentId',
        ),
        responsePaymentData: val?.data ?? {},
      });
      setPaymentLoadingState(false);
      // Navigate to chat page
      setTimeout(() => {
        updateChatConsultationState('chatStatus', 'OnGoing');
        navigate(clinicalRoutes?.chat);
      }, 3000);
    } else {
      toast.success(val?.message ?? `Something went wrong please try again!`);
      setPaymentLoadingState(false);
    }
  };

  const makePaymentClickFnc = async () => {
    setPaymentLoadingState(true);
    await socket.emit('chat_payment_initiate_by_patient', {
      doctorProfileId,
      doctorUserId,
      doctorProfilePicture,
      doctorName,
      doctorMobileNo,
      doctorChatConsultationAmount,
      chatRequestedDate,
      chatConsultationDuration,
      patientUserId,
      patientProfileId,
      patientProfilePicture,
      patientName,
      patientMobileNo,
      type: 'chatPaymentInitiateByPatient',
      chatConsultationRequestsId,
    });
    razorpayCall({
      baseURL: envConfig?.payment_url,
      orderAmount: doctorChatConsultationAmount.toString() ?? 0,
      name: 'Health Circles',
      description: 'description',
      preFillName: patientName ?? '',
      preFillEmail: '',
      preFillContactNumber: patientMobileNo ?? '',
      notesAddress: 'notesAddress',
      theme: '',
      paymentType: 1,
      setLoading,
      callBackResponse,
    });
  };

  const cancelRquestFnc = async () => {
    setCancelModalOpen(false);
    await socket.emit('chat_request_cancel_by_patient', {
      doctorProfileId,
      doctorUserId,
      doctorProfilePicture,
      doctorName,
      doctorMobileNo,
      doctorChatConsultationAmount,
      chatRequestedDate,
      chatConsultationDuration,
      patientUserId,
      patientProfileId,
      patientProfilePicture,
      patientName,
      patientMobileNo,
      type,
      chatConsultationRequestsId,
    });
    // Socket call for availability status change
    await socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'online',
    });
    navigate(clinicalRoutes?.chatListPage);
  };

  const closeCancelRequestModalFnc = () => {
    setCancelModalOpen(false);
  };

  useEffect(() => {
    if (
      !doctorProfileId ||
      doctorProfileId?.length === 0 ||
      doctorProfileId === undefined ||
      doctorProfileId === null
    ) {
      navigate(clinicalRoutes?.chatListPage);
    }
  }, []);

  return (
    <Box sx={chat_status_style.rootSx}>
      <Screenlayout
        title={patientName ?? ''}
        className={`class ${className}`}
        backRequired
        backRoute={-1}
        notshowFooter
      >
        <Box px={1.5}>
          <ChatStatusCard
            confirmed={
              type === 'patientRequestToDoctor' ||
              type === 'chatRequestAcceptByDoctor' ||
              type === 'chatPaymentInitiateByPatient' ||
              type === 'chatPaymentCompletedByPatient'
                ? true
                : false
            }
            message={
              type === 'patientRequestToDoctor'
                ? 'Chat Request Sent!'
                : type === 'chatRequestRejectByDoctor'
                ? 'Chat Request Rejected!'
                : type === 'chatRequestAcceptByDoctor' ||
                  type === 'chatPaymentInitiateByPatient' ||
                  type === 'chatPaymentCompletedByPatient'
                ? 'Approved Your Request'
                : ''
            }
            subMessage={
              type === 'patientRequestToDoctor'
                ? "Waiting for the doctor's response"
                : type === 'chatRequestRejectByDoctor'
                ? 'Doctor rejected your request'
                : type === 'chatRequestAcceptByDoctor' ||
                  type === 'chatPaymentInitiateByPatient' ||
                  type === 'chatPaymentCompletedByPatient'
                ? 'Doctor approved your request. Please make payment and chat with doctor.'
                : ''
            }
            type={type ?? ''}
            profilePicture={doctorProfilePicture ?? ''}
            name={doctorName ?? ''}
            mobileNo={doctorMobileNo ?? ''}
            doctorChatConsultationAmount={doctorChatConsultationAmount ?? ''}
            chatRequestedDate={chatRequestedDate ?? ''}
            chatConsultationDuration={chatConsultationDuration ?? '15 Mins'}
            cancelRequired={
              type === 'patientRequestToDoctor' ||
              type === 'chatRequestRejectByDoctor' ||
              type === 'chatRequestAcceptByDoctor' ||
              type === 'chatPaymentInitiateByPatient' ||
              type === 'chatPaymentCompletedByPatient'
                ? true
                : false
            }
            cancelButtonName={
              type === 'patientRequestToDoctor'
                ? ' Cancel request'
                : type === 'chatRequestRejectByDoctor'
                ? 'Back to home'
                : type === 'chatRequestAcceptByDoctor' ||
                  type === 'chatPaymentInitiateByPatient' ||
                  type === 'chatPaymentCompletedByPatient'
                ? 'Make payment'
                : ''
            }
            buttonDisabled={
              type === 'chatPaymentCompletedByPatient' ? true : false
            }
            buttonLoader={paymentLoadingState}
            onCancelModalClick={onCancelModalClickFnc}
            onHomePageClick={onHomePageClickFnc}
            makePaymentClick={makePaymentClickFnc}
          />
        </Box>
      </Screenlayout>
      {/* Notification Popup */}
      <NotificationPopup
        open={cancelModalOpen}
        title="Request cancel"
        subTitle={
          <div>
            Are you sure you want to cancel the chat with{' '}
            <span style={{ fontWeight: 600 }}>{doctorName}</span>?
          </div>
        }
        notifyBtnStyletwo={{
          bgcolor: 'primaryTints.200',
          color: 'primary.main',
          '&:hover': {
            bgcolor: 'primaryTints.200',
          },
        }}
        notifyBtnsStyle={{
          bgcolor: 'error.main',
          color: 'common.white',
          '&:hover': {
            bgcolor: 'error.main',
          },
        }}
        acceptButtonName="Cancel Now"
        declineButtonName="Cancel"
        onDecline={closeCancelRequestModalFnc}
        onAccpet={cancelRquestFnc}
      />
    </Box>
  );
}
