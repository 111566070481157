export const mbfChangePlanRequest_style = {
  rootSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2,

    overflow: 'overlay',
  },
  requestBtn: {
    border: '1px solid',
    borderColor: '#6856cd',
    backgroundColor: '#6856cd',
    textTransform: 'capitalize',
    borderRadius: '8px',
    letterSpacing: '0.4px',
    '&:hover': {
      backgroundColor: '#6856cd',
    },
  },
  customTextSx: {
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      lineHeight: '25px',
      p: 2,
      backgroundColor: '#fff',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        border: '2px solid',
        borderColor: '#6856cd',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid',
        borderColor: '#6856cd',
      },
    },
  },

  errorText: { fontSize: '12px', mt: 1, color: '#f00' },
};
