import { Box, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { emptyStateComponent_style } from './style';
import { CreateAccountIcon } from '../../../atoms';

function EmptyStateComponent(props) {
  const {
    className = '',
    rootStyle = {},
    illustrationChild = <CreateAccountIcon />,
    mainContent = 'All set!',
    isChild = false,
    subContent = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...emptyStateComponent_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ height: !isChild && '64vh', display: 'flex' }}>
        <Grid
          container
          alignSelf="center"
          display="flex"
          justifyContent="center"
        >
          <Grid pb={1.5} item display="flex" justifyContent="center" xs={12}>
            <Box>{illustrationChild}</Box>
          </Grid>
          <Grid pb={1.5} item xs={12}>
            <Typography textAlign="center" fontSize={16} fontWeight={600}>
              {mainContent}
            </Typography>
          </Grid>
          {!isChild && (
            <Grid item xs={12}>
              <Typography textAlign="center" fontSize={12} color="#888888">
                {subContent}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export { EmptyStateComponent };

EmptyStateComponent.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  illustrationChild: PropTypes.element,
  mainContent: PropTypes.string,
  subContent: PropTypes.string,
  isChild: PropTypes.bool,
};
