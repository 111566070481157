import { useMBFActivity } from '@hc/store';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import MbfIllustration from '../../../../assets/MbfIllustration.gif';
import { RightArrowIcon, TickIcon } from '../../../../atoms';
import { mbfStepperBanner_style } from './style';

function MbfStepperBanner(props) {
  const {
    className = '',
    rootStyle = {},
    onClickFunction,
    description,
    title,
    subTitle,
    onClickNext,
    ...rest
  } = props;

  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;

  const { status } = checkPlanData;

  const dataList = [
    {
      id: 1,
      status:
        status > 1 || status === 1
          ? 'completed'
          : status === null || status === 0 || status === undefined
          ? 'inProgress'
          : 'pending',
    },
    {
      id: 2,
      status:
        status > 3 || status === 3
          ? 'completed'
          : status === 1 || status === 2
          ? 'inProgress'
          : 'pending',
    },
    {
      id: 3,
      status:
        status > 4 || status === 4
          ? 'completed'
          : status === 3
          ? 'inProgress'
          : 'pending',
    },
  ];

  return (
    <Box
      sx={{
        ...mbfStepperBanner_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container sx={mbfStepperBanner_style.container}>
        <Grid item xs={3} sx={mbfStepperBanner_style.imgcontent}>
          {' '}
          <img src={MbfIllustration} alt="banner" style={{ height: '120px' }} />
        </Grid>
        <Grid item xs={9} pt={2.5} pl={2}>
          <Stack flexDirection="row" gap={3}>
            <Box sx={mbfStepperBanner_style.stepperrowSx}>
              {Array.isArray(dataList) &&
                dataList?.length > 0 &&
                dataList.map((val, i) => (
                  // <React.Fragment key={i}>
                  <Box
                    Box
                    key={i}
                    // sx={mbfStepperBanner_style.contentWrapSx}
                  >
                    <Box
                      sx={{
                        border:
                          val?.status === 'inProgress'
                            ? '2px solid #7A67D9'
                            : '',
                        borderRadius: '50%',
                        width: '25px',
                        height: '25px',
                        placeItems: 'center',
                        display: 'grid',
                        // left: val?.status === 'inProgress' ? '-0.30%' : '',
                      }}
                    >
                      <Box
                        sx={{
                          ...mbfStepperBanner_style.stepNumberSx,
                          backgroundColor:
                            val?.status === 'completed'
                              ? '#25C460'
                              : val?.status === 'pending'
                              ? '#F3F0FF'
                              : '#7A67D9',
                          color:
                            val?.status === 'completed'
                              ? '#fff'
                              : val?.status === 'pending'
                              ? '#7A67D9'
                              : '#fff',

                          width: '19px',
                          height: '19px',
                          display: 'grid',
                          alignItems: 'center',
                          justifyContent: 'center',
                          // top: 0,
                          placeItems: 'center',
                        }}
                      >
                        {val?.status === 'completed' ? (
                          <TickIcon
                            rootStyle={{
                              color: 'common.white',
                              stroke: 'common.white',
                            }}
                          />
                        ) : (
                          i + 1
                        )}
                      </Box>
                      {i !== 2 && (
                        <Box
                          sx={{
                            height: '1px',
                            width: '44px',
                            ml: '24px',
                            color: '#000',
                          }}
                        >
                          <Box
                            light
                            sx={{
                              // bgcolor:
                              //   val?.status === 'completed'
                              //     ? '#25C460'
                              //     : val?.status === 'inProgress'
                              //     ? '#7A67D9'
                              //     : '',
                              // height: '1.5px',
                              border:
                                val?.status === 'completed'
                                  ? '1.5px dashed #25C460'
                                  : val?.status === 'inProgress'
                                  ? '1.5px dashed #7A67D9'
                                  : '',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  // {/* </React.Fragment> */}
                ))}
            </Box>
            <IconButton
              onClick={() => onClickNext()}
              sx={{ background: '#DFD8FF ' }}
            >
              <RightArrowIcon
                rootStyle={{ width: '8px', height: '8px', color: '#6552CC' }}
              />
            </IconButton>
          </Stack>

          <Box mt={1} p={0.75}>
            <Typography sx={mbfStepperBanner_style.titileSx}>
              {title}
            </Typography>
            <Typography sx={mbfStepperBanner_style.subTitileSx}>
              {subTitle}
            </Typography>
          </Box>
        </Grid>
      </Grid>{' '}
    </Box>
  );
}

MbfStepperBanner.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MbfStepperBanner };

