export const vital_monitoring_style = {
  rootSx: {
    px: 3,
    pt: 10,
    pb: 3,
    bgcolor: '#F5F5F5',
  },
  inputSx: {
    width: 80,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderBottom: '2px solid #E2E2E2',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        // width:69
      },
      '&:hover fieldset': {
        // borderColor: 'border.hover',
        borderBottom: '2px solid #E2E2E2',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
      },
      '&.Mui-disabled fieldset': {
        borderBottom: '2px solid #E2E2E2',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
      },
      '&.Mui-focused fieldset': {
        borderBottom: '2px solid #E2E2E2',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
      },
    },
  },
};
