export const doctorProfile_style = {
  rootSx: {
    boxShadow: 'none',
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
  },
  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  pricingBoxSx: {
    // display: 'inline-block',
    width: 'auto',
    backgroundColor: 'secondary.main',
    marginLeft: '10px',
    position: 'relative',
    color: 'white',
    fontSize: '12px',
    borderRadius: '5px',
    lineHeight: '20px',
    px: 0.5,
    py: 0.5,
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      left: '-11px',
      top: '0px',
      width: 0,
      height: 0,
      borderTop: '14px solid transparent',
      borderBottom: '14px solid transparent',
      borderRight: '14px solid',
      borderRightColor: 'secondary.main',
      borderRadius: '6px',
    },
  },
  profilepicSx: {
    width: '44px',
    height: '44px',
  },
  assitantSx: {
    color: 'text.hint',
    fontSize: '12px !important',
    fontWeight: 'medium',
    ml: 1,
    // width: '100px',
  },
  profilepicSxassitantSx: {
    color: 'text.hint',
    fontSize: '12px!important',
    fontWeight: 'medium',
    ml: 1,
  },
  tagSx: {
    // display: 'flex',
    // alignItems: 'start',
  },
  doctorSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 0.5,
  },
  patientSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 0.5,
  },
  pricingRequiredSx: {
    fontFamily: 'Roboto',
    padding: '0px 2px',
    fontSize: '12px',
  },
  labelSxx: {
    // backgroundColor: 'PrimaryTints.A100',
    p: 0.4,
    borderRadius: '4px',
    color: '#868484',
    fontSize: '12px',
    ml: 0.8,
    fontWeight: 600,
  },
};
