export const myFamilyScreen_style = {
  addBtnSx: {
    textTranform: 'inherit',
  },
  boxRootSx: {
    px: 2.5,
  },
  familySX: {
    my: 1.5,
  },
  iconSx: {
    width: '100%',
    maxWidth: '240px',
    height: '202px',
    backgroundRepeat: 'no-repeat',
    margin: '0px auto',
  },
  childreDrawerSx: {
    display: 'flex',
    jutifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    mt: 1,
  },
  rootchildrenSx: {
    p: 1.25,
    mr: 1,
    mt: 1,
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'PrimaryTints.A100',
  },
  checkingSx: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      pb: 0,
    },
  },
  privacyIconSx: {
    ml: 0.75,
  },
  customSx: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  showSx: { pt: 2, pb: 2, fontSize: '14px', color: 'text.primary', fontWeight: 600 },
};
