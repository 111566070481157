import mbfBckgroundimg from '@assets/mbfBckgroundimg.svg';

export const MbfMoodSplash_style = {
  tickGifSx: {
    width: '100px',
    height: '100px',
  },
  rootSx: {
    minHeight: '100vh',
    background: '#F3F0FF',
  },
  descTypoSx: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
    color: '#6552CC',
  },
  containerSx: {
    height: '90vh',
    backgroundImage: `url(${mbfBckgroundimg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleTypoSx: {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: 600,
    color: '#6552CC',
    pb: 1,
  },
};
