import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity, useMBFMoodStats } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { MoodSelection } from '@hc/ui/components/clinical/mindBodyFood/moodSelection';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { moodForYou_style } from './style';

export default function MoodForYou(props) {
  const {
    whatsUp = "What's your mood for today?",
    className = '',
    ...rest
  } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const navigate = useNavigate();
  const { mbfMood, updateMoodStats, loading } = useMBFMoodStats((state) => ({
    mbfMood: state.mbfMood,
    updateMoodStats: state.updateMoodStats,
    loading: state.loading,
  }));

  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { status } = checkPlanData;

  const [hover, setHover] = useState({
    isActive: '',
  });

  // onHoverbtn
  const onHoverBtn = (active) => {
    if (hover.isActive === active) {
      setHover({ isActive: '' });
    } else {
      setHover({ isActive: active });
    }
  };

  const onSkipBtn = () => {
    navigate(clinicalRoutes.mindbodyfood, {
      state: {
        skip: true,
      },
    });
  };

  const onUpdateBtn = async () => {
    const { statusCode, mood } = await updateMoodStats(
      data?.user_id,
      hover?.isActive
    );
    if (statusCode === 200) {
      navigate(clinicalRoutes?.mbfMoodSplash, {
        state: {
          moodName: mood,
        },
      });
    }
  };

  const initialData = () => {
    if ((mbfMood !== null && status === 4) || status !== 4) {
      navigate(clinicalRoutes?.mindbodyfood);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  window.addEventListener('popstate', () => {
    navigate(clinicalRoutes.mindbodyfood);
  });

  return (
    <Box sx={moodForYou_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={moodForYou_style.containerSx}>
        {/* Title */}
        <Box sx={moodForYou_style.TotalSx}>
          <Typography sx={moodForYou_style.titleSx}>
            Hi {data?.name ?? ''}
          </Typography>
          <Typography sx={moodForYou_style.subTitleSx}>{whatsUp}</Typography>
        </Box>

        {/* Mood Selection */}
        <Box sx={moodForYou_style.totalEmojiSx}>
          <MoodSelection
            hover={hover.isActive}
            setHover={setHover}
            onHoverBtn={onHoverBtn}
          />
        </Box>
      </Box>

      {/* Footer Button */}
      <Box sx={{ ...moodForYou_style.footerSx }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <Button sx={moodForYou_style.skipSx} onClick={() => onSkipBtn()}>
              Skip
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Button
              disabled={!hover.isActive}
              onClick={() => onUpdateBtn()}
              sx={moodForYou_style.updateSx}
              fullwidth
              loading={loading}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
