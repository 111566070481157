export const stepper_style = {
  contentWrapSx: {
    '&::before': {
      content: '""',
      backgroundColor: 'purple.300',
      color: 'purple.600',
      // padding:'10px',
      // fontSize:'16px',
      position: 'absolute ',
      borderLeft: '2px dashed  !important',
      height: '100%',
      left: '11px',
      margin: '3px 1px',
    },
    '&:last-child': {
      '&: before': {
        borderLeft: '0px !important',
        content: '""',
      },
    },
    display: 'grid',
    position: 'relative',
  },
  completedSx: {
    color: 'green',
  },
  stepNumberSx: {
    borderRadius: '50%',
    color: 'mbf.main',
    fontWeight: '700',
    fontSize: '10px',
    position: 'absolute ',
    textAlign: 'center',
    margin: '1px 1px',
    height: '19px',
    width: '19px',
  },
  stepPointsSx: {
    marginLeft: '12%',
  },
  stepPointsHeadingSx: {
    color: 'text.primary',
    fontWeight: '600',
    fontSize: '16px',
  },
  stepPointsDescSx: {
    color: 'text.hint',
    fontSize: '14px',
    marginTop: '4px',
    marginBottom: '21px',
  },
};
