import { useFamilyMember } from '@hc/store';
import { FamilyMemberCard } from '@hc/ui/components/clinical/familyProfile/familyMemberCard';
import { getFirstLetterOfName } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import React from 'react';
import isEqual from 'react-fast-compare';

export function SelectFamilyMemberCard() {
  // const { className = '', ...rest } = props;

  // Store Data
  const { searchFamilyData } = useFamilyMember(
    (state) => ({
      searchFamilyData: state.searchFamilyData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  return (
    <Box>
      <FamilyMemberCard
        name={searchFamilyData?.name}
        profileName={getFirstLetterOfName(searchFamilyData?.name)}
        gender={searchFamilyData?.gender}
        src={searchFamilyData?.profile_pic}
        age={searchFamilyData?.age}
      />
    </Box>
  );
}
