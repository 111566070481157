import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useMedication } from '@hc/store/clinical/medication';
import { SearchIcon, SearchSelect } from '@hc/ui/atoms';
import { QusAnsSelectionLisiting } from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useKeyPress } from 'ahooks';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MedicationSelection_style } from './style';

export function MedicationSelection() {
  const filter = createFilterOptions();
  const { medicationData, get } = useMedication(
    (state) => ({
      medicationData: state?.medicationData,
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bookAppointmentState, clearPageData, deleteData, addMedication } =
    useBookAppointment(
      (state) => ({
        bookAppointmentState: state?.bookAppointmentState,
        clearPageData: state.clearPageData,
        deleteData: state.deleteData,
        addMedication: state.addMedication,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const { drugs } = bookAppointmentState;
  const [searchInput, setSearchInput] = useState(null);
  const [addMedications, setAddMedications] = useState('');
  // General Hooks

  // ADD MEDICATION FUNCTION
  const handleAdd = (e, option) => {
    if (option?.value) {
      // ADD MEDICATION
      addMedication(e, option);
      // GET MEDICATION DATA
      get();
      setSearchInput({ value: '', label: '', labelShow: '' });
      setAddMedications('');
    }
  };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  // DELETE DRUG FUNCTION
  const handleDelete = (i) => {
    // DELETE DRUG
    deleteData('drugs', i);
  };

  // CLEAR DRUG FUNCTION
  const handleClear = () => {
    // CLEAR DRUG
    clearPageData('drugs');
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  // GET MEDICATION DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <QusAnsSelectionLisiting
      QusAnsSelectionHeading="Are you under medications currently?"
      QusAnsListingHeading="Medications"
      QusAnsListingAdded="Added"
      OnClearAll={handleClear}
      displaySelectionValues={drugs}
      OnDelete={handleDelete}
      sugesstionClick={handleAdd}
      qsOptionViewKey="label"
      QusAnsSelectionListing={
        <SearchSelect
          startAdornment={<SearchIcon rootStyle={{ color: 'grey.500' }} />}
          placeholder="Eg. Azithromycin (500 mg)"
          onChange={handleAdd}
          renderOption={(props, option) => {
            return (
              <Box
                sx={{ cursor: 'pointer' }}
                height="40px"
                onClick={(e) => handleAdd(e, option)}
                px={2}
                key={option.label}
                display="flex"
                {...props}
              >
                {option?.label?.includes('ADD') ? (
                  <Box display="flex" alignSelf="center">
                    <Typography
                      sx={{
                        color: '#EB6D13',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      +ADD &nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {addMedications}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignSelf="center">
                    <Typography>{option.label}</Typography>
                  </Box>
                )}
              </Box>
            );
          }}
          filterOptions={(options, params) => getFilteredadata(options, params)}
          autocompeleteStyle={{
            ...MedicationSelection_style.autocompeleteSx,
          }}
          onChangeText={(e) => setAddMedications(e?.target?.value ?? '')}
          optionViewKey="label"
          value={searchInput}
          options={medicationData.filter(
            (medication) =>
              !drugs.some(
                (eachMedication) => eachMedication?.label === medication?.label
              )
          )}
        />
      }
    />
  );
}
