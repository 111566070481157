import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { chart_style } from '../style';

function JourneyAreaChart(props) {
  const {
    className = '',
    data = [],
    rootStyle = {},
    height,
    legendFontSize,
    label = '',
    width = '100%',
    showTooltip,
    from = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...chart_style.chartSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box
        pl={1}
        pb={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" fontWeight={600}>
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            fontWeight: 'medium',
            color: '#888888',
            mx: 1,
          }}
          fontWeight={600}
        >
          from :
          <span style={{ color: '#1FB476', fontSize: '12px' }}>{from}</span>
        </Typography>
      </Box>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          fontFamily="Poppins, sans-serif"
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis fontSize={8} color="#C5C5C5" dataKey="name" />
          <YAxis
            fontSize={8}
            color="#C5C5C5"
            tickFormatter={(tick) => `${tick}%`}
          />
          {showTooltip && (
            <Tooltip
              labelStyle={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: legendFontSize,
              }}
              contentStyle={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: legendFontSize,
              }}
              cursor={false}
            />
          )}
          <Area
            type="monotone"
            dataKey="value"
            stroke="#1FB476"
            fill="#E1FFF3"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}

export { JourneyAreaChart };

JourneyAreaChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  width: PropTypes.string,
  height: PropTypes.number,
  legendFontSize: PropTypes.number,
  chartType: PropTypes.string,
  showTooltip: PropTypes.bool,
  label: PropTypes.string,
  rootStyle: PropTypes.object,
  from: PropTypes.string,
};
