export const appointmentList_style = {
  rootSx: {
    p: 1,
  },
  titleSx: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  headerSx: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#18574CB7',
    fontWeight:500
  },
  noDataFound: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
  },
};
