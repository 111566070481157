export const bugReport_style = {
  boxRootSx: {
    px: 2.5,
  },
  subHeadSx: {
    fontSize: '16px',
    color: 'text.primary',
    p: 5.5,
    fontWeight: '25px',
  },
  customtextSx: {
    '& .MuiOutlinedInput-input': {
      height: '100px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      lineHeight: '25px',
      p: 0,
    },
  },
  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: 'primary.main',
    // opacity: '48%',
    height: '132px',
    // marginTop: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  browseTextSx: {
    fontSize: '14px',
    color: ' PrimaryTints.A100',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },
  childSx: { minHeight: '60vh', pt: 0, backgroundColor: 'common.white' },
  errorText: { fontSize: '12px', mt: 1, color: '#f00' },

  addMoreButtonSx: {
    fontSize: 14,
    color: 'primary.main',
    fontWeight: 'medium',
  },
};
