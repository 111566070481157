import { IllustrationCard } from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { Box } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { HealthIssuesIcon, HealthQueriesIcon } from '@hc/ui/atoms/illustration';
import { consultationReason_style } from './style';

export default function ConsultationReason(props) {
  const { className = '', ...rest } = props;

  const {
    bookAppointmentState,
    updateBAState,
    // loading: loader,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      updateBAState: state.updateBAState,
      bookeAppointmentState: state.bookeAppointmentState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { booking_method } = bookAppointmentState;

  const navigate = useNavigate();

  const ConsultationReason = (value) => {
    if (value === 'Health Issues') {
      // UPDATE STATE
      updateBAState('reason_for_consultation', value);
      // NAVIGATE TO HEALTH ISSUE ROUTE
      navigate(clinicalRoutes.healthIssues);
    }
    if (value === 'Health Queries') {
      updateBAState('reason_for_consultation', value);
      window.open(
        'https://healthcircles-support.freshdesk.com/support/tickets/new',
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  return (
    <Box
      sx={consultationReason_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={consultationReason_style.boxRootSx}>
        <Screenlayout
          notshowFooter
          backRequired
          backRoute={
            booking_method === 'myself'
              ? clinicalRoutes.selectPatient
              : booking_method === 'family'
              ? clinicalRoutes.selectFamilyMember
              : booking_method === 'others'
              ? clinicalRoutes.patientDetails
              : clinicalRoutes.selectPatient
          }
          title="Book Appointment"
        >
          <Box sx={consultationReason_style.BookingTextSx}>
            What is the reason for consultation?
          </Box>
          <Box mt={2} onClick={() => ConsultationReason('Health Issues')}>
            <IllustrationCard
              healthStatus="Health Issues"
              healthStatusContent="Mention your health issues and book an appointment"
              icon={<HealthIssuesIcon />}
              rootStyle={consultationReason_style.healthIssuesImageSx}
            />
          </Box>
          <Box mt={2} onClick={() => ConsultationReason('Health Queries')}>
            <IllustrationCard
              healthStatus="Health Queries"
              healthStatusContent="Raise your health queries and book an appointment"
              icon={<HealthQueriesIcon />}
              rootStyle={consultationReason_style.healthQueriesImageSx}
            />
          </Box>
        </Screenlayout>
      </Box>
    </Box>
  );
}
