/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-key */
// import { EditIcon } from '@atoms';
import { CheckBox } from '@atoms/checkBox';
import { CloseIcon, FilterIcon, SearchIcon } from '@atoms/icons';
import { Input } from '@atoms/input';
import { ReportFilter } from '@components';
import {
  getEndOfMonth,
  getEndOfWeeks,
  getEndOfYear,
  getStartOfMonth,
  getStartOfWeeks,
  getStartOfYear,
} from '@hc/dayjs';
import {
  useFamilyMember,
  useRelationShip,
  useReport,
  useReportType,
} from '@hc/store';
import { localStorageKeys, queryClient } from '@hc/utils';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Badge, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation } from 'react-router-dom';
import { ReportCardList } from '../../reportCardList';
import { report_list_style } from './style';

export function ReportLists(props) {
  const { className = '', tabIndex = '1', loading, ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const keydata = parseJwt(authToken);
  const location = useLocation();
  const IsBookAppoinmentReport = location?.state?.bookAppoinment;
  const [filterShow, setFilterShow] = useState(false);
  const [showFilterDrop, setShowFilterDrop] = useState(true);
  const [searchFor, setSearchFor] = useState([]);
  const [isSelf, setIsSelf] = useState(false);

  // General hooks

  const {
    reportState,
    getAllUploadedReports,
    getAllCaseSummaryReports,
    updateReportStore,
    upsertFilterData,
    clearFilterData,
    deleteReport,
    setSelfFilter,
    selectAllStoreUpdate,
    selectDeselectStore,
  } = useReport(
    (state) => ({
      setSelfFilter: state.setSelfFilter,
      deleteReport: state.deleteReport,
      reportState: state.reportState,
      getAllUploadedReports: state.getAllUploadedReports,
      getAllCaseSummaryReports: state.getAllCaseSummaryReports,
      updateReportStore: state.updateReportStore,
      upsertFilterData: state.upsertFilterData,
      clearFilterData: state.clearFilterData,
      selectAllStoreUpdate: state.selectAllStoreUpdate,
      selectDeselectStore: state.selectDeselectStore,
      clearBookAppointmentReports: state.clearBookAppointmentReports,
      updateReportStoreState: state.updateReportStoreState,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const { familyMembers, getAll } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      getAll: state.getAll,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { uploadReports, caseSummaryReports, searchNameReportFor, filterData } =
    reportState;
  const { getReportType, reportTypeData } = useReportType(
    (state) => ({
      getReportType: state?.getReportType,
      reportTypeData: state.reportTypeData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { get } = useRelationShip(
    (state) => ({
      get: state?.get,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const filters = [
    {
      name: 'Report Type',
      keyName: 'reportType',
      data: reportTypeData,
    },
    {
      name: 'Report For',
      keyName: 'reportFor',
      data: searchFor,
    },
  ];

  const dateFilterData = [
    { label: 'All', value: 'allDates' },
    { label: 'This Week', value: 'thisWeek' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'This Year', value: 'thisYear' },
  ];

  const IsBookAppoinmentReportFilter = filters.slice(0, -1);
  const IsBookAppoinmentCaseSummaryFilter = filters.slice(0, -2);
  const caseSummaryFilter = [
    {
      name: 'Case Summary For',
      keyName: 'reportFor',
      data: searchFor,
    },
  ];

  const handleChangeFns = async (event, tabIndex, reportId) => {
    await selectDeselectStore(
      tabIndex === '1'
        ? 'uploadedId'
        : tabIndex === '2'
        ? 'caseSummaryId'
        : 'uploadedId',
      reportId,
    );
  };

  const handleOnClick = () => {
    selectAllStoreUpdate(
      tabIndex === '1' ? 'selectAllReport' : 'selectAllCaseSummary',
      tabIndex === '1' ? 'uploadReportsCopy' : 'caseSummaryReportsCopy',
      tabIndex === '1' ? 'uploadedId' : 'caseSummaryId',
    );
  };

  const handleDrawerOpen = () => {
    setFilterShow(true);
    const filterDataCopy = JSON.parse(JSON.stringify(familyMembers));

    const resArr = [];

    const arr = [
      {
        label: 'All',
        value: 'All',
      },
      {
        label: 'Self',
        value: 'self',
      },
    ];
    for (const data of arr) {
      const obj = {
        label: data?.label,
        value: data?.value,
      };
      resArr.push(obj);
    }

    for (const data of filterDataCopy) {
      const obj = {
        label: data?.name,
        value: data?.family_member_id,
      };
      resArr.push(obj);
    }

    setSearchFor(resArr);
  };

  const getInitialReportData = async () => {
    if (tabIndex === '1') {
      queryClient?.invalidateQueries({
        queryKey: ['report-uploaded'],
      });
      await getAllUploadedReports(keydata?.user_id, tabIndex);
    } else if (tabIndex === '2') {
      queryClient?.invalidateQueries({
        queryKey: ['report-caseSummary'],
      });
      await getAllCaseSummaryReports(keydata?.user_id, tabIndex);
    }
  };

  const setOnSearchFnc = async (val) => {
    await updateReportStore('searchNameReportFor', val?.target?.value);
    getInitialReportData();
  };

  const handleClickFunction = (reportType, value, label) => {
    setIsSelf(false);
    if (value) {
      setShowFilterDrop(false);
    } else {
      setShowFilterDrop(true);
    }
    if (reportType === 'reportDate') {
      if (value === 'allDates') {
        upsertFilterData('searchStartReportDate', '');
        upsertFilterData('searchEndReportDate', '');
        upsertFilterData('searchReportDateType', value);
      } else if (value === 'thisWeek') {
        const start_of_week = getStartOfWeeks('YYYY-MM-DD');
        const end_of_week = getEndOfWeeks('YYYY-MM-DD');
        upsertFilterData('searchStartReportDate', start_of_week ?? '');
        upsertFilterData('searchEndReportDate', end_of_week ?? '');
        upsertFilterData('searchReportDateType', value);
      } else if (value === 'thisMonth') {
        const start_of_month = getStartOfMonth('YYYY-MM-DD');
        const end_of_month = getEndOfMonth('YYYY-MM-DD');
        upsertFilterData('searchStartReportDate', start_of_month ?? '');
        upsertFilterData('searchEndReportDate', end_of_month ?? '');
        upsertFilterData('searchReportDateType', value);
      } else if (value === 'thisYear') {
        const start_of_year = getStartOfYear('YYYY-MM-DD');
        const end_of_year = getEndOfYear('YYYY-MM-DD');
        upsertFilterData('searchStartReportDate', start_of_year ?? '');
        upsertFilterData('searchEndReportDate', end_of_year ?? '');
        upsertFilterData('searchReportDateType', value);
      }
    } else if (reportType === 'reportType') {
      upsertFilterData('searchReportType', value);
    } else if (reportType === 'reportFor') {
      if (label !== 'Self') {
        upsertFilterData('searchReportFor', label);
      } else {
        setIsSelf(true);
        upsertFilterData('searchReportFor', label);
      }
    }
  };

  const onApplyFilter = async () => {
    if (isSelf) {
      getInitialReportData();
      setSelfFilter();
      setFilterShow(false);
    } else {
      getInitialReportData();
      setFilterShow(false);
    }
  };

  const clearFilterDataFns = async () => {
    setIsSelf(false);
    await clearFilterData();
    setShowFilterDrop(true);
  };

  const deleteClick = async (value, tabIndex) => {
    await deleteReport(keydata?.user_id, value, tabIndex, false);
  };

  const handleSearchClear = async () => {
    updateReportStore('searchNameReportFor', '');
    getInitialReportData();
  };

  useEffect(() => {
    getInitialReportData();
    getAll();
  }, []);

  useEffect(() => {
    getReportType('filter');
  }, []);

  useEffect(() => {
    get('filter');
  }, []);

  return (
    <Box
      sx={{ ...report_list_style.rootSx }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Box sx={report_list_style.selectedBoxSx}>
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={10}>
              <Input
                id="search"
                placeholder={
                  tabIndex === '1'
                    ? 'Report Name, Report For'
                    : 'Doctor Name, Case Summary For'
                }
                textFieldStyle={report_list_style.searchFiledSx}
                onChange={setOnSearchFnc}
                value={searchNameReportFor}
                startAdornment={
                  !searchNameReportFor?.length > 0 && <SearchIcon />
                }
                endAdornment={
                  searchNameReportFor?.length > 0 && (
                    <Box onClick={handleSearchClear} sx={{ cursor: 'pointer' }}>
                      <CloseIcon />
                    </Box>
                  )
                }
              />
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={report_list_style.filterBoxSx}
                display="flex"
                justifyContent="center"
                onClick={handleDrawerOpen}
              >
                {showFilterDrop ? (
                  <FilterIcon id="filter" />
                ) : (
                  <Badge color="primary" badgeContent=" " variant="dot">
                    <FilterIcon id="filterApplied" />
                  </Badge>
                )}
              </Box>
            </Grid>
          </Grid>

          {IsBookAppoinmentReport && (
            <ReportFilter
              onCancel={clearFilterDataFns}
              onApply={onApplyFilter}
              setFilterShow={setFilterShow}
              filterShow={filterShow}
              filters={
                tabIndex === '1'
                  ? IsBookAppoinmentReportFilter
                  : IsBookAppoinmentCaseSummaryFilter
              }
              dateFilterData={dateFilterData}
              IsBookAppoinmentReport
              filterName={
                tabIndex === '1'
                  ? 'Report Issue Date'
                  : tabIndex === '2'
                  ? 'Case Summary Date'
                  : 'Report Date'
              }
              handleClickFnc={handleClickFunction}
              filterData={filterData}
            />
          )}

          {!IsBookAppoinmentReport && (
            <ReportFilter
              onCancel={() => clearFilterDataFns()}
              onApply={() => onApplyFilter()}
              setFilterShow={setFilterShow}
              filterShow={filterShow}
              filters={tabIndex === '1' ? filters : caseSummaryFilter}
              dateFilterData={dateFilterData}
              handleClickFnc={handleClickFunction}
              filterData={filterData}
              tabIndex={tabIndex}
            />
          )}
          {/* <BookAppoinmentFilter /> */}

          {IsBookAppoinmentReport && (
            <Box pt={2.4}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography fontSize={16} fontWeight={600}>
                    {tabIndex === '1'
                      ? `Selected Report (${
                          reportState?.uploadReportsCopy?.length -
                            reportState?.deselectedReports?.uploadedId?.length >
                          0
                            ? reportState?.uploadReportsCopy?.length -
                              reportState?.deselectedReports?.uploadedId?.length
                            : 0
                        })`
                      : `Selected Summary (${
                          reportState?.caseSummaryReportsCopy?.length -
                            reportState?.deselectedReports?.caseSummaryId
                              ?.length >
                          0
                            ? reportState?.caseSummaryReportsCopy?.length -
                              reportState?.deselectedReports?.caseSummaryId
                                ?.length
                            : 0
                        })`}
                  </Typography>
                </Grid>
                <Grid item display="flex">
                  <Typography fontSize={16} fontWeight={500}>
                    All
                  </Typography>
                  <Box pl={1}>
                    <CheckBox
                      onChange={() =>
                        tabIndex === '1' &&
                        reportState?.uploadReportsCopy?.length > 0
                          ? handleOnClick()
                          : tabIndex === '2' &&
                            reportState?.caseSummaryReportsCopy?.length > 0
                          ? handleOnClick()
                          : null
                      }
                      checked={
                        tabIndex === '1' &&
                        reportState?.deselectedReports?.uploadedId?.length === 0
                          ? true
                          : !!(
                              tabIndex === '2' &&
                              reportState?.deselectedReports?.caseSummaryId
                                ?.length === 0
                            )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <ReportCardList
          uploadReports={uploadReports}
          caseSummaryReports={caseSummaryReports}
          tabIndex={tabIndex}
          handleChangeFns={handleChangeFns}
          reportState={reportState}
          IsBookAppoinmentReport={IsBookAppoinmentReport}
          deleteClick={deleteClick}
          loading={loading}
        />
      </Box>
    </Box>
  );
}
ReportLists.propTypes = {
  className: PropTypes.node,
  tabIndex: PropTypes.number,
};
