import { getDateValue, getTimeValue } from '@hc/utils/helperFunctions';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  ChatStartIllustration,
  DoctorMedicalRepIcon,
  UploadCloseIcon,
  VitalMeterIcon,
} from '../../atoms';
import { EmptyStateComponent, PatientReportUploadCard } from '../clinical';
import { chatListingBox_style } from './style';

function ChatListingBox(props) {
  const {
    className = '',
    data = [],
    isDoctor,
    rootStyle = {},
    ...rest
  } = props;

  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  return (
    <Box
      sx={{
        ...chatListingBox_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {data?.length > 0 ? (
        data?.map((obj, i) => (
          <Grid container key={i}>
            <Grid item xs={12}>
              <Typography
                mt={2.5}
                mb={3.5}
                textAlign="center"
                fontWeight={500}
                fontSize={14}
              >
                {getDateValue(obj?.date ?? '') ?? ''}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {obj?.messageData?.map((object, i) => (
                <Box key={i}>
                  {(object?.user === 'patient' &&
                    !isDoctor &&
                    object?.type !== 5 &&
                    object?.type !== 4 &&
                    object?.type !== 6) ||
                  (object?.user !== 'patient' &&
                    isDoctor &&
                    object?.type !== 5 &&
                    object?.type !== 4 &&
                    object?.type !== 6) ? (
                    <Grid container justifyContent="end" mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.senderMessageContentSx}>
                          <Typography fontSize={12} alignSelf="center" mr={1}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                          <Typography
                            fontSize={14}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            You
                          </Typography>
                        </Box>
                        {object?.message?.length > 0 ? (
                          <Box sx={chatListingBox_style?.senderMessageSx}>
                            <Typography
                              alignSelf="center"
                              fontWeight={500}
                              fontSize={12}
                            >
                              {object?.message ?? ''}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={chatListingBox_style?.senderMessageFileSx}>
                            {object?.message_json?.map((obj, i) => (
                              <PatientReportUploadCard
                                key={i}
                                showReportName
                                isChat
                                showCheckBox
                                icon={<UploadCloseIcon />}
                                isUploadCard
                                reportName={obj?.filename ?? ''}
                                uploadedFor={obj?.size ?? ''}
                                uploadedData={[
                                  {
                                    file: `${obj?.downloadUrl ?? ''}`,
                                  },
                                ]}
                              />
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ) : (object?.user === 'patient' &&
                      isDoctor &&
                      object?.type !== 5 &&
                      object?.type !== 4 &&
                      object?.type !== 6) ||
                    (object?.user !== 'patient' &&
                      !isDoctor &&
                      object?.type !== 5 &&
                      object?.type !== 4 &&
                      object?.type !== 6) ? (
                    <Grid container mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.reciverMessageContentSx}>
                          <Typography
                            fontSize={14}
                            mr={1}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            {object?.senderName ?? ''}
                          </Typography>
                          <Typography alignSelf="center" fontSize={12}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                        </Box>
                        {object?.message?.length > 0 ? (
                          <Box sx={chatListingBox_style?.reciverMessageSx}>
                            <Typography
                              alignSelf="center"
                              fontWeight={500}
                              fontSize={12}
                            >
                              {object?.message ?? ''}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={chatListingBox_style?.reciverMessageFileSx}>
                            {object?.message_json?.map((obj, i) => (
                              <PatientReportUploadCard
                                key={i}
                                showReportName
                                isChat
                                showCheckBox
                                icon={<UploadCloseIcon />}
                                isUploadCard
                                reportName={obj?.filename ?? ''}
                                uploadedFor={obj?.size ?? ''}
                                uploadedData={[
                                  {
                                    file: `${obj?.downloadUrl ?? ''}`,
                                  },
                                ]}
                              />
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ) : (object?.user === 'patient' &&
                      isDoctor &&
                      object?.type === 4) ||
                    (object?.user !== 'patient' &&
                      !isDoctor &&
                      object?.type === 4) ? (
                    <Grid container mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.reciverMessageContentSx}>
                          <Typography
                            fontSize={14}
                            mr={1}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            {object?.senderName ?? ''}
                          </Typography>
                          <Typography alignSelf="center" fontSize={12}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                        </Box>
                        {/* {object?.message?.length > 0 && ( */}
                        <Box
                          sx={{
                            ...chatListingBox_style?.reciverMessageSx,
                            display: 'flow',
                          }}
                        >
                          <Box display="flex">
                            <VitalMeterIcon />
                            <Typography ml={0.5} fontSize={12} fontWeight={600}>
                              Vitals
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                pl: 1,
                                '& span': { color: '#C5C5C5' },
                              }}
                            >
                              {`${object?.message_json?.vitals?.height} Ft`}{' '}
                              <span>(Height) </span>,
                              {`${object?.message_json?.vitals?.weight} Kg`}
                              <span>(weight) </span>,<br />
                              {`${object?.message_json?.vitals?.temp_in_f} Temp`}{' '}
                              <span>(temp) </span>,
                              {`${object?.message_json?.vitals?.puls_bpm} Ft`}{' '}
                              <span>(Pulse) </span>, <br />
                              {`${
                                object?.message_json?.vitals?.bp ?? ''
                              } Ft`}{' '}
                              <span>(BP) </span>,
                              {`${object?.message_json?.vitals?.o2_level} O2`}{' '}
                              <span>(O2) </span>
                            </Typography>
                          </Box>
                        </Box>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  ) : (object?.user === 'patient' &&
                      !isDoctor &&
                      object?.type === 4) ||
                    (object?.user !== 'patient' &&
                      isDoctor &&
                      object?.type === 4) ? (
                    <Grid container justifyContent="end" mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.senderMessageContentSx}>
                          <Typography
                            fontSize={14}
                            mr={1}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            {object?.senderName ?? ''}
                          </Typography>
                          <Typography alignSelf="center" fontSize={12}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                        </Box>
                        {/* {object?.message?.length > 0 && ( */}
                        <Box
                          sx={{
                            ...chatListingBox_style?.senderMessageSx,
                            display: 'flow',
                          }}
                        >
                          <Box display="flex">
                            <VitalMeterIcon />
                            <Typography ml={0.5} fontSize={12} fontWeight={600}>
                              Vitals
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                pl: 1,
                                '& span': { color: '#C5C5C5' },
                              }}
                            >
                              {`${object?.message_json?.vitals?.height} Ft`}{' '}
                              <span>(Height) </span>,
                              {`${object?.message_json?.vitals?.weight} Kg`}
                              <span>(weight) </span>,<br />
                              {`${object?.message_json?.vitals?.temp_in_f} Temp`}{' '}
                              <span>(temp) </span>,
                              {`${object?.message_json?.vitals?.puls_bpm} Ft`}{' '}
                              <span>(Pulse) </span>, <br />
                              {`${
                                object?.message_json?.vitals?.bp ?? ''
                              } Ft`}{' '}
                              <span>(BP) </span>,
                              {`${object?.message_json?.vitals?.o2_level} O2`}{' '}
                              <span>(O2) </span>
                            </Typography>
                          </Box>
                        </Box>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  ) : (object?.user === 'patient' &&
                      isDoctor &&
                      object?.type === 6) ||
                    (object?.user !== 'patient' &&
                      !isDoctor &&
                      object?.type === 6) ? (
                    <Grid container mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.reciverMessageContentSx}>
                          <Typography
                            fontSize={14}
                            mr={1}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            {object?.senderName ?? ''}
                          </Typography>
                          <Typography alignSelf="center" fontSize={12}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                        </Box>
                        {/* {object?.message?.length > 0 && ( */}
                        <Box
                          sx={{
                            ...chatListingBox_style?.reciverMessageSx,
                            display: 'flow',
                          }}
                        >
                          <Box mt={1}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center' }}
                              mb={1}
                              px={1}
                            >
                              <DoctorMedicalRepIcon />
                              <Typography
                                fontSize="14px"
                                fontWeight={600}
                                color="#0E1824"
                                px={0.6}
                              >
                                Medical Condition
                              </Typography>
                            </Box>

                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Allergies
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_allergies?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Medication
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_active_medications?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Health Problems
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_health_problems?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  ) : (object?.user === 'patient' &&
                      !isDoctor &&
                      object?.type === 6) ||
                    (object?.user !== 'patient' &&
                      isDoctor &&
                      object?.type === 6) ? (
                    <Grid container justifyContent="end" mb={2}>
                      <Grid item>
                        <Box sx={chatListingBox_style?.senderMessageContentSx}>
                          <Typography
                            fontSize={14}
                            mr={1}
                            alignSelf="center"
                            fontWeight={500}
                          >
                            {object?.senderName ?? ''}
                          </Typography>
                          <Typography alignSelf="center" fontSize={12}>
                            {getTimeValue(object?.date) ?? ''}
                          </Typography>
                        </Box>
                        {/* {object?.message?.length > 0 && ( */}
                        <Box
                          sx={{
                            ...chatListingBox_style?.senderMessageSx,
                            display: 'flow',
                          }}
                        >
                          <Box mt={1}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center' }}
                              mb={1}
                              px={1}
                            >
                              <DoctorMedicalRepIcon />
                              <Typography
                                fontSize="14px"
                                fontWeight={600}
                                color="#0E1824"
                                px={0.6}
                              >
                                Medical Condition
                              </Typography>
                            </Box>

                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Allergies
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_allergies?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Medication
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_active_medications?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                            <Divider />
                            <Typography
                              mt={1}
                              fontSize="14px"
                              fontWeight={600}
                              color="#0E1824"
                              px={2}
                            >
                              Health Problems
                            </Typography>
                            <Box p={2}>
                              {object?.message_json?.medical_condition?.medical_health_problems?.map(
                                (val, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      background: '#F5F5F5',
                                      border: '1px solid #E3E3E3',
                                      px: 1,
                                      py: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {val}
                                    </Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
              ))}
            </Grid>
            {obj?.isEnd && (
              <Grid item xs={12}>
                <Root>
                  <Divider>
                    <Typography
                      mt={2.5}
                      mb={2.5}
                      textAlign="center"
                      fontWeight={500}
                      color="#929292"
                      fontSize={12}
                    >
                      You ended this chat
                    </Typography>
                  </Divider>
                </Root>
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <EmptyStateComponent
          illustrationChild={<ChatStartIllustration />}
          mainContent="All set!"
          subContent="Now you can start your chat"
        />
      )}
    </Box>
  );
}

ChatListingBox.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.array,
  isDoctor: PropTypes.bool,
};

export { ChatListingBox };
