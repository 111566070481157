import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Healthylifestyle from '../../../../assets/Healthylifestyle.svg';
import { mbfRenewEmptyState_style } from './style';

function MbfRenewEmptyState(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...mbfRenewEmptyState_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack alignItems="center" sx={{ background: '#F7F6FF ' }}>
        <Typography sx={mbfRenewEmptyState_style.title}>
          Plan under construction
        </Typography>
        <img src={Healthylifestyle} alt="emptystate" />
        <Typography sx={mbfRenewEmptyState_style.desc}>
          Keep up your healthy habits until the <br /> plan is ready
        </Typography>
      </Stack>
    </Box>
  );
}

MbfRenewEmptyState.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MbfRenewEmptyState };
