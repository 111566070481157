import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { routeTo } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useMBFMoodStats = create((set, get) => ({
  moodDetails: {},
  mbfMood: null,
  loading: true,
  error: null,
  getTodayMoodStats: async (user_id, type) => {
    try {
      set({ loading: true });
      // To refetch the query, which was cached
      await queryClient.invalidateQueries({
        queryKey: ['getTodayMoodStats'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getTodayMoodStats'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/today-mood-status/get?user_id=${user_id}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set({
          loading: false,
          mbfMood: data && data?.mood_name ? data?.mood_name : null,
        });
        if (type === 'layout') {
          return;
        }
        if (!data?.mood_name) {
          return routeTo(useRouting, clinicalRoutes?.moodforyou);
        }
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  updateMoodStats: async (user_id, isActive) => {
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery([
        '/mbf/today-mood-status/add',
        'post',
        {
          user_id: user_id ?? '',
          mood_name: isActive ?? '',
        },
      ]);

      if (response?.status?.code === 200) {
        const { mbfMood, getTodayMoodStats } = get();
        if (!mbfMood) {
          await getTodayMoodStats(user_id);
        }
        set({
          loading: false,
          moodDetails: response?.data,
        });
        // toast.success(response?.status?.message);
        // return routeTo(useRouting, clinicalRoutes?.mindbodyfood);
        return { statusCode: response?.status?.code, mood: isActive };
      }
      toast.error('Something went wrong please try again!');
      return { statusCode: response?.status?.code, mood: isActive };
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.messgae ??
          'Something went wrong please try again!'
      );
    }
  },
}));
