export const cancelAppointment_style = {
  rootSx: {},
  cancelSx: {
    textAlign: 'center',
    pt: 3,
  },
  textSx: {
    mt: 1,
    mb: 0.5,
    fontWeight: 500,
  },
  labelSx: {
    color: 'PrimaryTints.A100',
    fontWeight: 500,
  },
  backButtonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    borderRadius: 2,
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  cancelButtonSx: {
    borderRadius: 2,
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  titleSx: {
    fontWeight: 500,
    pt: 2,
    pb: 3,
    px: 3,
    color: 'text.primary',
  },
};
