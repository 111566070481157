import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { chart_style } from '../style';

import { RenderLegend } from '../helperFunctions';

function LinedChart(props) {
  const {
    className = '',
    chartType = '',
    data = [],
    rootStyle = {},
    legendData = [],
    height,
    legendFontSize,
    line1Key = '',
    line2Key = '',
    width = '100%',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...chart_style.chartSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          width={width}
          height={height}
          data={data}
          fontFamily="Poppins, sans-serif"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" fontSize={10} color="#888888" />
          <YAxis fontSize={10} color="#888888" />
          <Tooltip
            labelStyle={{ fontSize: '10px', display: 'flex' }}
            contentStyle={{
              fontSize: '10px',
              border: 0,
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 3px 3px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)',
              borderRadius: '8px',
            }}
          />
          {chartType === 'Blood Pressure' && (
            <Legend
              content={
                <RenderLegend
                  verticalAlign="bottom"
                  data={legendData}
                  legendFontSize={legendFontSize}
                  chartStyle={{ width: '60px' }}
                />
              }
            />
          )}
          <Line
            dataKey={line1Key}
            stroke="#6552CC"
            strokeWidth={3}
            dot={{ r: 4, strokeWidth: 3 }}
            activeDot={{ r: 8 }}
          />
          {chartType === 'Blood Pressure' && (
            <Line
              dot={{ r: 4 }}
              activeDot={{ r: 8 }}
              strokeWidth={3}
              dataKey={line2Key}
              stroke="#82ca9d"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export { LinedChart };

LinedChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  width: PropTypes.string,
  height: PropTypes.number,
  legendFontSize: PropTypes.number,
  chartType: PropTypes.string,
  line1Key: PropTypes.string,
  line2Key: PropTypes.string,
  rootStyle: PropTypes.object,
  legendData: PropTypes.array,
};
