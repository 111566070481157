export const doctorAvailable_style = {
  rootSx: {
    px: 2.5,
  },
  textFieldStyle: {
    width: '100%',
    marginTop: '4px',
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  doctorAvailableTextSx: {
    fontSize: 16,
    fontWeight: 600,
    color: 'text.primary',
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  numberCountSx: {
    backgroundColor: 'text.primary',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    ml: 1,
  },
  SearchSelectSx: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '80% 15%',
    boxSizing: 'border-box',
    gap: '16px',
    mt: 2,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // mt: 2,
  },
  searchFieldSx: {
    width: '100%',
    height: '47px',
  },
  doctorCardSx: {
    mt: 0.5,
  },
  doctorAvailableSx: {
    gap: 1,
  },
  availableCardSx: {
    mt: 2,
  },
  subHeadSx: {
    fontWeight: 400,
    color: 'text.hint',
  },
  filterIconSx: {
    border: '1px solid #DBEAE8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    height: '38px',
    width: '40px',
    ml: 'auto',
    cursor: 'pointer',
    mt: 0.4,
  },
  childreDrawerSx: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 1,
    mt: 1,
  },
  rootchildrenSx: {
    pr: 1,
    py: 0.8,
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'PrimaryTints.A100',
  },
};
