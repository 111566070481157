import { getDateFormatToString } from '@hc/dayjs';
import { useDoctorDetails } from '@hc/store/clinical/doctorDetails';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import { RWebShare } from 'react-web-share';
import {
  AboutEmptyState,
  CertificateIcon,
  Drawer,
  HospitalIcon,
  InfoSpecialityIcon,
  RedAddressIcon,
  RedLanguageIcon,
  RedServingFromIcon,
  RedspecialityIcon,
  ShareIcons,
} from '../../../atoms';
import { doctorDetailDrawer_style } from './style';

function DoctorDetailDrawer(props) {
  const { drawerOpen, handleDrawerClose } = props;
  const { doctorDetail } = useDoctorDetails(
    (state) => ({
      doctorDetail: state.doctorDetail,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  return (
    <Drawer
      show={drawerOpen}
      onCloseDrawer={handleDrawerClose}
      height="80vh"
      footer={false}
      childrenStyle={{ mb: 2 }}
    >
      <Box sx={doctorDetailDrawer_style.doctornameSx}>
        <Avatar
          src={doctorDetail.profile_pic}
          sx={doctorDetailDrawer_style.profilepicSx}
        />
        <Box sx={{ px: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: '600' }}>
            {doctorDetail.first_name}
          </Typography>
          <Box sx={doctorDetailDrawer_style.doctorSx}>
            {/* {Array.isArray(role) && role?.length > 0 && <CertificateIcon />} */}
            <CertificateIcon />
            <Typography sx={doctorDetailDrawer_style.subText}>
              {' '}
              {doctorDetail.education}
            </Typography>
          </Box>
          <Box sx={doctorDetailDrawer_style.doctorSx}>
            <HospitalIcon />
            <Typography sx={doctorDetailDrawer_style.subText}>
              {' '}
              {doctorDetail?.working_at?.[0]}
            </Typography>
          </Box>
        </Box>
        <Box sx={doctorDetailDrawer_style.shareIcon}>
          <RWebShare
            data={{
              text: 'Health circles Doctor profile - ',
              url: doctorDetail.doctor_profile_share_url,
              title: 'Share to',
            }}
          >
            <ShareIcons />
          </RWebShare>
        </Box>
      </Box>
      <Divider sx={doctorDetailDrawer_style.dividerSx} />
      <Box py={2}>
        <Box sx={doctorDetailDrawer_style.iconText}>
          <RedspecialityIcon />
          <Typography sx={doctorDetailDrawer_style.titileText}>
            Speciality
          </Typography>
        </Box>
        <Box sx={doctorDetailDrawer_style.iconText}>
          {Array.isArray(doctorDetail?.doctor_specialities) &&
            doctorDetail?.doctor_specialities.map((val, i) => (
              <Typography
                key={i}
                sx={doctorDetailDrawer_style.highLightedTextSx}
              >
                {val?.master_speciality?.speciality}
              </Typography>
            ))}
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Box sx={doctorDetailDrawer_style.iconText}>
          <RedServingFromIcon />
          <Typography sx={doctorDetailDrawer_style.titileText}>
            Serving From
          </Typography>
        </Box>
        <Typography sx={doctorDetailDrawer_style.detailSx}>
          {getDateFormatToString(doctorDetail?.serving_from, 'll')}
        </Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Box sx={doctorDetailDrawer_style.iconText}>
          <RedAddressIcon />
          <Typography sx={doctorDetailDrawer_style.titileText}>
            Address
          </Typography>
        </Box>
        <Typography sx={doctorDetailDrawer_style.detailSx}>
          {doctorDetail?.address}
        </Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Box sx={doctorDetailDrawer_style.iconText}>
          <RedLanguageIcon />
          <Typography sx={doctorDetailDrawer_style.titileText}>
            Languages Known
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {Array.isArray(doctorDetail?.user_languages) &&
            doctorDetail?.user_languages.map((val, i) => (
              <Typography
                key={i}
                sx={doctorDetailDrawer_style.highLightedTextSx}
              >
                {val?.master_language?.language}
              </Typography>
            ))}
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Box sx={doctorDetailDrawer_style.iconText}>
          <InfoSpecialityIcon />
          <Typography sx={doctorDetailDrawer_style.titileText}>
            About
          </Typography>
        </Box>
        {doctorDetail?.doctorDetailabout ? (
          <Typography sx={doctorDetailDrawer_style.detailSx}>
            {doctorDetail?.doctorDetailabout}
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AboutEmptyState />
            <Typography sx={{ fontSize: '12px', color: '#0E1824' }}>
              No bio found
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

DoctorDetailDrawer.propTypes = {
  drawerOpen: PropTypes.string,
};

export { DoctorDetailDrawer };
