export const activityCard_style = {
  cardSx: {
    bgcolor: 'white',
    border: '1px solid',
    borderColor: 'purple.700',
    borderRadius: '8px',
    minHeight: '70px',
    mt: 1.5,
    cursor: 'pointer',
  },
  activityStateSx: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'text.primary',
  },
  timeWithDaySx: {
    fontSize: '10px',
    mt: 0.625,
    color: 'text.secondary',
  },
  imgSx: {
    marginLeft: '10px',
    marginTop: '10px',
    width: '48px',
    height: '48px',
    objectFit: 'cover',
  },
  iconWithCircular: {
    mt: 2,
    ml: 1,
  },
};
