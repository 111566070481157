import { clinicalRoutes } from '@hc/routes';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { Button } from '@hc/ui/atoms';
import { Screenlayout } from '@hc/ui/components';
import { VitalsFormAppointment } from '@hc/ui/components/clinical/appointmentBooking/vitalsForm';
import { isValidBloodPressure } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import { uploadReport_style } from './style';

export default function VitalsForm(props) {
  const { className = '', ...rest } = props;

  const {
    bookAppointmentState,
    updateVitalsError,
    setSkipVitals,
    setVitalsInitailData,
  } = useBookAppointment(
    (state) => ({
      setSkipVitals: state?.setSkipVitals,
      setVitalsInitailData: state?.setVitalsInitailData,
      bookAppointmentState: state?.bookAppointmentState,
      updateVitalsError: state.updateVitalsError,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { vitals } = bookAppointmentState;

  const navigate = useNavigate();
  const location = useLocation();

  // VALIDATION
  const isIamValidToSave = () => {
    const vitalsCopy = JSON.parse(JSON.stringify(vitals));

    let isValid = true;
    const error = vitalsCopy?.error;

    // Checking temp_in_f pressure
    if (vitals?.temp_in_f.length > 0) {
      if (vitals.temp_in_f < 60 || vitals.temp_in_f > 120) {
        isValid = false;
        error.temp_in_f = 'Range should be between 60 and 120';
      } else {
        error.temp_in_f = '';
      }
    } else {
      error.temp_in_f = '';
    }
    // Checking puls_bpm pressure
    if (vitals?.puls_bpm.length > 0) {
      if (vitals.puls_bpm < 50 || vitals.puls_bpm > 200) {
        isValid = false;
        error.puls_bpm = 'Range should be between 50 to 200';
      } else {
        error.puls_bpm = '';
      }
    } else {
      error.puls_bpm = '';
    }
    // Checking o2_level pressure
    if (vitals?.o2_level.length > 0) {
      if (vitals.o2_level < 85 || vitals.o2_level > 100) {
        isValid = false;
        error.o2_level = 'Range should be between 85 to 100';
      } else {
        error.o2_level = '';
      }
    } else {
      error.o2_level = '';
    }
    // Checking blood pressure
    if (vitals?.blood_pressure.length > 0) {
      if (isValidBloodPressure(vitals?.blood_pressure)) {
        if (vitals?.blood_pressure.includes('/')) {
          if (
            vitals?.blood_pressure?.split('/')[0] &&
            vitals?.blood_pressure?.split('/')[1]
          ) {
            if (
              vitals?.blood_pressure?.split('/')[0] < 70 ||
              vitals?.blood_pressure?.split('/')[0] > 300 ||
              vitals?.blood_pressure?.split('/')[1] < 70 ||
              vitals?.blood_pressure?.split('/')[1] > 300
            ) {
              isValid = false;
              error.blood_pressure =
                'Range of Systolic and diastolic between 70/300mmHg';
            } else {
              error.blood_pressure = '';
            }
          } else {
            isValid = false;
            error.blood_pressure =
              'Range of Systolic and diastolic between 70/300mmHg';
          }
        } else {
          isValid = false;
          error.blood_pressure =
            'Range of Systolic and diastolic between 70/300mmHg';
        }
      } else {
        isValid = false;
        error.blood_pressure =
          'Range of Systolic and diastolic between 70/300mmHg';
      }
    } else {
      error.blood_pressure = '';
    }
    // UPDATE VITALS ERROR
    updateVitalsError(error);
    return isValid;
  };

  const onNext = () => {
    const result = isIamValidToSave();
    if (result) {
      location?.state?.prevRoute
        ? navigate(location?.state?.prevRoute)
        : navigate(clinicalRoutes.appointmentSummary);
    }
  };

  // VITALS DATA CLEAR
  const clearForm = () => {
    setSkipVitals();
  };

  const onBack = () => {
    if (!location?.state?.prevRoute) {
      clearForm();
    }
    location?.state?.prevRoute
      ? navigate(location?.state?.prevRoute)
      : navigate(clinicalRoutes.appointmentSummary);
  };

  useEffect(() => {
    setVitalsInitailData();
  }, []);

  return (
    <Box sx={uploadReport_style.rootSx} className={`${className}`} {...rest}>
      <Screenlayout
        backRequired={
          location?.state?.prevRoute
            ? location?.state?.prevRoute
            : '/appointmentbooking/uploadreports'
        }
        title="Book Appointment"
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onBack} buttonStyle={uploadReport_style.buttonSx}>
              Skip
            </Button>
            <Button
              disabled={
                !bookAppointmentState?.vitals?.height_in_cm &&
                !bookAppointmentState?.vitals?.weight_in_cm &&
                !bookAppointmentState?.vitals?.temp_in_f &&
                !bookAppointmentState?.vitals?.puls_bpm &&
                !bookAppointmentState?.vitals?.blood_pressure &&
                !bookAppointmentState?.vitals?.o2_level
              }
              onClick={onNext}
              buttonStyle={{ textTransform: 'none' }}
            >
              Continue
            </Button>
          </Box>
        }
      >
        <VitalsFormAppointment IsReadOnly />
      </Screenlayout>
    </Box>
  );
}
