export const bookAppointmentSummary_style = {
  rootSx: {
 
     '@media (maxWidth: 360px)':{
      px: 2,
      }
  },
  summaryTextSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'text.primary',
    mt: 2,
  },
  buttonSx: {
    textTransform:"none",
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
