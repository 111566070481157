export const resetPassword_style = {
  rootSx: {
    display: 'grid',
    gridTemplateRows: '34% 66%',
    backgroundColor: 'primary.lighter',
    minHeight: '100vh',
  },
  imgContSx: {
    maxWidth: '90%',
    mx: 'auto',
    my: 'auto',
  },
  imgSx: {
    width: '256px',
    height: '164px',
    borderRadius: '0%',
  },
};
