import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, localStorageKeys, parseJwt } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const UseSeekHelp = create((set, get) => ({
  seekHelpState: {
    emailId: '',
    description: '',
    attachmentURL: [],
    error: {
      emailId: '',
      description: '',
    },
  },
  loading: false,

  upsertSeekHelp: async () => {
    const { seekHelpState, clearForm } = get();
    const { emailId, description, attachmentURL } = seekHelpState;
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const user = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/seekHelp/create`,
        {
          user_id: user?.user_id ?? '',
          email_id: emailId ?? '',
          seek_help_data: {
            content: description ?? '',
            url:
              Array.isArray(attachmentURL) && attachmentURL?.length > 0
                ? // ? attachmentURL.map((val) => val.downloadUrl)
                  attachmentURL
                : [],
            name: user?.name ?? '',
            mobile_no: user?.mobile_no ?? '',
            country_code: user.country_code,
          },
        },
        true,
      );
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message);
        clearForm();
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  changeState: (key, value) => {
    const { seekHelpState } = get();
    set({ seekHelpState: { ...seekHelpState, [key]: value } });
  },

  isValid: () => {
    const { seekHelpState } = get();
    const { emailId, description, error } = seekHelpState;
    let valid = true;
    const Error = structuredClone(error);
    // Checking email_id
    const filter = /\S+@\S+\.\S+/;
    if (emailId?.length === 0) {
      valid = false;
      Error.emailId = 'Please enter the mail';
    } else if (!filter.test(emailId)) {
      valid = false;
      Error.emailId = 'Please enter the valid mail';
    } else {
      Error.emailId = '';
    }
    if (!description) {
      valid = false;
      Error.description = 'Please enter tha description';
    } else {
      Error.description = '';
    }

    set({ seekHelpState: { ...seekHelpState, error: { ...Error } } });
    return valid;
  },

  clearForm: () => {
    const { seekHelpState } = get();
    set({
      seekHelpState: {
        ...seekHelpState,
        emailId: '',
        description: '',
        attachmentURL: [],
        error: {
          emailId: '',
          description: '',
        },
      },
    });
  },

  attachmentURLList: (data) => {
    const { seekHelpState } = get();
    const { attachmentURL } = seekHelpState;
    set({
      seekHelpState: {
        ...seekHelpState,
        attachmentURL: [...attachmentURL, data],
      },
    });
  },

  seekHelpDelete: (i) => {
    const { seekHelpState } = get();
    const { attachmentURL } = seekHelpState;
    const feedBackPicturesCopy = JSON.parse(JSON.stringify(attachmentURL));
    feedBackPicturesCopy.splice(i, 1);
    set({
      seekHelpState: { ...seekHelpState, attachmentURL: feedBackPicturesCopy },
    });
  },
}));
