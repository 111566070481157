export const myWellnessPlan_Style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  activitySx: {
    mt: 21,
    px: 2.5,
  },
  titleTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 600,
    mt: 4,
  },
  activityTypeSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 2.5,
  },
  activityTypeSxx: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    gap: 1,
    mt: 2.5,
  },
};
