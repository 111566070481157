import upgradedbg from '@assets/upgradedbg.svg';

export const UpgradedCard_style = {
  rootBoxSx: {
    borderRadius: '8px',
  },
  cardheaderSx: {
    backgroundImage: `url(${upgradedbg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-x',
    backgroundColor: '#79C5B8',
    borderRadius: '8px',
  },
  cardBodySx: {
    padding: '12px',
  },
};
