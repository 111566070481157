import { useVitalMonitoring } from '@hc/store';
import { Screenlayout, VitalMonitoringChartCalender } from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { vital_monitoring_history_style } from './style';

export default function VitalHistory(props) {
  const { className = '', ...rest } = props;
  const [tabIndex, setTabIndex] = useState('1');
  const [value, setValue] = useState(0);
  const [dates, setDates] = useState(false);

  const { vitalMonitoringState, getVitalHistoryData } = useVitalMonitoring(
    (state) => ({
      getVitalHistoryData: state.getVitalHistoryData,
      vitalMonitoringState: state.vitalMonitoringState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { vitalHistory } = vitalMonitoringState;
  const { chartData, historyData } = vitalHistory;

  console.log(vitalMonitoringState, 'vitalMonitoringState');

  const legendData = [
    { name: 'SBP', color: '#6552CC ' },
    { name: 'DBP', color: '#82ca9d' },
  ];

  const getInitialData = async (payload) => {
    queryClient.invalidateQueries({
      queryKey: ['vitalHistory'],
    });
    await getVitalHistoryData(payload);
  };
  const onBackClick = () => {
    setValue(value + 1);
    if (tabIndex === '1') {
      const startDate = moment()
        .startOf('day')
        .subtract(value + 1, 'day')
        .toISOString();
      const endDate = moment()
        .endOf('day')
        .subtract(value + 1, 'day')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else if (tabIndex === '2') {
      const startDate = moment()
        .startOf('week')
        .subtract(value + 1, 'week')
        .toISOString();
      const endDate = moment()
        .endOf('week')
        .subtract(value + 1, 'week')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else {
      const startDate = moment()
        .startOf('month')
        .subtract(value + 1, 'month')
        .toISOString();
      const endDate = moment()
        .endOf('month')
        .subtract(value + 1, 'month')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    }
  };
  const onForwardClick = () => {
    setValue(value - 1);
    if (tabIndex === '1') {
      const startDate = moment()
        .startOf('day')
        .subtract(value - 1, 'day')
        .toISOString();
      const endDate = moment()
        .endOf('day')
        .subtract(value - 1, 'day')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else if (tabIndex === '2') {
      const startDate = moment()
        .startOf('week')
        .subtract(value - 1, 'week')
        .toISOString();
      const endDate = moment()
        .endOf('week')
        .subtract(value - 1, 'week')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else {
      const startDate = moment()
        .startOf('month')
        .subtract(value - 1, 'month')
        .toISOString();
      const endDate = moment()
        .endOf('month')
        .subtract(value - 1, 'month')
        .toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    }
  };

  useEffect(() => {
    const startDate = moment().startOf('day').toISOString();
    const endDate = moment().endOf('day').toISOString();
    const dateValue = `${new Date(startDate)
      .toString()
      .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

    setDates(dateValue);

    const payload = {
      vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
      from_date: startDate,
      to_date: endDate,
    };
    getInitialData(payload);
  }, []);

  useEffect(() => {
    if (tabIndex === '1') {
      setValue(0);
      const startDate = moment().startOf('day').toISOString();
      const endDate = moment().endOf('day').toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else if (tabIndex === '2') {
      setValue(0);
      const startDate = moment().startOf('week').toISOString();
      const endDate = moment().endOf('week').toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    } else {
      setValue(0);
      const startDate = moment().startOf('month').toISOString();
      const endDate = moment().endOf('month').toISOString();
      const dateValue = `${new Date(startDate)
        .toString()
        .slice(3, 10)} - ${new Date(endDate).toString().slice(3, 10)} `;

      setDates(dateValue);
      const payload = {
        vital_type_id: vitalMonitoringState?.dataDrawerContent?.keyValue ?? 0,
        from_date: startDate,
        to_date: endDate,
      };
      getInitialData(payload);
    }
  }, [tabIndex]);

  return (
    <Screenlayout
      title={vitalMonitoringState?.dataDrawerContent?.type ?? ''}
      notshowFooter
      masterchildStyle={{ pb: { xs: 0 }, bgcolor: '#F5F5F5' }}
      backRequired
      setTabIndex={setTabIndex}
      titleStyle={{ color: '#000' }}
      backIconStyle={{ color: '#000' }}
      fill="#00000029"
      tabIndex={tabIndex}
      className={`${className}`}
      appBarSx={{ bgcolor: '#fff' }}
      tabStyle={{ height: '40px' }}
      underTabSx={{
        '& .MuiTabs-flexContainer': {
          display: 'grid',
          gridTemplateColumns: 'repeat(3,1fr)',
          gap: '8px',
          boxSizing: 'border-box',

          height: '48px',
          bgcolor: '#DCF5F1',
          p: 0.5,
        },
      }}
      appBarStyle={{ bgcolor: '#fff' }}
      tabRequired
      tabData={[
        {
          label: 'Daily',
          value: '1',
        },
        {
          label: 'Weekly',
          value: '2',
        },
        {
          label: 'Monthly',
          value: '3',
        },
      ]}
    >
      <Box sx={{ ...vital_monitoring_history_style.rootSx }} {...rest}>
        <VitalMonitoringChartCalender
          dates={dates}
          onBackClick={onBackClick}
          onForwardClick={onForwardClick}
          legendData={legendData}
          chartType={vitalMonitoringState?.dataDrawerContent?.type}
          isBloodPressure={
            vitalMonitoringState?.dataDrawerContent?.type === 'Blood Pressure'
          }
          line1Key={
            vitalMonitoringState?.dataDrawerContent?.type === 'Blood Pressure'
              ? 'SBP'
              : vitalMonitoringState?.dataDrawerContent?.key
          }
          line2Key={
            vitalMonitoringState?.dataDrawerContent?.type ===
              'Blood Pressure' && 'DBP'
          }
          data={chartData}
        />
        {historyData?.length > 0 && (
          <Box py={1} px={1}>
            <Typography fontSize={14} fontWeight={600}>
              History
            </Typography>
            <Grid mt={2} container>
              {historyData?.map((obj, i) => (
                <Grid key={i} item xs={12}>
                  <Box sx={vital_monitoring_history_style?.historyCardSx}>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      alignSelf="center"
                    >
                      {obj?.value}
                    </Typography>
                    <Typography
                      color="#5C6266"
                      fontSize={10}
                      alignSelf="center"
                    >
                      {obj?.date}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Screenlayout>
  );
}

VitalHistory.propTypes = {
  className: PropTypes.string,
};
