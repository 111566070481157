/* eslint-disable no-underscore-dangle */
import { Button, CalenderIcon, Drawer } from '@atoms';
import { getDateFormat, getDateFormatWithoutDate } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { useBookAppointment } from '@hc/store';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AppointmentDateTimeSelection } from '../dateTimeSelection';
import { DoctorAvailable } from '../doctorAvailable';
import { doctorProfileCard_style } from './style';

export function DoctorProfileCard(props) {
  const {
    src = {},
    doctorName = '',
    profilepic = {},
    pricingRequired = null,
    // aboutDoctor = {},
    role = [],
    hospital = [],
    paymentType,
    aboutpatient = {},
    tagStyle = {},
    rootStyle = {},
    className = '',
    // onClick = () => false,
    handleDoctorDetails,
    payload,
    available = true,
    value = {},
    servingFrom = '',
    ...rest
  } = props;

  // General Hooks
  const [drawerOpen, setDrawerOpen] = useState();
  const [profileId, setProfileId] = useState();
  const navigate = useNavigate();

  const {
    bookAppointmentState,
    updateBAState,
    claerDoctorDetail,
    updateDoctorDetail,
    constructSlotDataState,
    getDoctorData,
    loading,
    slotLoading,
  } = useBookAppointment(
    (state) => ({
      getDoctorData: state.getDoctorData,
      bookAppointmentState: state.bookAppointmentState,
      constructSlotDataState: state.constructSlotDataState,
      updateBAState: state.updateBAState,
      claerDoctorDetail: state.claerDoctorDetail,
      updateDoctorDetail: state.updateDoctorDetail,
      loading: state.loading,
      slotLoading: state.slotLoading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // CONSTRUCT SLOT DATA
  const constructSlotData = (seDate, value) => {
    constructSlotDataState(seDate, value);
  };

  // SELECT DATE FUNCTION
  const selectDate_ = (val, value) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);
    constructSlotData(selectedDat, value);
  };

  const handleDrawerOpen = async (value) => {
    setProfileId(value?.user_profile_id);
    await getDoctorData(payload, value?.user_profile_id);
    const selectedDate = getDateFormatWithoutDate('YYYY-MM-DD');
    // UPDATE STATE

    // SELECT DATE FUNCTION
    selectDate_(selectedDate, value);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    // CLEAR DOCOTR DETAILS
    claerDoctorDetail();
    setDrawerOpen(false);
    setProfileId();
  };

  const onNext = () => {
    if (
      bookAppointmentState?.selectedDate &&
      bookAppointmentState?.selectedSlotStartTime
    ) {
      // UPDATE DOCOTR DETAILS
      updateDoctorDetail();
      // NAVIGATE TO REPORT UPLOAD PAGE
      navigate(clinicalRoutes.uploadReports);
    } else {
      return toast.error('Please select slot!');
    }
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...doctorProfileCard_style.rootSx, ...rootStyle }}>
        <Box sx={{ p: 1.5, pb: '12px!important' }}>
          <Box onClick={handleDoctorDetails} id="card">
            <DoctorAvailable
              doctorName={doctorName}
              tagStyle={tagStyle}
              paymentType={paymentType}
              // aboutDoctor={aboutDoctor}
              role={role}
              hospital={hospital}
              aboutpatient={aboutpatient}
              specialities={value.specialities}
              src={src}
              profilepic={profilepic}
              pricingRequired={pricingRequired}
              servingFrom={servingFrom}
            />
          </Box>
          <Divider sx={doctorProfileCard_style.dividerSx} />
          <Box sx={doctorProfileCard_style.footerSx}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CalenderIcon
                rootStyle={{ color: available ? '#4CAF50' : ' #F44F5A' }}
              />
              <Typography
                variant="caption"
                sx={{
                  px: 1,
                  fontWeight: 500,
                  color: available ? '#4CAF50' : ' #F44F5A',
                }}
              >
                {available ? 'Available Today' : 'Unavailable Today'}
              </Typography>
            </Box>
            <Box>
              <Button
                key={value?.user_profile_id}
                buttonStyle={doctorProfileCard_style.btnSx}
                onClick={() => handleDrawerOpen(value)}
                loading={profileId === value?.user_profile_id && slotLoading}
              >
                Book Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Drawer */}
      <Box>
        {drawerOpen && (
          <Drawer
            show={drawerOpen}
            onCloseDrawer={() => handleDrawerClose()}
            // rootStyle={doctorAvailable_style.drawerRootSx}
            height="60vh"
            footer={
              <Box>
                <Button
                  loading={loading}
                  buttonStyle={{
                    '&:hover': {
                      bgcolor: 'primary.main',
                    },
                  }}
                  onClick={onNext}
                >
                  Continue
                </Button>
              </Box>
            }
          >
            <AppointmentDateTimeSelection
              bookAppointmentStateData={bookAppointmentState}
              value={value}
            />
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

DoctorProfileCard.propTypes = {
  src: PropTypes.object,
  doctorName: PropTypes.string,
  pricingRequired: PropTypes.string,
  aboutDoctor: {
    role: PropTypes.string,
    hospital: PropTypes.string,
  },
  aboutpatient: { patientRole: PropTypes.string },
  onClick: PropTypes.func,
  available: PropTypes.bool,
  className: PropTypes.node,
  sx: PropTypes.object,
  paymentType: PropTypes.string,
  servingFrom: PropTypes.string,
};
