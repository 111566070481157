import { useBookAppointment, useSymptoms } from '@hc/store';
import { SearchIcon, SearchSelect } from '@hc/ui/atoms';
import { QusAnsSelectionLisiting } from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useKeyPress } from 'ahooks';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { symptoms_style } from './style';

export function SymptomsSelection() {
  const filter = createFilterOptions();
  const { symptomsData, quickSymptomsData, get } = useSymptoms(
    (state) => ({
      symptomsData: state?.symptomsData,
      quickSymptomsData: state?.quickSymptomsData,
      get: state?.get,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { bookAppointmentState, clearPageData, deleteData, addSymptom } =
    useBookAppointment(
      (state) => ({
        bookAppointmentState: state?.bookAppointmentState,
        deleteData: state.deleteData,
        clearPageData: state.clearPageData,
        addSymptom: state.addSymptom,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const { symptoms } = bookAppointmentState;

  // General Hooks

  const [reset, setReset] = useState(null);
  const [addSymtoms, setAddSymtoms] = useState('');

  // ADD SYMPTOM DATA
  const handleAdd = (e, option) => {
    if (option?.value) {
      // ADD SYMPTOM
      addSymptom(e, option);
      // GET SYMPTOMS DATA
      get();
      setReset({ value: '', label: '', labelShow: '' });
      setAddSymtoms('');
    }
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  // DELETE SYMPTOM DATA
  const handleDelete = (i) => {
    // DELETE DATA
    deleteData('symptoms', i);
  };

  // CLEAR SYMPTOMS DATA
  const handleClear = () => {
    clearPageData('symptoms');
  };

  // GET SYMPTOM DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <QusAnsSelectionLisiting
      QusAnsSelectionHeading="What symptoms do you have?"
      QusAnsListingHeading="Symptoms"
      QusAnsListingAdded="Added"
      OnClearAll={handleClear}
      quickSuggestion
      displaySelectionValues={symptoms}
      OnDelete={handleDelete}
      sugesstionClick={handleAdd}
      qsOptionViewKey="label"
      quickSuggestionRequired={quickSymptomsData}
      QusAnsSelectionListing={
        <SearchSelect
          startAdornment={<SearchIcon rootStyle={{ color: 'grey.500' }} />}
          autocompeleteStyle={{
            ...symptoms_style.autocompeleteSx,
          }}
          placeholder="Eg. Fever"
          onChangeText={(e) => setAddSymtoms(e?.target?.value ?? '')}
          onChange={handleAdd}
          renderOption={(props, option) => {
            return (
              <Box
                sx={{ cursor: 'pointer' }}
                height="40px"
                onClick={(e) => handleAdd(e, option)}
                px={2}
                key={option.label}
                display="flex"
                {...props}
              >
                {option?.label?.includes('ADD') ? (
                  <Box display="flex" alignSelf="center">
                    <Typography
                      sx={{
                        color: '#EB6D13',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      +ADD &nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {addSymtoms}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignSelf="center">
                    <Typography>{option.label}</Typography>
                  </Box>
                )}
              </Box>
            );
          }}
          filterOptions={(options, params) => getFilteredadata(options, params)}
          optionViewKey="label"
          value={reset}
          options={symptomsData.filter(
            (symptom) =>
              !symptoms.some(
                (eachSymptom) => eachSymptom?.label === symptom?.labelShow
              )
          )}
        />
      }
    />
  );
}
