/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-key */
import { clinicalRoutes } from '@hc/routes';
import { useReport } from '@hc/store';
// import { Loader } from '@hc/ui/atoms';
import { Button } from '@hc/ui/atoms/button';
import { ReportLists } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { report_style } from './style';

export default function Reports(props) {
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const location = useLocation();
  const { className = '' } = props;
  const BookAppoinment = location?.state?.bookAppoinment;
  const [showFoooter, setShowSetFooter] = useState(false);
  const [tabIndex, setTabIndex] = useState('1');

  const navigate = useNavigate();

  // General hooks
  const {
    reportState,
    getAllUploadedReports,
    getAllCaseSummaryReports,
    updateReportStore,
    clearFilterData,
    clearReportState,
    clearBookAppointmentReports,
    loading,
  } = useReport();

  const { uploadReports, caseSummaryReports } = reportState;

  const setTabIndexFnc = async (tabIndex) => {
    clearFilterData();
    updateReportStore('searchNameReportFor', '');
    if (tabIndex === '1') {
      queryClient?.invalidateQueries({
        queryKey: ['report-uploaded'],
      });
      await getAllUploadedReports(data?.user_id);
    } else if (tabIndex === '2') {
      queryClient?.invalidateQueries({
        queryKey: ['report-caseSummary'],
      });
      await getAllCaseSummaryReports(data?.user_id);
    }
    setTabIndex(tabIndex === '1' ? '2' : tabIndex === '2' ? '1' : '2');
  };

  const cancelBookAppointmentReportUpload = () => {
    clearBookAppointmentReports();
    navigate(clinicalRoutes.uploadReports);
  };

  const saveBookAppointmentReportUpload = () => {
    navigate(clinicalRoutes.uploadReports, {
      state: {
        status: 'Save',
        fromReport: true,
        uploadedId: reportState?.deselectedReports?.uploadedId,
        caseSummaryId: reportState?.deselectedReports?.caseSummaryId,
      },
    });
  };

  const handleShowFooter = () => {
    if (!BookAppoinment) {
      setShowSetFooter(false);
      if (tabIndex === '2') {
        setShowSetFooter(true);
      } else {
        setShowSetFooter(false);
      }
    } else {
      setShowSetFooter(false);
    }
  };

  const handleUploadOnclick = async () => {
    await clearReportState();
    navigate(clinicalRoutes.reportsUpload);
  };

  const getInitialData = async () => {
    if (tabIndex === '1') {
      queryClient?.invalidateQueries({
        queryKey: ['report-uploaded'],
      });
      await getAllUploadedReports(data?.user_id);
    } else if (tabIndex === '2') {
      queryClient?.invalidateQueries({
        queryKey: ['report-caseSummary'],
      });
      await getAllCaseSummaryReports(data?.user_id);
    }
  };

  const getInitialReportData = async () => {
    queryClient?.invalidateQueries({
      queryKey: ['report-uploaded'],
    });
    await getAllUploadedReports(data?.user_id);

    queryClient?.invalidateQueries({
      queryKey: ['report-caseSummary'],
    });
    await getAllCaseSummaryReports(data?.user_id);
  };

  useEffect(() => {
    getInitialReportData();
  }, []);

  useEffect(() => {
    handleShowFooter();
    getInitialData();
  }, [tabIndex]);

  return (
    <Screenlayout
      tabIndex={tabIndex}
      setTabIndex={() => setTabIndexFnc(tabIndex)}
      title="Report Vault"
      className={`class ${className}`}
      backRequired={
        BookAppoinment
          ? '/appointmentbooking/uploadreports'
          : clinicalRoutes?.home
      }
      backRoute={
        BookAppoinment
          ? '/appointmentbooking/uploadreports'
          : clinicalRoutes?.home
      }
      notshowFooter={showFoooter}
      footer={
        <Box sx={{ gap: 2 }}>
          {BookAppoinment && (
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  buttonStyle={{ ...report_style.cancelBtnSx }}
                  fullWidth
                  onClick={() => cancelBookAppointmentReportUpload()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  buttonStyle={{ ...report_style.addBtnSx }}
                  fullWidth
                  onClick={() => saveBookAppointmentReportUpload()}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          )}
          {!BookAppoinment && (
            <Button
              variant="contained"
              buttonStyle={report_style.addBtnSx}
              fullWidth
              onClick={handleUploadOnclick}
            >
              Upload Report
            </Button>
          )}
        </Box>
      }
      tabRequired
      tabData={[
        {
          label: `Upload (${
            uploadReports?.length > 0 ? uploadReports?.length : 0
          })`,
          value: '1',
        },
        {
          label: `Case Summary (${
            caseSummaryReports?.length > 0 ? caseSummaryReports?.length : 0
          })`,
          value: '2',
        },
      ]}
    >
      <Box>
        <ReportLists tabIndex={tabIndex} loading={loading} />
      </Box>
    </Screenlayout>
  );
}
