export const addProfile_style = {
  // rootSx: {
  //   display: 'grid',
  //   gap: 2,
  //   minHeight: '100vh',
  // },

  boxRootSx: {
    px: 2.5,
  },
};
