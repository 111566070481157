import { envConfig } from '@hc/config';
import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity } from '@hc/store';
import { CommonSteper } from '@hc/ui/atoms';
import { AirtableFormRenderer } from '@hc/ui/components/airtableFormRenderer';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { feedback_style } from './style';

export default function PlanEvaluation(props) {
  const { className = '', ...rest } = props;
  const [steperValue, setSteperValue] = useState(null);

  const navigate = useNavigate();

  const { checkPlanDataDetails, mbfState } = useMBFActivity((state) => ({
    checkPlanDataDetails: state.checkPlanDataDetails,
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { isFeedBackSubmitted, isGoalsSubmitted } = checkPlanData;

  const stepperTextData = ['Feedback', 'Goals'];
  const stepperIcon = {
    1: 1,
    2: 2,
  };

  const onSubmitFeedback = async () => {
    await checkPlanDataDetails();
    setSteperValue(1);
  };

  const onSubmitGoal = async () => {
    await checkPlanDataDetails();
    navigate(clinicalRoutes.mbfSplash, {
      state: {
        from: 'Renewal',
      },
    });
  };

  const getInitialFormData = async () => {
    if (
      typeof checkPlanData === 'object' &&
      Object.keys(checkPlanData)?.length === 0
    ) {
      await checkPlanDataDetails();
    }

    if (isFeedBackSubmitted && !isGoalsSubmitted) {
      setSteperValue(1);
    } else if (isFeedBackSubmitted && isGoalsSubmitted) {
      toast('Feedback & Goals already submitted!');
      navigate(clinicalRoutes.mindbodyfood);
    } else {
      setSteperValue(0);
    }
  };

  useEffect(() => {
    getInitialFormData();
  }, []);

  return (
    <Screenlayout
      title="New Plan"
      steperLayout
      notshowFooter
      appBarStyle={{ ...feedback_style.appBarSx }}
      backRequired
      childrenStyle={feedback_style.childrenSx}
      backIconStyle={{ color: 'mbf.main' }}
      backRoute={clinicalRoutes.mindbodyfood}
      stepperformWithAppbar
      stepperChildren={
        <CommonSteper
          step={steperValue}
          icons={stepperIcon}
          stepText={stepperTextData}
        />
      }
    >
      <Box sx={feedback_style.rootSx} className={`${className}`} {...rest}>
        <Box sx={feedback_style.containerSx}>
          {steperValue === 0 && (
            <AirtableFormRenderer
              baseType="FeedBack"
              baseId={envConfig.airtable_app_base_id}
              tableId={envConfig.airtable_plan_feedback}
              onSubmitFnc={onSubmitFeedback}
            />
          )}{' '}
          {steperValue === 1 && (
            <AirtableFormRenderer
              baseType="Goal"
              baseId={envConfig.airtable_app_base_id}
              tableId={envConfig.airtable_get_plan}
              onSubmitFnc={onSubmitGoal}
            />
          )}
        </Box>
      </Box>
    </Screenlayout>
  );
}
