export const previousMoodStats_style = {
  rootSx: {},
  ContainerSx: {
    border: '1px solid #E5E0FF',
    borderRadius: '8px',
    height: '65vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
  },
  progressContainerSx: {
    px: {
      xs: 1,
      sm: 5,
    },
    py: 3,
  },
  circularColorSx: {
    color: 'purple.100',
  },
};
