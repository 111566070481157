import { clinicalRoutes } from '@hc/routes';
import { useAppointment, useBookAppointment } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { BookingStatusAppointment } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box } from '@mui/material';
import React from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';

export default function AppointmentBookingStatus(props) {
  const { className = '', ...rest } = props;
  const ReferralUpadateData = localStorage.getItem(
    localStorageKeys?.isReferralAppoinment,
  );
  const isReferralUpadate = JSON.parse(ReferralUpadateData);
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const { clearBookAppointmentState } = useBookAppointment(
    (state) => ({
      clearBookAppointmentState: state.clearBookAppointmentState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getFollowupReferralAppointmentList } = useAppointment(
    (state) => ({
      getFollowupReferralAppointmentList:
        state.getFollowupReferralAppointmentList,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // General Hooks
  const navigate = useNavigate();

  // BACK FUNCTION
  const onBack = () => {
    // CLEAR BOOK APPOINTMENT STATE
    clearBookAppointmentState();
    if (isReferralUpadate?.id) {
      getFollowupReferralAppointmentList(data?.user_id);
      localStorage.removeItem(localStorageKeys?.isReferralAppoinment);
    }
    navigate(clinicalRoutes?.home);
  };

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired={clinicalRoutes?.home}
      backRoute={clinicalRoutes?.home}
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={() => onBack()}>Back to home</Button>
        </Box>
      }
      {...rest}
    >
      {/* Confirmed and UnComfirmed  Booking Status */}
      <BookingStatusAppointment />
    </Screenlayout>
  );
}
