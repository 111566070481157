/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
import { clinicalRoutes } from '@hc/routes';
import { useBookAppointment, useReport } from '@hc/store';
import { Button, DocumentIcon, ImageIcon, UploadIcon } from '@hc/ui/atoms';
import { CustomizeReportsCard, UploadFiles } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils/constants';
// import { handleUploadFile, parseJwt } from '@hc/utils/helperFunctions';
import {
  encodeImageFileAsURL,
  formatBytes,
  generateReportName,
  handleUploadFile,
  parseJwt,
  schemaDataGet,
} from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import { queryClient } from '@hc/utils';
import { appointmentReports_style } from './style';

export default function UploadReports(props) {
  const { className = '', ...rest } = props;
  const [upload, setUpload] = useState(false);
  const [loading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const options = [
    {
      name: 'Image(PNG,JPG)',
      value: 'image',
      icon: <ImageIcon />,
    },
    {
      name: 'File(only PDF)',
      value: 'pdf',
      icon: <DocumentIcon />,
    },
  ];

  const {
    bookAppointmentState,
    deleteData,
    clearPageData,
    updateUploadCasesummaryReport,
    clearBookAppointmentReport,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state?.bookAppointmentState,
      deleteData: state.deleteData,
      clearPageData: state.clearPageData,
      updateUploadCasesummaryReport: state.updateUploadCasesummaryReport,
      clearBookAppointmentReport: state.clearBookAppointmentReport,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    reportState,
    getAllUploadedReports,
    getAllCaseSummaryReports,
    updateReportStore,
    uploadStoreFile,
    upsertReport,
    clearReportState,
    constructReportEditData,
    clearUploadReportState,
    updateNewdata,
    getUploadedReports,
    deleteReport,
    updateStateValue,
    loader,
  } = useReport(
    (state) => ({
      getAllUploadedReports: state.getAllUploadedReports,
      getAllCaseSummaryReports: state.getAllCaseSummaryReports,
      loader: state.loading,
      deleteReport: state.deleteReport,
      constructReportEditData: state.constructReportEditData,
      clearReportState: state.clearReportState,
      getUploadedReports: state.getUploadedReports,
      updateNewdata: state.updateNewdata,
      clearUploadReportState: state.clearUploadReportState,
      reportState: state.reportState,
      updateReportStore: state.updateReportStore,
      upsertReport: state.upsertReport,
      uploadStoreFile: state.uploadStoreFile,
      updateStateValue: state.updateStateValue,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const navigate = useNavigate();
  const location = useLocation();

  const onNext = () => {
    reportState.prevRoute
      ? navigate(reportState.prevRoute)
      : navigate(clinicalRoutes.vitalsForm);
  };

  // SKIP FUNCTION
  const onSkip = () => {
    updateReportStore('selectTypeOfFile', '');
    if (!location?.state?.prevRoute) {
      clearPageData('reports');
    }
    clearBookAppointmentReport();
    location?.state?.prevRoute
      ? navigate(location?.state?.prevRoute)
      : navigate(clinicalRoutes.vitalsForm);
  };

  // DELETE REPORTS DATA
  const handleDelete_ = (i) => {
    if (bookAppointmentState?.reports?.length === 1) {
      // setActive(false);
      setUpload(false);
    }
    deleteData('reports', i);
  };

  const checkUpoadFile = (type) => {
    if (Array.isArray(reportState?.[type]) && reportState?.[type]?.length > 0) {
      const Arr = [];
      reportState?.[type]?.map(async (val) => {
        if (val?.sizeNumber / (1024 * 1024 * 5) > 1) {
          Arr.push(val?.sizeNumber);
        }
      });

      return !(Arr?.length > 0);
    }
  };

  const uploadData = async () => {
    if (
      reportState?.imageFiles_.length > 0 ||
      reportState?.pdfFiles_.length > 0
    ) {
      const returnData = await upsertReport(
        data?.user_id,
        !reportState?.formReportId,
        true,
        bookAppointmentState?.booking_method,
      );

      if (!loader) {
        setIsLoading(false);
      }

      // CLEAR Report STATE
      clearUploadReportState();

      const id = returnData?.data?.data?.report_id;
      await updateNewdata('uploadedReportId', { id });

      if (reportState?.uploadedReportId) {
        const allReports = getUploadedReports();
        let isArray = JSON.parse(JSON.stringify(allReports));
        let schemaArray = [];
        const schema = await schemaDataGet(
          isArray,
          reportState?.uploadedReportId,
        );
        schemaArray.push(schema);
      }
    }
  };

  const deleteClick = async (value) => {
    await deleteReport(data?.user_id, value, 1, true);
  };

  const getInitialData = async () => {
    queryClient?.invalidateQueries({
      queryKey: ['report-uploaded'],
    });
    queryClient?.invalidateQueries({
      queryKey: ['report-caseSummary'],
    });
    await getAllUploadedReports(data?.user_id);
    await getAllCaseSummaryReports(data?.user_id);
  };

  const handleFileUpload = async (e) => {
    setUpload(true);
    let fileList = e?.target?.files;
    if (e?.target?.files) {
      await updateReportStore('uploadId', null);
      let url = '';
      let arrayData = [];

      if (reportState?.selectTypeOfFile === 'image') {
        setIsLoading(true);

        for (const file of fileList) {
          const fileFormData = file;
          url = await encodeImageFileAsURL(file);
          const size = formatBytes(file?.size ?? 0, 0);
          const filename = file?.name;
          const type = file?.type;
          const sizeNumber = file?.size ?? 0;
          arrayData.push({
            fileFormData: { 0: fileFormData, length: 1 },
            url: [url],
            size,
            filename,
            type,
            sizeNumber,
          });
        }
      } else if (reportState?.selectTypeOfFile === 'pdf') {
        setIsLoading(true);

        const getData = await handleUploadFile(e.target.files);

        const data = getData.map((val) => val.downloadUrl);
        url = data;
      }
      const size_ = formatBytes(e?.target?.files?.[0]?.size ?? 0, 0);
      const arr = reportState?.imageFiles?.filter(
        (val) => val.filename === e?.target?.files?.[0]?.name,
      );

      if (arr?.length > 0) {
        toast.error(`Please Don't upload same image`);
      } else {
        if (reportState?.selectTypeOfFile === 'pdf') {
          const obj = {
            fileFormData: e.target.files,
            downloadUrl: url,
            filename: e?.target?.files?.[0]?.name ?? '',
            type: e?.target?.files?.[0]?.type ?? '',
            size: size_,
            sizeNumber: e?.target?.files?.[0]?.size ?? 0,
          };
          await uploadStoreFile(
            reportState?.selectTypeOfFile === 'image'
              ? 'imageFiles'
              : 'pdfFiles',
            obj,
          );
        } else if (reportState?.selectTypeOfFile === 'image') {
          for (const data of arrayData) {
            const obj = {
              fileFormData: data?.fileFormData,
              downloadUrl: data?.url,
              filename: data?.filename,
              type: data?.type,
              size: data?.size,
              sizeNumber: data?.sizeNumber,
            };
            await uploadStoreFile(
              reportState?.selectTypeOfFile === 'image'
                ? 'imageFiles'
                : 'pdfFiles',
              obj,
            );
          }
        }
        // STATE UPDATE FOR REPORTS

        if (
          reportState?.selectTypeOfFile === 'image' &&
          reportState?.imageFiles?.length === 0
        ) {
          await updateReportStore(
            'formReportName',
            generateReportName(e?.target?.files?.[0]?.name),
          );
        } else if (reportState?.selectTypeOfFile === 'pdf') {
          await updateReportStore(
            'formReportName',
            generateReportName(e?.target?.files?.[0]?.name),
          );
        }
      }
    }

    if (reportState?.selectTypeOfFile === 'image') {
      if (checkUpoadFile('imageFiles')) {
        let array = [];
        setIsLoading(true);

        reportState?.imageFiles?.map(async (val) => {
          if (!val?.sizeNumber) {
            return false;
          }

          array.push(handleUploadFile(val?.fileFormData));
        });

        Promise.all(array)
          .then(async (results) => {
            results.map((result) => {
              uploadStoreFile('imageFiles_', result[0]);
            });
            uploadData();
          })
          .then(() => getInitialData());

        return true;
      }
      return toast.error('File Size is Too Large it must be less than 1MB');
    }

    if (reportState?.selectTypeOfFile === 'pdf') {
      if (checkUpoadFile('pdfFiles')) {
        let array = [];
        setIsLoading(true);
        reportState?.pdfFiles?.map(async (val) => {
          array.push(handleUploadFile(val?.fileFormData));
        });

        Promise.all(array)
          .then(async (results) => {
            results.map((result) => {
              uploadStoreFile('pdfFiles_', result[0]);
            });
            uploadData();
          })
          .then(() => getInitialData());
        return true;
      }
      return toast.error('File Size is Too Large it must be less than 1MB');
    }
  };

  const selectTypeOfFileFunction = (value) => {
    if (reportState?.selectTypeOfFile === value) {
      updateReportStore('selectTypeOfFile', '');
    } else {
      updateReportStore('selectTypeOfFile', value);
    }
  };

  const onEdit = async (value) => {
    await clearReportState();
    await constructReportEditData(value);
    navigate(clinicalRoutes.reportsUpload, {
      state: {
        status: 'edit',
      },
    });
  };

  useEffect(() => {
    getInitialData();
    clearReportState(true);
  }, []);

  useEffect(() => {
    if (location?.state?.fromReport) {
      updateUploadCasesummaryReport(
        location?.state?.uploadedId,
        location?.state?.caseSummaryId,
      );
    }
    if (location?.state?.prevRoute) {
      updateStateValue('prevRoute', location?.state?.prevRoute);
    }
  }, [location?.state?.fromReport]);

  return (
    <Box className={`${className}`} {...rest}>
      <Screenlayout
        backRequired
        backRoute={
          location?.state?.fromReport
            ? '/appointmentbooking/availabledoctors'
            : location?.state?.prevRoute
            ? location?.state?.prevRoute
            : '/appointmentbooking/availabledoctors'
        }
        title="Booking Appointment"
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              disabled={isLoading}
              buttonStyle={appointmentReports_style.buttonSx}
              onClick={onSkip}
            >
              Skip
            </Button>
            <Button
              disabled={isLoading}
              loading={loading}
              buttonStyle={{ borderRadius: '8px', textTransform: 'none' }}
              onClick={() => onNext()}
            >
              Continue
            </Button>
          </Box>
        }
      >
        {/* Customize report */}

        {reportState?.uploadReports?.length < 1 &&
        reportState?.caseSummaryReportsCopy?.length < 1 ? (
          ''
        ) : (
          <Box px={2.5}>
            <CustomizeReportsCard
              allReportDetail="reports will be displayed to the doctor"
              uploadData={
                reportState?.deselectedReports?.uploadedId?.length > 0
                  ? reportState?.uploadReports?.length -
                    reportState?.deselectedReports?.uploadedId?.length
                  : reportState?.uploadReports?.length ?? 0
              }
              caseSummaryData={
                reportState?.caseSummaryReportsCopy?.length -
                  reportState?.deselectedReports?.caseSummaryId?.length >
                0
                  ? reportState?.caseSummaryReportsCopy?.length -
                    reportState?.deselectedReports?.caseSummaryId?.length
                  : 0
              }
              header="Report Vault"
              bodyContent="Your previous reports are all visible to the doctor. You can customize which report you want to share with your doctor."
            />
          </Box>
        )}
        {/* Upload File */}
        <Box sx={appointmentReports_style.rootUploadSx}>
          <UploadFiles
            reportTitle="Upload reports (If any)"
            browserText="Click to browse and upload"
            icon={<UploadIcon />}
            uploadsTextReqired
            reportState={reportState}
            uploadsText="Uploaded Reports"
            onEdit={onEdit}
            reportDelete={deleteClick}
            uploadFilesData={reportState?.uploadReports}
            uploadReportId={reportState?.uploadedReportId}
            uploadFileStyle={appointmentReports_style.uploadFileSizeSx}
            uploadIconStyle={appointmentReports_style.uploadIconCenterSx}
            handleFileUpload={handleFileUpload}
            handleDelete={handleDelete_}
            progressBar={false}
            isBokAppoinmentUpload
            options={options}
            uploaded={upload}
            isLoading={isLoading}
            type={reportState?.selectTypeOfFile}
            selectTypeOfFileFns={selectTypeOfFileFunction}
          />
        </Box>
      </Screenlayout>
    </Box>
  );
}
