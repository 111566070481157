import { chart_style } from './style';

export function RenderLegend(props) {
  const { data = [], legendFontSize,chartStyle, chartType } = props;

  return (
    <div
      style={{
        ...chart_style.optionRootSx,
        ...(chartType === 'Column' && { margin: '12px 0 0 75px' }),
        ...(chartType === 'Pie' && { paddingLeft: '0px' }),
        ...(chartType === 'Donut' && { margin: '0 0 0 28px', gap: '25px' }),
        ...chartStyle,
      }}
    >
      {data.map((entry, index) => (
        <div key={index} style={chart_style.optionBoxSx}>
          <div
            style={{
              backgroundColor: entry.color,
              ...chart_style.optionColorDotSx,
            }}
          />
          <text style={{ fontSize: legendFontSize }}>{entry.name}</text>
        </div>
      ))}
    </div>
  );
}
