import { envConfig } from '@hc/config';
import { queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const useBloodGroup = create((set) => ({
  bloodGroupData: [],
  loading: null,
  error: null,
  getBloodGroupData: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        '/getMasterBloodGroup/getAll',
        'get',
        `${envConfig.api_url}`,
      ]);
      const bloodGroupDataCopy = [];
      if (Array.isArray(data) && data?.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const val of data) {
          bloodGroupDataCopy.push({
            value: val?.id ?? '',
            label: val?.blood_group ?? '',
          });
        }
      }
      return set(() => ({
        loading: false,
        bloodGroupData: bloodGroupDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },
}));
