import { Button } from '@atoms';
import { getDateFormat, getTimeFormat } from '@hc/dayjs';
import { useAppointment } from '@hc/store';
import { camelCaseToTitleCase } from '@hc/utils/helperFunctions';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { appointmentNotifyCard_style } from './style';

export function AppointmentNotifyCard(props) {
  const {
    src = {},
    doctorName = '',
    index = '',
    data = {},
    professional = [],
    appointmentDate = '',
    appointmentStartTime = '',
    appointmentEndTime = '',
    type = '',
    type_name = '',
    min = 0,
    sec = 0,
    button = '',
    totalBoxStyle = {},
    onLivebtn = () => {},
    onClosebtn = () => {},
    handleResumeBooking = () => {},
    className = '',
    ...rest
  } = props;

  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);
  const [stateType, setStateType] = useState(type);
  const [buttonState, setButtonState] = useState(button);

  const { loading } = useAppointment((state) => ({
    loading: state.loading,
  }));

  const types = [
    {
      button: 'disableJoin',
      color: {
        text: 'text.secondary',
        backgroundColor: 'grey.400',
      },
    },
    {
      button: 'enableJoin',
      color: {
        text: 'common.white',
        backgroundColor: 'blue',
      },
    },
    {
      button: 'past',
      color: {
        text: 'red.800',
        backgroundColor: '#FFE0E0',
      },
    },
  ];

  useEffect(() => {
    setMinutes(min);
    setSeconds(sec);
  }, [min, sec]);

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      if (stateType === 'upcoming') {
        setStateType('live');
        setButtonState('enableJoin');
        return;
      }
      return;
    }
    const myInterval = setInterval(() => {
      // Block Resend State
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...appointmentNotifyCard_style.totalBoxSx, ...totalBoxStyle }}>
        <Box sx={appointmentNotifyCard_style.dateSx}>
          <Box sx={appointmentNotifyCard_style.initialSx}>
            <Typography sx={appointmentNotifyCard_style.dateTextSx}>
              {' '}
              {getDateFormat(appointmentDate, 'DD MMM YY')} |{' '}
              {/* 05 PM to 05:30 PM */}
              {`${getTimeFormat(
                appointmentStartTime,
                'hh:mm'
              )} to ${getTimeFormat(appointmentEndTime, 'hh:mm A')}`}
            </Typography>
          </Box>
          {type_name === 'paymentPending' ? (
            <Typography
              variant="caption"
              sx={{
                ...appointmentNotifyCard_style.referchangingSx,
                color: 'common.white',
                backgroundColor: '#616161',
              }}
            >
              {camelCaseToTitleCase(type_name)}
            </Typography>
          ) : stateType === 'upcoming' ? (
            <Typography
              variant="caption"
              sx={{
                ...appointmentNotifyCard_style.referchangingSx,
                width: '78px',
                color: 'common.white',
                backgroundColor: '#F58634',
              }}
            >
              {`In ${minutes}m ${seconds < 10 ? `0${seconds}` : seconds}s `}
            </Typography>
          ) : stateType === 'live' ? (
            <Typography
              variant="caption"
              sx={{
                ...appointmentNotifyCard_style.referchangingSx,
                color: 'red.800',
                backgroundColor: '#FCD4D6',
              }}
            >
              <span
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#F44F5A',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              />
              Live Now
            </Typography>
          ) : (
            <Typography
              variant="caption"
              sx={{
                ...appointmentNotifyCard_style.referchangingSx,
                color: 'common.white',
                backgroundColor: '#616161',
              }}
            >
              {camelCaseToTitleCase(type_name)}
            </Typography>
          )}
        </Box>
        <Box sx={{ ...appointmentNotifyCard_style.appointmentCardSx }}>
          <Box sx={{ ...appointmentNotifyCard_style.doctornameSx }}>
            <Avatar
              src={src}
              sx={{ ...appointmentNotifyCard_style.profilepicSx }}
            />
            <Box sx={{ px: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: '600' }}>
                {doctorName}
              </Typography>

              <Box sx={appointmentNotifyCard_style.professionalContainerSx}>
                {Array.isArray(professional) &&
                  professional?.length > 0 &&
                  professional.map((val, i) => (
                    <Typography
                      key={i}
                      sx={appointmentNotifyCard_style.professionalSx}
                    >
                      • {val?.master_speciality?.s ?? ''} &nbsp;
                    </Typography>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...appointmentNotifyCard_style.typeSx }}>
            {type_name === 'paymentPending' ? (
              <Button
                size="small"
                loading={loading}
                onClick={() => handleResumeBooking(index, data)}
                sx={{ width: 'max-content' }}
              >
                Resume Booking
              </Button>
            ) : (
              <Button
                size="medium"
                buttonStyle={{
                  ...appointmentNotifyCard_style.btnSx,
                  backgroundColor:
                    types.find((typeValue) => typeValue.button === buttonState)
                      ?.color?.backgroundColor ?? 'common.white',
                  color:
                    types.find((typeValue) => typeValue.button === buttonState)
                      ?.color?.text ?? 'common.white',
                  '&:hover': {
                    color:
                      types.find(
                        (typeValue) => typeValue.button === buttonState
                      )?.color?.text ?? 'common.white',
                    backgroundColor:
                      types.find(
                        (typeValue) => typeValue.button === buttonState
                      )?.color?.backgroundColor ?? 'common.white',
                  },
                }}
                onClick={() =>
                  buttonState === 'past'
                    ? onClosebtn(index, data)
                    : buttonState === 'enableJoin'
                    ? onLivebtn(data)
                    : null
                }
              >
                {buttonState === 'past' ? 'Close' : 'Join'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

AppointmentNotifyCard.propTypes = {
  src: PropTypes.string,
  after: PropTypes.string,
  doctorName: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object,
  professional: PropTypes.array,
  appointmentDate: PropTypes.string,
  appointmentStartTime: PropTypes.string,
  appointmentEndTime: PropTypes.string,
  type: PropTypes.string,
  type_name: PropTypes.string,
  button: PropTypes.string,
  min: PropTypes.number,
  sec: PropTypes.number,
  onLivebtn: PropTypes.func,
  onClosebtn: PropTypes.func,
};
