export const iconText_style = {
  rootSx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameInactiveSx: {
    fontSize: 12,
    fontWeight: 500,
    color: 'text.primary',
    mt: 1,
    textAlign: 'center',
  },
  avatarinactiveSx: {
    padding: 1.2,
    color: 'text.primary',
    textAlign: 'center',
    fontSize: '12px',
    border: '1px solid',
    borderColor: 'primaryGreyishTints.300',
    backgroundColor: 'primaryTints.50',
    borderRadius: '6px',
    '& img': {
      objectFit: 'contain',
    },
  },
  badgeSx: {
    '& .MuiBadge-badge': {
      border: '3px solid',
      borderColor: 'secondary.light',
      opacity: 1,
      height: 14,
      minWidth: 14,
      borderRadius: '50%',
    },
  },
};
