// import { useFamilyMember, useRelationShip } from '@hc/store';
import { clinicalRoutes } from '@hc/routes';
import { FamilyMemberCard } from '@hc/ui/components/clinical/familyProfile/familyMemberCard';
import { getFirstLetterOfName } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { React, useState } from 'react';
import { myFamilyScreen_style } from './style';

export function MyFamilyMemberSelect({ familyMembers, editFamily = () => {} }) {
  const [setDrawerOpen] = useState(false);

  const handleDrawer = () => {
    setDrawerOpen(true);
  };

  return (
    <Box>
      {familyMembers.map((val, index) => (
        <FamilyMemberCard
          key={index}
          value={val}
          name={val?.name}
          relationship={val?.relationship_data?.releationship}
          gender={val?.gender}
          age={val?.age}
          profileName={getFirstLetterOfName(val?.name)}
          pathLink={clinicalRoutes.editFamilyMember}
          onClickEdit={editFamily}
          rootStyle={myFamilyScreen_style.familySX}
          onClick={handleDrawer}
          is_use_my_number={val.is_use_my_number}
          // selectable
          // confirmed
          invited={val?.status === 'Invited'}
          editId={`editFamilyMember_${index}`}
        />
      ))}
    </Box>
  );
}
