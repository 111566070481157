export const mbfHomeScreen_style = {
  rootSx: {
    minHeight: '100vh',
    bgcolor: 'purple.50',
  },
  containerSx: {
    p: 2.2,
  },
  titleTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 600,
    my: 3,
  },
  activityTypeSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 2.5,
    pl: 1,
  },
  adSx: {
    mt: 3,
    height: '48vh',
  },

  descSx: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
  spanSx: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#6856cd',
  },
};
