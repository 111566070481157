import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { bloodGroupSelection_style } from './style';

export function BloodGroupSelection(props) {
  const {
    className = '',
    onChange,
    value,
    name,
    handleChange,
    isError = false,
    bloodGroupData = [],
    errorMessage = '',
    bloodData,
    ...rest
  } = props;

  return (
    <Box>
      <Box
        display="flex"
        gap={1.1}
        sx={{ my: 0.5 }}
        className={`${className}`}
        {...rest}
      >
        {bloodGroupData?.map((val, i) => (
          <Box
            id={`bloodgroup_${i}`}
            key={i}
            sx={{
              display: 'grid',
              placeItems: 'center',
              gap: 0.5,
            }}
            onClick={() => handleChange(name, val?.value)}
          >
            <Box
              sx={
                val?.value === bloodData?.blood_group
                  ? bloodGroupSelection_style.bloodgroupactiveSx
                  : bloodGroupSelection_style.bloodgroupinactiveSx
              }
            >
              <Typography>{val?.label}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

BloodGroupSelection.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.object,
  options: PropTypes.shape({
    value: PropTypes.string,
    bloodgroup: PropTypes.string,
  }),
  sx: PropTypes.object,
};
