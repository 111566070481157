export const chatListingBox_style = {
  senderMessageSx: {
    bgcolor: '#EEEEEE',
    borderRadius: '12px 12px 4px 12px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '400px',
    px: 1.5,
    py: 1,
  },
  senderMessageFileSx: {
    bgcolor: '#EEEEEE',
    borderRadius: '12px 12px 4px 12px',
    // display: 'flex',
    // justifyContent: 'center',
    maxWidth: '368px',
    px: 1.5,
    py: 1,
  },
  senderMessageContentSx: {
    display: 'flex',
    justifyContent: 'end',
    mb: 1,
  },
  reciverMessageSx: {
    borderRadius: '12px 12px 12px 4px',
    border: '1px solid #E9E9E9',
    display: 'flex',
    justifyContent: 'center',
    px: 1.5,
    py: 1,
    maxWidth: '368px',
  },
  reciverMessageFileSx: {
    borderRadius: '12px 12px 12px 4px',
    border: '1px solid #E9E9E9',
    // display: 'flex',
    // justifyContent: 'center',
    px: 1.5,
    py: 1,
    maxWidth: '345px',
  },
  reciverMessageContentSx: {
    display: 'flex',
    mb: 1,
  },
};
