import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { clinicalLandingPageSkeleton_style } from './style';

function ClinicalLandingPageSkeleton(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...clinicalLandingPageSkeleton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box display="flex">
        <Skeleton
          variant="circular"
          width={30}
          height={30}
          animation="wave"
          sx={{
            ...clinicalLandingPageSkeleton_style.skeletonRoot,
            mt: 0.7,
          }}
        />
        <Skeleton
          variant="text"
          width={185}
          height={40}
          animation="wave"
          sx={{
            ...clinicalLandingPageSkeleton_style.skeletonRoot,
            mb: 1.5,
            ml: 1,
          }}
        />
      </Box>

      <Skeleton
        variant="text"
        width={85}
        height={25}
        animation="wave"
        sx={{
          ...clinicalLandingPageSkeleton_style.skeletonRoot,
          mb: 1.5,
          borderRadius: '4px',
        }}
      />
      <Skeleton
        variant="text"
        width={285}
        animation="wave"
        sx={{
          ...clinicalLandingPageSkeleton_style.skeletonRoot,
          mb: 1.5,
        }}
      />
      <Grid container spacing={2}>
        {Array.from(Array(2)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant="rounded"
              height={116}
              animation="wave"
              sx={{
                ...clinicalLandingPageSkeleton_style.skeletonRoot,
                mb: 2,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Skeleton
          variant="text"
          width={230}
          height={30}
          animation="wave"
          sx={{
            ...clinicalLandingPageSkeleton_style.skeletonRoot,
            mb: 1.5,
            borderRadius: '4px',
          }}
        />
        <Skeleton
          variant="text"
          width={85}
          animation="wave"
          sx={{
            ...clinicalLandingPageSkeleton_style.skeletonRoot,
            mb: 1.5,
          }}
        />
      </Box>
      <Grid container spacing={2}>
        {Array.from(Array(4)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant="rounded"
              height={116}
              animation="wave"
              sx={{
                ...clinicalLandingPageSkeleton_style.skeletonRoot,
                mb: 2,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Skeleton
        variant="rounded"
        height={116}
        animation="wave"
        sx={{
          ...clinicalLandingPageSkeleton_style.skeletonRoot,
          mb: 2,
        }}
      />

      <Skeleton
        variant="text"
        width={285}
        height={30}
        animation="wave"
        sx={{
          ...clinicalLandingPageSkeleton_style.skeletonRoot,
          mb: 1.5,
          borderRadius: '4px',
        }}
      />
      <Grid container spacing={2}>
        {Array.from(Array(2)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant="rounded"
              height={116}
              animation="wave"
              sx={{
                ...clinicalLandingPageSkeleton_style.skeletonRoot,
                mb: 2,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ClinicalLandingPageSkeleton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ClinicalLandingPageSkeleton };
