import Calm from '@assets/calm.svg';
import Endurance from '@assets/endurance.svg';
import FitFreak from '@assets/fitFreak.svg';
import Flexy from '@assets/flexy.svg';
import Ripped from '@assets/ripped.svg';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { MbfRank } from '../mbfrank';
import { mbfRankCard_style } from './style';

function MbfRankCard(props) {
  const { className = '', rootStyle = {}, data = [], ...rest } = props;
  const firstRanker = data?.[0];
  const secondRankers = data?.[1];
  const thirdRankers = data?.[2];
  const fourthRankers = data?.[3];
  const fifthRankers = data?.[4];

  return (
    <Box
      sx={{
        ...mbfRankCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {/* Fourth Rank */}
        <MbfRank
          Rank={4}
          firstName={fourthRankers?.first_name ?? 'Endurance'}
          rootStyle={{
            mt: 6,
          }}
          avatarStyle={{ width: 65, height: 65 }}
          profilePic={fourthRankers?.profile_pic ?? Endurance}
          firstNameStyle={{
            width: '60px',
            marginLeft: '-8px',
          }}
        />{' '}
        {/* Second Rank */}
        <MbfRank
          Rank={2}
          firstName={secondRankers?.first_name ?? 'Ripped'}
          rootStyle={{
            mt: 4,
          }}
          avatarStyle={{
            width: 75,
            height: 75,
            borderColor: '#D5CCFF',
            zIndex: -1,
          }}
          firstNameStyle={{
            color: '#0E1824',
            width: '60px',
          }}
          profilePic={secondRankers?.profile_pic ?? Ripped}
        />{' '}
        {/* First Rank */}
        <MbfRank
          Rank={1}
          firstName={firstRanker?.first_name ?? 'Fit Freak'}
          profilePic={firstRanker?.profile_pic ?? FitFreak}
          avatarStyle={{
            width: 100,
            height: 100,
            borderColor: '#FACC15',
            zIndex: 1,
          }}
          firstNameStyle={{
            fontSize: '12px',
            color: '#FACC15',
            fontWeight: 600,
            mt: 0.5,
            marginLeft: '-8px',
          }}
        />{' '}
        {/* Third Rank */}
        <MbfRank
          Rank={3}
          firstName={thirdRankers?.first_name ?? 'Flexy'}
          rootStyle={{
            mt: 4,
          }}
          avatarStyle={{
            width: 75,
            height: 75,
            borderColor: '#D5CCFF',
            zIndex: -1,
          }}
          firstNameStyle={{
            color: '#0E1824',
            marginLeft: '-8px',
          }}
          profilePic={thirdRankers?.profile_pic ?? Flexy}
        />{' '}
        {/* Fifth Rank */}
        <MbfRank
          Rank={5}
          firstName={fifthRankers?.first_name ?? 'Calm'}
          rootStyle={{
            mt: 6,
          }}
          avatarStyle={{ width: 65, height: 65, zIndex: -2 }}
          profilePic={fifthRankers?.profile_pic ?? Calm}
          firstNameStyle={{
            marginLeft: '-8px',
          }}
        />{' '}
      </Box>
    </Box>
  );
}

MbfRankCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.array,
};
export { MbfRankCard };

