export const rootLayout_style = {
  rootSx: {
    bgcolor: 'white',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    // pb: 4,
  },

  backgroundSx: {
    bgcolor: {
      sm: '#F1F5F9',
    },
    boxShadow: {
      sm: '0px 0px 10px #efefef',
    },
    minHeight: '95vh',
    width: '100%',
    maxHeight: 'auto',
  },
  notifyCardSx: {
    bottom: '64px',
    width: '100%',
    maxWidth: '395px',
    m: '16px auto',
  },
  prescriptionTextSx: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'medium',
    mt: 2.1,
    width: '300px',
    mx: 'auto',
    color: '#0F0B11',
  },
  subtextSx: {
    fontSize: '12px',
    fontWeight: 400,
    width: '237px',
    mt: 2.1,
    mb: 1.85,
    mx: 'auto',
    color: '#0F0B11',
  },
  cancelSx: {
    color: 'grey.700',
    fontSize: '32px',
    position: 'absolute',
    right: '17px',
    top: '-17px',
  },
  boxSx: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    bottom: '64px',
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  slotDrawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      height: '60vh',
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    // p: 2,
    overflow: 'overlay',
  },
  headerSx: { p: 2, borderBottom: '1px solid', borderColor: 'border.main' },
  childrenSx: { p: 2, overflow: 'overlay' },
  jitsiMeetMiniSx: {
    zIndex: 1,
    width: '250px',
    height: '50px',
    bottom: 255,
    right: 20,
    position: 'fixed',
  },
  jitsiMeetMaxSx: {
    width: '100%',
    right: 20,
  },
  jitsiGridContainerMiniSx: {
    ml: 0,
    width: '100% ',
    pb: 1.5,
    borderRadius: '16px 16px 16px 16px',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiGridContainerMaxSx: {
    ml: 0,
    maxWidth: '425px',
    borderRadius: '0',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiButtonMiniSx: {
    bgcolor: '#000',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#000',
    },
  },
  jitsiButtonMaxSx: {
    bgcolor: '#424242',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#424242',
    },
  },
};
