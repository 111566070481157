import { clinicalRoutes } from '@hc/routes';
import { useChatConsultation } from '@hc/store/clinical/chatConsultation';
import {
  AngryFaceEmoji,
  Button,
  CustomTextArea,
  HappyFaceEmoji,
  HeartFaceEmoji,
  NeutralFaceEmoji,
  NotOkEmoji,
} from '@hc/ui/atoms';
import { Box, Modal, Typography } from '@mui/material';
import { isEqual } from 'date-fns/esm';
import PropTypes from 'prop-types';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { chatFeedbackModal_style } from './style';

function ChatFeedbackModal(props) {
  const {
    isModalOpen,
    handleClose = () => {},
    cancleLable = 'CANCEL',
    acceptLable = 'LOGOUT',
  } = props;

  const [emoji, setEmoji] = useState(null);
  const [sendBtn, setsendBtn] = useState(false);
  const navigate = useNavigate();

  const {
    chatConsultationState,
    changeRating,
    changeState,
    isValid,
    SendFeedback,
  } = useChatConsultation(
    (state) => ({
      chatConsultationState: state.chatConsultationState,
      changeRating: state.changeRating,
      changeState: state.changeState,
      isValid: state.isValid,
      SendFeedback: state.SendFeedback,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { feedBack, error } = chatConsultationState;

  const FeedEmoji = [
    { id: 0, icon: <AngryFaceEmoji />, percentage: 1 },
    { id: 1, icon: <NotOkEmoji />, percentage: 2 },
    { id: 2, icon: <NeutralFaceEmoji />, percentage: 3 },
    { id: 3, icon: <HappyFaceEmoji />, percentage: 4 },
    { id: 4, icon: <HeartFaceEmoji />, percentage: 5 },
  ];

  const handleRating = (emj, percent) => {
    setEmoji(emj);
    changeRating(percent);
    if (sendBtn) isValid();
  };

  const handleFeedBack = (value) => {
    changeState(value);
  };

  const handleSendFeeds = async () => {
    setsendBtn(true);
    const valid = isValid();
    if (valid) {
      const res = await SendFeedback();
      if (res?.code === 200) {
        localStorage.removeItem('chatTimerMins');
        localStorage.removeItem('chatTimerMins');
        localStorage.removeItem('isChatTimer');
        toast.success(res?.message);
        setEmoji(null);
        setTimeout(() => {
          navigate(clinicalRoutes?.chatListPage);
        }, 3000);
      }
    }
  };

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box sx={chatFeedbackModal_style.totalModalSx}>
        <Box sx={chatFeedbackModal_style.totalBoxSx}>
          <Box mt={2}>
            <Box py={2}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  pb: 2,
                  textAlign: 'center',
                }}
              >
                How is your experience with us?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '310px',
                }}
              >
                {FeedEmoji.map((item, i) => (
                  <Box
                    key={i}
                    onClick={() => handleRating(i, item.percentage)}
                    sx={{
                      ...chatFeedbackModal_style.emojiContainer,
                      boxShadow: '0 0 3px #FDB408 , inset 0 0 3px #FDB408',
                      background: emoji === i ? '#FDB408' : '',
                    }}
                  >
                    {item.icon}{' '}
                  </Box>
                ))}
              </Box>
              <Typography
                sx={chatFeedbackModal_style.errorText}
                display={{
                  display: error?.rating ? 'block' : 'none',
                }}
              >
                {error?.rating}
              </Typography>
            </Box>
            <Box py={2}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, py: 2 }}>
                Write your comment
              </Typography>
              <CustomTextArea
                placeholder="Say what you think about"
                textFieldStyle={chatFeedbackModal_style.customtextSx}
                value={feedBack}
                onChange={(e) => handleFeedBack(e.target.value)}
              />
            </Box>
            <Box
              mt={3}
              pb={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Button
                sx={chatFeedbackModal_style.closebuttonSx}
                onClick={() => handleClose()}
              >
                {cancleLable}
              </Button>
              <Button
                sx={chatFeedbackModal_style.DeletebuttonSx}
                onClick={() => handleSendFeeds()}
              >
                {acceptLable}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

ChatFeedbackModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  cancleLable: PropTypes.string,
  acceptLable: PropTypes.string,
};

export { ChatFeedbackModal };
