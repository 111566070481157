/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-key */
import FitFreak from '@assets/fitFreak.svg';
import Flexy from '@assets/flexy.svg';
import { endWeekMonth, startWeekMonth } from '@hc/dayjs';
import { useMBFLeaderboard } from '@hc/store';
import { Crown, Loader, TickIcon } from '@hc/ui/atoms';
import {
  LeaderBoardCard,
  LeaderTitleCard,
  Screenlayout,
} from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import leaderboardEmptyState from '../../../assets/emptyLeaderboard.svg';
import { leaderBoard_style } from './style';

export default function Leaderboard() {
  const selection = [
    {
      value: 'over_all',
      label: 'Over All',
    },
    {
      value: 'this_week',
      label: 'This Week',
    },
    {
      value: 'this_month',
      label: 'This Month',
    },
  ];

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const { getLeaderboardData, mbfLeaderboardState, loading } =
    useMBFLeaderboard((state) => ({
      getLeaderboardData: state.getLeaderboardData,
      mbfLeaderboardState: state.mbfLeaderboardState,
      loading: state.loading,
    }));
  const { member_data, user_data } = mbfLeaderboardState;

  const [open, setOpen] = useState(selection[0]);

  const handleChange = (value) => {
    if (value?.value === 'over_all') {
      getLeaderboardData({
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: '',
        end_date: '',
      });
    } else if (value?.value === 'this_week') {
      const paylaod = {
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: new Date(startWeekMonth()).toJSON(),
        end_date: new Date(endWeekMonth()).toJSON(),
      };
      getLeaderboardData(paylaod);
    } else if (value?.value === 'this_month') {
      const paylaod = {
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: new Date(startWeekMonth()).toJSON(),
        end_date: new Date(endWeekMonth()).toJSON(),
      };
      getLeaderboardData(paylaod);
    }
    setOpen(value);
  };

  useEffect(() => {
    if (data?.employee_membership_code) {
      getLeaderboardData({
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: '',
        end_date: '',
      });
    }
  }, []);

  return (
    <Box>
      <Screenlayout
        appBarStyle={leaderBoard_style.appBarStyleSx}
        title="My Leaderboard"
        backRequired
        backRoute="/mindbodyfood"
        notshowFooter
        backIconStyle={{ color: 'mbf.main' }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.8,
              fontSize: '16px',
              fontWeight: 600,
              ml: 1.5,
              // mt: 1,
            }}
          >
            Leaderboard <Crown />
          </Typography>
          <Box>
            <Select
              value={open?.value}
              IconComponent={BiChevronDown}
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: '0px 0px 4px 2px #E3E3E3',
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSvgIcon-root': {
                  display: 'none',
                },
                fontSize: '12px',
              }}
            >
              {selection?.map((val, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleChange(val)}
                  value={val?.value}
                  //   sx={leaderBoard_style.menuListSx}
                >
                  {open?.value === val?.value ? <TickIcon /> : null}
                  &nbsp;{val?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>
          <Box sx={{ pl: 0.8, pr: 0.4 }}>
            <LeaderTitleCard headerTextStyle={{ px: 0.2 }} />{' '}
          </Box>

          {loading && (
            <Loader
              circularProgressProps={{ size: 35 }}
              rootStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
              }}
            />
          )}

          {!loading && member_data && member_data?.length > 0 && (
            <>
              <Box px={0.8}>
                {Array.isArray(member_data) && member_data?.length > 0
                  ? member_data.map((val, index) => (
                      <Box key={index} sx={{ mt: 1.5 }}>
                        <LeaderBoardCard
                          mindValue={
                            Array.isArray(val?.mbf) && val?.mbf?.length > 0
                              ? Math.round(
                                  val?.mbf?.filter(
                                    (val) => val?.type === 'Mind'
                                  )?.[0]?.percentage
                                ) ?? 0
                              : 0
                          }
                          bodyValue={
                            Array.isArray(val?.mbf) && val?.mbf?.length > 0
                              ? Math.round(
                                  val?.mbf?.filter(
                                    (val) => val?.type === 'Body'
                                  )?.[0]?.percentage
                                ) ?? 0
                              : 0
                          }
                          foodValue={
                            Array.isArray(val?.mbf) && val?.mbf?.length > 0
                              ? Math.round(
                                  val?.mbf?.filter(
                                    (val) => val?.type === 'Food'
                                  )?.[0]?.percentage
                                ) ?? 0
                              : 0
                          }
                          leaderName={val?.first_name}
                          rankList={val?.rank}
                          running={val?.assinged_activity}
                          energyPercentage={
                            val?.total ? Math.round(val?.total) : 0
                          }
                          index={index}
                          you={val?.is_you}
                          src={
                            val?.profile_pic
                              ? val?.profile_pic
                              : val?.gender === 'Male'
                              ? FitFreak
                              : Flexy
                          }
                          leaderBoardRankList={{
                            bgcolor:
                              data?.user_id === val?.user_id
                                ? '#FCF8E8'
                                : '#fff',
                            borderColor:
                              index < 3 || data?.user_id === val?.user_id
                                ? '#E6BA4C'
                                : '#EEEEEE',
                          }}
                        />
                      </Box>
                    ))
                  : null}

                {Object.keys(user_data).length > 0 &&
                  Object.keys(user_data?.user_rank).length > 0 &&
                  Object.keys(user_data?.profile_mbf).length > 0 && (
                    <Box key={11} sx={{ mt: 1.5 }}>
                      <>
                        <Box display="grid" gap={1} pb={1.5} pl={0.5}>
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: '#888888',
                              fontSize: '6px',
                            }}
                          />
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: '#888888',
                              fontSize: '6px',
                            }}
                          />
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: '#888888',
                              fontSize: '6px',
                            }}
                          />
                          <RiCheckboxBlankCircleFill
                            style={{
                              color: '#888888',
                              fontSize: '6px',
                            }}
                          />
                        </Box>
                      </>
                      <LeaderBoardCard
                        mindValue={
                          Array.isArray(user_data?.profile_mbf) &&
                          user_data?.profile_mbf?.length > 0
                            ? Math.round(
                                user_data?.profile_mbf?.filter(
                                  (val) => val?.type === 'Mind'
                                )?.[0]?.percentage
                              ) ?? 0
                            : 0
                        }
                        bodyValue={
                          Array.isArray(user_data?.profile_mbf) &&
                          user_data?.profile_mbf?.length > 0
                            ? Math.round(
                                user_data?.profile_mbf?.filter(
                                  (val) => val?.type === 'Body'
                                )?.[0]?.percentage
                              ) ?? 0
                            : 0
                        }
                        foodValue={
                          Array.isArray(user_data?.profile_mbf) &&
                          user_data?.profile_mbf?.length > 0
                            ? Math.round(
                                user_data?.profile_mbf?.filter(
                                  (val) => val?.type === 'Food'
                                )?.[0]?.percentage
                              ) ?? 0
                            : 0
                        }
                        leaderName={user_data?.user_rank?.[0]?.first_name}
                        rankList={user_data?.user_rank?.[0]?.rank}
                        running={user_data?.user_rank?.[0]?.assigned_activity}
                        energyPercentage={
                          user_data?.user_rank?.[0]?.total
                            ? Math.round(user_data?.user_rank?.[0]?.total)
                            : 0
                        }
                        index={11}
                        you
                        src={
                          user_data?.user_rank?.[0]?.profile_pic ??
                          user_data?.user_rank?.[0]?.gender === 'Male'
                            ? FitFreak
                            : Flexy
                        }
                        leaderBoardRankList={{
                          bgcolor: '#FCF8E8',
                          borderColor: '#E6BA4C',
                        }}
                      />
                    </Box>
                  )}
              </Box>{' '}
            </>
          )}
          {!loading && member_data && member_data?.length === 0 && (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={3}
              >
                <img
                  style={{ height: '100%' }}
                  src={leaderboardEmptyState}
                  alt=""
                />
              </Box>
              <Box textAlign="center" mt={2}>
                <Typography fontSize={16} fontWeight={600}>
                  No challengers yet
                </Typography>
              </Box>
              <Box textAlign="center" mt={1.5} mb={2}>
                <Typography fontSize={12} color="#888888">
                  Complete activities to get hosted here!
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Screenlayout>
    </Box>
  );
}
