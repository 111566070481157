export const report_list_style = {
  rootSx: {},
  selectedBoxSx: {
    px: 2.4,
    pt: 2.4,
  },
  filterBoxSx: {
    border: '1.5px solid #DBEAE8',
    cursor: 'pointer',
    borderRadius: '8px',
    py: 1.5,
  },
  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
        color: '#0E1824',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
  },
  searchInputSx: {
    height: '40px',
  },
};
