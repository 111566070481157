import { envConfig } from '@hc/config';
import { getEndOfWeeks, getStartOfWeeks } from '@hc/dayjs';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFPerformance = create((set, get) => ({
  mbfPerformanceData: {
    mindStatus: 0,
    bodyStatus: 0,
    foodStatus: 0,
    startDate: getStartOfWeeks('YYYY-MM-DD'),
    endDate: getEndOfWeeks('YYYY-MM-DD'),
    planCount: 0,
  },
  planStartDate: '',
  planEndDate: '',
  loading: null,
  error: null,
  getPerfomanceStats: async (payload) => {
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [
          'performanceStats',
          `${payload.start_date}-${payload.end_date}`,
        ],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/performance/percentage/current-plan`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const { mbfPerformanceData } = get();
      set({
        loading: false,
        mbfPerformanceData: {
          ...mbfPerformanceData,
          mindStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Mind')?.[0]
                  ?.percentage ?? 0
              : 0,
          bodyStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Body')?.[0]
                  ?.percentage ?? 0
              : 0,
          foodStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Food')?.[0]
                  ?.percentage ?? 0
              : 0,
          planCount: data?.previous_plan_count ?? 0,
        },
        planStartDate: data?.plan_details?.plan_start_date,
        planEndDate: data?.plan_details?.plan_end_date,
      });
      if (status?.code === 200) {
        set({ loading: false });
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
      return status?.code;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getPreviousPerfomanceStats: async (payload) => {
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['previousPerformanceStats'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/performance/percentage/previous-plan?user_id=${payload?.user_id}&page=${payload?.pageValue}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const { mbfPerformanceData } = get();
      set({
        loading: false,
        mbfPerformanceData: {
          ...mbfPerformanceData,
          mindStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Mind')?.[0]
                  ?.percentage ?? 0
              : 0,
          bodyStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Body')?.[0]
                  ?.percentage ?? 0
              : 0,
          foodStatus:
            Array.isArray(data?.percentage) && data?.percentage?.length > 0
              ? data?.percentage.filter((v) => v?.category_name === 'Food')?.[0]
                  ?.percentage ?? 0
              : 0,
          planCount: data?.previous_plan_count ?? 0,
        },
        planStartDate: data?.plan_details?.plan_start_date,
        planEndDate: data?.plan_details?.plan_end_date,
      });
      if (status?.code === 200) {
        set({ loading: false });
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  startEndDateUpdate: (start, end) => {
    const { mbfPerformanceData } = get();
    set({
      mbfPerformanceData: {
        ...mbfPerformanceData,
        startDate: start,
        endDate: end,
      },
    });
  },
}));
