import { getDateFormat } from '@hc/dayjs';
import { useMBFWellnessJourney } from '@hc/store';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MbfChartCard } from '../chartCard';
import { PreviousStatsNav } from '../previousStatsNav';
import { WellnessActivityCard } from '../wellnessActivityCard';
import { wellnessJourneyHistory_style } from './style';

function WellnessJourneyHistory(props) {
  const {
    className = '',
    stats,
    active,
    onNext = () => {},
    onBack = () => {},
    rootStyle = {},
    ...rest
  } = props;

  const { mbfState, planStartDate, planEndDate, loading } =
    useMBFWellnessJourney((state) => ({
      mbfState: state.mbfState,
      loading: state.loading,
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
    }));

  return (
    <Box
      sx={{
        ...wellnessJourneyHistory_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!loading && (
        <>
          <Box px={2.5} py={2}>
            <PreviousStatsNav
              onNext={onNext}
              onBack={onBack}
              plan={`Plan ${stats === 0 ? 1 : stats}`}
              startAndEndDate={`${getDateFormat(
                planStartDate,
                'MMM DD yyyy'
              )} - ${getDateFormat(planEndDate, 'MMM DD yyyy')}`}
              active={active}
            />
          </Box>
          <Box>
            {mbfState?.graphData &&
            mbfState?.graphData.length > 0 &&
            mbfState?.graphData[1]?.value ? (
              <MbfChartCard />
            ) : null}
            {mbfState?.activities && mbfState?.activities.length > 0 ? (
              <WellnessActivityCard />
            ) : (
              <Typography
                textAlign="center"
                py={15}
                color="#616161"
                fontWeight={600}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </>
      )}
      {loading && (
        <Box sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}>
          <CircularProgress sx={{ color: 'mbf.main' }} />
        </Box>
      )}
    </Box>
  );
}

WellnessJourneyHistory.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { WellnessJourneyHistory };

