import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store';
import { UseFeedbacks } from '@hc/store/clinical/feedback';
import {
  AngryFaceEmoji,
  Button,
  CustomTextArea,
  HappyFaceEmoji,
  HeartFaceEmoji,
  NeutralFaceEmoji,
  NotOkEmoji,
  UploadCloseIcon,
  UploadIcon
} from '@hc/ui/atoms';
import { PatientReportUploadCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { handleUploadFile } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { feedback_style } from './style';

export default function FeedBack() {
  const [emoji, setEmoji] = useState(null);
  const [sendBtn, setsendBtn] = useState(false);
  const navigate = useNavigate();

  // Emojis 😎
  const FeedEmoji = [
    { id: 0, icon: <AngryFaceEmoji />, percentage: 0 },
    { id: 1, icon: <NotOkEmoji />, percentage: 25 },
    { id: 2, icon: <NeutralFaceEmoji />, percentage: 50 },
    { id: 3, icon: <HappyFaceEmoji />, percentage: 75 },
    { id: 4, icon: <HeartFaceEmoji />, percentage: 100 },
  ];

  // ----------- STORE ---------------------
  const {
    SendFeedback,
    changeState,
    feedBack,
    changeRating,
    isValid,
    error,
    feedBackPictureList,
    feedBackPictures,
    feedsDelete,
    clearFeedBack,
    loading,
  } = UseFeedbacks(
    (state) => ({
      SendFeedback: state.SendFeedback,
      changeState: state.changeState,
      feedBack: state.feedBack,
      changeRating: state.changeRating,
      loading: state.loading,
      isValid: state.isValid,
      error: state.error,
      feedBackPictureList: state.feedBackPictureList,
      feedBackPictures: state.feedBackPictures,
      feedsDelete: state.feedsDelete,
      clearFeedBack: state.clearFeedBack,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { profile } = useProfile(
    (state) => ({
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  // -------------- HANDLE FUNCTIONS ----------------
  const handleRating = (emj, percent) => {
    setEmoji(emj);
    changeRating(percent);
    if (sendBtn) isValid();
  };

  const handleFeedBack = (value) => {
    changeState(value);
    if (sendBtn) isValid();
  };

  const handleActivityPicUpload = async (e) => {
    const [res] = await handleUploadFile(e.target.files);
    if (res?.message === 'Upload Successfully!') {
      feedBackPictureList(res);
    }
  };

  const handleSendFeeds = () => {
    setsendBtn(true);
    const valid = isValid();
    if (valid) {
      SendFeedback(profile);
      setEmoji(null);
      navigate(clinicalRoutes.profileView);
    }
  };
  useEffect(() => clearFeedBack(), []);
  // filename
  return (
    <Screenlayout
      title="Feedback"
      backRequired
      childrenStyle={feedback_style.childSx}
      notshowFooter={false}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            sx={{ borderRadius: '10px' }}
            onClick={() => handleSendFeeds()}
            loading={loading}
          >
            Submit
          </Button>
        </Box>
      }
    >
      <Box sx={feedback_style.boxRootSx}>
        <Box py={2}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, pb: 2 }}>
            How is your experience with us?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {FeedEmoji.map((item, i) => (
              <Box
                key={i}
                onClick={() => handleRating(i, item.percentage)}
                sx={{
                  ...feedback_style.emojiContainer,
                  boxShadow: '0 0 3px #FDB408 , inset 0 0 3px #FDB408',
                  background: emoji === i ? '#FDB408' : '',
                }}
              >
                {item.icon}{' '}
              </Box>
            ))}
          </Box>
          <Typography
            sx={feedback_style.errorText}
            display={{
              display: error.rating ? 'block' : 'none',
            }}
          >
            {error.rating}
          </Typography>
        </Box>
        <Box py={2}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, py: 2 }}>
            Write your comment
          </Typography>
          <CustomTextArea
            placeholder="Say what you think about this app"
            textFieldStyle={feedback_style.customtextSx}
            value={feedBack}
            onChange={(e) => handleFeedBack(e.target.value)}
          />
          <Typography
            sx={feedback_style.errorText}
            display={{
              display: error.feedBack ? 'block' : 'none',
            }}
          >
            {error.feedBack}
          </Typography>
        </Box>
        <Box>
          {feedBackPictures.length === 0 && (
            <Box sx={feedback_style.activeUploadFileSx}>
              <Box component="label" sx={feedback_style.uploadIconSx}>
                <UploadIcon />
                <input
                  hidden
                  name="img"
                  accept="image/*"
                  multiple="multiple"
                  type="file"
                  id="uplodebtnv"
                  onChange={handleActivityPicUpload}
                />
              </Box>
              <Box>
                <Typography sx={feedback_style.browseTextSx}>
                  Click to browse and upload
                </Typography>
              </Box>
            </Box>
          )}
          {feedBackPictures && (
            <Box mb={1}>
              {Array.isArray(feedBackPictures) &&
                feedBackPictures.length > 0 &&
                feedBackPictures.map((val, i) => (
                  <PatientReportUploadCard
                    key={i}
                    showReportName
                    icon={<UploadCloseIcon />}
                    isUploadCard
                    reportName={val?.filename ?? ''}
                    uploadedFor={val?.size ?? ''}
                    uploadedData={[{ file: `${val?.downloadUrl ?? ''}` }]}
                    IconOnClick={() => feedsDelete(i)}
                  />
                ))}
            </Box>
          )}
          {feedBackPictures && feedBackPictures.length > 0 && (
            <Box component="label" sx={{ cursor: 'pointer' }}>
              <Typography pt={2} sx={feedback_style.addMoreButtonSx}>
                + Add More
              </Typography>
              <input
                hidden
                name="img"
                accept="image/*"
                multiple
                type="file"
                onChange={handleActivityPicUpload}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Screenlayout>
  );
}
