/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';
// import { httpRequest, routeTo, localStorageKeys} from '@hc/utils';

export const useReportType = create((set, get) => ({
  reportTypeData: [],
  // reportFilterData:[],
  loading: null,
  error: null,
  getReportType: async (type) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/masterReportTypes/getAll',
        'get',
      ]);
      const reportTypeDataDummy = [];
      if (type === 'filter') {
        reportTypeDataDummy.push({
          value: 'allReportType',
          label: 'All',
        });
      }
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          reportTypeDataDummy.push({
            value: val?.id ?? '',
            label: val?.report_type ?? '',
          });
        });
      }
      // const reportFilterDummyData = [];
      // if (type === 'filter') {
      //   reportFilterDummyData.push({
      //     value: 'allReportType',
      //     label: 'All',
      //   });
      // }

      // if (Array.isArray(data) && data?.length > 0) {
      //   data.map((val) => {
      //     reportFilterDummyData.push({
      //       value: val?.report_type ?? '',
      //       label: val?.report_type ?? '',
      //     });
      //   });
      // }

      return set((state) => ({
        loading: false,
        reportTypeData: reportTypeDataDummy,
        // reportFilterData:reportFilterDummyData
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
