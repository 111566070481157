import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { localStorageKeys, parseJwt, queryClient, routeTo } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

import { envConfig } from '@hc/config';
import { useRouting } from '../common/routing';

// import { useRouting } from '../common/routing';

export const useAirtable = create((set) => ({
  app: {},
  messaging: {},
  formData: {},
  records: null,
  loading: true,
  loadingWrite: false,
  table: null,
  isFeedBackSubmitted: null,
  isGoalsSubmitted: null,
  error: null,
  getTable: async (baseId, tableId) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const response = await queryClient.fetchQuery([
        // '/airTable/get-table',
        `/mbf/airtable/form-meta/get?tableId=${tableId}&baseId=${baseId}`,
        'get',
        { baseId, tableId },
      ]);
      if (response?.status?.code === 200) {
        set({
          loading: false,
          table: response?.data,
          error: null,
        });
      }
    } catch (error) {
      set({
        loading: false,
        error,
      });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  setFormData: (formData) => {
    set({
      formData,
    });
  },
  // checkAirtableForm: async () => {
  //   try {
  //     const authToken = localStorage.getItem(localStorageKeys.authToken);
  //     const tokenData = parseJwt(authToken);
  //     queryClient.invalidateQueries({
  //       queryKey: ['feedBack'],
  //     });
  //     const { data, status } = await queryClient.fetchQuery({
  //       queryKey: ['feedBack'],
  //       queryFn: async () => {
  //         const { data } = await httpRequest(
  //           'get',
  //           `${
  //             envConfig.api_url
  //           }/mbf/feedback-and-goals/submitted/get?user_id=${
  //             tokenData?.user_id ?? ''
  //           }`,
  //           {},
  //           true
  //         );
  //         return data;
  //       },
  //       staleTime: Infinity,
  //     });

  //     if (status?.code === 200) {
  //       set({
  //         loading: false,
  //         isFeedBackSubmitted: data?.is_feedback_submiited,
  //         isGoalsSubmitted: data?.is_goals_submitted,
  //       });
  //     }
  //   } catch (error) {
  //     set({ loading: false });
  //     log('error', error);
  //     return toast.error(
  //       error?.data?.message ??
  //         error?.message ??
  //         'Something went wrong please try again!'
  //     );
  //   }
  // },
  createRecord: async (baseId, tableId, recordData, onSubmit) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loadingWrite: true });
      let payload;
      if (tableId === envConfig.airtable_pre_evaluation_questionnaire) {
        payload = {
          baseId,
          tableId,
          recordData,
        };
      } else if (tableId === envConfig.airtable_plan_feedback) {
        payload = {
          user_id: tokenData?.user_id ?? '',
          baseId,
          tableId,
          recordData,
        };
      } else {
        payload = {
          user_id: tokenData?.user_id ?? '',
          baseId,
          tableId,
          recordData,
        };
      }
      // For preventing duplicate request, we're using react-query
      const response = await queryClient.fetchQuery([
        tableId === envConfig.airtable_pre_evaluation_questionnaire
          ? '/airTable/create-record'
          : tableId === envConfig.airtable_plan_feedback
          ? '/airTable/create-feedback'
          : '/airTable/create-goals',
        'post',
        { ...payload },
      ]);

      if (response?.status?.code === 200) {
        if (response?.data?.id) {
          toast.success('Form Submitted Successfully');
          onSubmit();
        } else {
          toast(response?.status?.message);
          onSubmit();
          routeTo(useRouting, clinicalRoutes.mindbodyfood);
        }
      }
      set({ loadingWrite: false, formData: {} });
    } catch (error) {
      set({
        loadingWrite: false,
      });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
