import {
  CircularProgressWithLabel,
  Crown,
  LightningIcon,
  RunningIcon,
} from '@atoms';
import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { leaderBoardCard_style } from './style';

export function LeaderBoardCard(props) {
  const {
    leaderName = '',
    rankList = '',
    src = {},
    running = '',
    energyPercentage = '',
    leaderBoardRankList = {},
    index = {},
    you = false,
    foodValue = 0,
    bodyValue = 0,
    mindValue = 0,
    className = '',
    ...rest
  } = props;
  const matches = useMediaQuery('(min-width:992px)');

  return (
    <Box className={`${className}`} {...rest}>
      <Grid
        container
        sx={{ ...leaderBoardCard_style.cardSx, ...leaderBoardRankList }}
      >
        <Grid
          item
          xs={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1.5,
          }}
        >
          <Typography sx={leaderBoardCard_style.rankListSx}>
            {you && (
              <Typography
                sx={{
                  position: 'absolute',
                  bgcolor: '#E6BA4C',
                  fontSize: '12px',
                  color: '#fff',
                  bottom: '26px',
                  left: '-12px',
                  px: 0.4,
                  borderRadius: '3px',
                  borderTopLeftRadius: '8px',
                }}
              >
                You
              </Typography>
            )}
            {rankList}
          </Typography>
          <Box sx={{ position: 'relative' }}>
            {index < 3 && (
              <Crown
                rootStyle={{
                  position: 'absolute',
                  top: '-5px',
                  left: '13px',
                  transform: 'rotate(-40deg)',
                }}
              />
            )}
            <Avatar
              src={src}
              alt="src"
              style={leaderBoardCard_style.avatarSx}
            />
          </Box>

          <Box sx={leaderBoardCard_style.textWithStatusSx}>
            <Typography sx={leaderBoardCard_style.leaderNameSx}>
              {leaderName}
            </Typography>

            <Box sx={leaderBoardCard_style.activitySx}>
              <Typography sx={leaderBoardCard_style.runningSx}>
                {running}
                &nbsp;
              </Typography>
              <RunningIcon />
              &nbsp;
              <RiCheckboxBlankCircleFill
                style={{ fontSize: '4px', pl: 1, pr: 0.5, color: 'grey' }}
              />
              &nbsp;
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={leaderBoardCard_style.runningSx}
                  component="span"
                >
                  {energyPercentage}%
                </Typography>
                &nbsp;
                <LightningIcon />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={5} sx={leaderBoardCard_style.withCircular}>
          <Grid container>
            <Grid item xs={4}>
              <CircularProgressWithLabel
                value={mindValue}
                size={matches ? 35 : 30}
                thickness={matches ? 4 : 3}
                typo={{ fontSize: '12px' }}
                color={{
                  mainColor: 'sky.800',
                  backgroundColor: 'sky.200',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CircularProgressWithLabel
                value={bodyValue}
                size={matches ? 35 : 30}
                thickness={matches ? 4 : 3}
                typo={{ fontSize: '12px' }}
                color={{
                  mainColor: 'lime.800',
                  backgroundColor: 'lime.200',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CircularProgressWithLabel
                value={foodValue}
                size={matches ? 35 : 30}
                thickness={matches ? 4 : 3}
                typo={{ fontSize: '12px' }}
                color={{
                  mainColor: 'amber.800',
                  backgroundColor: 'amber.200',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
LeaderBoardCard.propTypes = {
  leaderName: PropTypes.string,
  rankList: PropTypes.string,
  src: PropTypes.object,
  running: PropTypes.string,
  energyPercentage: PropTypes.string,
  leaderBoardRankList: PropTypes.object,
  index: PropTypes.object,
  you: PropTypes.bool,
  foodValue: PropTypes.object,
  bodyValue: PropTypes.bool,
  mindValue: PropTypes.bool,
  className: PropTypes.string,
};
