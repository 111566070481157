/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { httpRequest, queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import {
  BMICalculationProfileFamily,
  isBloodPressure,
  isDecimalNumber,
  isNumber,
  isString,
  parseJwt,
  routeTo,
} from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useProfile = create((set, get) => ({
  profile: {
    name: '',
    gender: '',
    age: '',
    mobile_no: '',
    country_code: '',
    blood_group: 0,
    email_id: '',
    height: '',
    weight: '',
    bmi: '',
    blood_pressure: '',
    temp_in_f: '',
    puls_bpm: '',
    o2_level: '',
    profile_pic: '',
    employee_membership_code: null,
    organisation_name: null,
    organization_logo: '',
    isCorporateUser: '',
    contractExpired: false,
    expiry_date: '',
    remaining_free_consultion: 0,
    total_free_consultation: 0,
    medical_allergies: [],
    medical_health_problems: [],
    medical_active_medications: [],
    error: {
      name: '',
      gender: '',
      age: '',
      email_id: '',
      mobile_no: '',
      vitals: '',
      medical_allergies: '',
      medical_health_problems: '',
      medical_active_medications: '',
      height: '',
      weight: '',
      blood_group: '',
    },
  },
  loading: null,
  profileUploadeLoading: null,
  error: null,
  get: async () => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/userprofiles/view-profile',
      ]);
      return set((state) => ({
        loading: false,
        profile: {
          ...state.profile,
          // ...data,
          id: data?.id ?? '',
          name: data?.name ?? '',
          gender: data?.gender ?? '',
          age: data?.age ?? '',
          mobile_no: data?.mobile_no ?? '',
          country_code: data?.country_code ?? '',
          email_id: data?.email_id ?? '',
          height: data?.vitals?.height ?? '',
          weight: data?.vitals?.weight ?? '',
          bmi: data?.vitals?.bmi ?? '',
          blood_group: data?.vitals?.blood_group ?? 0,
          blood_group_name: data?.vitals?.blood_group_name ?? '',
          blood_pressure: data?.vitals?.blood_pressure ?? '',
          temp_in_f: data?.vitals?.temp_in_f ?? '',
          puls_bpm: data?.vitals?.puls_bpm ?? '',
          o2_level: data?.vitals?.o2_level ?? '',
          organisation_name: data?.organisation_name ?? null,
          employee_membership_code: data?.employee_membership_code ?? null,
          organization_logo: data?.organization_logo ?? '',
          profile_pic: data?.profile_pic ?? '',
          medical_allergies: data?.medical_allergies ?? [],
          medical_health_problems: data?.medical_health_problems ?? [],
          medical_active_medications: data?.medical_active_medications ?? [],
        },
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getProfileById: async (profileId) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/getProfile`,
        {
          id: profileId,
        },
        true,
      );

      if (response?.data?.status?.code === '200') {
        set((state) => ({
          loading: false,
          profile: {
            ...state.profile,

            name: response?.data.data?.name ?? '',
            gender: response?.data.data?.gender ?? '',
            age: response?.data.data?.age ?? '',
            mobile_no: response?.data.data?.mobile_no ?? '',
            country_code: response?.data.data?.country_code ?? '',
            email_id: response?.data.data?.email_id ?? '',
            height: response?.data.data?.vitals?.height ?? '',
            weight: response?.data.data?.vitals?.weight ?? '',
            bmi: response?.data.data?.vitals?.bmi ?? '',
            // blood_group: response?.data.data?.blood_group ?? 0,
            blood_group: response?.data?.data?.vitals?.blood_group
              ? parseInt(response?.data.data?.vitals?.blood_group, 10)
              : 0,
            blood_pressure: response?.data.data?.vitals?.blood_pressure ?? '',
            temp_in_f: response?.data?.vitals?.temp_in_f ?? '',
            puls_bpm: response?.data?.vitals?.puls_bpm ?? '',
            o2_level: response?.data?.vitals?.o2_level ?? '',
            profile_pic: response?.data?.data?.profile_pic ?? '',
          },
        }));
        return response?.data.data;
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  upsert: async (payload, isAdd, isBookAppoinment) => {
    try {
      set({ loading: true });
      const data = {
        name: payload?.name ?? '',
        gender: payload?.gender ?? '',
        age: payload?.age ?? '',
        mobile_no: payload?.mobile_no ?? '',
        country_code: payload?.country_code ?? '',
        email_id: payload?.email_id ?? '',
        profile_pic: payload?.profile_pic ?? '',
        employee_membership_code: payload?.employee_membership_code ?? null,
        organisation_name: payload?.organisation_name ?? null,
        blood_group: payload?.blood_group
          ? parseInt(payload?.blood_group, 10)
          : 0,
      };
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/upsert-profile`,
        {
          ...data,
        },
        true,
      );
      if (!isBookAppoinment) {
        if (response?.data?.status?.code === '200') {
          toast.success(
            response?.data?.status?.message ??
              `Your profile has been ${
                isAdd ? 'created' : 'updated'
              } successfully.`,
          );
          if (isAdd) {
            const token = response?.data?.data?.token;
            localStorage.setItem(localStorageKeys.authToken, token);
          } else {
            setTimeout(() => {
              get().get();
            }, 1000);
          }
          set({ loading: false });
          return routeTo(
            useRouting,
            isAdd ? clinicalRoutes.home : clinicalRoutes.profileView,
          );
        }
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  profilePictureUpsert: async (event, user_id) => {
    try {
      set({ profileUploadeLoading: true });
      event.preventDefault();

      const formdata = new FormData();
      const files = event.target.files?.[0];

      formdata.append('file', files);
      formdata.append('id', user_id);
      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(localStorageKeys?.authToken) ?? ''
          }`,
        },
      };
      const uploads = await fetch(
        `${envConfig.api_url}/userprofiles/upsert-profile-picture`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => {
          console.log('error', error);
        });
      set({ profileUploadeLoading: false });
      return uploads;
    } catch (err) {
      set({ profileUploadeLoading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // CLEAR PROFILE STATE
  clearProfile: () => {
    set((state) => ({
      profile: {
        ...state.profile,
        name: '',
        gender: '',
        age: '',
        mobile_no: '',
        country_code: '',
        email_id: '',
        height: '',
        weight: '',
        bmi: '',
        blood_pressure: 0,
        blood_group: '',
        temp_in_f: '',
        puls_bpm: '',
        o2_level: '',
        profile_pic: '',
        organisation_name: '',
        medical_allergies: [],
        medical_health_problems: [],
        medical_active_medications: [],
        error: {
          name: '',
          gender: '',
          age: '',
          email_id: '',
          mobile_no: '',
          vitals: '',
          medical_allergies: '',
          medical_health_problems: '',
          medical_active_medications: '',
        },
      },
      error: null,
    }));
  },
  clearPageData: (key) => {
    set((state) => ({
      profile: {
        ...state.profile,
        [key]: [],
      },
    }));
  },
  // DELETE DATA
  deleteData: (type, i) => {
    const { profile } = get();
    const copyData = JSON.parse(JSON.stringify(profile?.[type]));
    copyData.splice(i, 1);
    set({
      profile: {
        ...profile,
        [type]: copyData,
      },
    });
  },

  // ADD Data at medical condition
  addData: (key, text) => {
    const { profile } = get();
    const profileDataCopy = JSON.parse(JSON.stringify(profile[key]));
    profileDataCopy.push(text);
    set({
      profile: {
        ...profile,
        [key]: profileDataCopy,
      },
    });
  },
  replaceData: (key, value) => {
    const { profile } = get();
    set({
      profile: {
        ...profile,
        [key]: value,
      },
    });
  },
  // HANDLE PROFILE DATA CAHNGE
  handleProfileDataChange: (key, value) => {
    const { profile, updateProfileError } = get();
    // For preventing change to the original state
    const profileCopy = JSON.parse(JSON.stringify(profile));

    if (key === 'height' || key === 'weight') {
      // BMI CALCULATION
      const BMI = BMICalculationProfileFamily(key, value, profile);
      return set({
        profile: {
          ...profileCopy,
          [key]: value,
          bmi: BMI,
        },
      });
    }
    if (key === 'temp_in_f' || key === 'puls_bpm' || key === 'o2_level') {
      if (isDecimalNumber(value)) {
        return set({
          profile: {
            ...profileCopy,
            [key]: value,
          },
        });
      }
    } else if (key === 'name') {
      if (isString(value)) {
        return set({
          profile: {
            ...profileCopy,
            name: value,
          },
        });
      }
    } else if (key === 'mobileNumber') {
      return set({
        profile: {
          ...profileCopy,
          mobile_no: value?.mobile,
          country_code: value?.mobileCode,
        },
      });
    } else if (key === 'age') {
      if (isNumber(value)) {
        const error = profileCopy?.error;
        if (value <= 120) {
          error.age = '';
          updateProfileError(error);
        } else {
          // Checking age
          error.age = 'Age must be less than or equal to 120';
          updateProfileError(error);
        }
        if (value <= 120) {
          return set({
            profile: {
              ...profileCopy,
              age: value,
            },
          });
        }
      }
    } else if (key === 'blood_pressure') {
      if (isBloodPressure(value)) {
        return set({
          profile: {
            ...profileCopy,
            blood_pressure: value,
          },
        });
      }
    } else if (key === 'blood_group') {
      if (profile?.blood_group === value) {
        set({
          profile: {
            ...profileCopy,
            [key]: 'k',
          },
        });
      } else {
        set({
          profile: {
            ...profileCopy,
            [key]: value,
          },
        });
      }
    } else {
      return set({
        profile: {
          ...profileCopy,
          [key]: value,
        },
      });
    }
  },
  // UPDATE PROFILE ERROR
  updateProfileError: (error) => {
    const { profile } = get();
    set({
      profile: {
        ...profile,
        error,
      },
    });
  },
  // UPDATE MOBILE NUMBER
  updateMobileNumber: (data) => {
    const { profile } = get();
    set({
      profile: {
        ...profile,
        mobile_no: data?.mobile_no,
        country_code: data?.country_code ?? '+91',
      },
    });
  },
  // UPDATE PROFILE PICTURE
  updateProfilePicture: (profile_pic_url) => {
    const { profile } = get();
    set({
      profile: {
        ...profile,
        profile_pic: profile_pic_url,
      },
    });
  },

  // Delete PROFILE PICTURE
  deleteProfileImage: async (id) => {
    const { profile } = get();
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/delete-profile-picture`,
        {
          user_id: id,
        },
        true,
      );
      if (response.data?.status?.code === 200) {
        set({
          profile: {
            ...profile,
            profile_pic: '',
          },
          loading: false,
        });
        toast.success(response?.data?.status?.message ?? '');
        return response;
      }
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', err);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  refresh: async () => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/refresh`,
        {},
        true,
      );
      const token = response?.data?.data?.token;
      localStorage.setItem(localStorageKeys.authToken, token);
      const authToken = token;
      const data = parseJwt(authToken);
      return set((state) => ({
        loading: false,
        profile: {
          ...state.profile,
          organisation_name: data?.organisation_name ?? '',
        },
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getCorporateUserData: async (userID) => {
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['freeConsultation'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/userprofiles/free-consultation/get?user_id=${userID}`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status.code === 200) {
        set((state) => ({
          loading: false,
          profile: {
            ...state.profile,
            isCorporateUser: data?.isCorporateUser ?? '',
            contractExpired: data?.contractExpired ?? '',
            expiry_date: data?.expiry_date ?? '',
            remaining_free_consultion: data?.remaining_free_consultion ?? '',
            total_free_consultation: data?.total_free_consultation ?? '',
          },
        }));
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  upsertMedicalCondition: async () => {
    try {
      const { profile } = get();
      set({ loading: true });
      const data = {
        height: profile?.height ?? '',
        weight: profile?.weight ?? '',
        bmi: profile?.bmi ?? '',
        blood_group: profile?.blood_group
          ? parseInt(profile?.blood_group, 10)
          : 0,
        medical_allergies: profile?.medical_allergies ?? [],
        medical_health_problems: profile?.medical_health_problems ?? [],
        medical_active_medications: profile?.medical_active_medications ?? [],
      };

      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/upsert-medical-conditions`,
        {
          ...data,
        },
        true,
      );
      if (response?.data?.status?.code === 200) {
        toast.success(
          response?.data?.status?.message ??
            `Medical Condition Updated Sucessfully`,
        );
        set({ loading: false });
        return 'Successs';
      }
      set({ loading: false });
      return 'Failed';
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
