import { useFamilyMember } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { FamilyMemberEdit } from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { isValidBloodPressure } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';

import { addEditFamilyMember_style } from './style';

export default function EditFamilyMember(props) {
  const { className = '', ...rest } = props;

  // General Hooks
  const navigate = useNavigate();

  const {
    familyMember,
    upsertFamilyMember,
    updateFamilyMemberError,
    clearFamilyMemberState,
  } = useFamilyMember(
    (state) => ({
      familyMember: state.familyMember,
      updateFamilyMemberError: state.updateFamilyMemberError,
      clearFamilyMemberState: state.clearFamilyMemberState,
      upsertFamilyMember: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const isIamValidToSave = () => {
    const familyMemberCopy = JSON.parse(JSON.stringify(familyMember));

    let isValid = true;
    const error = familyMemberCopy?.error;
    // Checking name
    if (!familyMember?.name) {
      isValid = false;
      error.name = 'Please enter your name';
    } else {
      error.name = '';
    }

    // Checking relationship
    if (!familyMember?.relationship) {
      isValid = false;
      error.relationship = 'Please select relationship';
    } else {
      error.relationship = '';
    }

    // Checking gender
    if (!familyMember?.gender) {
      isValid = false;
      error.gender = 'Please enter your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!familyMember?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }

    // Checking o2_level pressure
    if (familyMember?.o2_level.length > 0) {
      if (familyMember.o2_level < 95 || familyMember.o2_level > 100) {
        isValid = false;
        error.o2_level = 'Range between 95 and 100';
      } else {
        error.o2_level = '';
      }
    } else {
      error.o2_level = '';
    }

    // Checking puls_bpm pressure
    if (familyMember?.puls_bpm.length > 0) {
      if (familyMember.puls_bpm < 60 || familyMember.puls_bpm > 100) {
        isValid = false;
        error.puls_bpm = 'Range between 60 and 100';
      } else {
        error.puls_bpm = '';
      }
    } else {
      error.puls_bpm = '';
    }

    // Checking temp_in_f pressure
    if (familyMember?.temp_in_f.length > 0) {
      if (familyMember.temp_in_f < 80 || familyMember.temp_in_f > 120) {
        isValid = false;
        error.temp_in_f = 'Range between 80 and 120';
      } else {
        error.temp_in_f = '';
      }
    } else {
      error.temp_in_f = '';
    }

    // Checking blood pressure
    if (familyMember?.blood_pressure.length > 0) {
      if (isValidBloodPressure(familyMember?.blood_pressure)) {
        if (familyMember?.blood_pressure.includes('/')) {
          if (
            familyMember?.blood_pressure?.split('/')[0] &&
            familyMember?.blood_pressure?.split('/')[1]
          ) {
            if (
              familyMember?.blood_pressure?.split('/')[0] < 90 ||
              familyMember?.blood_pressure?.split('/')[0] > 120 ||
              familyMember?.blood_pressure?.split('/')[1] < 60 ||
              familyMember?.blood_pressure?.split('/')[1] > 80
            ) {
              isValid = false;
              error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
            } else {
              error.blood_pressure = '';
            }
          } else {
            isValid = false;
            error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
          }
        } else {
          isValid = false;
          error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
        }
      } else {
        isValid = false;
        error.blood_pressure = 'Range between 90/60mmHg and 120/80mmHg';
      }
    }
    // Checking email_id
    const filter = /\S+@\S+\.\S+/;
    if (familyMember?.email_id?.length > 0) {
      if (!filter.test(familyMember?.email_id)) {
        isValid = false;
        error.email_id = 'Please enter the valid mail';
      } else {
        error.email_id = '';
      }
    } else {
      isValid = false;
      error.email_id = 'Please enter the mail';
    }
    // Checking mobile_no
    if (familyMember?.mobile_no?.length > 0) {
      if (familyMember?.mobile_no?.length !== 10) {
        isValid = false;
        error.mobile_no = 'Please enter your valid mobilenumber';
      } else {
        error.mobile_no = '';
      }
    } else {
      isValid = false;
      error.mobile_no = 'Please enter your mobilenumber';
    }
    // UPDATE FAMILY MEMBER ERROR
    updateFamilyMemberError(error);
    return isValid;
  };

  const handleUpdate = async () => {
    const result = isIamValidToSave();
    if (result) {
      await upsertFamilyMember(familyMember, false);
      // CLEAR FAMILY MEMBER STATE
      clearFamilyMemberState();
    }
  };

  const familyMemberView = () => {
    navigate(clinicalRoutes.myFamily);
    // CLEAR FAMILY MEMBER STATE
    clearFamilyMemberState();
  };

  return (
    <Box
      sx={addEditFamilyMember_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={addEditFamilyMember_style.boxRootSx}>
        <Screenlayout
          backRequired
          title="Edit Family Member"
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={familyMemberView}
                buttonStyle={addEditFamilyMember_style.buttonSx}
              >
                Cancel
              </Button>
              <Button onClick={handleUpdate}>Save</Button>
            </Box>
          }
        >
          <FamilyMemberEdit useMyNumber={familyMember?.is_use_my_number} />
        </Screenlayout>
      </Box>
    </Box>
  );
}
