export const appointmentDetails_style = {
  rootSx: {
    p: 1,
  },
  titleSx: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  labelSx: {
    backgroundColor: 'PrimaryTints.A100',
    p: 0.4,
    borderRadius: '4px',
    color: 'text.primary',
    fontSize: '12px',
    ml: 0.8,
  },
  headerSx: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#18574CB7',
    fontWeight:500
  },
};
