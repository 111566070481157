import MbfHomeBanner from '@assets/mbfHomeBanner.svg';
import { useMBFActivity } from '@hc/store';
import { TodaysActivity } from '@hc/ui/components/clinical/mindBodyFood/todaysActivity';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Stack, Typography } from '@mui/material';
import { isEqual } from 'date-fns';
import React from 'react';

import {
  AnyTimeIcon,
  ChangeRequestEmojiIcon, MoonIcon,
  NoonIcon,
  SpecificTimeIcon,
  SunRiseIcon,
  SunSetIcon
} from '../../../../../atoms';
import { SkeletonCard } from '../../../skeletonCard';
import { MbfEmptyState } from '../../mbfEmptyState';
import { mbfHome_activity_card_style } from './style';

export function MBFHomeActivityCard(props) {
  const { openRequestDrawer } = props;
  // const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);

  const data = parseJwt(authToken);

  const {
    mbfState,
    activityUpsert,
    getTodayActivity,
    updateMbfStore,
    updateMbfEditDataStore,
    loading,
    getOverallPlanPercentage,
  } = useMBFActivity(
    (state) => ({
      getTodayActivity: state.getTodayActivity,
      getOverallPlanPercentage: state.getOverallPlanPercentage,
      mbfState: state.mbfState,
      activityUpsert: state.activityUpsert,
      updateMbfStore: state.updateMbfStore,
      updateMbfEditDataStore: state.updateMbfEditDataStore,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { mbfActivityStateConstructData, editData, progressValue, planId } =
    mbfState;

  const updateStore = (val) => {
    updateMbfStore('editData', val);
  };
  const updateProgressStore = (val) => {
    updateMbfEditDataStore('user_updated_quantity', val);
  };

  const onUpdate = async () => {
    const paylaod = {
      user_id: data?.user_id ?? '',
      user_updated_quantity: editData?.user_updated_quantity ?? 0,
      user_plan_id: planId ?? '',
      activity_id: editData?.activity_id ?? '',
      is_measurable: editData?.is_measurable ?? true,
    };
    await activityUpsert(paylaod);
    // To refetch the query, which was cached
    await queryClient.invalidateQueries({
      queryKey: ['getTodayActivity'],
    });
    await getTodayActivity(false);

    // To refetch the query, which was cached
    await queryClient.invalidateQueries({
      queryKey: ['getOverallPlanPercentage'],
    });

    await getOverallPlanPercentage(false);

    // To invalidate the cache of performanceStats
    queryClient.invalidateQueries({
      queryKey: ['performanceStats'],
    });

    // To invalidate the cache of wellnessJourney
    queryClient.invalidateQueries({
      queryKey: ['wellnessJourney'],
    });

    // To invalidate the cache of wellnessPlan
    queryClient.invalidateQueries({
      queryKey: ['wellnessPlan'],
    });
  };

  return (
    <Box>
      {mbfActivityStateConstructData &&
        Object.keys(mbfActivityStateConstructData).length > 0 && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: '16px',
                color: 'text.primary',
                fontWeight: 600,
                mt: 3,
                mb: 2,
              }}
            >
              Today &apos;s Activity
            </Typography>
            {/* <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                color: '#6856cd',
                mt: 3,
                mb: 2,
                cursor: 'pointer',
              }}
              onClick={() => openRequestDrawer()}
            >
              Change Request
            </Typography> */}
          </Stack>
        )}

      {!loading &&
        mbfActivityStateConstructData &&
        Object.keys(mbfActivityStateConstructData).length > 0 &&
        ['Morning', 'Noon', 'Evening', 'Night', 'Anytime', 'Specific time'].map(
          (i, key) => {
            if (
              !mbfActivityStateConstructData[i] ||
              mbfActivityStateConstructData[i].length === 0
            )
              return;
            return (
              <Box key={key} px={0.5}>
                <Box sx={mbfHome_activity_card_style.activityTypeSx}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      fontWeight: 'medium',
                    }}
                  >
                    {i}
                  </Typography>
                  {mbfActivityStateConstructData?.[i]?.[0]?.icon}
                  {i === 'Morning' && <SunRiseIcon />}
                  {i === 'Noon' && <NoonIcon />}
                  {i === 'Evening' && <SunSetIcon />}
                  {i === 'Night' && <MoonIcon />}
                  {i === 'Anytime' && <AnyTimeIcon />}
                  {i === 'Specific time' && <SpecificTimeIcon />}
                </Box>
                <TodaysActivity
                  onUpdate={onUpdate}
                  loading={loading}
                  updateStores={updateStore}
                  updateProgressStore={updateProgressStore}
                  todaysActivity={mbfActivityStateConstructData?.[i]}
                  editData={editData}
                  progressValue={progressValue}
                />
              </Box>
            );
          },
        )}
      {!loading && Object.keys(mbfActivityStateConstructData).length === 0 && (
        <Box>
          <MbfEmptyState />
        </Box>
      )}

      {!loading && mbfActivityStateConstructData && (
        <Box
          onClick={() => openRequestDrawer()}
          sx={{
            mt: 2,
            backgroundColor: 'purple.600',
            borderRadius: '10px',
            backgroundImage: `url(${MbfHomeBanner})`,
            backgroundPosition: 'bottom',
            height: '54px',
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            px: 2,
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
        >
          <ChangeRequestEmojiIcon />
          <Typography sx={mbfHome_activity_card_style.descSx}>
            Not satisfied with plan?
          </Typography>

          <Typography
            sx={mbfHome_activity_card_style.spanSx}
            onClick={() => openRequestDrawer()}
          >
            change plan
          </Typography>
        </Box>
      )}
      {loading &&
        Array(4)
          .fill(1)
          .map((i) => (
            <SkeletonCard
              key={i}
              isCardDetailsRequired={false}
              color="#D5CCFF"
            />
          ))}
    </Box>
  );
}
