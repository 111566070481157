/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
import { envConfig } from '@hc/config';
import { clinicalRoutes } from '@hc/routes';
import { useChatConsultation } from '@hc/store/clinical/chatConsultation';
import {
  AwrImgIcon,
  Button,
  ChatIllustration,
  DocumentIcon,
  Drawer,
  DropDown,
  Loader,
  RegisterIcon,
  UploadCloseIcon,
} from '@hc/ui/atoms';
import {
  ChartListingInputBox,
  ChatFeedbackModal,
  ChatListingBox,
  ConfigurationModal,
  EmptyStateComponent,
  MedicalCard,
  PatientReportUploadCard,
  Screenlayout,
} from '@hc/ui/components';
import {
  ChatConstructData,
  formatBytes,
  handleUploadFile,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { useSocket } from '@hc/utils/socket';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { razorpayCall } from 'crayond_payment_gateway';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { clinical_chat_page_style } from './style';

export default function ClinicalChatPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isTimeOutModalOpen, setTimeOutModalOpen] = useState(false);

  // const [isScroll, setIsScroll] = useState();
  const [loading, setLoading] = useState(false);
  // const [isShow,setIsShow] =
  const [open, setOpen] = useState(false);
  const [isAttachFileOpen, setIsAttachFileOpen] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const [isFeedModalOpen, setFeedModalOpen] = useState(false);
  const [timer, setTimer] = useState('');

  const handleEndModal = () => {
    setModalOpen(false);
  };

  const handleTimeOutModal = () => {
    setTimeOutModalOpen(false);
  };

  const handleTimeOutTask = () => {
    setTimeOutModalOpen(true);
  };

  // to open the config modal
  const handleEndTask = () => {
    setModalOpen(true);
  };
  const handleCloseFeeds = () => {
    setFeedModalOpen(false);
  };

  const socket = useSocket();

  const {
    chatConsultationState,
    getChatCounsultationById,
    getChatHistoryDataById,
    getMedicalCardDataById,
    updateChatConsultationState,
    getLocalStorage,
  } = useChatConsultation(
    (state) => ({
      getLocalStorage: state.getLocalStorage,
      getChatHistoryDataById: state.getChatHistoryDataById,
      getMedicalCardDataById: state.getMedicalCardDataById,
      getChatCounsultationById: state.getChatCounsultationById,
      chatConsultationState: state.chatConsultationState,
      updateChatConsultationState: state.updateChatConsultationState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    chatStatusData,
    onlineTyping,
    typingMessage,
    patientChatData,
    messageType,
    messageJSON,
    chatCounsultationData,
    patientMedicalData,
    medicalCondition,
    sendMedicalCondition,
    sendVital,
    isShowChatExtend,
    isChatExtend,
    isScroll,
  } = chatConsultationState;

  // console.log(chatCounsultationData, 'chatCounsultationData');

  // console.log(chatStatusData, 'chatStatusData');

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    // type = '',
    chatConsultationRequestsId = '',
    chatConsutationMessageId = '',
  } = chatStatusData;

  const commonSocketDataValue = {
    doctorProfileId,
    doctorUserId,
    doctorProfilePicture,
    doctorName,
    doctorMobileNo,
    doctorChatConsultationAmount,
    chatRequestedDate,
    chatConsultationDuration,
    patientUserId,
    patientProfileId,
    patientProfilePicture,
    patientName,
    patientMobileNo,
    chatConsultationRequestsId,
    type: 'onlineByPatient',
    chatConsultationId: localStorage.getItem('chatConsultationId'),
  };

  // console.log(chatConsultationState, 'chatConsultationState');

  const uploadUtills = [
    {
      value: 'image',
      name: 'Image',
      icon: (
        <AwrImgIcon
          fill="#666"
          rootStyle={{ height: '50px', width: '50px', color: '#000' }}
        />
      ),
      accept: 'image/*',
    },
    {
      value: 'pdf',
      name: 'PDF',
      icon: (
        <DocumentIcon
          rootStyle={{ height: '50px', width: '50px', color: '#666' }}
        />
      ),
      accept: 'application/pdf',
    },
  ];

  const onMessageTypeFnc = async (val) => {
    updateChatConsultationState('typingMessage', val);
    updateChatConsultationState('messageType', 1);
    // call socket for chat_live_typing_status
    await socket.emit('chat_live_typing_status', {
      ...commonSocketDataValue,
      chatStatus: 'typing...',
    });
  };

  // Attach PDF or IMG file scenario
  const onAttachFileFnc = () => {
    setIsAttachFileOpen(true);
  };

  const onCloseAttachFileDrawer = () => {
    setIsAttachFileOpen(false);
    setIsSelected('');
  };

  let myIntervalll;

  const stopInterval = () => {
    clearTimeout(myIntervalll);
  };

  // console.log(timer,"timer")

  const timerFuntion = (futureTime, data) => {
    // ;
    stopInterval();
    myIntervalll = setInterval(() => {
      // ;

      const countDownDate = new Date(futureTime).getTime();
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // ;
      if (minutes === 5 && seconds === 0) {
        updateChatConsultationState('isShowChatExtend', true);
      }

      if (minutes === 0 && seconds === 30) {
        updateChatConsultationState('isShowChatExtend', false);
      }

      if (minutes >= 0 && seconds >= 0) {
        setTimer(`${minutes}mins ${seconds}secs`);
      }
      if (
        minutes <= 0 &&
        seconds <= 0 &&
        data?.consultation_status_name === 'OnGoing' &&
        data?.chat_starts_at?.length > 0
      ) {
        clearInterval(myIntervalll);
        handleTimeOutTask();
      }
    }, 1000);
    return () => {
      clearInterval(myIntervalll);
    };
  };

  const getChatDetails = async (consultation_id) => {
    //
    // debugger;
    queryClient?.invalidateQueries({
      queryKey: ['chat_consultation_history_by_id'],
    });
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_data_by_id'],
    });

    const response = await getChatCounsultationById(consultation_id);
    if (response?.data?.consultation_status_name === 'OnGoing') {
      await getChatHistoryDataById(
        consultation_id,
        response?.data?.consultation_status_name,
      );
    }

    updateChatConsultationState('chatCounsultationData', response?.data);
    const paymentCount = response
      ? response?.data?.chat_payment_count
      : chatCounsultationData?.chat_payment_count;

    const chatTime = paymentCount * 15;

    let futureTime = new Date(
      new Date(
        response
          ? response?.data?.chat_starts_at
          : chatCounsultationData?.chat_starts_at,
      ).getTime() +
        chatTime * 60 * 1000,
    );

    stopInterval();
    //
    // myIntervalll = undefined;
    setTimer('');
    timerFuntion(futureTime, response?.data);
  };

  const onSendMessageFnc = async () => {
    if (isAttachFileOpen) {
      updateChatConsultationState('messageJSON', []);
      updateChatConsultationState('messageType', 1);
      onCloseAttachFileDrawer();
    }
    updateChatConsultationState('isScroll', true);

    // Send message scenario
    const eventData = {
      chatMessage: {
        type: messageType ?? 1,
        message: typingMessage,
        message_json: messageJSON ?? null,
        created_at: new Date(),
        is_seen: false,
        chatData: patientChatData,
      },
      messageSender: 'patient',
    };

    ChatConstructData(
      eventData,
      tokenData,
      patientChatData,
      'patientChatData',
      updateChatConsultationState,
      true,
    );

    // call socket for chat_between_doctor_patient
    await socket.emit('chat_consultation_message', {
      ...commonSocketDataValue,
      messageSender: 'patient',
      chatMessage: eventData?.chatMessage,
      chatConsutationMessageId,
      chatConsultationRequestPaymentId: '',
      responsePaymentData: {},
    });

    updateChatConsultationState('typingMessage', '');
    updateChatConsultationState('isScroll', false);

    await socket.emit('chat_live_typing_status', {
      ...commonSocketDataValue,
      chatStatus: 'online',
    });
  };

  const handleTimeoutCloseChat = async () => {
    // call socket for chat_live_typing_status
    await socket.emit('chat_consultation_completion', {
      ...commonSocketDataValue,
      chatEndedBy: patientProfileId,
      endedByWhom: 'Time Out',
    });

    setTimeOutModalOpen(false);
    // Online or Typing store update
    updateChatConsultationState('onlineTyping', '');
    // Open feedback modal
    setFeedModalOpen(true);
    // Socket call for availability status change
    await socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'online',
    });
  };

  const handleCloseChat = async () => {
    // call socket for chat_live_typing_status
    await socket.emit('chat_consultation_completion', {
      ...commonSocketDataValue,
      chatEndedBy: patientProfileId,
      endedByWhom: 'patient',
    });
    localStorage.removeItem('chatTimerMins');
    localStorage.removeItem('chatTimerMins');
    localStorage.removeItem('isChatTimer');
    setModalOpen(false);
    // Online or Typing store update
    updateChatConsultationState('onlineTyping', '');
    // Open feedback modal
    setFeedModalOpen(true);
    // Socket call for availability status change
    await socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'online',
    });
  };

  const handleModelOpen = async () => {
    queryClient.invalidateQueries({
      queryKey: ['chat_medical_card_data_by_id'],
    });

    const response = await getMedicalCardDataById();
    if (response?.code === 200) {
      setOpen(true);
    }
  };

  const handleModelClose = () => setOpen(false);

  const onDelete = (object) => {
    const data = medicalCondition;
    const arr = [];

    for (const obj of data) {
      if (obj?.value !== object?.value) {
        arr?.push(obj);
      }
    }
    updateChatConsultationState('medicalCondition', arr);
  };

  const onVitalChange = (value) => {
    updateChatConsultationState('sendVital', value);
  };

  const onMedicalConditionChange = (value) => {
    updateChatConsultationState('sendMedicalCondition', value);
  };

  const onSendVitals = async () => {
    updateChatConsultationState('isScroll', true);
    let medicalData = {};
    if (sendMedicalCondition) {
      for (const object of medicalCondition) {
        medicalData = {
          ...medicalData,
          [object?.key]: patientMedicalData?.medical_condition?.[object?.key],
        };
      }
    }
    const obj = {
      patient_details: { ...patientMedicalData?.patient_details },
      vitals: sendVital ? { ...patientMedicalData?.vitals } : {},
      medical_condition: medicalData ?? {},
      is_vital_sent: sendVital,
      is_medical_condition_sent: sendMedicalCondition,
    };

    await socket.emit('send_medical_card_to_doctor', {
      ...commonSocketDataValue,
      medicalCard: obj,
    });
    if (sendVital) {
      const eventData = {
        chatMessage: {
          type: 4,
          message: '',
          message_json: obj,
          created_at: new Date(),
          is_seen: false,
          chatData: patientChatData,
        },
        messageSender: 'patient',
      };

      ChatConstructData(
        eventData,
        tokenData,
        patientChatData,
        'patientChatData',
        updateChatConsultationState,
        true,
      );
      // call socket for chat_between_doctor_patient
      await socket.emit('chat_consultation_message', {
        ...commonSocketDataValue,
        messageSender: 'patient',
        chatMessage: eventData?.chatMessage,
        chatConsutationMessageId,
        chatConsultationRequestPaymentId: '',
        responsePaymentData: {},
      });
      updateChatConsultationState('isScroll', false);
    }
    if (sendMedicalCondition) {
      const eventData = {
        chatMessage: {
          type: 6,
          message: '',
          message_json: obj,
          created_at: new Date(),
          is_seen: false,
          chatData: patientChatData,
        },
        messageSender: 'patient',
      };

      ChatConstructData(
        eventData,
        tokenData,
        patientChatData,
        'patientChatData',
        updateChatConsultationState,
        true,
      );
      // call socket for chat_between_doctor_patient
      await socket.emit('chat_consultation_message', {
        ...commonSocketDataValue,
        messageSender: 'patient',
        chatMessage: eventData?.chatMessage,
        chatConsutationMessageId,
        chatConsultationRequestPaymentId: '',
        responsePaymentData: {},
      });
      updateChatConsultationState('isScroll', false);
    }

    updateChatConsultationState('typingMessage', '');
    handleModelClose();
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_data_by_id'],
    });
    const chatConsultationId = localStorage.getItem('chatConsultationId');
    await getChatCounsultationById(chatConsultationId);
  };

  const callBackResponse = async (val) => {
    if (val?.statusCode === 200) {
      updateChatConsultationState('isScroll', true);
      toast.success(val?.message ?? `Payment Success.`);
      const eventData = {
        chatMessage: {
          type: 1,
          message: '',
          message_json: messageJSON ?? null,
          created_at: new Date(),
          is_seen: false,
          chatData: patientChatData,
        },
        messageSender: 'patient',
      };

      await socket.emit('chat_consultation_message', {
        ...commonSocketDataValue,
        messageSender: 'patient',
        chatMessage: eventData?.chatMessage,
        chatConsutationMessageId,
        type: 'Payment Completed',
        chatConsultationRequestPaymentId: localStorage.getItem(
          'chatConsultationRequestPaymentId',
        ),
        responsePaymentData: val?.data ?? {},
      });

      // Navigate to chat page
      // setTimeout(() => {
      updateChatConsultationState('isScroll', false);
      updateChatConsultationState('chatStatus', 'OnGoing');
      const chatConsultationId = localStorage.getItem('chatConsultationId');
      updateChatConsultationState('isShowChatExtend', false);
      updateChatConsultationState('isChatExtend', false);
      //
      getChatDetails(chatConsultationId);
      navigate(clinicalRoutes?.chat);
      window.location.reload();

      // }, 3000);
    } else {
      toast.success(val?.message ?? `Something went wrong please try again!`);
      // setPaymentLoadingState(false);
    }
  };

  const makePaymentClickFnc = async () => {
    // setPaymentLoadingState(true);
    updateChatConsultationState('isScroll', true);
    const eventData = {
      chatMessage: {
        type: 1,
        message: '',
        message_json: messageJSON ?? null,
        created_at: new Date(),
        is_seen: false,
        chatData: patientChatData,
      },
      messageSender: 'patient',
    };

    await socket.emit('chat_consultation_message', {
      ...commonSocketDataValue,
      messageSender: 'patient',
      chatMessage: eventData?.chatMessage,
      chatConsutationMessageId,
      type: 'Payment Initiated',
      chatConsultationRequestPaymentId: '',
      responsePaymentData: {},
    });

    updateChatConsultationState('isScroll', false);
    razorpayCall({
      baseURL: envConfig?.payment_url,
      orderAmount: doctorChatConsultationAmount.toString() ?? 0,
      name: 'Health Circles',
      description: 'description',
      preFillName: patientName ?? '',
      preFillEmail: '',
      preFillContactNumber: patientMobileNo ?? '',
      notesAddress: 'notesAddress',
      theme: '',
      paymentType: 1,
      setLoading,
      callBackResponse,
    });
  };

  const onRequestOrPayClick = async () => {
    if (isShowChatExtend) {
      stopInterval();
      updateChatConsultationState('isScroll', true);
      updateChatConsultationState('requestRaised', true);
      const eventData = {
        chatMessage: {
          type: 1,
          message: "I'm request to chat extension",
          message_json: messageJSON ?? null,
          created_at: new Date(),
          is_seen: false,
          chatData: patientChatData,
        },
        messageSender: 'patient',
      };

      ChatConstructData(
        eventData,
        tokenData,
        patientChatData,
        'patientChatData',
        updateChatConsultationState,
        true,
      );
      // call socket for chat_between_doctor_patient
      await socket.emit('chat_consultation_message', {
        ...commonSocketDataValue,
        messageSender: 'patient',
        chatMessage: eventData?.chatMessage,
        chatConsutationMessageId,
        chatConsultationRequestPaymentId: '',
        responsePaymentData: {},
      });
      updateChatConsultationState('isScroll', false);
      updateChatConsultationState('typingMessage', '');
      updateChatConsultationState('isShowChatExtend', false);
    } else if (isChatExtend) {
      makePaymentClickFnc();
    }
  };

  const onSelectUploadType = (i) => {
    if (isSelected === '') {
      setIsSelected(i);
    }
  };

  const handleFileUpload = async (e, value) => {
    setIsLoading(true);
    let fileList = e?.target?.files;
    let url = '';
    let arrayData = [];
    if (value === 'image') {
      updateChatConsultationState('messageType', 2);
      // setIsLoading(true);
      for (const file of fileList) {
        const fileFormData = file;
        const getData = await handleUploadFile([file]);
        const data = getData.map((val) => val.downloadUrl);
        url = data[0];
        const size = formatBytes(file?.size ?? 0, 0);
        const filename = file?.name;
        const type = file?.type;
        const sizeNumber = file?.size ?? 0;
        arrayData.push({
          fileFormData: { 0: fileFormData, length: 1 },
          downloadUrl: [url],
          size,
          filename,
          type,
          sizeNumber,
        });
      }
      updateChatConsultationState('messageJSON', arrayData);
    } else if (value === 'pdf') {
      let reArr = [];
      updateChatConsultationState('messageType', 3);
      for (const file of fileList) {
        const getData = await handleUploadFile([file]);
        const data = getData.map((val) => val.downloadUrl);
        url = data[0];
        const fileFormData = file;
        const size = formatBytes(file?.size ?? 0, 0);
        const filename = file?.name;
        const type = file?.type;
        const sizeNumber = file?.size ?? 0;
        reArr.push({
          fileFormData: { 0: fileFormData, length: 1 },
          downloadUrl: [url],
          size,
          filename,
          type,
          sizeNumber,
        });
      }
      updateChatConsultationState('messageJSON', reArr);
    }
    return setIsLoading(false);
  };

  const handleScroll = () => {
    updateChatConsultationState('isScroll', false);
  };

  const handleDelete = (value) => {
    let reArr = [];
    for (const obj of chatConsultationState?.messageJSON) {
      if (obj?.filename !== value) {
        reArr?.push(obj);
      }
    }
    updateChatConsultationState('messageJSON', reArr);
  };

  const onBack = () => {
    updateChatConsultationState('messageJSON', []);
    updateChatConsultationState('messageType', 1);
    onCloseAttachFileDrawer();
  };

  const handleFedModalClose = () => {
    setFeedModalOpen(false);
    navigate(clinicalRoutes?.chatListPage);
  };

  useEffect(() => {
    const jsonData = localStorage.getItem('clinicalChatStatusData');
    const chatStatusData = JSON.parse(jsonData);
    updateChatConsultationState('chatStatusData', chatStatusData);
    const chatConsultationId = localStorage.getItem('chatConsultationId');
    if (location?.state?.history !== 'isHistory') {
      updateChatConsultationState('patientChatData', []);
    }
    getChatDetails(chatConsultationId);
    getLocalStorage();
  }, []);

  useEffect(() => {
    if (socket) {
      // Chat start by doctor
      socket.on('chat_start_by_doctor', (eventData) => {
        if (tokenData?.user_id === eventData?.patientUserId) {
          // const data = {
          //   ...commonSocketData(eventData),
          //   chatConsultationId: eventData?.chatConsultationId,
          // };
          const chatConsultationId = localStorage.getItem('chatConsultationId');
          getChatDetails(chatConsultationId);
          localStorage.setItem('chatStatusData', chatStatusData);
        }
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  return (
    <Screenlayout
      isChatTittleRequired
      titleRequired={
        chatCounsultationData?.consultation_status_name === 'OnGoing' && false
      }
      isChatEndButton={
        chatCounsultationData?.consultation_status_name === 'OnGoing'
      }
      title={chatCounsultationData?.doctor_first_name ?? ''}
      titleChildren={
        <Box display="-webkit-box">
          <Grid container>
            <Grid item xs={12}>
              <Typography color="#fff" fontWeight={600}>
                {chatCounsultationData?.doctor_first_name}
              </Typography>
            </Grid>
            {chatCounsultationData?.consultation_status_name === 'OnGoing' && (
              <Grid item xs={12}>
                <Box display="flex">
                  <Typography fontSize={12} mr={1} color="#fff">
                    {timer}
                  </Typography>
                  <Typography fontSize={12} color="#fff">
                    {onlineTyping ?? ''}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      }
      masterchildStyle={{ pb: '70px' }}
      backRequired
      backRoute={clinicalRoutes.chatListPage}
      stateValue={{
        from:
          location?.state?.history === 'isHistory' ? 'fromChatHistory' : 'chat',
      }}
      notshowFooter
      handleEndTask={handleEndTask}
    >
      <Box>
        {chatCounsultationData?.consultation_status_name !==
          'Waiting For Doctor' && (
          <Box>
            <Box
              sx={clinical_chat_page_style?.medicalCardSx}
              onClick={handleModelOpen}
            >
              <Grid container display="flex" justifyContent="space-between">
                <Grid item>
                  <Box display="flex">
                    <RegisterIcon rootStyle={{ color: '#007965' }} />
                    <Typography
                      ml={0.8}
                      fontSize={14}
                      color="#007965"
                      fontWeight={500}
                    >
                      Medical card
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <DropDown rootStyle={{ color: '#007965' }} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ px: 2.5 }}>
              <ChartListingInputBox
                isScroll={isScroll}
                chatExtendBodyContent={
                  isShowChatExtend
                    ? 'Would you like to raise a Request'
                    : isChatExtend
                    ? 'Pay to continue'
                    : ''
                }
                buttonText={
                  isShowChatExtend ? 'Request' : isChatExtend ? 'Pay' : ''
                }
                isPay={isShowChatExtend || isChatExtend}
                // isPay
                onRequestOrPayClick={onRequestOrPayClick}
                chatStatus={chatCounsultationData?.consultation_status_name}
                message={typingMessage}
                handleScroll={handleScroll}
                onMessageType={onMessageTypeFnc}
                onSendMessage={onSendMessageFnc}
                onAttachFile={onAttachFileFnc}
              >
                <ChatListingBox data={patientChatData} />
              </ChartListingInputBox>
            </Box>
          </Box>
        )}

        {timer?.length === 0 &&
          chatCounsultationData?.consultation_status_name !== 'Completed' && (
            <EmptyStateComponent
              illustrationChild={<ChatIllustration />}
              mainContent="Your payment has done!"
              subContent="Doctor will start your chat soon. Please wait"
            />
          )}
      </Box>

      <ConfigurationModal
        isModalOpen={isModalOpen}
        handleClose={handleEndModal}
        handleDelete={handleCloseChat}
        title="End Chat?"
        message="Are you sure you want to end the chat session with "
        cancleLable="Cancel"
        acceptLable="End Now"
        name={`Dr. ${doctorName}`}
      />
      <ConfigurationModal
        isModalOpen={isTimeOutModalOpen}
        handleClose={handleTimeOutModal}
        handleDelete={handleTimeoutCloseChat}
        title="Chat Time Ended"
        message="Thanks for the chat consultation"
        cancleLable="Cancel"
        acceptLable="Ok"
        name={`Dr. ${doctorName}`}
      />
      <ChatFeedbackModal
        isModalOpen={isFeedModalOpen}
        handleClose={handleFedModalClose}
        handleDelete={handleCloseFeeds}
        cancleLable="Cancel"
        acceptLable="Submit"
      />
      <Drawer
        show={isAttachFileOpen}
        onCloseDrawer={() => onCloseAttachFileDrawer()}
        height="30vh"
        header="Select Upload Type"
        headerStyle={{ fontWeight: 600 }}
        footer={
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button
                // loading={doctorsLoading}
                buttonStyle={clinical_chat_page_style.backButtonSx}
                onClick={onBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                // loading={doctorsLoading}
                buttonStyle={clinical_chat_page_style.applyButtonSx}
                onClick={onSendMessageFnc}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        }
      >
        {chatConsultationState?.messageJSON?.length === 0 && !isLoading ? (
          <Grid container columnSpacing={2}>
            {uploadUtills?.map((obj, i) => (
              <Grid item xs={6} key={i}>
                <label htmlFor={`uplodebtnv${obj.value}`}>
                  <Box
                    sx={
                      isSelected === ''
                        ? clinical_chat_page_style?.uploadTypeBoxSx
                        : isSelected === i
                        ? clinical_chat_page_style?.uploadTypeBoxSx
                        : clinical_chat_page_style?.uploadTypeBoxDisableSx
                    }
                    onClick={() => onSelectUploadType(i)}
                  >
                    <Grid container pt={2}>
                      <Grid
                        item
                        display="flex"
                        pt={2}
                        justifyContent="center"
                        xs={12}
                      >
                        <Box alignSelf="center">{obj?.icon}</Box>
                      </Grid>
                      <Grid item display="flex" justifyContent="center" xs={12}>
                        <Typography fontSize={14} fontWeight={500}>
                          {obj.name}
                        </Typography>
                      </Grid>
                      {i === 0 ? (
                        <input
                          hidden
                          name="img"
                          accept="image/*"
                          multiple="multiple"
                          type="file"
                          id={`uplodebtnv${obj.value}`}
                          onChange={(e) => handleFileUpload(e, obj.value)}
                        />
                      ) : (
                        <input
                          hidden
                          name={obj.value}
                          accept={obj.accept}
                          multiple="multiple"
                          type="file"
                          id={`uplodebtnv${obj.value}`}
                          onChange={(e) => handleFileUpload(e, obj.value)}
                        />
                      )}
                    </Grid>
                  </Box>
                </label>
              </Grid>
            ))}
          </Grid>
        ) : (
          isLoading && (
            <Box display="flex" justifyContent="center ">
              <Loader sx={{ alignSelf: 'center' }} />
            </Box>
          )
        )}

        <Grid container display="flex" justifyContent="center">
          {chatConsultationState?.messageJSON?.length > 0 &&
            chatConsultationState?.messageJSON?.map((obj, i) => (
              <Grid item xs={12} key={i} mt={1.5}>
                <PatientReportUploadCard
                  showReportName
                  icon={<UploadCloseIcon />}
                  isUploadCard
                  reportName={obj?.filename ?? ''}
                  uploadedFor={obj?.size ?? ''}
                  uploadedData={[
                    {
                      file: `${obj?.downloadUrl ?? ''}`,
                    },
                  ]}
                  IconOnClick={() => handleDelete(obj?.filename)}
                />
              </Grid>
            ))}
        </Grid>
      </Drawer>

      <Modal
        open={open}
        onClose={handleModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={clinical_chat_page_style?.modalSx}>
          {chatCounsultationData?.medical_card_count === 0 ? (
            <MedicalCard
              // isShow={chatCounsultationData?.medical_card_count > 0}
              medicalCondition={medicalCondition}
              onDelete={onDelete}
              onSendVitals={onSendVitals}
              onVitalChange={onVitalChange}
              onMedicalConditionChange={onMedicalConditionChange}
              patientName={chatCounsultationData?.patient_first_name ?? ''}
              subText={`${chatCounsultationData?.patient_gender ?? ''},${
                chatCounsultationData?.patient_age ?? ''
              }Yrs`}
              age={chatCounsultationData?.patient_age ?? ''}
              mobile={`${chatCounsultationData?.patient_mobile_no_country_code} ${chatCounsultationData?.patient_mobile_no}`}
              userPic={chatCounsultationData?.patient_profile_pic ?? ''}
              height={patientMedicalData?.vitals?.height}
              weight={patientMedicalData?.vitals?.weight}
              temp={patientMedicalData?.vitals?.temp_in_f}
              Pulse={patientMedicalData?.vitals?.puls_bpm}
              bp={patientMedicalData?.vitals?.blood_pressure}
              o2={patientMedicalData?.vitals?.o2_level}
              isVitalCheck={sendVital}
              isMedicalConditionCheck={sendMedicalCondition}
              // isMedicalCardSent={chatCounsultationData?.medical_card_count > 0}
              data={chatCounsultationData}
            />
          ) : (
            <MedicalCard
              isShow
              patientName={chatCounsultationData?.patient_first_name ?? ''}
              subText={`${chatCounsultationData?.patient_gender ?? ''},${
                chatCounsultationData?.patient_age ?? ''
              }Yrs`}
              age={chatCounsultationData?.patient_age ?? ''}
              mobile={`${chatCounsultationData?.patient_mobile_no_country_code} ${chatCounsultationData?.patient_mobile_no}`}
              userPic={chatCounsultationData?.patient_profile_pic ?? ''}
              height={
                chatCounsultationData?.medical_card_data?.vital_json?.height
              }
              weight={
                chatCounsultationData?.medical_card_data?.vital_json?.weight
              }
              temp={
                chatCounsultationData?.medical_card_data?.vital_json?.temp_in_f
              }
              Pulse={
                chatCounsultationData?.medical_card_data?.vital_json?.puls_bpm
              }
              bp={
                chatCounsultationData?.medical_card_data?.vital_json
                  ?.blood_pressure
              }
              o2={
                chatCounsultationData?.medical_card_data?.vital_json?.o2_level
              }
              allergies={
                chatCounsultationData?.medical_card_data?.medical_condition_json
                  ?.medical_allergies
              }
              medications={
                chatCounsultationData?.medical_card_data?.medical_condition_json
                  ?.medical_active_medications
              }
              health_problems={
                chatCounsultationData?.medical_card_data?.medical_condition_json
                  ?.medical_health_problems
              }
              isMedicalCardSent={chatCounsultationData?.medical_card_count > 0}
              data={chatCounsultationData}
              isVitalShow={
                chatCounsultationData?.medical_card_data?.is_vital_sent
              }
              isMedicalConditionShow={
                chatCounsultationData?.medical_card_data
                  ?.is_medical_condition_sent
              }
            />
          )}
        </Box>
      </Modal>
    </Screenlayout>
  );
}
