import { Button } from '@atoms/button';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { homeBanner_style } from './style';

export function HomeBanner(props) {
  const {
    image = false,
    component = [],
    desc = '',
    buttonDescription = '',
    rootStyleSx = {},
    buttonStyleSx = {},
    onClickFnc = () => {},
    descStyleSx = {},
    currentDay = '',
    Totaldays = '',
    className = '',
    type = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...homeBanner_style.rootSx, ...rootStyleSx }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={homeBanner_style.contSx}>
        <Box sx={{ flexBasis: '40%' }}>
          {image ? (
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
              <Box sx={homeBanner_style.imgSx}>{image}</Box>
            </Box>
          ) : (
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
              <Box sx={homeBanner_style.componentsx}>{component}</Box>
              {currentDay ? (
                <Typography sx={homeBanner_style.dayStatusSx}>
                  {currentDay}
                  <span style={homeBanner_style.dayStatusSxx}>
                    {' '}
                    of {Totaldays} days
                  </span>
                </Typography>
              ) : (
                <Typography sx={homeBanner_style.dayStatusSx}>
                  0 of 0 days
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flexBasis: '60%',
            display: 'grid',
            gap: 1.5,
          }}
        >
          <Typography sx={{ ...homeBanner_style.descSx, ...descStyleSx }}>
            {desc}
          </Typography>
          <Button
            buttonStyle={{ ...homeBanner_style.buttonSx, ...buttonStyleSx }}
            onClick={() => onClickFnc(type)}
          >
            {buttonDescription}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

HomeBanner.propTypes = {
  image: PropTypes.object,
  desc: PropTypes.string,
  buttonDescription: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
