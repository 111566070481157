/* eslint-disable react/prop-types */
import { UploadCloseIcon, UploadToggleButton } from '@atoms';
import { PatientReportUploadCard } from '@components/clinical';
import { getDateFormat } from '@hc/dayjs';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { PatientReportCard } from '../clinical';
import { uploadFiles_style } from './style';

export function UploadFiles(props) {
  const {
    className = '',
    reportTitle = '',
    uploadsText = '',
    browserText = '',
    icon = {},
    IsReportUpload,
    isLoading,
    isBokAppoinmentUpload,
    uploadFileStyle = [],
    uploadIconStyle = [],
    handleFileUpload,
    handleDelete,
    options = [],
    type = 'image',
    isEdit,
    IsUploadOnesDone,
    reportState,
    onEdit = () => {},
    reportDelete = () => {},
    uploadFilesData = [],
    selectTypeOfFileFns = () => {},
    // uploaded = false,
    ...rest
  } = props;
  console.log(uploadFilesData);
  return (
    <Box sx={uploadFiles_style.rootSx} className={`${className}`} {...rest}>
      <Box>
        {/* Select PDF or IMG */}
        {IsReportUpload && (
          <Box>
            <Box mb={2}>
              <UploadToggleButton
                header="Select type of file"
                options={options}
                value={type}
                selected={IsUploadOnesDone}
                selectTypeOfFileFns={selectTypeOfFileFns}
                activeStyle={{ border: '1px solid' }}
                inActiveStyle={{ border: '1px solid' }}
                radioStyle={{ border: '1px solid' }}
              />
            </Box>
          </Box>
        )}
      </Box>

      {reportState?.uploadedReportId &&
        reportState?.uploadedReportId.length > 0 && (
          <Box>
            {!IsReportUpload && (
              <Grid
                container
                pb={1.5}
                display="flex"
                justifyContent="space-between"
              >
                <Grid item xs={8} sm={8} md={8}>
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid item display="flex">
                      <Typography sx={uploadFiles_style.uploadTextSx}>
                        {uploadsText}
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#000',
                          height: '24px',
                          width: '24px',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignSelf: 'end',
                          ml: 0.5,
                        }}
                      >
                        <Typography
                          alignSelf="center"
                          color="#fff"
                          fontSize={13}
                        >
                          {reportState?.uploadedReportId.length <= 9
                            ? `0${reportState?.uploadedReportId.length}`
                            : reportState?.uploadedReportId.length}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!IsReportUpload && (
              <Box>
                {uploadFilesData.length > 0 &&
                  uploadFilesData.map((val, i) =>
                    reportState?.uploadedReportId?.some(
                      (id) => id.id === val.id,
                    ) ? (
                      <Box pb={1} key={i}>
                        <PatientReportCard
                          reportId={val?.id ?? ''}
                          showUploadedBy
                          showReportType
                          showReportName
                          reportName={val.report_name}
                          reportType={
                            val?.master_report_type?.report_type ?? ''
                          }
                          uploadedBy="by me"
                          uploadedFor={
                            val?.master_relationships?.releationship
                              ? val?.master_relationships
                                  ?.user_family_members?.[0]?.name
                              : val.user_profile?.name ||
                                val.user_profile?.first_name
                          }
                          relationShip={
                            val?.master_relationships?.releationship
                              ? val?.master_relationships?.releationship
                              : 'Self'
                          }
                          uploadedData={
                            Array.isArray(val?.upload?.upload_data)
                              ? val?.upload?.upload_data
                              : typeof val?.upload?.upload_data === 'object' &&
                                !Array.isArray(val?.upload?.upload_data) &&
                                val?.upload?.upload_data !== null
                              ? [val?.upload?.upload_data]
                              : []
                          }
                          uploadeddate={
                            val?.report_date
                              ? getDateFormat(val?.report_date, 'DD-MM-YYYY')
                              : ''
                          }
                          objectData={val}
                          handleEdit={onEdit}
                          reportDelete={reportDelete}
                        />
                      </Box>
                    ) : (
                      ''
                    ),
                  )}
              </Box>
            )}
          </Box>
        )}
      {!isLoading && (
        <Box>
          {!IsReportUpload && (
            <Box>
              <Box>
                <Typography sx={uploadFiles_style.uploadTextSx}>
                  {reportTitle}
                </Typography>
              </Box>
              <Box mb={2}>
                <UploadToggleButton
                  header="Select type of file"
                  options={options}
                  value={type}
                  selectTypeOfFileFns={selectTypeOfFileFns}
                />
              </Box>
            </Box>
          )}

          {/* upload Field  */}
          {!IsUploadOnesDone && (
            <Box sx={uploadFiles_style.uploadFileRootSx}>
              <label htmlFor={`uplodebtnv${type}`}>
                <Box
                  sx={{
                    ...(type
                      ? uploadFiles_style.activeUploadFileSx
                      : uploadFiles_style.uploadFileSx),
                    ...uploadFileStyle,
                  }}
                >
                  <Box
                    component="label"
                    sx={{
                      ...uploadFiles_style.uploadIconSx,
                      ...uploadIconStyle,
                    }}
                  >
                    {icon}
                    {type === 'image' ? (
                      <input
                        hidden
                        name="img"
                        accept="image/*"
                        multiple="multiple"
                        type="file"
                        id={`uplodebtnv${type}`}
                        onChange={handleFileUpload}
                      />
                    ) : type === 'pdf' ? (
                      <input
                        hidden
                        name="pdf"
                        accept="application/pdf"
                        type="file"
                        id={`uplodebtnv${type}`}
                        onChange={handleFileUpload}
                      />
                    ) : null}
                  </Box>
                  <Box>
                    <Typography sx={uploadFiles_style.browseTextSx}>
                      {browserText}
                    </Typography>
                  </Box>
                </Box>
              </label>
            </Box>
          )}
        </Box>
      )}
      {IsReportUpload && (
        <Box mb={1}>
          {Array.isArray(uploadFilesData) &&
            uploadFilesData.length > 0 &&
            uploadFilesData.map((val, i) => (
              <PatientReportUploadCard
                key={i}
                showReportName
                showUploadedBy={isBokAppoinmentUpload}
                icon={<UploadCloseIcon />}
                // icon={arr.length > 1 ? <UploadCloseIcon /> : ''}
                isUploadCard
                reportName={val?.filename ?? ''}
                uploadedFor={val?.size ?? ''}
                uploadedData={[{ file: `${val?.downloadUrl ?? ''}` }]}
                IconOnClick={() => handleDelete(i, false)}
                isLoading={isLoading}
              />
            ))}
        </Box>
      )}
      {IsReportUpload && type === 'image' && uploadFilesData.length > 0 && (
        <Box component="label" sx={{ cursor: 'pointer' }}>
          <Typography pt={2} sx={uploadFiles_style.addMoreButtonSx}>
            + Add More
          </Typography>
          <input
            hidden
            name="img"
            accept="image/*"
            multiple
            type="file"
            onChange={handleFileUpload}
          />
        </Box>
      )}
    </Box>
  );
}

UploadFiles.propTypes = {
  reportTitle: PropTypes.string,
  uploadsText: PropTypes.string,
  browserText: PropTypes.string,
  consultationDate: PropTypes.string,
  progressBar: PropTypes.bool,
  icon: PropTypes.object,
  dataSize: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.node,
  uploadsTextReqired: PropTypes.bool,
  uploaded: PropTypes.bool,
  sx: PropTypes.object,
  handleFileUpload: PropTypes.func,
  IsReportUpload: PropTypes.bool,
  handleDelete: PropTypes.func,
};
