import OtpBg from '@assets/otpBg.svg';

export const OtpSplash_style = {
  tickGifSx: {
    width: '150px',
    height: '150px',
  },
  rootSx: {
    minHeight: '100vh',
    background: 'linear-gradient(172deg, #007965E3 25%, #279DAFE6 100%)',
  },
  descTypoSx: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
    color: '#fff',
  },
  containerSx: {
    height: '90vh',
    backgroundImage: `url(${OtpBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};
