import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { upload_toggle_Button_style } from './style';

export function UploadToggleButton(props) {
  const {
    className = '',
    selected = false,
    activeStyle = {},
    inActiveStyle = {},
    radioStyle = {},
    value,
    options = [],
    isError = false,
    errorMessage = '',
    header = '',
    selectTypeOfFileFns = () => {},
    ...rest
  } = props;

  return (
    <Box>
      <Typography sx={upload_toggle_Button_style.headerSx}>{header}</Typography>
      <Box
        display="flex"
        gap={1}
        sx={{ my: 0.5 }}
        className={`${className}`}
        {...rest}
      >
        <Grid container columnSpacing={1}>
          {options?.map((val, i) => (
            <Grid key={i} item xs={6} display="flex">
              <Box
                id={`toggleBtn_${i}`}
                sx={{
                  ...(val.value === value
                    ? { ...upload_toggle_Button_style.activeSx, ...activeStyle }
                    : selected
                    ? {
                        ...upload_toggle_Button_style.inActiveSx,
                        ...inActiveStyle,
                      }
                    : { ...upload_toggle_Button_style.radioSx, ...radioStyle }),
                }}
                width="100%"
                onClick={() => {
                  selected ? null : selectTypeOfFileFns(val.value);
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      ...(val.value === value
                        ? upload_toggle_Button_style.iconActiveSx
                        : upload_toggle_Button_style.iconInactiveSx),
                    }}
                    px={1}
                  >
                    {val.icon}
                  </Box>

                  {val?.name && (
                    <Typography fontSize={12}>{val.name}</Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

UploadToggleButton.propTypes = {
  options: PropTypes.array,
  selectTypeOfFileFns: PropTypes.func,
  value: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
  header: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
  activeStyle: PropTypes.object,
  inActiveStyle: PropTypes.object,
  radioStyle: PropTypes.object,
};
