export const uploadFiles_style = {
  uploadTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 600,
    mt: 2,
  },

  uploadFileSx: {
    border: '2px dashed',
    borderColor: 'divider',
    opacity: '48%',
    height: '232px',

    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: 'primary.main',
    // opacity: '48%',
    height: '232px',
    // marginTop: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  browseTextSx: {
    fontSize: '14px',
    color: ' PrimaryTints.A100',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },

  avatarSx: {
    width: '23px',
    height: '23px',
    backgroundColor: 'text.primary',
    fontSize: '12px',
    color: 'common.white',
    marginLeft: '6px',
  },

  iconWithTextSx: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
    minHeight: '65px',
    marginTop: '12px',
  },
  consultationRootSx: {
    marginLeft: '16Px',
  },

  consultationTextSx: {
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 'medium',
    // width: '240px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },

  circularbarSx: {
    marginLeft: '32px',
    position: 'relative',
  },

  iconsBgSx: {
    marginLeft: '16px',
    background: 'grey.A300',
    width: '28px',
    height: '28px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataSizeSx: {
    fontSize: '12px',
    color: 'text.primary',
    fontWeight: 'medium',
  },
  circularSx: {
    color: 'grey.400',
    position: 'absolute',
    top: '0px',
    borderRadius: '12px',
    marginLeft: '32px',
  },

  rootStopIconSx: {
    top: 0,
    left: 0,
    bottom: 7,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  smallSx: {
    backgroundColor: 'text.primary',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    ml: 1,
    mt: '16px',
  },
  addMoreButtonSx: {
    fontSize: 14,
    color: 'primary.main',
    fontWeight: 'medium',
  },
};
