/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { getDateFormat, getDateFormatWithoutDate } from '@hc/dayjs';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import {
  BMICalculation,
  isBloodPressure,
  isDecimalNumber,
  isNumber,
  isString,
  parseJwt
} from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useBookAppointment = create((set, get) => ({
  bookAppointmentStateHealthQuries: {
    additionalSymptoms: [],
    additionalHealth_problems: [],
    additionalAllergies: [],
    additionalDrugs: [],
  },

  bookAppointmentState: {
    // DOCTOR FILTER
    doctorSearch: '',
    languages: [],
    speciality: '',
    response_appointment_id: '',
    payment_status: '',
    booking_method: '',
    family_member_id: '',
    booking_for: {
      name: '',
      gender: '',
      dob: '',
      age: '',
      relationship: '',
      mobileCode: '',
      mobile: '',
      email: '',
      error: {
        name: '',
        relationship: '',
        gender: '',
        age: '',
        mobile: '',
        email: '',
      },
    },
    reason_for_consultation: 'Health Issues',
    health_issues: [],
    symptoms: [],
    health_problems: [],
    allergies: [],
    drugs: [],

    appointed_doctor_details: {
      id: '',
      appointment_date: '',
      appointment_time_slot: '',
      sendInvite: true,
      doctor_name: '',
      appointment_client_start_time: '',
      appointment_client_end_time: '',
    },
    reports: [],
    deselectedReports: {
      uploadedId: [],
      caseSummaryId: [],
    },
    new_reports: [],
    vitalsInitialData: {
      height_in_cm: '',
      weight_in_cm: '',
      bmi_in_kg_m2: '',
      temp_in_f: '',
      puls_bpm: '',
      blood_pressure: '',
      bp_sys: '',
      bp_dia: '',
      o2_level: '',
      blood_group: '',
      error: {
        height_in_cm: '',
        weight_in_cm: '',
        temp_in_f: '',
        puls_bpm: '',
        blood_pressure: '',
        o2_level: '',
      },
    },
    vitals: {
      height_in_cm: '',
      weight_in_cm: '',
      bmi_in_kg_m2: '',
      temp_in_f: '',
      puls_bpm: '',
      blood_pressure: '',
      bp_sys: '',
      bp_dia: '',
      o2_level: '',
      blood_group: '',
      error: {
        height_in_cm: '',
        weight_in_cm: '',
        temp_in_f: '',
        puls_bpm: '',
        blood_pressure: '',
        o2_level: '',
      },
    },

    bookedBy: '',
    patientDetails: {
      id: '',
      name: '',
      gender: '',
      age: '',
      mobile_no: '',
      country_code: '',
      email_id: '',
      vitals: {
        height: '',
        weight: '',
        bmi: '',
        blood_pressure: '',
        temp_in_f: '',
        puls_bpm: '',
        o2_level: '',
      },
    },
    doctorAvailableDetails: null,
    doctorAvailableDetailsCopy: null,
    doctorDetail: {},
    selectedDate: getDateFormatWithoutDate('YYYY-MM-DD'),
    selectedSlot: '',
    selectedSlotStartTime: '',
    selectedSlotEndTime: '',
    morningSlotData: [],
    afternoonSlotData: [],
    eveningSlotData: [],
    nightSlotData: [],
    appointmentStatusDetails: {},
    calenderData: {
      startofWeek: '',
      endofWeek: '',
      betweenDates: [],
    },
    previousAppointment: '',
  },
  loading: false,
  slotLoading: false,
  error: null,

  updateBookAppointmentStore: (stateData) => {
    set({
      bookAppointmentState: {
        ...stateData,
      },
    });
  },

  getDoctorData: async (payload, id) => {
    try {
      const { bookAppointmentState } = get();
      set({ slotLoading: true });

      const payloadData = {
        dates: payload?.dates ?? [],
        startDateTime: payload?.startDateTime ?? '',
        endDateTime: payload?.endDateTime ?? '',
        clientTimeZone: payload?.clientTimeZone ?? '',
        id: payload?.id ? payload?.id : id,
      };
      // Hitting the get doctor lisiting API
      const { data } = await queryClient.fetchQuery([
        '/appointments/doctors/availability',
        'post',
        { ...payloadData },
      ]);

      set(() => ({
        slotLoading: false,
        bookAppointmentState: {
          ...bookAppointmentState,
          doctorDetail: data?.[0],
          doctorAvailableDetailsCopy: data,
        },
      }));
      return data?.[0];
    } catch (error) {
      set({ slotLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getDoctorsList: async (payload, isRootLayout, doctrorId) => {
    try {
      const { bookAppointmentState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);

      set({ loading: true });
      const payloadData = {
        user_id: tokenData?.user_id ?? '',
        search: payload?.search ?? '',
        speciality_filter:
          payload?.speciality !== '' ? parseInt(payload?.speciality, 10) : 0,
        language_filter: payload?.languages ?? [],
      };
      // Hitting the get doctor lisiting API
      const { data } = await queryClient.fetchQuery([
        '/appointments/verified-doctors/get/all',
        'post',
        { ...payloadData },
      ]);
      let doctorDetailArr = [];
      if (isRootLayout) {
        if (doctrorId) {
          for (const val of data) {
            if (val?.id === doctrorId) {
              doctorDetailArr.push(val);
            }
          }
        }
        set(() => ({
          loading: false,
          bookAppointmentState: {
            ...bookAppointmentState,
            doctorAvailableDetails: data,
            doctorDetail: doctrorId ? doctorDetailArr?.[0] : data?.[0],
          },
        }));
        return doctorDetailArr?.[0];
      }
      set(() => ({
        loading: false,
        bookAppointmentState: {
          ...bookAppointmentState,
          doctorAvailableDetails: data,
        },
      }));
      return doctorDetailArr?.[0];
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  bookAppointmentFnc: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/book`,
        {
          ...payload,
        },
        true
      );

      if (response?.data?.statusCode === '200') {
        // toast.success(
        //   response?.data?.message ?? `Appointment Booked Successfully.`
        // );
        localStorage.setItem(
          localStorageKeys.responseAppointmentId,
          response.data.data.appointmentId
        );
        set({ loading: false });
        return response?.data;
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('err', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  referralUpdate: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/referral/update`,
        {
          ...payload,
        },
        true
      );

      if (response?.data?.statusCode === 200) {
        // toast.success(
        //   response?.data?.message ?? `Appointment Booked Successfully.`
        // );
        localStorage.setItem(
          localStorageKeys.responseAppointmentId,
          response.data.data.appointmentId
        );
        set({ loading: false });
        return response?.data;
      }
      return response?.data;
    } catch (err) {
      set({ loading: false });
      log('err', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  updatePaymentStatusDetails: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/payment`,
        {
          ...payload,
        },
        true
      );
      if (response?.data?.statusCode === 200) {
        toast.success(
          response?.data?.message ?? `Appointment Booked Successfully.`
        );
        set({ loading: false });
        return response?.data;
      }
      return set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        err?.response?.data?.message ??
          err?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  rescheduleAppointmentFnc: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        appointment_date: payload?.appointment_date ?? '',
        appointment_time_slot: payload?.appointment_time_slot ?? '',
        user_id: payload?.bookedBy ?? '',
        sendInvite: true,
        appointment_client_start_time:
          payload?.appointment_client_start_time ?? '',
        appointment_client_end_time: payload?.appointment_client_end_time ?? '',
        time_lapsed_appointment: payload?.appointment_id ?? '',
        clientTimeZone: payload?.clientTimeZone ?? '',
        user_device_token: '',
      };
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/bookingReScheduleAppointment`,
        {
          ...payloadData,
        },
        true
      );
      if (response?.data?.statusCode === 200) {
        toast.success(
          response?.data?.message ?? `Appointment Rescheduled Successfully.`
        );
        return response?.data;
      }
      return set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        err?.response?.data?.message ??
          err?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  followUpAppointmentFnc: async (payload) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/appointments/slot-select`,
        {
          ...payload,
        },
        true
      );
      set({ loading: false });
      return response?.data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.message ??
          error?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getAppointmentStatus: async (
    appointmentId,
    bookingMethod,
    familyMemberId
  ) => {
    try {
      set({ loading: true });
      const data = {
        id: appointmentId,
        booking_method: bookingMethod,
        family_member_id: familyMemberId,
      };

      const response = await queryClient.fetchQuery([
        '/appointments/confirmation',
        'post',
        { ...data },
      ]);
      if (response?.status?.code === 200) {
        set({ loading: false });
        return set((state) => ({
          loading: false,
          bookAppointmentState: {
            ...state.bookAppointmentState,
            appointmentStatusDetails: response?.data,
          },
        }));
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        err?.response?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  // DELETE DATA
  deleteData: (type, i) => {
    const { bookAppointmentState } = get();
    const copyData = JSON.parse(JSON.stringify(bookAppointmentState?.[type]));
    copyData.splice(i, 1);
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        [type]: copyData,
      },
    });
  },
  updateUploadCasesummaryReport: (uploadedId, caseSummaryId) => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        deselectedReports: {
          uploadedId,
          caseSummaryId,
        },
      },
    });
  },

  // DELETE DATA
  clearBookAppointmentReport: () => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        reports: [],
        deselectedReports: {
          uploadedId: [],
          caseSummaryId: [],
        },
      },
    });
  },

  // ADD ALLERGIES
  addAllergies: (e, option) => {
    const { bookAppointmentStateHealthQuries } = get();

    if (option?.value === option?.labelShow) {
      const array = bookAppointmentStateHealthQuries?.additionalAllergies;
      array.push(option?.value);
      set({
        bookAppointmentStateHealthQuries: {
          ...bookAppointmentStateHealthQuries,
          additionalAllergies: array,
        },
      });
    }

    const { bookAppointmentState } = get();
    const { allergies } = bookAppointmentState;
    let allergiesCopy = JSON.parse(JSON.stringify(allergies));
    if (allergiesCopy.length > 0) {
      const allergiesFilter = allergiesCopy.filter(
        (v) => v?.value === option?.value
      );
      if (!allergiesFilter?.length > 0) {
        allergiesCopy.push(option);
      } else {
        const allergiesFilterData = allergiesCopy.filter(
          (v) => v?.value !== option?.value
        );
        allergiesCopy = allergiesFilterData;
      }
    } else {
      allergiesCopy.push(option);
    }
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        allergies: allergiesCopy,
      },
    });
  },
  // CLEAR PAGE DATA
  clearPageData: (key) => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: { ...bookAppointmentState, [key]: [] },
    });
  },
  // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
  setStartEndBetween: (start, end, betweenDates) => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        calenderData: {
          startofWeek: '',
          endofWeek: '',
          betweenDates: '',
        },
      },
    });

    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        calenderData: {
          startofWeek: start,
          endofWeek: end,
          betweenDates: betweenDates?.map((i) => i.toISOString()),
        },
      },
    });
  },
  constructSlotDataState: (seDate, value) => {
    const { bookAppointmentState } = get();
    const { doctorDetail } = bookAppointmentState;
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    bookAppointmentStateCopy.morningSlotData = [];
    bookAppointmentStateCopy.afternoonSlotData = [];
    bookAppointmentStateCopy.eveningSlotData = [];
    bookAppointmentStateCopy.nightSlotData = [];
    if (value && value?.id?.length > 0) {
      if (
        Array.isArray(value?.slots?.[seDate]) &&
        value?.slots?.[seDate]?.length > 0
      ) {
        value?.slots?.[seDate].map((val, i) => {
          if (val?.slot) {
            const hours = new Date(val?.client_date_time).getHours();
            if (hours <= 11) {
              bookAppointmentStateCopy.morningSlotData.push(val);
            } else if (hours > 11 && hours <= 15) {
              bookAppointmentStateCopy.afternoonSlotData.push(val);
            } else if (hours > 15 && hours <= 18) {
              bookAppointmentStateCopy.eveningSlotData.push(val);
            } else {
              bookAppointmentStateCopy.nightSlotData.push(val);
            }
          }
        });
      }
    } else if (
      Array.isArray(doctorDetail?.slots?.[seDate]) &&
      doctorDetail?.slots?.[seDate]?.length > 0
    ) {
      doctorDetail?.slots?.[seDate].map((val, i) => {
        if (val?.slot) {
          const hours = new Date(val?.client_date_time).getHours();
          if (hours <= 11) {
            bookAppointmentStateCopy.morningSlotData.push(val);
          } else if (hours > 11 && hours <= 15) {
            bookAppointmentStateCopy.afternoonSlotData.push(val);
          } else if (hours > 15 && hours <= 18) {
            bookAppointmentStateCopy.eveningSlotData.push(val);
          } else {
            bookAppointmentStateCopy.nightSlotData.push(val);
          }
        }
      });
    }
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        ...JSON.parse(JSON.stringify(bookAppointmentStateCopy)),
      },
    });
  },
  updateBAState: (key, value) => {
    const { bookAppointmentState } = get();
    // const { languages } = bookAppointmentState;
    // if(key === "languages"){
    //   let languagesCopy = JSON.parse(JSON.stringify(languages));
    //   languagesCopy.push(value)
    //   set({
    //     bookAppointmentState: {
    //       ...bookAppointmentState,
    //       languages: languagesCopy,
    //     },
    //   });
    // } else {
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        [key]: value,
      },
    });
    // }
  },
  // CLEAR DOCOTR DETAILS
  claerDoctorDetail: () => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        doctorDetail: {},
        selectedDate: '',
        selectedSlot: '',
        selectedSlotStartTime: '',
        selectedSlotEndTime: '',
        morningSlotData: [],
        afternoonSlotData: [],
        eveningSlotData: [],
        nightSlotData: [],
        appointmentStatusDetails: {},
        previousAppointment: '',
      },
    });
  },
  // UPDATE DOCOTR DETAILS
  updateDoctorDetail: () => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        appointed_doctor_details: {
          id: bookAppointmentState?.doctorDetail?.id ?? '',
          appointment_date: getDateFormat(
            bookAppointmentState?.selectedSlotStartTime,
            'YYYY-MM-DD'
          ),
          appointment_time_slot: getDateFormat(
            bookAppointmentState?.selectedSlotStartTime,
            'HH:mm:ss'
          ),
          sendInvite: true,
          doctor_name: bookAppointmentState?.doctorDetail?.name ?? '',
          appointment_client_start_time:
            bookAppointmentState?.selectedSlotStartTime,
          appointment_client_end_time:
            bookAppointmentState?.selectedSlotEndTime ?? '',
        },
      },
    });
  },

  // SELECT SLOT UPDATE
  selectSlotUpdateNew: async (slotStartTime, slotEndTime, slot) => {
    const { bookAppointmentState } = get();
    bookAppointmentState.selectedSlotStartTime = slotStartTime;
    bookAppointmentState.selectedSlotEndTime = slotEndTime;
    bookAppointmentState.selectedSlot = slot;
    await set({
      bookAppointmentState: {
        ...bookAppointmentState,
      },
    });
  },
  // SELECT SLOT UPDATE
  selectSlotUpdate: async (slotStartTime, slotEndTime, slot) => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        selectedSlotStartTime: slotStartTime,
        selectedSlotEndTime: slotEndTime,
        selectedSlot: slot,
      },
    });
  },
  // CLEAR BOOK APPOINTMENT STATE
  clearBookAppointmentState: () => {
    set({
      bookAppointmentState: {
        doctorSearch: '',
        languages: [],
        speciality: '',
        response_appointment_id: '',
        payment_status: '',
        booking_method: '',
        family_member_id: '',
        booking_for: {
          name: '',
          gender: '',
          dob: '',
          age: '',
          relationship: '',
          mobileCode: '',
          mobile: '',
          email: '',
          error: {
            name: '',
            relationship: '',
            gender: '',
            age: '',
            mobile: '',
            email: '',
          },
        },
        reason_for_consultation: 'Health Issues',
        health_issues: [],
        symptoms: [],
        health_problems: [],
        allergies: [],
        drugs: [],
        appointed_doctor_details: {
          id: '',
          appointment_date: '',
          appointment_time_slot: '',
          sendInvite: true,
          doctor_name: '',
          appointment_client_start_time: '',
          appointment_client_end_time: '',
        },
        reports: [],
        deselectedReports: {
          uploadedId: [],
          caseSummaryId: [],
        },
        new_reports: [],
        vitalsInitialData: {
          height_in_cm: '',
          weight_in_cm: '',
          bmi_in_kg_m2: '',
          temp_in_f: '',
          puls_bpm: '',
          blood_pressure: '',
          bp_sys: '',
          bp_dia: '',
          o2_level: '',
          blood_group: '',
          error: {
            height_in_cm: '',
            weight_in_cm: '',
            temp_in_f: '',
            puls_bpm: '',
            blood_pressure: '',
            o2_level: '',
          },
        },
        vitals: {
          height_in_cm: '',
          weight_in_cm: '',
          bmi_in_kg_m2: '',
          temp_in_f: '',
          puls_bpm: '',
          blood_pressure: '',
          bp_sys: '',
          bp_dia: '',
          o2_level: '',
          error: {
            height_in_cm: '',
            weight_in_cm: '',
            temp_in_f: '',
            puls_bpm: '',
            blood_pressure: '',
            o2_level: '',
          },
        },
        bookedBy: '',
        patientDetails: {
          id: '',
          name: '',
          gender: '',
          age: '',
          mobile_no: '',
          country_code: '',
          email_id: '',
          vitals: {
            height: '',
            weight: '',
            bmi: '',
            blood_pressure: '',
            temp_in_f: '',
            puls_bpm: '',
            o2_level: '',
          },
        },
        doctorAvailableDetails: null,
        doctorDetail: {},
        selectedDate: getDateFormatWithoutDate('YYYY-MM-DD'),
        selectedSlot: '',
        selectedSlotStartTime: '',
        selectedSlotEndTime: '',
        morningSlotData: [],
        afternoonSlotData: [],
        eveningSlotData: [],
        nightSlotData: [],
        appointmentStatusDetails: {},
        calenderData: {
          startofWeek: '',
          endofWeek: '',
          betweenDates: [],
        },
        previousAppointment: '',
      },
    });
  },
  // ADD HEALTH ISSUE
  addHealthIssue: (text) => {
    const { bookAppointmentState } = get();
    const { health_issues } = bookAppointmentState;
    const healthIssuesCopy = JSON.parse(JSON.stringify(health_issues));
    healthIssuesCopy.push(text);
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        health_issues: healthIssuesCopy,
      },
    });
  },
  // ADD HEALTH PROBLEM
  addHealthProblems: (e, option) => {
    const { bookAppointmentStateHealthQuries } = get();
    if (option?.value === option?.labelShow) {
      const array = bookAppointmentStateHealthQuries?.additionalHealth_problems;
      array.push(option?.value);
      set({
        bookAppointmentStateHealthQuries: {
          ...bookAppointmentStateHealthQuries,
          additionalHealth_problems: array,
        },
      });
    }

    const { bookAppointmentState } = get();
    const { health_problems } = bookAppointmentState;
    let healthProblemsCopy = JSON.parse(JSON.stringify(health_problems));
    if (healthProblemsCopy.length > 0) {
      const healthProblemsFilter = healthProblemsCopy.filter(
        (v) => v?.value === option?.value
      );
      if (!healthProblemsFilter?.length > 0) {
        healthProblemsCopy.push(option);
      } else {
        const healthProblemsFilterData = healthProblemsCopy.filter(
          (v) => v?.value !== option?.value
        );
        healthProblemsCopy = healthProblemsFilterData;
      }
    } else {
      healthProblemsCopy.push(option);
    }
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        health_problems: healthProblemsCopy,
      },
    });
  },

  // ADD MEDICATION
  addMedication: (e, option) => {
    const { bookAppointmentStateHealthQuries } = get();
    if (option?.value === option?.labelShow) {
      const array = bookAppointmentStateHealthQuries?.additionalDrugs;
      array.push(option?.value);
      set({
        bookAppointmentStateHealthQuries: {
          ...bookAppointmentStateHealthQuries,
          additionalDrugs: array,
        },
      });
    }

    const { bookAppointmentState } = get();
    const { drugs } = bookAppointmentState;
    let drugsCopy = JSON.parse(JSON.stringify(drugs));
    if (drugsCopy.length > 0) {
      const drugsFilter = drugsCopy.filter((v) => v?.value === option?.value);
      if (!drugsFilter?.length > 0) {
        drugsCopy.push(option);
      } else {
        const drugsFilterData = drugsCopy.filter(
          (v) => v?.value !== option?.value
        );
        drugsCopy = drugsFilterData;
      }
    } else {
      drugsCopy.push(option);
    }
    set({
      bookAppointmentState: { ...bookAppointmentState, drugs: drugsCopy },
    });
  },
  // UPDATE BOOKING FOR ERROR
  updateBookingForError: (error) => {
    const { bookAppointmentState } = get();
    const { booking_for } = bookAppointmentState;
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        booking_for: {
          ...booking_for,
          error,
        },
      },
    });
  },
  // CLEAR PATIENT DEATILS DATA
  clearPatientDetailsData: () => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        booking_for: {
          name: '',
          gender: '',
          dob: '',
          age: '',
          relationship: '',
          mobileCode: '',
          mobile: '',
          email: '',
          error: {
            name: '',
            relationship: '',
            gender: '',
            age: '',
            mobile: '',
            email: '',
          },
        },
        error: null,
      },
    });
  },
  // HANDLE PATIENT DATA CAHNGE
  handlePatientDataChange: (key, value) => {
    const { bookAppointmentState } = get();
    const { booking_for } = bookAppointmentState;
    // For preventing change to the original state
    const bookingForCopy = JSON.parse(JSON.stringify(booking_for));

    if (key === 'name') {
      if (isString(value)) {
        set({
          bookAppointmentState: {
            ...bookAppointmentState,
            booking_for: {
              ...bookingForCopy,
              name: value,
            },
          },
        });
      }
    } else if (key === 'mobileNumber') {
      set({
        bookAppointmentState: {
          ...bookAppointmentState,
          booking_for: {
            ...bookingForCopy,
            mobile: value?.mobile,
            mobileCode: value?.mobileCode,
          },
        },
      });
    } else if (key === 'age') {
      if (isNumber(value)) {
        set({
          bookAppointmentState: {
            ...bookAppointmentState,
            booking_for: {
              ...bookingForCopy,
              age: value,
            },
          },
        });
      }
    } else {
      set({
        bookAppointmentState: {
          ...bookAppointmentState,
          booking_for: {
            ...bookingForCopy,
            [key]: value,
          },
        },
      });
    }
  },
  // HANDLE FAMILY MEMBER DETAILS
  setFamilyMmeberDetails: (name) => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        vitals: {
          height_in_cm: name?.vitals?.height ?? '',
          weight_in_cm: name?.vitals?.weight ?? '',
          bmi_in_kg_m2: name?.vitals?.bmi ?? '',
          temp_in_f: name?.vitals?.temp_in_f ?? '',
          puls_bpm: name?.vitals?.puls_bpm ?? '',
          blood_pressure: name?.vitals?.blood_pressure ?? '',
          bp_sys: '',
          bp_dia: '',
          o2_level: name?.vitals?.o2_level ?? '',
          error: {
            height_in_cm: '',
            weight_in_cm: '',
            temp_in_f: '',
            puls_bpm: '',
            blood_pressure: '',
            o2_level: '',
          },
        },
        family_member_id: name?.family_member_id,
        patientDetails: {
          id: name?.family_member_id ?? '',
          name: name?.name ?? '',
          gender: name?.gender ?? '',
          age: name?.age ?? '',
          mobile_no: name?.mobile_no ?? '',
          country_code: name?.country_code ?? '',
          email_id: name?.email_id ?? '',
          vitals: {
            height: name?.vitals?.height ?? '',
            weight: name?.vitals?.weight ?? '',
            bmi: name?.vitals?.bmi ?? '',
            blood_pressure: name?.vitals?.blood_pressure ?? '',
            temp_in_f: name?.vitals?.temp_in_f ?? '',
            puls_bpm: name?.vitals?.puls_bpm ?? '',
            o2_level: name?.vitals?.o2_level ?? '',
          },
        },
      },
    });
  },
  // UPDATE PROFILE DATA
  updateProfileData: (value, data, profileData, type) => {
    const { bookAppointmentState } = get();
    if (type === 'myself') {
      set({
        bookAppointmentState: {
          ...bookAppointmentState,
          booking_method: value,
          bookedBy: data?.user_id,
          family_member_id: '',
          booking_for: {
            name: '',
            gender: '',
            dob: '',
            age: '',
            relationship: '',
            mobileCode: '',
            mobile: '',
            email: '',
            error: {
              name: '',
              relationship: '',
              gender: '',
              age: '',
              mobile: '',
              email: '',
            },
          },
          vitals: {
            height_in_cm: profileData?.vitals?.height ?? '',
            weight_in_cm: profileData?.vitals?.weight ?? '',
            bmi_in_kg_m2: profileData?.vitals?.bmi ?? '',
            temp_in_f: profileData?.vitals?.temp_in_f ?? '',
            puls_bpm: profileData?.vitals?.puls_bpm ?? '',
            blood_pressure: profileData?.vitals?.blood_pressure ?? '',
            blood_group: profileData?.vitals?.blood_group ?? '',
            bp_sys: '',
            bp_dia: '',
            o2_level: profileData?.vitals?.o2_level ?? '',
            error: {
              height_in_cm: '',
              weight_in_cm: '',
              temp_in_f: '',
              puls_bpm: '',
              blood_pressure: '',
              o2_level: '',
            },
          },
          patientDetails: {
            id: data?.user_id ?? '',
            name: profileData?.name ?? '',
            gender: profileData?.gender ?? '',
            age: profileData?.age ?? '',
            mobile_no: profileData?.mobile_no ?? '',
            country_code: profileData?.country_code ?? '',
            email_id: profileData?.email_id ?? '',
            vitals: {
              height: profileData?.vitals?.height ?? '',
              weight: profileData?.vitals?.weight ?? '',
              bmi: profileData?.vitals?.bmi ?? '',
              blood_pressure: profileData?.vitals?.blood_pressure ?? '',
              temp_in_f: profileData?.vitals?.temp_in_f ?? '',
              puls_bpm: profileData?.vitals?.puls_bpm ?? '',
              o2_level: profileData?.vitals?.o2_level ?? '',
            },
          },
        },
      });
    } else {
      set({
        bookAppointmentState: {
          ...bookAppointmentState,
          booking_method: value,
          bookedBy: data?.user_id,
          family_member_id: '',
          booking_for: {
            name: '',
            gender: '',
            dob: '',
            age: '',
            relationship: '',
            mobileCode: '',
            mobile: '',
            email: '',
            error: {
              name: '',
              relationship: '',
              gender: '',
              age: '',
              mobile: '',
              email: '',
            },
          },
          patientDetails: {
            id: '',
            name: '',
            gender: '',
            age: '',
            mobile_no: '',
            country_code: '',
            email_id: '',
            vitals: {
              height: '',
              weight: '',
              bmi: '',
              blood_pressure: '',
              temp_in_f: '',
              puls_bpm: '',
              o2_level: '',
            },
          },
        },
      });
    }
  },
  // ADD SYMPTOM
  addSymptom: (e, option) => {
    const { bookAppointmentStateHealthQuries } = get();

    if (option?.value === option?.labelShow) {
      const array = bookAppointmentStateHealthQuries?.additionalSymptoms;
      array.push(option?.value);
      set({
        bookAppointmentStateHealthQuries: {
          ...bookAppointmentStateHealthQuries,
          additionalSymptoms: array,
        },
      });
    }

    const { bookAppointmentState } = get();
    const { symptoms } = bookAppointmentState;
    let symptomsDataCopy = JSON.parse(JSON.stringify(symptoms));
    if (symptomsDataCopy.length > 0) {
      const symptomsDataFilter = symptomsDataCopy.filter(
        (v) => v?.value === option?.value
      );
      if (!symptomsDataFilter?.length > 0) {
        symptomsDataCopy.push(option);
      } else {
        const symptomsDataFilterData = symptomsDataCopy.filter(
          (v) => v?.value !== option?.value
        );
        symptomsDataCopy = symptomsDataFilterData;
      }
    } else {
      symptomsDataCopy.push(option);
    }
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        symptoms: symptomsDataCopy,
      },
    });
  },
  // REPORT UPLOAD TO STATE
  reportUpload: (value) => {
    const { bookAppointmentState } = get();
    const { reports } = bookAppointmentState;
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        reports: [...reports, value],
      },
    });
  },

  // UPDATE VITALS ERROR
  updateVitalsError: (error) => {
    const { bookAppointmentState } = get();
    const { vitals } = bookAppointmentState;
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        vitals: {
          ...vitals,
          error,
        },
      },
    });
  },
  // VITALS DATA CLEAR
  vitalsDataClear: () => {
    const { bookAppointmentState } = get();
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        vitals: {
          height_in_cm: '',
          weight_in_cm: '',
          bmi_in_kg_m2: '',
          temp_in_f: '',
          puls_bpm: '',
          blood_pressure: '',
          bp_sys: '',
          bp_dia: '',
          o2_level: '',
          error: {
            height_in_cm: '',
            weight_in_cm: '',
            temp_in_f: '',
            puls_bpm: '',
            blood_pressure: '',
            o2_level: '',
          },
        },
      },
    });
  },
  // HANDLE VITALS DATA CAHNGE
  handleVitalsDataChange: async (key, value) => {
    const { bookAppointmentState } = get();
    const { vitals } = bookAppointmentState;
    // For preventing change to the original state
    const vitalsCopy = JSON.parse(JSON.stringify(vitals));

    if (key === 'height_in_cm' || key === 'weight_in_cm') {
      // BMI CALCULATION
      const BMI = await BMICalculation(key, value, vitals);
      return set({
        bookAppointmentState: {
          ...bookAppointmentState,
          vitals: {
            ...vitalsCopy,
            [key]: value,
            bmi_in_kg_m2: BMI,
          },
        },
      });
    }
    if (key === 'temp_in_f') {
      if (isDecimalNumber(value)) {
        return set({
          bookAppointmentState: {
            ...bookAppointmentState,
            vitals: {
              ...vitalsCopy,
              temp_in_f: value,
            },
          },
        });
      }
    } else if (key === 'blood_pressure') {
      if (isBloodPressure(value)) {
        return set({
          bookAppointmentState: {
            ...bookAppointmentState,
            vitals: {
              ...vitalsCopy,
              blood_pressure: value,
            },
          },
        });
      }
    } else {
      return set({
        bookAppointmentState: {
          ...bookAppointmentState,
          vitals: {
            ...vitalsCopy,
            [key]: value,
          },
        },
      });
    }
  },
  setVitalsInitailData: () => {
    const { bookAppointmentState } = get();
    const { vitals } = bookAppointmentState;
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        vitalsInitialData: vitals,
      },
    });
  },
  setSkipVitals: () => {
    const { bookAppointmentState } = get();
    const { vitalsInitialData } = bookAppointmentState;
    set({
      bookAppointmentState: {
        ...bookAppointmentState,
        vitals: vitalsInitialData,
      },
    });
  },
}));
