import { useBookAppointment } from '@hc/store';
import { localStorageKeys } from '@hc/utils/constants';
import { useEffect } from 'react';

function BookAppointmentRootLayout(props) {
  const { children } = props;

  const { bookAppointmentState, updateBookAppointmentStore } =
    useBookAppointment((state) => ({
      bookAppointmentState: state.bookAppointmentState,
      updateBookAppointmentStore: state.updateBookAppointmentStore,
    }));

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem(localStorageKeys?.bookAppointmentLocalStorage)
    );
    if (storedData) {
      updateBookAppointmentStore(storedData);
    }
  }, []);

  useEffect(() => {
    const data = localStorage.getItem(localStorageKeys?.isReferralAppoinment);
    const jsonData = JSON.parse(data);
    if (bookAppointmentState?.booking_method?.length > 0) {
      localStorage.setItem(
        localStorageKeys?.bookAppointmentLocalStorage,
        JSON.stringify(bookAppointmentState)
      );
    } else if (jsonData?.is_patient_referral) {
      localStorage.setItem(
        localStorageKeys?.bookAppointmentLocalStorage,
        JSON.stringify(bookAppointmentState)
      );
    }
  }, [bookAppointmentState]);

  return <div>{children}</div>;
}

export { BookAppointmentRootLayout };
