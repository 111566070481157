/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { clinicalRoutes } from '@hc/routes';
import { useAppointment, useBookAppointment } from '@hc/store';
import { BookingStatus } from '@hc/ui/components/clinical/appointmentBooking/bookingStatus';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { React, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';

export function BookingStatusAppointment() {
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const authTokenData = parseJwt(authToken);
  const navigate = useNavigate();

  const { bookAppointmentState, getAppointmentStatus } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      getAppointmentStatus: state.getAppointmentStatus,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getPastUpcomingAppointmentList } = useAppointment(
    (state) => ({
      getPastUpcomingAppointmentList: state.getPastUpcomingAppointmentList,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // General Hooks
  const location = useLocation();

  // GET LOCATION STATE DATA
  const { status, appointmentId, bookingMethod, familyMemberId } =
    location?.state;

  useEffect(() => {
    if (status && appointmentId) {
      getAppointmentStatus(appointmentId, bookingMethod, familyMemberId);
      if (status === 'reschedule') {
        getPastUpcomingAppointmentList(authTokenData?.user_id, false);
      }
    } else {
      navigate(clinicalRoutes?.home);
    }
  }, []);

  return (
    <Box>
      {status && (
        <Box>
          <BookingStatus
            appointmentStatusDetails={
              bookAppointmentState?.appointmentStatusDetails
            }
            confirmed={status === 'confirmed'}
            message={
              status === 'confirmed'
                ? 'Appointment Confirmed'
                : status === 'reschedule'
                ? 'Appointment Rescheduled'
                : 'Appointment Cancelled'
            }
            myself={
              bookAppointmentState?.appointmentStatusDetails?.patientDetails
                ?.user_id === authTokenData?.user_id
            }
          />
        </Box>
      )}
      {!status && <div> </div>}
    </Box>
  );
}
