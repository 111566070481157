// import { useMBFOnboarding } from '@hc/store';
import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity } from '@hc/store';
import { AirtableFormRenderer } from '@hc/ui/components/airtableFormRenderer';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, CircularProgress } from '@mui/material';

import { envConfig } from '@hc/config';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { questionevaluation_style } from './style';

export default function EvaluationType(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();

  const { loading, checkPlanDataDetails, mbfState, getAnalysePlan } =
    useMBFActivity((state) => ({
      checkPlanDataDetails: state.checkPlanDataDetails,
      mbfState: state.mbfState,
      loading: state.loading,
      getAnalysePlan: state.getAnalysePlan,
    }));

  const { checkPlanData } = mbfState;
  const { status, mbfUserPlanCount } = checkPlanData;

  const initialData = async () => {
    if (
      typeof checkPlanData === 'object' &&
      Object.keys(checkPlanData)?.length === 0
    ) {
      // Check plan status
      await checkPlanDataDetails();
    }

    // Check form already filled or not
    if (
      !loading &&
      typeof checkPlanData === 'object' &&
      Object.keys(checkPlanData).length > 0
    ) {
      if (status > 0) {
        toast('Form already submitted!');
        navigate(clinicalRoutes.getStarted);
      }
    }
  };

  const onSubmitPre = async () => {
    await getAnalysePlan();

    navigate(clinicalRoutes.mbfSplash, {
      state: {
        from: 'New',
        planCount: mbfUserPlanCount,
      },
    });
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Screenlayout
      title="Questionnaire"
      notshowFooter
      appBarStyle={{ ...questionevaluation_style.appBarSx }}
      backRequired
      childrenStyle={questionevaluation_style.childrenSx}
      backIconStyle={{ color: 'mbf.main' }}
      backRoute={clinicalRoutes.getStarted}
    >
      {loading && (
        <Box sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}>
          <CircularProgress sx={{ color: 'mbf.main' }} />
        </Box>
      )}
      {!loading && (
        <Box
          sx={questionevaluation_style.rootSx}
          className={`${className}`}
          {...rest}
        >
          <Box sx={questionevaluation_style.containerSx}>
            <AirtableFormRenderer
              baseType="New"
              baseId={envConfig.airtable_app_base_id}
              tableId={envConfig.airtable_pre_evaluation_questionnaire}
              onSubmitFnc={onSubmitPre}
            />
          </Box>
        </Box>
      )}
    </Screenlayout>
  );
}
