export const consultationReason_style = {
  rootSx: {
    display: 'grid',
    gap: 2,
    minHeight: '100vh',
  },
  boxRootSx: {
    px: 2.5,
  },
  BookingTextSx: {
    fontSize: 16,
    fontWeight: 600,
    color: 'text.primary',
    mt: 2,
  },
  healthIssuesImageSx: {
    backgroundColor: '#A3E1DC',
    cursor: 'pointer',
  },
  healthQueriesImageSx: {
    backgroundColor: '#ECD6E3',
    cursor: 'pointer',
  },
};
