import { Avatar, Box, Divider, Typography, Stack } from '@mui/material';
import { useDoctorDetails } from '@hc/store/clinical/doctorDetails';
import { isEqual } from 'date-fns';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getDateFormatToString } from '@hc/dayjs';
import { useEffect } from 'react';
import {
  CalenderSVGIcon,
  CertificateIcon,
  DashIcon,
  HospitalIcon,
  InformationIcon,
  LanguageIcon,
  LocationIcon,
  PrivateBackIcon,
  RedCalendarIcon,
  ShareIcons,
  SpecialityIcon,
  StarIcon,
} from '@hc/ui/atoms';
import DotorProfile from '@hc/ui/assets/DotorProfile.svg';
import { ViewDoctorDetails_style } from './style';

function ViewDoctorDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { doctorDetail, getDoctorsDetails } = useDoctorDetails(
    (state) => ({
      doctorDetail: state.doctorDetail,
      getDoctorsDetails: state.getDoctorsDetails,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const doctor_id = searchParams.get('doctor_id');
    getDoctorsDetails(doctor_id);
  }, []);

  return (
    <Box sx={ViewDoctorDetails_style.rootSx}>
      <Box mb={2}>
        <Box
          sx={{
            ...ViewDoctorDetails_style.topbar,
            backgroundImage: `url(${DotorProfile})`,
            backgroundRepeat: ' no-repeat',
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{ position: 'absolute', inset: '10px 0 0 10px' }}
            onClick={handleBack}
          >
            {' '}
            <PrivateBackIcon />{' '}
          </Box>
        </Box>
        <Box sx={ViewDoctorDetails_style.topSecondBar}>
          <Avatar
            src={doctorDetail.profile_pic}
            sx={ViewDoctorDetails_style.profilepicSx}
          />
          <Stack
            sx={{
              px: 1,
              width: '100%',
              alignItems: 'center',
              mt: 10,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: '600' }}>
              {doctorDetail.first_name}
            </Typography>
            <Box sx={ViewDoctorDetails_style.doctorSx}>
              {/* {Array.isArray(role) && role?.length > 0 && <CertificateIcon />} */}
              <CertificateIcon />
              <Typography sx={ViewDoctorDetails_style.subText}>
                {' '}
                {doctorDetail.education}
              </Typography>
            </Box>
            <Box sx={ViewDoctorDetails_style.doctorSx}>
              <HospitalIcon />
              <Typography sx={ViewDoctorDetails_style.subText}>
                {' '}
                {doctorDetail?.working_at?.[0]}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Stack justifyContent="space-around" flexDirection="row">
        <Box sx={ViewDoctorDetails_style.iconsList}>
          <StarIcon />
        </Box>
        <Box sx={ViewDoctorDetails_style.greenIconSx}>
          <CalenderSVGIcon />
        </Box>
        <Box sx={ViewDoctorDetails_style.greenIconSx}>
          <ShareIcons />
        </Box>
        <Box
          sx={{ ...ViewDoctorDetails_style.greenIconSx, background: '#FFE9E9' }}
        >
          <DashIcon />
        </Box>
      </Stack>
      <Box p={1}>
        <Box py={1}>
          <Box sx={ViewDoctorDetails_style.iconText}>
            <SpecialityIcon />
            <Typography sx={ViewDoctorDetails_style.titileText}>
              SPECIALTY
            </Typography>
          </Box>
          <Box sx={ViewDoctorDetails_style.iconText}>
            {Array.isArray(doctorDetail?.doctor_specialities) &&
              doctorDetail?.doctor_specialities.map((val, i) => (
                <Typography
                  key={i}
                  sx={ViewDoctorDetails_style.highLightedTextSx}
                >
                  {val?.master_speciality?.speciality}
                </Typography>
              ))}
          </Box>
        </Box>
        <Divider sx={ViewDoctorDetails_style.lightBorder} />
        <Box py={1}>
          <Box sx={ViewDoctorDetails_style.iconText}>
            <RedCalendarIcon />
            <Typography sx={ViewDoctorDetails_style.titileText}>
              Serving Form
            </Typography>
          </Box>
          <Typography sx={ViewDoctorDetails_style.detailSx}>
            {getDateFormatToString(doctorDetail?.serving_from, 'll')}
          </Typography>
        </Box>
        <Divider sx={ViewDoctorDetails_style.lightBorder} />
        <Box py={1}>
          <Box sx={ViewDoctorDetails_style.iconText}>
            <LocationIcon />
            <Typography sx={ViewDoctorDetails_style.titileText}>
              ADDRESS
            </Typography>
          </Box>
          <Typography sx={ViewDoctorDetails_style.detailSx}>
            {doctorDetail?.address}
          </Typography>
        </Box>
        <Divider sx={ViewDoctorDetails_style.lightBorder} />
        <Box py={1}>
          <Box sx={ViewDoctorDetails_style.iconText}>
            <LanguageIcon />
            <Typography sx={ViewDoctorDetails_style.titileText}>
              LANGUAGES KNOWN
            </Typography>
          </Box>
          {Array.isArray(doctorDetail?.user_languages) &&
            doctorDetail?.user_languages.map((val, i) => (
              <Typography
                key={i}
                sx={ViewDoctorDetails_style.highLightedTextSx}
              >
                {val?.master_language?.language}
              </Typography>
            ))}
        </Box>
        <Divider sx={ViewDoctorDetails_style.lightBorder} />
        <Box py={1}>
          <Box sx={ViewDoctorDetails_style.iconText}>
            <InformationIcon />
            <Typography sx={ViewDoctorDetails_style.titileText}>
              ABOUT
            </Typography>
          </Box>
          <Typography sx={ViewDoctorDetails_style.detailSx}>
            {doctorDetail?.doctorDetailabout}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export { ViewDoctorDetails };
