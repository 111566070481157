export const appointmentDetails_style = {
  rootSx: {
    p: 1,
  },
  titleSx: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  labelSx: {
    backgroundColor: 'PrimaryTints.A100',
    // p: 0.4,
    borderRadius: '4px',
    color: 'text.primary',
    fontSize: '12px',
    // ml: 0.8,
    fontWeight: 600,
  },
  labelSxx: {
    // backgroundColor: 'PrimaryTints.A100',
    p: 0.4,
    borderRadius: '4px',
    color: '#868484',
    fontSize: '12px',
    // ml: 0.8,
    fontWeight: 600,
  },
  headerSx: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#18574CB7',
    fontWeight:500
  },
  noDataFound: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
  },
  pointsSx: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    pl: 0.5,
    pr: 0.5,
    pt: 0.3,
    pb: 0.3,
    ml: 1,
    fontSize: '12px',
    backgroundColor: 'grey.100',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.secondary',
  },
};
