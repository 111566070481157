import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFOnboarding = create((set) => ({
  mbfState: {},
  // levels: {},
  loading: false,
  formLoading: false,
  error: null,
  assignPlanLoading: false,

  createUserRecord: async (payload, baseType) => {
    try {
      set({ formLoading: true });
      // Hitting the signup API
      const { status } = await queryClient.fetchQuery({
        queryKey: ['mbfCreateUserRecord'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            baseType === 'New'
              ? `${envConfig.api_url}/mbf/plan/request`
              : baseType === 'FeedBack'
              ? `${envConfig.api_url}/mbf/plan-feedback/submit`
              : `${envConfig.api_url}/mbf/goals/renewal-plan/request`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        if (baseType === 'New' || baseType === 'Goal') {
          toast.success(status?.message);
        }
      }
      set({ formLoading: false });
      return status;
    } catch (err) {
      set({ formLoading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getPlanDetails: async (id) => {
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery([
        '/mindBodyFood/get-plan-details',
        'post',
        { id: id ?? '' },
      ]);

      if (response?.status?.code === 200) {
        set({ loading: false });
        return toast.success(response?.status?.message);
      }
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getStartedMbf: async (user_id) => {
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery([
        '/mindBodyFood/get-started',
        'post',
        { user_id: user_id ?? '' },
      ]);

      if (response?.status?.code === 200) {
        set({
          loading: false,
          mbfMood:
            response?.data && response?.data?.mood_name
              ? response?.data?.mood_name
              : null,
        });
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
