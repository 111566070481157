import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import AngryImage from '@assets/angryEmoji.png';
import FaceBandageEmoji from '@assets/faceBandageEmoji.png';
import FaceWIthStreamEmoji from '@assets/faceWIthStreamEmoji.png';
import NeutralFaceEmoji from '@assets/neutralFaceEmoji.png';
import SleepyFaceEmoji from '@assets/sleepyFaceEmoji.png';
import SmileEmoji from '@assets/smileEmoji.png';
import SmileWithStarEmoji from '@assets/smileWithStarEmoji.png';
import { clinicalRoutes } from '@hc/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { MbfMoodSplash_style } from './style';

export default function MbfMoodSplash(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const moodName = location?.state?.moodName ?? '';
  // const mbfMoodImages = {
  //   happy: SmileEmoji,
  //   sad: SleepyFaceEmoji,
  //   neutral: NeutralFaceEmoji,
  //   Tired: FaceBandageEmoji,
  //   stressed: FaceWIthStreamEmoji,
  //   angry: AngryImage,
  //   energetic: SmileWithStarEmoji,
  // };

  const mbfMoodImages = (type) => {
    switch (type) {
      case 'Happy':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={SmileEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>Happy</Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              {/* Stay happy to increase <br /> longevity */}
              Overflowing with joy and <br /> positivity! 😁✨
            </Typography>
          </Stack>
        );

      case 'Sad':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={SleepyFaceEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>Sad</Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Rainy days within, <br /> waiting for the sun. 😔☔
            </Typography>
          </Stack>
        );

      case 'Neutral':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={NeutralFaceEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>
              Neutral
            </Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Embracing the calm of the <br /> present moment. 🌻🐳
            </Typography>
          </Stack>
        );

      case 'Tired':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={FaceBandageEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>Tired</Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Heavy lids and a mind <br /> ready to rest. 😴🌙
            </Typography>
          </Stack>
        );

      case 'Stressed':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={FaceWIthStreamEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>
              Stressed
            </Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Juggling tasks like a pro, <br /> despite the chaos. 🤹😓
            </Typography>
          </Stack>
        );

      case 'Angry':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={AngryImage}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>Angry</Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Burning fire within, seeking <br /> a path of calm 🔥😡
            </Typography>
          </Stack>
        );

      case 'Energetic':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={SmileWithStarEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>
              Energetic
            </Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Dancing through the day <br /> with endless vitality! 💃💫
            </Typography>
          </Stack>
        );

      default:
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={MbfMoodSplash_style.containerSx}
          >
            <Box>
              <img
                src={SmileEmoji}
                alt="Verified tick gif"
                style={MbfMoodSplash_style.tickGifSx}
              />
            </Box>
            <Typography sx={MbfMoodSplash_style.titleTypoSx}>Happy</Typography>
            <Typography sx={MbfMoodSplash_style.descTypoSx}>
              Overflowing with joy and <br /> positivity! 😁✨
            </Typography>
          </Stack>
        );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      navigate(clinicalRoutes.mindbodyfood);
    }, 2500);
  }, []);

  return (
    <Box
      sx={{
        ...MbfMoodSplash_style.rootSx,
      }}
      className={`${className}`}
      {...rest}
    >
      {mbfMoodImages(moodName)}
    </Box>
  );
}
