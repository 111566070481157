import { clinicalRoutes } from '@hc/routes';
import { useFamilyMember, useRelationShip } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { ExistingMember } from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function AddExistingMember(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  // Store Data
  const {
    searchFamilyData,
    familyMember,
    familyMembers,
    updateFamilyMemberError,
    clearFamilyMemberState,
    upsertExistingMember,
    // loading,
  } = useFamilyMember(
    (state) => ({
      searchFamilyData: state.searchFamilyData,
      familyMember: state.familyMember,
      familyMembers: state.familyMembers,
      updateFamilyMemberError: state.updateFamilyMemberError,
      clearFamilyMemberState: state.clearFamilyMemberState,
      upsertExistingMember: state.upsertExistingMember,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { get } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // Checking Save Validation
  const isIamValidToSave = () => {
    const familyMemberCopy = JSON.parse(JSON.stringify(familyMember));

    let isValid = true;
    const error = familyMemberCopy?.error;

    // Checking relationship
    if (!familyMember?.relationship) {
      isValid = false;
      error.relationship = 'Please select relationship';
    } else {
      error.relationship = '';
    }

    // checking if the mobile number already exists in the family members
    if (
      familyMembers.some(
        (familyMemberData) =>
          familyMemberData.mobile_no === searchFamilyData?.mobile_no
      )
    ) {
      toast('User is already your family member');
      return navigate(clinicalRoutes.myFamily);
    }
    // UPDATE FAMILY MEMBER ERROR
    updateFamilyMemberError(error);
    return isValid;
  };

  const familyMemberView = () => {
    navigate(clinicalRoutes.myFamily);
    // CLEAR FAMILY MEMBER STATE
    clearFamilyMemberState();
  };
  const upsertExistingMemberFnc = () => {
    const result = isIamValidToSave();
    if (result) {
      upsertExistingMember(data?.user_id ?? '');
      // CLEAR FAMILY MEMBER STATE
      clearFamilyMemberState();
      // clear search member data
      useFamilyMember.setState({
        searchFamilyData: null,
      });
    }
  };

  // GET RELATIONSHIP DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <Box className={`${className}`} {...rest}>
      <Screenlayout
        backRequired
        backRoute={clinicalRoutes.myFamily}
        title="Add Existing Member"
        footer={
          searchFamilyData?.mobile_no ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                sx={{
                  bgcolor: 'primaryTints.300',
                  color: 'primary.main',
                  borderRadius: '8px',
                  '&:hover': {
                    bgcolor: 'primaryTints.300',
                  },
                }}
                onClick={familyMemberView}
              >
                Cancel
              </Button>
              <Button
                buttonStyle={{
                  borderRadius: '8px',
                }}
                onClick={upsertExistingMemberFnc}
              >
                Confirm
              </Button>
            </Box>
          ) : null
        }
      >
        <Box px={2}>
          <ExistingMember />
        </Box>
      </Screenlayout>
    </Box>
  );
}
