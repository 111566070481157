import { clinicalRoutes } from '@hc/routes';
import { useOnboarding } from '@hc/store/clinical/onboarding';
import { Button, Label, MobileInput } from '@hc/ui/atoms';
import { Box, Typography } from '@mui/material';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';
import { forgotPassword_style } from './style';

export function ClinicalForgotPassword() {
  const { user, setUser, forgotPassword, loading } = useOnboarding(
    (state) => ({
      user: state.user,
      loading: state.loading,
      setUser: state.setUser,
      forgotPassword: state.forgotPassword,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // General Hooks
  const [data, setData] = useState(user);

  const updateState = (key, value) => {
    if (key === 'mobileNumber') {
      setData({
        ...data,
        countryCode: value.mobileCode,
        mobileNumber: value.mobile,
      });
    }
  };

  const validate = () => {
    let isValid = true;
    const error = data?.error;
    // Checking Mobile Number
    if (data?.mobileNumber.length === 0) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else if (data?.mobileNumber.length < 10) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobileNumber = '';
    }
    setData({ ...data, error });
    return isValid;
  };
  const SignIn = () => {
    if (validate()) {
      const error = data?.error;
      error.password = '';
      error.mobileNumber = '';
      setData({ ...data, error });
      forgotPassword(data);
    }
  };

  useEffect(() => {
    setUser({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
    setData({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
  }, []);
  return (
    <Box>
      <Box sx={forgotPassword_style.paperSx}>
        <Box>
          <Typography variant="h6" sx={forgotPassword_style.titleSx}>
            Forgot Password
          </Typography>
          <Typography variant="body2" sx={forgotPassword_style.subTitleSx}>
            Provide us the registered phone number to reset your password.
          </Typography>
          <Box sx={{ display: 'grid', gap: 3, mt: 3 }}>
            <Box sx={forgotPassword_style.inputGroupSx}>
              <Label
                htmlFor="mobileInput"
                labelStyle={forgotPassword_style.labelStyle}
                isRequired
              >
                Phone Number
              </Label>
              <MobileInput
                id="mobileInput"
                isError={data.error.mobileNumber !== ''}
                helperText={data.error.mobileNumber}
                value={{
                  mobile_code: data.countryCode ?? '+91',
                  mobile: data.mobileNumber ?? '',
                }}
                onChange={(e) => updateState('mobileNumber', e)}
              />
            </Box>
            <Box>
              <Button
                fullWidth
                onClick={SignIn}
                loading={loading}
                buttonStyle={forgotPassword_style.buttonStyle}
              >
                Get OTP
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', placeItems: 'center', mx: 'auto', pb: 3 }}>
          <Typography variant="body2" sx={forgotPassword_style.subTitleSx}>
            Remembered the password?
          </Typography>
          <Link to={clinicalRoutes.login}>
            <Typography variant="body1" sx={forgotPassword_style.linkSx}>
              Sign in
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
