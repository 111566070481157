export const upcomingAppointment_style = {
  rootSx: {
    px: 2.5,
  },
  upcomingListSx: {
    gap: 2,
  },
  upcomingcardStyle: {
    mt: 2,
  },
  passAppointmentStyle: {
    mt: 1.5,
  },
  subHeadSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.secondary',
    mt: 1,
  },

  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '8px',
    p: 1.5,
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  cancelAppointment: {
    ml: 0.5,
  },
  morelistSx: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 0px 7px 1px #b7b7b7',
    },
    '& .MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px',
    },
  },
};
