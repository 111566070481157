export const questionevaluation_style = {
  rootSx: {
    minHeight: '100vh',
  },
  containerSx: {
    pt: 0,
  },
  btnSx: {
    bgcolor: 'mbf.main',
    '&:hover': {
      bgcolor: 'mbf.main',
    },
  },
  footerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 3,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderTop: '1.5px dashed',
    borderColor: 'border.main',
    boxShadow: '0px -5px 10px #0000000A',
  },
  backBtnSx: {
    bgcolor: 'purple.300',
    width: '48px',
    height: '40px',
    marginRight: '12px',
    '&:hover': {
      bgcolor: 'purple.50',
    },
  },
  appBarSx: {
    bgcolor: 'purple.A100',
    pb: 2,
  },
  backIconSx: {
    bgcolor: 'purple.A100',
  },
  childrenSx: {
    pb: 0,
    backgroundColor: 'grey.100',
  },
};
