export const homeLeaderboard_Style = {
  leaderboradRankTxt: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#5C6266',
    fontWeight: 500,
  },
  leaderboradRankNumTxt: {
    color: '#0E1824',
  },
};
