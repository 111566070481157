export const otpVerification_style = {
  rootSx: {
    display: 'grid',
    gridTemplateRows: '34% 66%',
    backgroundColor: 'primary.lighter',
    // minHeight: '100vh',
  },

  imgContSx: {
    maxWidth: '90%',
    mx: 'auto',
    pt: 2,
    pb: 4,
  },

  exerciseImageSx: {
    borderRadius: '0%',
    width: '260px',
    height: '204px',
  },
};
