export const PatientDetailCard_style = {
  rootSx: {
    // height:'104px',
    // weight:'375px',
    backgroundColor: 'common.white',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 1,
  },
  inputSx: {
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    color: '#888888',
  },
  inputTimeSx: {
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    color: '#888888',
    float: 'right',
    pr: 0.5,
  },
  inputNameSx: {
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    color: 'text.primary',
    fontWeight: 600,
  },
};
