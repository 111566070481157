export const bodyFoodText_Style = {
  cardSx: {
    boxShadow: '0px -5px 30px 12px #0000000a',
    borderRadius: '8px',
    width: '100%',
    gap: '12px',
    p:0.5
  },
  colorBoxSx: {
    color: '#6552CC',
    bgcolor: '#6552CC',
    width: '44px',
    height: '10px',
  },
  subColorSx: {
    ml: 0.75,
    color: '#E4E0FF',
    bgcolor: '#E4E0FF',
    width: '12px',
    height: '10px',
  },
  colorWithTextSx: {
    display: 'flex',
    alignItems: 'center',
    mt: 3,
    px: 2.5
  },
  headerTextSx: {    
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    mt: 2,
    px: 2.5
  },
  subTextSx: {    
    fontSize: '14px',
    color: 'text.hint',
    mt: 1.5,
    px: 2.5
  },
};
