import { Typography, Box } from '@mui/material';
import {
  CustomTextArea,
  CancelAppointmentIcon,
  Label,
  Drawer,
  Button,
} from '@atoms';
import PropTypes from 'prop-types';
import { useAppointment } from '@hc/store';
import { cancelAppointment_style } from './style';

export function CancelAppointment(props) {
  const {
    drawerOpen,
    onNext = () => {},
    onBack = () => {},
    setDrawerOpen,
    ReasonForCancellation,
    value,
    removeAppointmentDrawer = false,
    title = 'Are you sure you want to cancel this appointment?',
    label = 'Reason For Cancellation (Optional)',
  } = props;

  const { loading } = useAppointment((state) => ({
    loading: state.loading,
  }));
  return (
    <Box>
      <Drawer
        show={drawerOpen}
        onCloseDrawer={() => setDrawerOpen(false)}
        rootStyle={cancelAppointment_style.drawerRootSx}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={onBack}
              buttonStyle={cancelAppointment_style.backButtonSx}
              disabled={loading === true}
            >
              Go Back
            </Button>
            <Button
              onClick={onNext}
              loading={loading}
              buttonStyle={cancelAppointment_style.cancelButtonSx}
            >
              Yes, Cancel
            </Button>
          </Box>
        }
      >
        <Box sx={cancelAppointment_style.rootSx}>
          <Box sx={cancelAppointment_style.cancelSx}>
            <CancelAppointmentIcon />
            <Typography variant="body2" sx={cancelAppointment_style.titleSx}>
              {title}
            </Typography>
          </Box>
          {!removeAppointmentDrawer && (
            <Box>
              <Label labelStyle={cancelAppointment_style.labelSx}>
                {label}
              </Label>
              <CustomTextArea
                rows={5}
                rowsMax={10}
                placeholder="Type here..."
                textFieldStyle={cancelAppointment_style.textSx}
                value={value}
                onChange={ReasonForCancellation}
              />
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}
CancelAppointment.propTypes = {
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  ReasonForCancellation: PropTypes.func,
  value: PropTypes.string,
  removeAppointmentDrawer: PropTypes.bool,
};
