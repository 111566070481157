import { Button } from '@hc/ui/atoms';
import { Questionnaire } from '@hc/ui/components/clinical';
import { Box } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine } from "react-icons/ri";
import { ClinicalLayout } from '@hc/ui/components';
import { questionevaluation_style } from './style';

export default function Evaluation(props) {
  const { footerStyle = {}, className = '', ...rest } = props;

  const [questionSelected, setQuestionSelected] = useState(0);

  const onBack = () => {
    if (questionSelected > 0) {
      setQuestionSelected(questionSelected - 1);
    }
  };
  const onNext = () => {
    setQuestionSelected(questionSelected + 1);
  };

  return (
    <ClinicalLayout isMindBodyFood isHealthCircle={false} footBarRequired={false}>
      <Box
        sx={questionevaluation_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Box sx={questionevaluation_style.containerSx}>
          <Questionnaire progressValue={questionSelected} />
        </Box>
      </Box>
      <Box sx={{ ...questionevaluation_style.footerSx, ...footerStyle }}>
        {questionSelected > 0 && (
          <Button
            buttonStyle={questionevaluation_style.backBtnSx}
            onClick={onBack}
          >
            <RiArrowLeftLine
              style={{ fontSize: '40px', color: '#6552CC', fontWeight: 600 }}
            />
          </Button>
        )}
        <Button buttonStyle={questionevaluation_style.btnSx} onClick={onNext}>
          Next
        </Button>
      </Box>
    </ClinicalLayout>
  );
}

Evaluation.propTypes ={
  footerStyle:PropTypes.object,
  className:PropTypes.string,
}
