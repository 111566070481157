/* eslint-disable camelcase */
export const mood_stats_progress_style = {
  rootSx: {
    border: '1px solid #E5E0FF',
    borderRadius: '8px',
    // height: '50vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
  },
  statsFromSx: {
    bgcolor: 'mbf.light',
    borderRadius: '10px 10px 0px 0px',
    px: 2,
    py: 1,
    mb: -0.5,
  },
  progressContainerSx: {
    px: {
      xs: 1,
      sm: 5,
    },
    py: 3,
  },
  iconsRootSx: {
    height: '10px',
    width: '5.48px',
  },
  circularColorSx: {
    color: 'purple.100',
  },
};
