export const multiSelect_style = {
  CloseSx: {
    color: '#000!important',
  },
  chipTotalSx: {
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#0F0B117A',
    bgcolor:"#18445714",
    p: 0.4,
    mx: 0.4,
    mt: 1.25,
  },
};