export const profileView_style = {
  rootSx: {
    background: 'linear-gradient(117deg, #6EBDAB 2%, #239179 39%)',
    backgroundRepeat: 'no-repeat',
    pb: 4.3,
  },

  paperSx: {
    px: 2.5,
    pt: 2.5,
    pb: 4,
    position: 'relative',
    backgroundColor: 'primaryGreyishTints.50',
    borderRadius: '0px',
    cursor: 'pointer',
  },
  profileSx: {
    fontSize: '46px',
    color: 'primary.main',
    backgroundColor: 'common.white',
    width: '106px',
    height: '106px',
    border: '2px solid',
    borderColor: 'common.white',
  },
  profileviewSx: {
    p: 2.5,
  },
  profileDetailsWrapperSx: {
    display: 'grid',
    placeItems: 'center',
  },
  profileChip: {
    color: 'white',
    background: 'primary.main',
    boxShadow: '0px 4px 10px #00000014',
    borderRadius: '4px',
    opacity: 1,
    border: 'none',
    minWidth: '90px',
    height: '28px',
    backgroundColor: '#007965',
    top: '-14px !important',
    position: 'relative',
  },
  nameSx: {
    pt: 2,
    pb: 1,
    color: 'common.white',
    fontWeight: 600,
  },
  subnameSx: {
    color: 'common.white',
    fontWeight: 400,
    pb: 0.75,
  },
  vitalSx: {
    justifyContent: 'space-between',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px #0000000A',
    borderRadius: '8px',
    alignItems: 'center',
    p: 2,
  },
  medicalConditionFontSX: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 500,
    color: '#101010',
  },
  addFontSX: {
    color: '#239179',
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 600,
  },
  profileseekSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profilexSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cameraSx: {
    // position: 'absolute',
    // bottom: '-5px',
    // margin: '-3px 16px',
    // mx: 1,
    // my: -1,
    backgroundColor: 'common.white',
    borderRadius: '50px',
    border: '2px solid',
    borderColor: 'common.white',
    '&:hover': {
      backgroundColor: 'common.white',
      borderColor: 'primaryTints.A200',
      border: '2px solid',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
  },
  linkSx: {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '500',
    position: 'absolute',
    cursor: 'pointer',
    right: '20px',
    color: '#fff',
  },
  logoutSx: {
    backgroundColor: 'transparent',
    px: 0,
    py: 0,
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonSx: {
    bgcolor: '#DFF7F3',
    color: 'primary.main',
    '&:hover': {
      bgcolor: '#DFF7F3',
    },
  },
  logoutConfirmSx: {
    textAlign: 'Center',
    py: 4.5,
  },
  callButtonSx: {
    borderRadius: 2,
    bgcolor: 'error.main',
    '&:hover': {
      bgcolor: 'error.main',
    },
  },
};
