export const medicalCard_style = {
  contentSx: {
    pt: 2,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },
  noDataFound: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
  },
  dialogSx: {
    width: '469px',
  },
  labelSx: {
    backgroundColor: 'grey.100',
    p: 0.5,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'grey.400',
    mr: 1,
    mb: 1,
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
  },
  avatar: {
    background: '#fff',
  },
  subText: {
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 500,
    color: '#808080',
  },

  totalCardLeftSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  overAllSx: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px dashed',
    borderColor: '#808080',
    pt: 1,
    pb: 3,
  },
  referCardSx: {
    fontSize: '14px',
    backgroundColor: 'transparent',
    color: 'primary.main',
    textTransform: 'capitalize',
    border: '1px solid ',
    borderColor: 'primary.main',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.main',
      boxShadow: 'none',
    },
  },
  totalCardRightSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalPatientCardSx: {
    // py: 3,
  },
  viewSx: {
    width: '100px',
  },
  documentSx: {
    color: 'orangeTints.400',
    width: '23px',
    height: '23px',
  },
  viewSummarySx: {
    '&: hover': {
      backgroundColor: '#fff5ef',
    },
  },
  nameSx: {
    width: '280px',
  },
  gridcenter: {
    justifyContent: 'center',
  },
  gridcenterTotal: {
    // border: '1px solid #000',
    mt: 2,
    pb: 3,
  },
  totalBoxSx: {
    mt: 2,
    mb: 3,
  },
  headingSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0E1824',
  },
};
