import create from 'zustand';
// import { httpRequest, routeTo, localStorageKeys} from '@hc/utils';

export const useQuestionnaire = create((set, get) => ({
  questionnaire: [
    {
      title: 'Pre Evaluation',
      id: 1,
      question:
        '1. What are your personal goals toward your own health and wellbeing over the coming 3 months?',
      options: [
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
        {
          option: 'Improve my diet',
          selected: false,
        },
        {
          option: 'Increase my level of activity',
          selected: false,
        },
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
      ],
    },
    {
      title: 'Pre Evaluation',
      id: 2,
      question:
        '2.What are your personal goals toward your own health and wellbeing over the coming 3 months?',
      options: [
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
        {
          option: 'Improve my diet',
          selected: false,
        },
        {
          option: 'Increase my level of activity',
          selected: true,
        },
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
      ],
    },
    {
      title: 'Pre Evaluation',
      id: 3,
      question:
        '3.What are your personal goals toward your own health and wellbeing over the coming 3 months?',
      options: [
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
        {
          option: 'Improve my diet',
          selected: false,
        },
        {
          option: 'Increase my level of activity',
          selected: true,
        },
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
      ],
    },
    {
      title: 'Pre Evaluation',
      id: 4,
      question:
        '4.What are your personal goals toward your own health and wellbeing over the coming 3 months?',
      options: [
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
        {
          option: 'Improve my diet',
          selected: false,
        },
        {
          option: 'Increase my level of activity',
          selected: true,
        },
        {
          option: 'Sustain my current lifestyle',
          selected: false,
        },
      ],
    },
  ],
  handleSelect: (id, index) => {
    const questionnaireCopy = JSON.parse(JSON.stringify(get().questionnaire));
    questionnaireCopy.forEach((question) => {
      if (question.id === id) {
        question.options.forEach((option, optionIndex, array) => {
          if (optionIndex === index) {
            // eslint-disable-next-line no-param-reassign
            array[optionIndex] = {
              ...option,
              selected: option?.selected !== true,
            };
          }
        });
      }
    });
    set({
      questionnaire: questionnaireCopy,
    });
  },
}));
