export const layout_style = {
  rootSx: {
    position: 'relative',
    // minHeight: '100vh',
    width: '100%',
    backgroundColor: 'primaryGreyishTints.50',
    // pb: 8,
    pb: 2,
  },
  appBarSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 2.5,
    py: 1,
    boxShadow: '0px 1px 10px #0000000A',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    maxWidth: 425,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    zIndex: 1000,
  },

  notificationSx: {
    '& .MuiBadge-badge': {
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      minWidth: 'auto',
      right: '28%',
      top: '16%',
      border: '1.5px solid',
      borderColor: 'common.white',
    },
  },

  footerNavBarSx: {
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    display: 'flex',
    justifyContent: 'space-between',
    px: 5,
    py: 1.5,
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderColor: 'border.main',
    boxShadow: '0px -2px 23px #00000014',
    borderRadius: '24px 24px 0px 0px',
  },

  navItemSx: {
    display: 'grid',
    placeItems: 'center',
    gap: 0.5,
    cursor: 'pointer',
  },
  profileNavItemSx: {
    display: 'grid',
    placeItems: 'center',
    gap: 0.5,
    cursor: 'pointer',
    position: 'relative',
  },
  navItemTextSx: {
    fontSize: '12px',
    fontWeight: 600,
  },
  cameraSx: {
    backgroundColor: 'common.white',
    borderRadius: '50px',
    border: '2px solid',
    borderColor: 'primaryTints.A200',
    '&:hover': {
      backgroundColor: 'common.white',
      borderColor: 'primaryTints.A200',
      border: '2px solid',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
  },
  profileDetailsWrapperSx: {
    display: 'grid',
    placeItems: 'center',
  },
  organizationSx: { fontSize: '12px', fontWeight: 500 },
};
