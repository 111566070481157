export const viewAwareness_style = {
  boxRootSx: {
    px: 2,
    backgroundColor: '#EEF3F9',
  },
  subHeadSx: {
    fontSize: '16px',
    color: 'text.primary',
    p: 5.5,
    fontWeight: '25px',
  },
  backgroundImg: {
    width: '207px',
    height: '272px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    pb: 2,
  },
  titleSx: {
    fontSize: '23px',
    color: '#fff',
    p: 3,
    fontWeight: 'bold',
  },
  slidertitleSx: {
    color: '#3D4690',
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    p: 1,
    pt: 1,
    mb: 6,
  },
  paginationSx: {
    p: 1,
    color: '#B7B7B7',
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '400',
  },
  subtitleSx: {
    p: 3,
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#000',
  },
};
