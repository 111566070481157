export const uploadReport_style = {
  rootSx: {
    px: 2.5,
  },
  buttonSx: {
    textTransform:"none",
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },

  VitalsTextSx: {
    color: 'text.primary',
    fontSize: 16,
    fontWeight: 600,
    my: 2,
  },

  inputGroupSx: {
    display: 'grid',
    gap: 0.5,
    fontSize: 12,
    width:'50%'
  },

  bmiCalculationValueSx: {
    fontSize: 12,
    color: 'text.label',
    fontWeight: 100,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },

  bmiCalculationGroupSx: {
    border: '1px solid',
    borderColor: 'secondary.main',
    backgroundColor: 'amber.50',
    borderRadius: '4px',
    padding: '10px',
    mt: 2,
  },
  vitalLabelSx: {
    color: 'text.label',
    fontSize: 12,
  },
  inputFieldSx: {
    mt: 0.5,
  },
  totalSx: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    width:'100%',

  },
  skipButtonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
    borderRadius: '8px',
  },


    endAdornmentTextSx: {
    fontSize: '12px',
    color: 'text.disabled',
    opacity: 1,
  },
    vitalFormGroup: {
    display: 'grid',
    gridTemplateColumn: '50% 50%',
  },
};
