import { ExerciseImageIcon } from '@hc/ui/atoms/illustration';
import { ClinicalOtpVerification } from '@hc/ui/components/clinical/otpVerification';
import { Box } from '@mui/material';
import { otpVerification_style } from './style';

export default function OtpVerification(props) {
  const { className = '', ...rest } = props;

  return (
    <>
      <Box
        sx={otpVerification_style.rootSx}
        className={`class ${className}`}
        {...rest}
      >
        <Box sx={otpVerification_style.imgContSx}>
          <ExerciseImageIcon
            rootStyle={otpVerification_style.exerciseImageSx}
          />
        </Box>
      </Box>
      <ClinicalOtpVerification />
    </>
  );
}
