import { useProfile } from '@hc/store';
import { Box, Drawer, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'react-fast-compare';
import { SelectMemberType } from '../../familyProfile/selectMemberType';
import { freeConsultation_style } from './style';

function FreeConsultation(props) {
  const {
    addDrawerOpen,
    setAddDrawerOpen,
    PaidConsultant = () => false,
    FreeConsultant = () => false,
    className = '',
    rootStyle,
    ...rest
  } = props;

  const { profile } = useProfile(
    (state) => ({
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  return (
    <Box
      sx={{
        ...freeConsultation_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {addDrawerOpen && (
          <Drawer
            height="auto"
            anchor="bottom"
            sx={freeConsultation_style.rootSx}
            open={addDrawerOpen}
            onClose={() => setAddDrawerOpen(false)}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, pl: 2.5, pt: 2 }}
            >
              Choose consultation type
            </Typography>
            <Box px={1} py={1}>
              <Box onClick={() => FreeConsultant()}>
                <SelectMemberType
                  title="Free"
                  subTitle={`${profile.remaining_free_consultion} out of ${profile.total_free_consultation} free consultations left`}
                />
              </Box>
              <Box sx={{ mt: '-20px' }} onClick={() => PaidConsultant()}>
                <SelectMemberType
                  title="Paid"
                  subTitle="After viewing the consultation details will be redirected to payment page"
                />
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

FreeConsultation.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { FreeConsultation };
