export const addPatient_style = {
  rootSx: {
    display: 'grid',
    gap: 1.5,
  },

  boxRootSx: {
    display: 'grid',
    gap: 2,
  },

  PersonalTextSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textAlign: 'left',
  },

  inputGroupSx: { display: 'grid', gap: 0.5 },

  vitalsTextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'primary.main',
    textAlign: 'left',
  },

  endAdornmentTextSx: {
    fontSize: '12px',
    color: 'text.disabled',
    opacity: 1,
  },
  inputWidthSx: {
    width: '100%',
    marginTop: '4px',
  },

  inputFieldSx: {
    marginTop: '4px',
  },
  rootVitalSx: {
    mt: 3,
  },
};
