export const leaderBoard_style = {
  cardListSx: {
    height: {
      sm: 400,
      md: 400,
      lg: 515,
      xl: 515,
    },
    overflowY: 'scroll',
  },
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
};
