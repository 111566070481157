/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useChatConsultation = create((set, get) => ({
  chatConsultationState: {
    // DOCTOR FILTER
    doctorSearch: '',
    languages: [],
    speciality: 0,
    doctorAvailablityId: 0,
    // Live doctor list data
    liveDoctorList: [],
    messageJSON: [],
    messageType: '',
    liveDoctorCount: 0,
    chatStatus: '',
    chatStatusData: {},
    patientMedicalData: {},
    medicalCondition: [],
    sendVital: null,
    sendMedicalCondition: null,
    chatHistoryData: [],
    chatCounsultationData: {},
    chatHistoryCount: '',
    chatStartDate: '',
    chatEndDate: '',
    isShowChatExtend: false,
    isChatExtend: false,
    requestRaised: false,
    onlineTyping: 'online',
    chatTimer: null,
    typingMessage: '',
    feedBack: '',
    rating: null,
    fileType: '',
    isScroll: false,
    uploadJSON: [],
    error: {
      rating: '',
    },
    // Chat Consultation Message
    patientChatData: [],
  },

  loading: false,
  error: null,

  getLiveDoctorData: async (searchValue, offset, isSearch) => {
    try {
      const { chatConsultationState } = get();
      const {
        doctorName,
        languages,
        speciality,
        doctorAvailablityId,
        liveDoctorList,
      } = chatConsultationState;

      set({ loading: true });
      const payloadData = {
        search: searchValue ?? doctorName,
        speciality_filter: speciality ?? 0,
        language_filter: languages ?? [],
        doctor_status_filter: doctorAvailablityId ?? 0,
        limit: 10,
        offset: offset ?? 0,
      };
      // Hitting the get live doctor lisiting API
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getLiveDoctorData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/chatConsultation/live-doctors/get/all`,
            { ...payloadData },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        if (isSearch) {
          set(() => ({
            chatConsultationState: {
              ...chatConsultationState,
              liveDoctorList:
                Array.isArray(data?.live_doctors) &&
                data?.live_doctors?.length > 0
                  ? data?.live_doctors
                  : [],
              liveDoctorCount: data?.count ?? 0,
            },
          }));
        } else {
          set(() => ({
            chatConsultationState: {
              ...chatConsultationState,
              liveDoctorList:
                Array.isArray(data?.live_doctors) &&
                data?.live_doctors?.length > 0
                  ? [...liveDoctorList, ...data?.live_doctors]
                  : Array.isArray(liveDoctorList) && liveDoctorList?.length > 0
                  ? liveDoctorList
                  : [],
              liveDoctorCount: data?.count ?? 0,
            },
          }));
        }
      }
      return set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  updateChatConsultationState: (key, value) => {
    const { chatConsultationState } = get();
    set({
      chatConsultationState: {
        ...chatConsultationState,
        [key]: value,
      },
    });
    if (key === 'chatStatusData') {
      localStorage.setItem('clinicalChatStatusData', JSON.stringify(value));
    }
  },

  getLocalStorage: () => {
    const { chatConsultationState } = get();
    const data = localStorage.getItem('clinicalChatStatusData');
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        chatConsultationState: {
          ...chatConsultationState,
          chatStatusData: jsonData,
        },
      });
    }
  },

  SendFeedback: async () => {
    const { chatConsultationState, clearFeedBack } = get();
    const { feedBack, rating } = chatConsultationState;
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/chatConsultation/feedback/submit`,
        {
          chat_consultation_id: localStorage.getItem('chatConsultationId'),
          rating: rating ?? 1,
          feedback_content: feedBack,
        },
        true,
      );
      if (data.status.code === 200) {
        clearFeedBack();
      }
      set({ loading: false });
      return data.status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getChatHistoryDataById: async (chat_consltation_id, chatEnd) => {
    const { chatConsultationState } = get();

    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_history_by_id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatConsultation/message/get/all?chat_consultation_id=${chat_consltation_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      let result = [];
      if (status.code === 200) {
        const resArr = [];
        const resArr2 = [];
        let date = '';
        let date1 = '';

        const isChatEnd = chatEnd === 'OnGoing' ? false : true;
        for (const object of data?.message_data) {
          if (
            new Date(data?.message_data[0]?.createdAt).getDate() ===
            new Date(object?.createdAt).getDate()
          ) {
            if (
              (object?.message_type === 1 && object?.message?.length > 0) ||
              object?.message_type !== 1
            ) {
              const obj = {
                user:
                  object?.sender_profile?.user_id === tokenData?.user_id
                    ? 'patient'
                    : 'doctor',
                type: object?.message_type ?? '',
                message: object?.message ?? '',
                message_json: object?.message_json,
                senderName: object?.sender_profile?.first_name ?? '',
                date: object?.createdAt ?? '',
                is_seen: false,
                ...object,
              };

              date = object?.createdAt ?? '';

              resArr?.push(obj);
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (
              (object?.message_type === 1 && object?.message?.length > 0) ||
              (object?.message_type !== 1 && object?.message?.length === 0)
            ) {
              const obj = {
                user:
                  object?.user_id === tokenData?.user_id ? 'patient' : 'doctor',
                type: object?.message_type ?? '',
                message: object?.message ?? '',
                message_json: object?.message_json,
                senderName: object?.sender_profile?.first_name ?? '',
                date: object?.createdAt ?? '',
                is_seen: false,
                ...object,
              };

              date1 = object?.createdAt ?? '';

              resArr2?.push(obj);
            }
          }
        }

        if (resArr?.length > 0 && resArr2.length > 0) {
          result = [
            {
              date,
              isEnd: isChatEnd,
              messageData: resArr,
            },
            {
              date: date1,
              isEnd: isChatEnd,
              messageData: resArr2,
            },
          ];
        } else {
          result = [
            {
              date,
              isEnd: isChatEnd,
              messageData: resArr,
            },
          ];
        }

        set({
          loading: false,
          chatConsultationState: {
            ...chatConsultationState,
            patientChatData: resArr?.length > 0 ? result : [],
          },
        });
      }

      const resData = {
        status,
        result,
      };

      return resData;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getMedicalCardDataById: async () => {
    const { chatConsultationState } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);

    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_medical_card_data_by_id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatConsultation/medical-card/get?user_id=${tokenData?.user_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status.code === 200) {
        let resArr = [];
        if (
          data?.medical_condition?.medical_active_medications[0] !==
          'No Medications'
        ) {
          resArr.push({
            key: 'medical_active_medications',
            value: 'Medications',
          });
        }

        if (data?.medical_condition?.medical_allergies[0] !== 'No Allergies') {
          resArr.push({
            key: 'medical_allergies',
            value: 'Allergies',
          });
        }

        if (
          data?.medical_condition?.medical_health_problems[0] !==
          'No Health Problems'
        ) {
          resArr.push({
            key: 'medical_health_problems',
            value: 'Health Problems',
          });
        }
        set({
          loading: false,
          chatConsultationState: {
            ...chatConsultationState,
            patientMedicalData: data,
            medicalCondition: resArr,
          },
        });
      }

      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getChatCounsultationById: async (counsultation_id) => {
    const { chatConsultationState } = get();

    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_data_by_id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatConsultation/details/get?chat_consultation_id=${counsultation_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status.code === 200) {
        set({
          chatConsultationState: {
            ...chatConsultationState,
            chatCounsultationData: data,
          },
        });
      }
      set({ loading: false });
      const result = {
        data,
        status,
      };
      return result;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getChatHistoryData: async (searchValue, offset, event) => {
    const { chatConsultationState } = get();
    const { chatStatusData } = chatConsultationState;
    try {
      set({ loading: true });

      const payloadData = {
        search: searchValue,
        from_date: chatConsultationState?.chatStartDate ?? '',
        to_date: chatConsultationState?.chatEndDate ?? '',
        limit: 10,
        offset: offset ?? 0,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_history'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/chatConsultation/history/get`,
            {
              ...payloadData,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === 200) {
        const resArr = [
          {
            key: 'active_chats',
          },
          {
            key: 'past_chats',
          },
        ];

        const result = [];
        for (const obj of resArr) {
          const array = [];
          for (const object of data?.[obj?.key]) {
            if (obj?.key === 'active_chats') {
              const objVal = {
                avatar: object?.profile_pic ?? '',
                userName: object?.first_name ?? '',
                chatTime: object?.chat_starts_at ?? '',
                status: object?.consultation_status_name ?? '',
                ...object,
              };
              array?.push(objVal);
            } else {
              const objVal = {
                avatar: object?.profile_pic ?? '',
                userName: object?.first_name ?? '',
                chatTime: object?.chat_ends_at ?? '',
                status: object?.consultation_status_name ?? '',
                ...object,
              };
              array?.push(objVal);
            }
          }
          const objVal = {
            type: obj?.key === 'active_chats' ? 'Active Chats' : 'Past Chats',
            doctorActiveData: array ?? '',
          };
          result.push(objVal);
        }

        set({
          loading: false,
          chatConsultationState: {
            ...chatConsultationState,
            chatHistoryData: result,
            chatHistoryCount: data?.past_chats_count ?? '',
          },
        });
      }
      set({
        loading: false,
      });
      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  changeState: (value) => {
    const { chatConsultationState } = get();
    set({
      chatConsultationState: { ...chatConsultationState, feedBack: value },
    });
  },

  changeRating: (rate) => {
    const { chatConsultationState } = get();
    set({ chatConsultationState: { ...chatConsultationState, rating: rate } });
  },

  isValid: () => {
    const { chatConsultationState } = get();
    const { feedBack, rating, error } = chatConsultationState;
    let valid = true;
    const Error = structuredClone(error);
    if (rating === null) {
      valid = false;
      Error.rating = 'Please select your rating';
    } else {
      Error.rating = '';
    }
    set({
      chatConsultationState: { ...chatConsultationState, error: { ...Error } },
    });
    return valid;
  },
  clearFeedBack: () => {
    const { chatConsultationState } = get();
    set({
      chatConsultationState: {
        ...chatConsultationState,
        feedBack: '',
        rating: null,
        error: {
          rating: '',
        },
      },
    });
  },
}));
