import BgMoodSelection from '@assets/bgMoodSelection.svg';

export const moodForYou_style = {
  rootSx: {
    minHeight: '100vh',
    backgroundImage: `url(${BgMoodSelection})`,
    backgroundColor: 'purple.400',
    backgroundRepeat: 'no-repeat',
  },
  containerSx: {},
  TotalSx: {
    textAlign: 'center',
    pt: 3.4,
    pb: 3.4,
  },
  totalEmojiSx: {
    pt: 5.5,
    backgroundColor: 'common.white',
    borderRadius: '24px 24px 0px 0px',
  },
  titleSx: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'text.primary',
    pb: 1.25,
  },
  subTitleSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'mbf.main',
  },
  footerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 3,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
  },
  skipSx: {
    backgroundColor: 'purple.400',
    color: 'mbf.main',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'purple.400',
    },
  },
  updateSx: {
    backgroundColor: 'mbf.main',
    '&:hover': {
      backgroundColor: 'mbf.main',
    },
    '&.Mui-disabled': {
      backgroundColor: '#c7bef8',
      color: '#fff',
    },
    borderRadius: '8px',
  },
};
