import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { otpInput_style } from './style';

export function OTP(props) {
  const {
    handleChange = () => false,
    value,
    isError,
    className = '',
    numInputs = 4,
    ...rest
  } = props;

  return (
    <OtpInput
      data-testid="Otp"
      className={`${className}`}
      {...rest}
      value={value}
      onChange={handleChange}
      numInputs={numInputs}
      separator={<span>&nbsp;&nbsp;&nbsp;</span>}
      isInputNum
      shouldAutoFocus
      hasErrored={isError}
      inputStyle={otpInput_style.inputStyle}
      focusStyle={{
        border: '2px solid',
        borderColor: '#007965',
        outline: 'none',
      }}
    />
  );
}
OTP.propTypes = {
  handleChange: PropTypes.func,
  isError: PropTypes.bool,
  numInputs: PropTypes.string,
  value: PropTypes.string,
  focusStyle: PropTypes.bool,
  className: PropTypes.node,
  inputStyle: PropTypes.node,
  onChange: PropTypes.func,
};
