/* eslint-disable react/no-array-index-key */
import BeltyIcon from '@assets/belty.png';
import FoodIcon from '@assets/greenSalad.png';
import SimmasanamIcon from '@assets/simmasana.png';
import Box from '@mui/material/Box';
import React from 'react';
import { CircularProgressWithLabel } from '../../../../../atoms';
import { PerformanceStats } from '../../perfomanceStats';

export function PerfomanceStatsMapping(props) {
  const { mindStatus, bodyStatus, foodStatus } = props;

  const data = [
    {
      value: mindStatus,
      icon: SimmasanamIcon,
      title: 'Mind',
      subTitleText: 'All problems are illusions of the minds',
      color: 'sky.800',
      bgColor: 'sky.50',
      backColor: 'sky.200',
      subTextColor: 'sky.600',
    },
    {
      value: bodyStatus,
      icon: BeltyIcon,
      title: 'Body',
      subTitleText: 'The human body is simply the most beautiful complexity',
      color: 'lime.800',
      bgColor: 'lime.50',
      backColor: 'lime.200',
      subTextColor: 'lime.600',
    },
    {
      value: foodStatus,
      icon: FoodIcon,
      title: 'Food',
      subTitleText: 'Food is symbolic of love when words are inadequate',
      color: 'amber.800',
      bgColor: 'orangeTints.200',
      backColor: 'amber.200',
      subTextColor: 'amber.600',
    },
  ];

  return (
    <Box>
      {data.map((val, index) => (
        <Box mt={2} key={index}>
          <PerformanceStats
            headerCircular={
              <CircularProgressWithLabel
                value={val?.value !== null ? val?.value : 0}
                size={85}
                thickness={5}
                color={{
                  mainColor: val?.color,
                  backgroundColor: val?.backColor,
                }}
              />
            }
            titleText={val?.title}
            subTitleText={val?.subTitleText}
            icon={val?.icon}
            cardRootStyle={{ bgcolor: val?.bgColor }}
            textStyle={{
              color: val?.color,
            }}
            subtextStyle={{
              color: val?.subTextColor,
            }}
          />
        </Box>
      ))}
    </Box>
  );
}
