import { differenceInYearsFncChat } from '@hc/dayjs';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DollorIcon, LanguageIcon } from '../../../atoms';
import { clinicalChatListCard_style } from './style';

function ClinicalChatListCard(props) {
  const {
    className = '',
    rootStyle = {},
    profilePicture = '',
    name = '',
    speciality = '',
    experience = '',
    status = 0,
    chatFee = '',
    language = '',
    data = {},
    onChatClick = () => {},
    ...rest
  } = props;

  console.log(speciality, 'speciality');

  return (
    <Box
      sx={{
        ...clinicalChatListCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={clinicalChatListCard_style?.cardRootSx}>
        <Grid container>
          <Grid xs={2} item>
            <Avatar
              sx={{ height: '44px', width: '44px', alignSelf: 'center' }}
              src={profilePicture ?? ''}
            >
              {name?.length > 0 ? name?.slice(0, 1) : 'A'}
            </Avatar>
          </Grid>
          <Grid xs={8} alignSelf="center" item>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                {name ? `Dr. ${name}` : ''}
              </Typography>
              <Box display="flex">
                <Box>
                  {speciality?.length > 0 &&
                    speciality?.map((val, i) => (
                      <Typography key={i} fontSize={12} color="#888888">
                        {` • ${val ?? ''}`}
                      </Typography>
                    ))}
                </Box>

                <Typography ml={3} fontSize={12} color="#888888">{` • ${
                  experience ? differenceInYearsFncChat(experience) : ''
                }`}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2} display="flex" justifyContent="end" item>
            {status === 1 ? (
              <Box
                sx={{
                  bgcolor: '#E8F4F1',
                  borderRadius: '4px',
                  height: '19px',
                  display: 'flex',
                  px: 0.5,
                }}
              >
                <Typography alignSelf="center" fontSize={10} color="#239179 ">
                  Online
                </Typography>
              </Box>
            ) : status === 2 ? (
              <Box
                sx={{
                  bgcolor: '#FFF6F0',
                  borderRadius: '4px',
                  px: 0.5,
                  display: 'flex',
                  height: '19px',
                }}
              >
                <Typography alignSelf="center" fontSize={10} color="#EB6D13">
                  Busy
                </Typography>
              </Box>
            ) : status === null ? (
              <Box
                sx={{
                  bgcolor: '#FEEAEA',
                  borderRadius: '4px',
                  px: 0.5,
                  display: 'flex',
                  height: '19px',
                }}
              >
                <Typography alignSelf="center" fontSize={10} color="#F44F5A">
                  Offline
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid xs={7} item>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box mr="6px" alignSelf="center">
                    <DollorIcon />
                  </Box>
                  <Typography
                    alignSelf="center"
                    fontSize={12}
                    fontWeight={500}
                    color="#5C6266"
                  >
                    {chatFee}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box mr="6px" alignSelf="center">
                    <LanguageIcon rootStyle={{ color: '#888888' }} />
                  </Box>

                  <Box display="flex">
                    {/* {language?.map((value, i) => ( */}
                    <Typography
                      alignSelf="center"
                      fontSize={12}
                      color="#5C6266"
                      fontWeight={500}
                      // key={i}
                    >
                      {language}
                      {/* {`${value} ${
                          data?.languages?.length - 1 !== i ? ',' : ''
                        }`} */}
                    </Typography>
                    {/* ))} */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={5} display="flex" item>
            <Button
              variant="outlined"
              sx={clinicalChatListCard_style?.buttonSx}
              onClick={() => onChatClick(data)}
              disabled={status === 1 ? false : true}
            >
              Chat
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ClinicalChatListCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onChatClick: PropTypes.func,
  profilePicture: PropTypes.string,
  name: PropTypes.string,
  speciality: PropTypes.string,
  experience: PropTypes.string,
  status: PropTypes.number,
  chatFee: PropTypes.string,
  language: PropTypes.string,
  data: PropTypes.object,
};

export { ClinicalChatListCard };

