import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import { ForwardIconBtn } from '@hc/ui/atoms';
import { SkeletonCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { awareness_style } from './style';

export default function Awareness() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const { awarenessState, getAwarenessData, updateAwarenessState, loading } =
    useAwareness(
      (state) => ({
        updateAwarenessState: state.updateAwarenessState,
        awarenessState: state.awarenessState,
        getAwarenessData: state.getAwarenessData,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { awarenessData } = awarenessState;
  const { awareness } = awarenessData;
  const { high_priority_awareness } = awarenessData;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getAwarenessData'],
    });
    await getAwarenessData();
  };

  // GET AWARENESS LIST DATA
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Screenlayout
      appBarStyle={{
        bgcolor: '#239179',
        borderBottom: '1px solid #239179',
      }}
      backIconStyle={{ color: '#239179' }}
      masterchildStyle={{ pb: 4, backgroundColor: '#fff' }}
      title="Awareness"
      notshowFooter
      backRequired={data?.user_id}
      childrenStyle={{
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <Box p={1}>
        <Box sx={awareness_style.boxRootSx}>
          <Typography
            pl={2}
            mb={0.5}
            color="#0E1824"
            sx={{ fontSize: 16, fontWeight: 600 }}
          >
            Topic in Trends
          </Typography>
          <Typography
            ml={2}
            mb={2}
            color="#888888"
            sx={{ fontSize: 12, fontWeight: 500 }}
          >
            What aspects of awareness are you curious about?
          </Typography>
          <Box mt={1}>
            <Slider {...settings}>
              {Array.isArray(high_priority_awareness) &&
                high_priority_awareness?.length > 0 &&
                high_priority_awareness.map((val, i) => (
                  <Box
                    mx={1}
                    key={i}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (data?.user_id) {
                        navigate(clinicalRoutes?.awarenessCategory);
                      } else {
                        navigate('/awarenesscategory');
                      }
                      updateAwarenessState('awareness_id', val?.awareness_id);
                      updateAwarenessState(
                        'awareness_name',
                        val?.awareness_name,
                      );
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        borderRadius: '10px',
                      }}
                    >
                      <img
                        src={val?.high_priority_awareness_image}
                        loading="lazy"
                        alt="Awarenesss"
                        style={{
                          borderRadius: '10px',
                          width: '190px',
                          height: '130px',
                          objectFit: 'fill',
                          alignSelf: 'center',
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{ ...awareness_style.titleSx, marginTop: '-140px' }}
                    >
                      {val?.awareness_name}{' '}
                    </Typography>
                  </Box>
                ))}
            </Slider>
          </Box>
          {/* EmptyState Design */}
          <Box display="flex" justifyContent="space-between">
            <Typography pl={2} mt={1} color="#0E1824" fontWeight={600}>
              Browse Topics by Category
            </Typography>
          </Box>

          {!loading && (
            <Grid container spacing={0.5} mt={1}>
              {Array.isArray(awareness) &&
                awareness?.length > 0 &&
                awareness.map((val, i) => (
                  <Grid
                    id={`awarnessCard-${i}`}
                    key={i}
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    onClick={() => {
                      if (data?.user_id) {
                        navigate(clinicalRoutes?.awarenessCategory);
                      } else {
                        navigate('/awarenesscategory');
                      }
                      updateAwarenessState('awareness_id', val?.awareness_id);
                      updateAwarenessState(
                        'awareness_name',
                        val?.awareness_name,
                      );
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        borderRadius: '8px',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={val?.awareness_image}
                        loading="lazy"
                        alt="Awarenesss"
                        style={{
                          borderRadius: '10px',
                          width: '190px',
                          height: '130px',
                          objectFit: 'fill',
                          marginBottom: '16px',
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        sx={{
                          ...awareness_style.titleSx,
                        }}
                      >
                        {val?.awareness_name}{' '}
                      </Typography>
                      <Box sx={awareness_style.navSx}>
                        <ForwardIconBtn />
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      </Box>
      {/* Loader */}
      {loading &&
        Array(2)
          .fill(1)
          .map((val, i) => (
            <SkeletonCard key={i} award color="#8ee0d3" awardTitle={false} />
          ))}
    </Screenlayout>
  );
}
