import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { clinicalRoutes } from '@hc/routes';
import { DumbleSmallIcon } from '../../../../atoms/icons/iconSet8';
import SleepingPanda from '../../../../assets/SleepingPanda.svg';
import { mbfEmptyState_style } from './style';

function MbfEmptyState(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ...mbfEmptyState_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack alignItems="center">
        <img
          src={SleepingPanda}
          width="173px"
          height="160px"
          alt="SleepingPanda"
        />
        <Typography textAlign="center" fontSize="12px" color="#5C6266" fontWeight={500}>
          Your <span style={{color:"#0E1824",fontWeight:600}}>plan</span> has
         <span style={{color:"#0E1824",fontWeight:600}}> ended</span>, get a new plan to
          <br /> continue your wellness
          journey
        </Typography>
        <Typography
          mt={2}
          textAlign="center"
          fontSize="14px"  
          color="mbf.main"
          fontWeight="500"
          sx={mbfEmptyState_style.getBtn}
          onClick={() => navigate(clinicalRoutes.PlanEvaluation)}
        >
         <DumbleSmallIcon/>  Get plan
        </Typography>
      </Stack>
    </Box>
  );
}

MbfEmptyState.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MbfEmptyState };
