export const foodActivityUpdate_style = {
  activeSx: {
    opacity: 1,
    color: 'border.focus',
    fontSize: '12px',
  },
  radioSx: {
    color: 'text.secondary',
    fontSize: '12px',
  },
  nameInactiveSx: {
    fontWeight: 500,
    color: 'text.hint',
  },
  nameActiveSx: {
    fontWeight: 600,
    color: 'text.primary',
  },

  buttonactiveSx: {
    color: 'text.primary',
    fontWeight: 600,
    bgcolor: '#FBD74A',
    borderColor: '#FBD74A',
    p: 1.5,
    borderRadius: '8px',
    '&:hover': {
      bgcolor: '#FBD74A',
    },
  },

  buttoninactiveSx: {
    fontSize: '12px',
    border: '1px solid',
    borderColor: '#00000014',
    p: 1.5,
    borderRadius: '8px',
    bgcolor: '#FFFFFF',
  },
};
