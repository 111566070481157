import { Box, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { previousStatsNav_style } from './style';
import { BackArrowIcon, ForwardArrowIcon } from '../../../../atoms';

function PreviousStatsNav(props) {
  const {
    className = '',
    rootStyle = {},
    onNext = () => {},
    onBack = () => {},
    plan = '',
    startAndEndDate = '',
    active,
    ...rest
  } = props;
  return (
    <Box
      sx={{
        ...previousStatsNav_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          disableRipple
          disableFocusRipple
          sx={{
            ...previousStatsNav_style.arrowBtnSx,
            backgroundColor: !active ? '#DFD8FF' : '#00000014',
          }}
          onClick={() => onBack()}
        >
          <BackArrowIcon
            rootStyle={{ color: !active ? '#6552CC' : '#aaafb2' }}
          />
        </IconButton>
        <Box>
          <Typography textAlign="center" sx={previousStatsNav_style.planTitle}>
            {plan}
          </Typography>
          <Typography sx={previousStatsNav_style.PlanstartEndDate}>
            {startAndEndDate}
          </Typography>
        </Box>
        <IconButton
          disableRipple
          disableFocusRipple
          sx={{
            ...previousStatsNav_style.arrowBtnSx,
            backgroundColor: active ? '#DFD8FF' : '#00000014',
          }}
          onClick={() => onNext()}
        >
          <ForwardArrowIcon
            rootStyle={{ color: active ? '#6552CC' : '#aaafb2' }}
          />
        </IconButton>
      </Stack>
    </Box>
  );
}

PreviousStatsNav.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PreviousStatsNav };
