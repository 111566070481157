import { envConfig } from '@hc/config';
import {
  getDateFormat,
  getDateFormatWithoutDate,
  getTimeZoneFnc
} from '@hc/dayjs';
import { log } from '@hc/logger';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useMBFMyWellnessPlanSlice = create((set, get) => ({
  wellnessPlanData: {
    planstatus: {},
    planConstructData: {},
    startDate: null,
    endDate: null,
    selectedDate: getDateFormatWithoutDate('YYYY-MM-DD'),
    calenderData: {
      startofWeek: '',
      endofWeek: '',
      betweenDates: [],
    },
    todayActivityData: [],
  },
  planDetails: {
    duration: 0,
    durationType: '',
    totalActivities: 0,
  },
  loading: false,
  planActivityDataUpsertLoading: false,
  error: null,
  getwellnessplan: async (payload) => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        // queryKey: ['wellnessPlan', `${payload.start_date}-${payload.end_date}`],
        queryKey: ['wellnessPlan'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/wellness-plan/get`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const { wellnessPlanData, constructDataState } = get();
      const res = await constructDataState(wellnessPlanData.selectedDate, data);
      set({
        loading: false,
        wellnessPlanData: {
          ...wellnessPlanData,
          planstatus: data,
        },
      });
      if (status?.code === 200) {
        set({ loading: false });
        // toast.success(status?.message);
        return res;
      }
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getPlanDetails: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const response = await queryClient.fetchQuery({
        queryKey: ['planDetails'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/plan-details/get?user_id=${tokenData?.user_id}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (response?.status?.code === 200 && response?.data?.total_activities) {
        return set({
          planDetails: {
            duration: response?.data?.duration ?? 0,
            durationType: response?.data?.duration_type ?? '',
            totalActivities: response?.data?.total_activities ?? 0,
          },
          loading: false,
        });
      }
      set({ loading: false });
      return toast.error(response?.data?.status?.message ?? '');
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // Update Start End Date
  updateStartEndDate: (start, end) => {
    const { wellnessPlanData } = get();
    set({
      wellnessPlanData: {
        ...wellnessPlanData,
        startDate: start,
        endDate: end,
      },
    });
  },

  // Start and End Between
  setStartEndBetween: (start, end, betweenDates) => {
    const { wellnessPlanData } = get();
    set({
      wellnessPlanData: {
        ...wellnessPlanData,
        calenderData: {
          startofWeek: start,
          endofWeek: end,
          betweenDates: betweenDates?.map((i) => i.toISOString()),
        },
      },
    });
  },

  // Construct Data
  constructDataState: (seDate, value) => {
    const { wellnessPlanData } = get();
    const wellnessPlanDataCopy = JSON.parse(JSON.stringify(wellnessPlanData));
    wellnessPlanDataCopy.todayActivityData = [];
    if (Array.isArray(value) && value?.length > 0 && seDate) {
      // eslint-disable-next-line no-restricted-syntax
      for (const val of value) {
        if (getDateFormat(val?.date, 'YYYY-MM-DD') === seDate) {
          wellnessPlanDataCopy.todayActivityData.push(val);
        }
      }
    }

    // if (Array.isArray(value?.[seDate]) && value?.[seDate]?.length > 0) {
    //   value?.[seDate].map((val) => {
    //     const obj = {
    //       id: val?.id ?? '',
    //       activityId: val?.user_daily_activity?.id ?? '',
    //       name: val?.master_activity?.activity_name,
    //       image: val?.master_activity?.activity_image,
    //       // val?.master_activity?.activity_name === 'Sleep'
    //       //   ? Sleeping
    //       //   : val?.master_activity?.activity_name === 'Drinking water'
    //       //   ? DrinkingWater
    //       //   : val?.master_activity?.activity_name === 'Yoga'
    //       //   ? Yoga
    //       //   : val?.master_activity?.activity_name === 'Stretches'
    //       //   ? Stretch
    //       //   : val?.master_activity?.activity_name === 'Meditation'
    //       //   ? Meditation
    //       //   : val?.master_activity?.activity_name === 'Jogging'
    //       //   ? Jogging
    //       //   : val?.master_activity?.activity_name === 'Brisk walking'
    //       //   ? BriskWalking
    //       //   : val?.master_activity?.activity_name === 'Bicycle riding'
    //       //   ? BicycleRiding
    //       //   : val?.master_activity?.activity_name === 'Muscle-strengthening'
    //       //   ? MuscleStrengthening
    //       //   : BodyWeightExercises,
    //       time: `${val?.master_activity?.quantity} ${val?.master_activity?.unit} a day `,
    //       timeOfDay: val?.master_activity?.schedule,
    //       unit: val?.master_activity?.unit,
    //       mbfStatus: val?.master_activity?.type,
    //       min: 0,
    //       max: val?.master_activity?.quantity,
    //       progress: val?.quantity_value,
    //       quantity: val?.master_activity?.quantity,
    //       is_measurable: val?.master_activity?.is_measurable,
    //     };
    //     wellnessPlanDataCopy.todayActivityData.push(obj);
    //   });
    // }
    set({
      wellnessPlanData: {
        ...wellnessPlanData,
        ...JSON.parse(JSON.stringify(wellnessPlanDataCopy)),
      },
    });

    return wellnessPlanDataCopy?.todayActivityData;
  },

  // UpdatePlan State
  updatePlanState: (key, value) => {
    const { wellnessPlanData } = get();
    set({
      wellnessPlanData: {
        ...wellnessPlanData,
        [key]: value,
      },
    });
  },

  planActivityDataUpsert: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ planActivityDataUpsertLoading: true });
      const response = await queryClient.fetchQuery({
        queryKey: ['planActivityDataUpsert'],
        queryFn: async () => {
          const { status } = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/plan/daily-activity/add`,
            {
              user_id: tokenData?.user_id ?? '',
              timeZone: getTimeZoneFnc(),
            },
            true
          );
          return status;
        },
        staleTime: Infinity,
      });
      if (response === 200) {
        set({
          planActivityDataUpsertLoading: false,
        });
        return response;
      }
      set({ planActivityDataUpsertLoading: false });
    } catch (error) {
      set({ planActivityDataUpsertLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
