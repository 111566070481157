export const doctorProfileCard_style = {
  rootSx: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none',
  },
  footerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnSx: {
    backgroundColor: 'common.white',
    color: 'primary.main',
    border: '2px solid',
    borderColor: 'border.focus',
    boxShadow: 'none',
    px: 1,
    py: 1,
    borderRadius: 2,
    width: '108px',
    '&:hover': {
      backgroundColor: 'common.white',
      color: 'primary.main',
    },
  },
  dividerSx: {
    my: 1.25,
    borderStyle: 'dashed',
    color: 'grey.400',
  },
};
