export const appointmentSummary_style = {
  // rootSx: {
  //   p: 2,
  // },
  nameStyle: {
    alignItems: 'center',
  },

  dividerSx: {
    mt: 1.2,
    borderStyle: 'dashed',
    borderColor: 'divider',
  },
};
