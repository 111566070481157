import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { RiSendPlaneFill } from 'react-icons/ri';
import { AlarmClockIcon, Input } from '../../../atoms';
import { chatBotMessageBox_style } from './style';

function ChatBotMessageBox(props) {
  const {
    className = '',
    placeholder = '',
    value = '',
    children,
    showMessageTimer = true,
    height = '',
    remainingChatCount,
    historyDeleteTime,
    onChange = () => false,
    handleClick = () => false,
    rootStyle,
    isScroll,
    ...rest
  } = props;

  const [isSelected, setIsSelected] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      if (isScroll) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [children]);

  return (
    <Box
      sx={{
        ...chatBotMessageBox_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          backgroundColor: 'background.surface.100',
          borderRadius: '10px',
          py: 2,
        }}
      >
        <Box
          ref={scrollRef}
          sx={{
            height,
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
        <div
          style={{ height: '126px' }}
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            handleClick(false, {});
          }}
        >
          {showMessageTimer && value?.length === 0 && (
            <Stack
              mb="10px"
              mt="10px"
              px={4}
              direction="row"
              alignItems="self-start"
              justifyContent="center"
              gap="5px"
            >
              <AlarmClockIcon />
              <Typography
                sx={{ color: '#BDBDBD', fontSize: '12px', fontStyle: 'italic' }}
              >
                {`Conversation is closed, chat history will be cleared after 48
                hrs.`}
              </Typography>
            </Stack>
          )}

          <Box px={1.5} pb={1} >
         
            <Typography sx={{ color: '#BDBDBD', fontSize: '12px' }}>
              {`Your remaining chat count for this month ${remainingChatCount}`}
            </Typography>
            <Input
              textFieldStyle={chatBotMessageBox_style?.textRootSx}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
              endAdornment={
                <Box
                  onClick={() => (isSelected ? handleClick() : null)}
                  style={{ cursor: 'pointer', display: 'flex' }}
                >
                  <RiSendPlaneFill
                    color={value?.length > 0 ? '#aeefe5' : 'rgb(200 205 204)'}
                  />
                </Box>
              }
              disabled={!remainingChatCount}
              onClick={() => setIsSelected(true)}
              onFocus={() => setIsSelected(true)}
              onBlur={
                value.length > 0
                  ? () => setIsSelected(true)
                  : () => setIsSelected(false)
              }
              value={value}
            />
            
          </Box>
        </div>
      </Box>
    </Box>
  );
}

ChatBotMessageBox.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.element,
  showMessageTimer: PropTypes.bool,
  isScroll: PropTypes.bool,
  height: PropTypes.string,
  historyDeleteTime: PropTypes.string,
  onChange: PropTypes.func,
  handleClick: PropTypes.func,
  remainingChatCount: PropTypes.any,
};

export { ChatBotMessageBox };
