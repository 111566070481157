/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { envConfig } from '@hc/config';
import { useAppointment } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { Box } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';

function JitsiMeetEmbed(props) {
  const {
    onReadyToClose = () => {},
    patient,
    roomId = '',
    doctor,
    apptId = '',
    minimizeMeetWindow,
    setResumeConsult = () => {},
    minHeight = '',
    maxHeight = '',
    jitsiId = '',
  } = props;

  const tokenData = localStorage.getItem(localStorageKeys.authToken);
  const parseTokenData = parseJwt(tokenData);

  const { appointmentStatusChange, patientStartTime, DoctorStartTime } =
    useAppointment((state) => ({
      appointmentStatusChange: state.appointmentStatusChange,
      patientStartTime: state.patientStartTime,
      DoctorStartTime: state.DoctorStartTime,
    }));

  const onApiReady = (externalApi) => {
    toast.dismiss();
    externalApi.on('videoConferenceLeft', async () => {
      onReadyToClose();
      if (patient) {
        const payload = {
          id: apptId,
          patient_joining_date_time: '',
          patient_leaving_date_time: moment().format(),
          doctor_joining_date_time: '',
          doctor_leaving_date_time: '',
        };
        await appointmentStatusChange(payload);
        externalApi.dispose();
      }
      if (doctor) {
        const payload = {
          id: apptId,
          patient_joining_date_time: '',
          patient_leaving_date_time: '',
          doctor_joining_date_time: '',
          doctor_leaving_date_time: moment().format(),
        };
        await appointmentStatusChange(payload);
        externalApi.dispose();
      }
    });
    externalApi.on('videoConferenceJoined', async () => {
      localStorage.setItem('startCall', 1);
      if (patient) {
        if (!patientStartTime) {
          const payload = {
            id: apptId,
            patient_joining_date_time: moment().format(),
            patient_leaving_date_time: '',
            doctor_joining_date_time: '',
            doctor_leaving_date_time: '',
          };
          await appointmentStatusChange(payload);
        }
      }
      if (doctor) {
        setResumeConsult(true);
        if (!DoctorStartTime) {
          const payload = {
            id: apptId,
            patient_joining_date_time: '',
            patient_leaving_date_time: '',
            doctor_joining_date_time: moment().format(),
            doctor_leaving_date_time: '',
          };
          await appointmentStatusChange(payload);
        }
      }
    });
  };

  useEffect(() => {
    toast.loading('Please wait..!!');
  }, []);

  useEffect(() => {
    const el = document.querySelector(`#jitsiMeeting-${jitsiId}`);
    if (minimizeMeetWindow) {
      el.style.height = minHeight;
      el.style.width = '100%';
      el.style.borderRadius = '0 0 16px 16px';
      el.style.backgroundColor = '#000';
      el.style.border = '2px solid #000';
    } else {
      el.style.height = maxHeight;
      el.style.width = '100%';
      el.style.borderRadius = '0';
      el.style.backgroundColor = '#000';
      el.style.border = '2px solid #000';
    }
  }, [minimizeMeetWindow]);

  return (
    <Box>
      <JitsiMeeting
        // domain="dev-meet.prodkt.co"
        domain={envConfig.jitsi_meet_domain}
        roomName={roomId}
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        userInfo={{
          displayName: doctor
            ? `Dr.${parseTokenData?.name}`
            : parseTokenData?.name
            ? parseTokenData?.name
            : '',
        }}
        onApiReady={(externalApi) => onApiReady(externalApi)}
      />
    </Box>
  );
}

JitsiMeetEmbed.propTypes = {
  onReadyToClose: PropTypes.func,
  minimizeMeetWindow: PropTypes.bool,
  setResumeConsult: PropTypes.func,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  patient: PropTypes.bool,
  roomId: PropTypes.string,
  doctor: PropTypes.bool,
  apptId: PropTypes.string,
  jitsiId: PropTypes.string,
};

export { JitsiMeetEmbed };
