export const appointmentNotifyCard_style = {
  totalBoxSx: {
    border: '2px solid',
    borderColor: 'primary.main',
    borderRadius: '8px',
    p: 1.75,
    bgcolor: '#E6F5F2',
  },
  dateSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 1,
  },
  initialSx: {},
  updateSx: {},
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
  },
  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilepicSx: {
    width: '44px',
    height: '44px',
  },
  professionalContainerSx: {
    display: 'flex',
    width: '170px',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },

  professionalSx: {
    color: 'text.hint',
    fontSize: '12px',
    pt: 0.25,
    whiteSpace: 'nowrap',
  },
  labelSx: {
    color: '#868484',
    fontSize: '12px',
    margin: '0px 2px',
    fontWeight: 600,
  },
  typeSx: {
    display: 'flex',
    alignItems: 'start',
    pt: 0.45,
  },
  btnSx: {
    color: 'primary.main',
    borderRadius: '6px',
    border: '0px solid',
    textTransform: 'none',
    boxShadow: 'none',
    px: 2.25,
    py: 0.8,
    width: '108px',
    '&.Mui-disabled': {
      border: '2px solid #E2E2E2',
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: 'common.white',
      backgroundColor: 'none',
    },
  },
  dateTextSx: {
    color: 'text.secondary',
    fontSize: '10px',
    border: '1px solid',
    borderColor: '#707070',
    backgroundColor: 'common.white',
    p: 0.4,
    borderRadius: '4px',
    fontWeight: 600,
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    mx: 0.75,
    pl: 1,
    pr: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  prescriptionTextSx: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'medium',
    mt: 2.1,
    width: '300px',
    mx: 'auto',
    color: '#0F0B11',
  },
  subtextSx: {
    fontSize: '12px',
    fontWeight: 400,
    width: '237px',
    mt: 2.1,
    mb: 1.85,
    mx: 'auto',
    color: '#0F0B11',
  },
  cancelSx: {
    color: 'grey.700',
    fontSize: '32px',
    position: 'absolute',
    right: '17px',
    top: '-17px',
  },
};
