import { CheckBox, LinearProgressBar } from '@atoms';
import { useQuestionnaire } from '@hc/store';
import { Box, Typography } from '@mui/material';
import { questionnaire_style } from './style';

export function Questionnaire(props) {
  const {
    isActive,
    // selected = false,
    setSelected,
    progressValue,
    className = '',
    ...rest
  } = props;

  const { questionnaire, handleSelect } = useQuestionnaire((state) => ({
    questionnaire: state.questionnaire,
    handleSelect: state.handleSelect,
  }));

  const val = questionnaire?.[progressValue];
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={questionnaire_style.linearBoxStyle}>
        <LinearProgressBar
          linearStyle={{}}
          value={(100 / questionnaire?.length) * (progressValue + 1)}
        />
      </Box>

      <Box sx={questionnaire_style.qusAnsSx}>
        <Typography sx={questionnaire_style.titleSx}>{val?.title}</Typography>
        <Box>
          <Typography sx={questionnaire_style.paraSx}>
            {val?.question}
          </Typography>
          <Box sx={questionnaire_style.totalBoxSx}>
            {val?.options?.map((option, index) => (
              <Box
              key={index}
                sx={{
                  ...questionnaire_style.totalSx,
                  borderColor: option?.selected ? 'mbf.main' : 'purple.600',
                }}
              >
                <Typography
                  sx={{
                    ...questionnaire_style.quesSx,
                    color: option?.selected ? 'mbf.main' : 'common.black',
                    fontWeight: option?.selected ? '600' : '400',
                  }}
                >
                  {option.option}
                </Typography>
                <CheckBox
                  checkStyle={questionnaire_style.checkBoxSx}
                  checkSecondStyle={questionnaire_style.checkSecondBoxSx}
                  onChange={() => handleSelect(progressValue + 1, index)}
                  checked={option?.selected}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
