/* eslint-disable no-lonely-if */
import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import { AwrImgIcon, AwrVideoIcon, ForwardIconAlt } from '@hc/ui/atoms';
import { SkeletonCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { AwarenessCategory_style } from './style';

export default function AwarenessCategory() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const {
    awarenessState,
    getCategoryDataByAwarenessId,
    updateAwarenessState,
    loading,
  } = useAwareness(
    (state) => ({
      updateAwarenessState: state.updateAwarenessState,
      awarenessState: state.awarenessState,
      getCategoryDataByAwarenessId: state.getCategoryDataByAwarenessId,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { categoryData } = awarenessState;

  const getInitialData = async () => {
    await getCategoryDataByAwarenessId(awarenessState?.awareness_id);
  };

  // GET AWARENESS LIST DATA
  useEffect(() => {
    if (awarenessState?.awareness_id) {
      getInitialData();
    } else {
      if (data?.user_id) {
        navigate(clinicalRoutes?.landingPage);
      } else {
        navigate(clinicalRoutes?.home);
      }
    }
  }, []);

  return (
    <Screenlayout
      appBarStyle={{
        bgcolor: '#239179',
        borderBottom: '1px solid #239179',
      }}
      backIconStyle={{ color: '#239179' }}
      masterchildStyle={{ pb: 5, backgroundColor: '#fff' }}
      title={awarenessState?.awareness_name}
      notshowFooter
      backRequired
      backRoute={data?.user_id.length > 0 ? -1 : '/'}
      childrenStyle={{
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <Box sx={AwarenessCategory_style.boxRootSx}>
        {!loading && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '.5fr .5fr',
              gridAutoRows: '220px 220px',
              gap: 3,
              p: 2,
            }}
          >
            {Array.isArray(categoryData) && categoryData?.length > 0 ? (
              categoryData.map((val, i) => (
                <Box
                  id={`categoryData_${i}`}
                  sx={{
                    boxShadow: '0px 4px 10px #0000000A',
                    border: '1px solid #DBEAE8',
                    borderRadius: '8px',
                    pt: 2,
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                  }}
                  key={i}
                >
                  <Box pr={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                    {' '}
                    {val?.note_type === 'video' ? (
                      <AwrVideoIcon />
                    ) : (
                      ''
                      // <AwrImgIcon fill="#666" />
                    )}
                  </Box>

                  <Box
                    onClick={() => {
                      if (data?.user_id) {
                        navigate(clinicalRoutes?.awarenesSlider);
                      } else {
                        navigate('/awarenesscategory/awarenessslide');
                      }

                      updateAwarenessState('cate_id', val?.id);
                      updateAwarenessState(
                        'cate_name',
                        val.awareness_category_name,
                      );
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={val?.awareness_category_image}
                      loading="lazy"
                      alt="Awarenesss"
                      style={{
                        borderRadius: '100%',
                        width: '82px',
                        height: '82px',
                        objectFit: 'cover',
                      }}
                    />
                    <Typography sx={AwarenessCategory_style.titleSx}>
                      {' '}
                      {val?.awareness_category_name}{' '}
                    </Typography>
                    <Box sx={AwarenessCategory_style.navSx}>
                      <ForwardIconAlt />
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                textAlign="center"
                py={15}
                color="#616161"
                fontWeight={600}
              >
                No Category Data Found
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {/* Loader */}
      {loading &&
        Array(3)
          .fill(1)
          .map((val, i) => (
            <SkeletonCard key={i} award color="#8ee0d3" awardTitle={false} />
          ))}
    </Screenlayout>
  );
}
