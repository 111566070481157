import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ChatBotChatIcon, LoadingMessage } from '../../../atoms';
import { chatBotChatListing_style } from './style';

function ChatBotChatListing(props) {
  const {
    className = '',
    isUser = false,
    data = [],
    isInitialMessage,
    loadingMessage,
    onClickMessage = () => {},
    chatStyle,
    url = '',
    altText = '',
    message = '',
    messageTime = new Date(),
    chatRespondStyle,
    profileUrl,
    header = '',
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...chatBotChatListing_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {isUser ? (
        <Box display="flex" justifyContent="end">
          <Box sx={{ ...chatBotChatListing_style.mainBox, ...chatStyle }}>
            <Box>
              <Box sx={chatBotChatListing_style.clipSx}>
                <Typography sx={chatBotChatListing_style.replyTestSx}>
                  {message}
                </Typography>
              </Box>
              <Typography sx={chatBotChatListing_style.timeSx}>
                {moment(messageTime).format('hh:mm A')}
              </Typography>
            </Box>
            <Box sx={chatBotChatListing_style.profileBox}>
              <Avatar
                sx={{
                  height: url ? '25px' : '20px',
                  width: url ? '25px' : '20px',
                  fontSize: '13px',
                }}
                src={profileUrl}
              >
                {altText[0]?.toUpperCase()}
              </Avatar>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            ...chatBotChatListing_style.mainRespondBox,
            ...chatRespondStyle,
          }}
        >
          <Box sx={chatBotChatListing_style.chatBotIconSx}>
            <ChatBotChatIcon />
          </Box>
          <Box flexGrow={1}>
            <Box
              sx={
                isInitialMessage
                  ? chatBotChatListing_style.clipMessageSx
                  : chatBotChatListing_style.clipRespondSx
              }
            >
              <Typography
                sx={
                  isInitialMessage
                    ? chatBotChatListing_style.initialRespondTestSx
                    : chatBotChatListing_style.respondTestSx
                }
              >
                {message.length === 0 && loadingMessage ? (
                  <LoadingMessage />
                ) : isInitialMessage ? (
                  header
                ) : (
                  message && message
                )}
              </Typography>

              {isInitialMessage && (
                <Stack
                  direction="column"
                  divider={
                    <Divider
                      sx={{ borderColor: 'grey.A300' }}
                      orientation="horizontal"
                    />
                  }
                >
                  {data.map((val, i) => (
                    <Typography
                      key={i}
                      onClick={() => onClickMessage(val)}
                      sx={chatBotChatListing_style.startTestSx}
                    >
                      {val}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Box>
            <Typography
              sx={{
                ...chatBotChatListing_style.timeRespondSx,
                visibility: messageTime ? 'visible' : 'hidden',
              }}
            >
              {moment(messageTime).format('hh:mm A')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

ChatBotChatListing.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isUser: PropTypes.bool,
  data: PropTypes.array,
  isInitialMessage: PropTypes.bool,
  loadingMessage: PropTypes.bool,
  onClickMessage: PropTypes.func,
  chatStyle: PropTypes.object,
  url: PropTypes.string,
  altText: PropTypes.string,
  message: PropTypes.string,
  messageTime: PropTypes.string,
  chatRespondStyle: PropTypes.object,
  header: PropTypes.string,
};

export { ChatBotChatListing };

