import { Button } from '@hc/ui/atoms';
import { SymptomsSelection } from '@hc/ui/components/clinical/appointmentBooking/selection/symptoms';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
// import { useBookAppointment } from '@hc/store';
// import isEqual from 'react-fast-compare';
import { useKeyPress } from 'ahooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { symptoms_style } from './style';

export default function Symptoms(props) {
  const { className = '', ...rest } = props;
  // const {
  //   clearPageData,
  // loading: loader,
  // } = useBookAppointment(
  //   (state) => ({
  //     bookAppointmentState: state?.bookAppointmentState,
  //     clearPageData: state.clearPageData,
  //   }),
  //   (prev, curr) => isEqual(prev, curr)
  // );

  // General Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });
  // CLEAR ALLERGY FUNCTION
  // const handleClear = () => {
  // CLEAR ALLERGY
  //   clearPageData('symptoms');
  // };

  // SKIP FUNCTION
  const onSkip = () => {
    // handleClear();
    navigate(-1);
  };

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={onSkip} buttonStyle={symptoms_style.buttonSx}>
            Go back
          </Button>
          <Button onClick={() => navigate(location?.state?.prevRoute)}>
            Save
          </Button>
        </Box>
      }
      {...rest}
    >
      {/* Symptoms */}
      <Box>
        <Box sx={symptoms_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <SymptomsSelection />
        </Box>
      </Box>
    </Screenlayout>
  );
}
