export const clinicalLandingPageSkeleton_style = {
    skeletonRoot: {
        backgroundColor: 'primaryTints.300',
        '&::after': {
          background:
            'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
        },
        borderRadius:'12px'
      },
};
