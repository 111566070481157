import { clinicalRoutes } from '@hc/routes';
import { usePrescription } from '@hc/store';
import { Button, DownloadsIcon, ReportsEmptyState } from '@hc/ui/atoms';
import { ClinicalEmptyState } from '@hc/ui/components';
import {
  PrescDoctorDetailCard,
  PrescPatientDetailCard,
} from '@hc/ui/components/clinical';
import { ActivePrescriptionCard } from '@hc/ui/components/clinical/activePrescriptionCard';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { activePrescriptionCard_style } from './style';

export default function ActivePrescription(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();
  const { prescriptionData } = usePrescription(
    (state) => ({
      prescriptionData: state.prescriptionData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  useEffect(() => {
    if (!prescriptionData) {
      return navigate(clinicalRoutes?.home);
    }
  }, []);

  if (prescriptionData) {
    return (
      <Box
        sx={activePrescriptionCard_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Screenlayout
          title="Prescription"
          backRequired="/"
          childrenStyle={{
            minHeight: '90vh',
            pt: 0,
            backgroundColor: 'grey.100',
          }}
          footer={
            <Box sx={{ gap: 2 }}>
              <a
                href={prescriptionData?.prescriptionDownloadUrl}
                download
                style={{ textDecoration: 'none' }}
              >
                <Button
                  startIcon={
                    <DownloadsIcon
                      rootStyle={{
                        color: 'common.white',
                      }}
                    />
                  }
                >
                  Download
                </Button>
              </a>
            </Box>
          }
        >
          <PrescDoctorDetailCard />
          <Box sx={{ px: 3, mt: 2 }}>
            <PrescPatientDetailCard />
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '14px',
                fontWeight: 'bold',
                py: 2,
              }}
            >
              Prescription
            </Typography>

            {prescriptionData?.prescriptionData?.length > 0 ? (
              prescriptionData?.prescriptionData?.map((val, i) => (
                <ActivePrescriptionCard data={val} key={i} />
              ))
            ) : (
              <ClinicalEmptyState
                IconRequired={<ReportsEmptyState />}
                emptyStateStyle={{ mt: 3 }}
                mainHead="No Prescription Found"
                subHead="Prescription will appear once we upload"
              />
            )}
          </Box>
        </Screenlayout>
      </Box>
    );
  }
}
