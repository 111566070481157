/* eslint-disable react/jsx-key */
import { DocumentIcon } from '@atoms/icons';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from '@atoms';
import { report_custmize_style } from './style';

function CustomizeReportsCard(props) {
  const {
    className = '',
    rootStyle = {},
    header = '',
    uploadData,
    caseSummaryData,
    bodyContent = '',
    allReportDetail = '',
    boxStyle = {},
    // reportCount,
    IsReportUpload,
    ...rest
  } = props;

  const navigate = useNavigate();
  return (
    <Box
      sx={{ ...report_custmize_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...report_custmize_style.rootBoxSx, ...boxStyle }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display="flex">
            <Box pl={0.5}>
              <DocumentIcon />
            </Box>
            <Typography pl={2} fontSize={14} fontWeight={600}>
              {header}
            </Typography>
          </Grid>
          {!IsReportUpload && (
            <Grid pt={3} item xs={12} sm={12} md={12}>
              <Grid container display="flex" justifyContent="center">
                <Grid item xs={5.5} sm={5.5} md={5.5}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography fontSize={20} fontWeight={600}>
                        {uploadData}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography sx={report_custmize_style.dataTextSx}>
                        Selected Reports
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider
                  sx={report_custmize_style.dividerSx}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Grid item xs={5.5} sm={5.5} md={5.5}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography fontSize={20} fontWeight={600}>
                        {caseSummaryData}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography sx={report_custmize_style.dataTextSx}>
                        Case Summaries
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!IsReportUpload && (
            <Grid pt={3} pb={1.5} item xs={12} sm={12} md={12}>
              <Typography sx={report_custmize_style.bodyContentSx}>
                {bodyContent}
              </Typography>
            </Grid>
          )}
          {IsReportUpload && (
            <Grid pt={3} pb={1.5} item xs={12} sm={12} md={12} display="flex">
              <Typography mr={1} sx={report_custmize_style.reportCountSx}>
                {uploadData + caseSummaryData}
              </Typography>
              <Typography sx={report_custmize_style.bodyContentSx}>
                {allReportDetail}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <Button
              onClick={() => {
                navigate(clinicalRoutes.reports, {
                  state: {
                    bookAppoinment: true,
                    name: 'chandru',
                  },
                });
              }}
              buttonStyle={report_custmize_style.buttonRootSx}
            >
              {IsReportUpload ? (
                <Typography py={0.6} sx={report_custmize_style.buttonTextSx}>
                  View Reports
                </Typography>
              ) : (
                <Typography py={0.6} sx={report_custmize_style.buttonTextSx}>
                  Customize Reports
                </Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export { CustomizeReportsCard };

CustomizeReportsCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  header: PropTypes.string,
  uploadData: PropTypes.number,
  caseSummaryData: PropTypes.number,
  // reportCount: PropTypes.number,
  bodyContent: PropTypes.string,
  allReportDetail: PropTypes.string,
  boxStyle: PropTypes.object,
  IsReportUpload: PropTypes.bool,
};
