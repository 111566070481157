import { useBloodGroup, useFamilyMember, useRelationShip } from '@hc/store';
import { AddEditFamilyMember } from '@hc/ui/components/clinical';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';

export function FamilyMemberAddEdit(props) {
  const { handleCheckBox = () => {}, useMyNumber } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const {
    familyMember,
    handleFamilyMemberChange,
    // upsertFamilyMember,
    // updateFamilyMemberError,
    // clearFamilyMemberState,
    // loading,
  } = useFamilyMember(
    (state) => ({
      familyMember: state.familyMember,
      handleFamilyMemberChange: state.handleFamilyMemberChange,
      updateFamilyMemberError: state.updateFamilyMemberError,
      clearFamilyMemberState: state.clearFamilyMemberState,
      loading: state.loading,
      upsertFamilyMember: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    get,
    relationShipData,
    // loading: loader,
  } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE FAMILY MEMBER DATA CAHNGE
    handleFamilyMemberChange(key, value);
  };

  // GET RELATIONSHIP DATA
  useEffect(() => {
    handleFamilyMemberChange('login_user_mobile_number', data?.mobile_no ?? '');
    handleFamilyMemberChange(
      'login_user_country_code',
      data?.country_code ?? ''
    );
    get();
    getBloodGroupData();
  }, []);

  return (
    <Box>
      <AddEditFamilyMember
        familyMember={familyMember}
        relationShipData={
          Array.isArray(relationShipData) ? relationShipData : []
        }
        bloodGroupData={bloodGroupData}
        handleChange={handleChange}
        handleCheckBox={handleCheckBox}
        useMyNumber={useMyNumber}
        isEdit={familyMember?.family_member_id?.length > 0}
      />
    </Box>
  );
}
