export const FreeConsultationCard_style = {
  dateSx: {
    color: 'text.disabled',
    fontSize: '10px',
  },
  dateparentSx: {
    border: '1px dashed #DBDBDB',
    padding: '4px',
    borderRadius: '4px',
    marginTop: '4px',
  },
  rootBoxSx: {
    padding: '0px 12px',
    boxShadow: '0px 4px 10px #0000000A',
    borderRadius: '8px',
    backgroundColor: 'common.white',
  },
};
