import FitFreak from '@assets/fitFreak.svg';
import Flexy from '@assets/flexy.svg';
import { ordinalSuffixOf } from '@hc/utils/helperFunctions';
import { Avatar, AvatarGroup, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Crown } from '../../../../atoms/icons';
import { homeLeaderboard_Style } from './style';

export function HomeLeaderboardCard(props) {
  const { member_data = [], user_rank = '', className = '', ...rest } = props;

  const navigate = useNavigate();

  return (
    <Box className={`${className}`} {...rest}>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'teal.400',
          bgcolor: 'teal.200',
          borderRadius: '8px',
          cursor: 'pointer',
          my: 4,
          px: 1,
          py: 1.5,
        }}
        onClick={() => navigate('/mindbodyfood/leaderboard')}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.8,
            fontSize: '16px',
            fontWeight: 600,
            mb: 1.5,
          }}
        >
          <Crown /> Leaderboard
        </Typography>
        <Box
          sx={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'repeat(2,1fr)',
          }}
        >
          <Box sx={{ justifySelf: 'self-start' }}>
            <AvatarGroup
              max={member_data?.length > 4 ? 4 : member_data?.length}
            >
              {member_data?.map((val, i) => (
                <Avatar
                  key={i}
                  src={
                    val?.profile_pic
                      ? val?.profile_pic
                      : val?.gender === 'Male'
                      ? FitFreak
                      : Flexy
                  }
                  alt={i}
                />
              ))}
            </AvatarGroup>
          </Box>
          <Box sx={{ justifySelf: 'self-end', mr: 2 }}>
            <Stack alignItems="end">
              <BsArrowRight style={{ color: '#0E1824' }} size={32} />
              <Typography sx={homeLeaderboard_Style.leaderboradRankTxt}>
                You are in{' '}
                <span style={homeLeaderboard_Style.leaderboradRankNumTxt}>
                  {' '}
                  {ordinalSuffixOf(user_rank ?? 0)}{' '}
                </span>{' '}
                place
              </Typography>
            </Stack>
          </Box>
          {/* {user_rank !== null && user_rank !== undefined && user_rank !== '' ? (
            <Box px={1.5}>
              <Box sx={{ textAlign: 'end' }}>
                <BsArrowRight />
              </Box>
              <Typography sx={{ fontSize: '14px', color: 'text.hint' }}>
                You are in{' '}
                <Typography
                  as="span"
                  sx={{
                    color: 'text.primary',
                    fontSize: '14px',
                    fontWeight: 600,
                    px: 0.5,
                  }}
                >
                  {' '}
                  {ordinal_suffix_of(user_rank)}
                </Typography>
                Place
              </Typography>
            </Box>
          ) : (
            <Typography sx={{ fontSize: '14px', color: 'text.hint' }}>
              Not yet started your activities!
            </Typography>
          )} */}
        </Box>
      </Box>
    </Box>
  );
}
HomeLeaderboardCard.propTypes = {
  src: PropTypes.object,
  className: PropTypes.object,
};
