export const chartListingInputBox_style = {
  chartInputSx: {
    opacity: 1,
    bgcolor: '#E9E9E9',
    borderRadius: '22px',
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 500,
      fontSize: '14px',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '22px',
        borderColor: '#E9E9E9',
      },
      '&:hover fieldset': {
        borderColor: '#E9E9E9',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#E9E9E9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E9E9E9',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
    '& input::placeholder': {
      fontSize: '14px',
      fontWeight: 400,
      color: '#000',
    },
  },
  chatPayCardSx: {
    borderRadius: '4px',
    bgcolor: '#FFF6F0',
    display: 'flex',
    justifyContent: 'space-between',
    px: 1,
    py: 0.5,

    position: 'fixed',
    bottom: '68px',
    zIndex: '1000',
    left: 0,
    right: 0,
    ml:'auto',
    mr:'auto',
    // width: '100%',
    maxWidth:{
      sm:'375px',
      xs:'350px'
    } ,
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
  },
  chatExtendCardSx: {
    borderRadius: '4px',
    bgcolor: '#DBEAE8',
    display: 'flex',
    justifyContent: 'space-between',
    px: 1,
    py: 0.5,

    position: 'fixed',
    bottom: '68px',
    zIndex: '1000',
    left: 0,
    right: 0,
    // width: '100%',
    maxWidth: 375,
    mx: {
      md:'auto',
      sm: 'auto',
      xs:'auto',

    },
    boxSizing: 'border-box',
  },
  chartInputBoxSx: {
    p: 2,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
  },
  isDoctorAppInput: {
    p: 2,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: {
      // sm: 0,
      md: '36%',
      lg: '40%',
    },
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
      md: 648,
    },
    mx: {
      sm: 'auto',
      md: '0',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
  },
  payButtonSx: {
    bgcolor: '#EB6D13',
    color: '#fff',
    height: '28px',
    width: '50px',
    alignSelf: 'ccenter',
    textTransform: 'capitalize',
    fontSize: '12px',
    '&:hover': {
      bgcolor: '#EB6D13',
      color: '#fff',
      height: '28px',
      width: '50px',
      textTransform: 'capitalize',
      fontSize: '12px',
    },
  },
  delineButtonSx: {
    bgcolor: '#F44F5A',
    color: '#fff',
    height: '28px',
    width: '50px',
    alignSelf: 'ccenter',
    textTransform: 'capitalize',
    fontSize: '12px',
    '&:hover': {
      bgcolor: '#F44F5A',
      color: '#fff',
      height: '28px',
      width: '50px',
      textTransform: 'capitalize',
      fontSize: '12px',
    },
  },
  acceptButtonSx: {
    bgcolor: '#239179',
    color: '#fff',
    height: '28px',
    width: '50px',
    alignSelf: 'ccenter',
    textTransform: 'capitalize',
    fontSize: '12px',
    '&:hover': {
      bgcolor: '#239179',
      color: '#fff',
      height: '28px',
      width: '50px',
      textTransform: 'capitalize',
      fontSize: '12px',
    },
  },
};
