import { NotificationPanel } from '@hc/ui/components';
import { Box } from '@mui/material';

import { notification_style } from './style';

export default function Notification(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{ ...notification_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <NotificationPanel />
    </Box>
  );
}
