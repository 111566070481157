/* eslint-disable no-restricted-syntax */
import { clinicalRoutes } from '@hc/routes';
import {
  Button,
  ComingSoonIllustration
} from '@hc/ui/atoms';
import { EmptyStateComponent, Screenlayout } from '@hc/ui/components';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { clinical_self_health_style } from './style';

export default function SelfHealthPage() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(clinicalRoutes.home);
  };

  return (
    <Screenlayout
      title="Self Health"
      backRequired
      backRoute="/home"
      masterchildStyle={{ pb: 0.5 }}
      footer={
        <Box sx={{ gap: 2 }}>
          <Button
            variant="contained"
            buttonStyle={clinical_self_health_style.addBtnSx}
            fullWidth
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </Box>
      }
    >
      <Box px={1.5}>
        <EmptyStateComponent
          illustrationChild={<ComingSoonIllustration />}
          mainContent="Thanks for your interest!"
          subContent="We are building this feature as you read this. We will notify soon!"
        />
      </Box>
    </Screenlayout>
  );
}
