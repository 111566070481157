import { AddEditFamilyMember } from '@components';
import { clinicalRoutes } from '@hc/routes';
import { useBloodGroup, useFamilyMember, useRelationShip } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';

export function FamilyMemberEdit(props) {
  const { useMyNumber } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const navigate = useNavigate();
  const { familyMember, handleFamilyMemberChange } = useFamilyMember(
    (state) => ({
      familyMember: state.familyMember,
      handleFamilyMemberChange: state.handleFamilyMemberChange,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    get,
    relationShipData,
    // loading: loader
  } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE FAMILY MEMBER DATA CAHNGE
    handleFamilyMemberChange(key, value);
  };

  // GET RELATIONSHIP DATA
  useEffect(() => {
    handleFamilyMemberChange('login_user_mobile_number', data?.mobile_no ?? '');
    handleFamilyMemberChange(
      'login_user_country_code',
      data?.country_code ?? ''
    );
    get();
    getBloodGroupData();
    if (!familyMember?.name) {
      return navigate(clinicalRoutes.myFamily);
    }
  }, []);

  return (
    <Box>
      <AddEditFamilyMember
        familyMember={familyMember}
        relationShipData={
          Array.isArray(relationShipData) ? relationShipData : []
        }
        isEdit
        handleChange={handleChange}
        bloodGroupData={bloodGroupData}
        useMyNumber={useMyNumber}
      />
    </Box>
  );
}
