import { clinicalRoutes } from '@hc/routes';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { activePrescriptionCard_style } from './style';

export function ActivePrescriptionCard(props) {
  const { className = '', rootStyleSx = {}, data, ...rest } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      return navigate(clinicalRoutes?.home);
    }
  }, []);

  return (
    <Box
      sx={{ ...activePrescriptionCard_style.rootSx, ...rootStyleSx }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography sx={activePrescriptionCard_style.medicineSx}>
              {data?.master_drug?.drug_na}
          </Typography>
          <Typography sx={activePrescriptionCard_style.directionSx}>
            {`${data?.time} ${data?.when}, ${data?.frequency} for ${data?.duration}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={activePrescriptionCard_style.dosageSx}>
            {data?.dose}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ActivePrescriptionCard.propTypes = {
  medicine: PropTypes.string,
  direction: PropTypes.string,
  dosage: PropTypes.string,

  sx: PropTypes.object,
};
