import { Box, Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import RenewBannerIllustration from '../../../../assets/RenewBannerIllustration.svg';
import { planRenewalBanner_style } from './style';

export function PlanRenewalBanner(props) {
  const {
    className = '',
    rootStyle = {},
    description = '',
    btnDescription = '',
    type = '',
    onClickFnc = () => {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...planRenewalBanner_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack direction="row" sx={planRenewalBanner_style.bannerBox}>
        <Box sx={planRenewalBanner_style.imgContainerSx}>
          <img
            src={RenewBannerIllustration}
            alt="RenewBannerIllustration"
            width="365px"
            style={{ display: 'block' }}
          />
        </Box>

        <Stack justifyContent="center">
          <Box mb={1}>
            {description}
            {/* <Typography sx={planRenewalBanner_style.descSx}>
              Start <span style={planRenewalBanner_style.spanSx}>NOW!</span>{' '}
              <br />
              not TOMORROW
            </Typography> */}
          </Box>
          <Button
            sx={planRenewalBanner_style.getPlanBtn}
            onClick={() => onClickFnc(type)}
          >
            <Typography sx={planRenewalBanner_style.getPlanBtnText}>
              {btnDescription}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

PlanRenewalBanner.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};
