import { useBookAppointment, useRelationShip } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { AddPatient } from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { addpatient_details_style } from './style';

export default function PatientDetails(props) {
  const { className = '', ...rest } = props;

  // General Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const {
    bookAppointmentState,
    updateBookingForError,
    clearPatientDetailsData,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state?.bookAppointmentState,
      updateBookingForError: state.updateBookingForError,
      clearPatientDetailsData: state.clearPatientDetailsData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { booking_for } = bookAppointmentState;

  const {
    get,
    // relationShipData
    // loading: loader,
  } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // VALIDATION FUNCTION
  const isIamValidToSave = () => {
    const bookingForCopy = JSON.parse(JSON.stringify(booking_for));

    let isValid = true;
    const error = bookingForCopy?.error;
    // Checking name
    if (!booking_for?.name) {
      isValid = false;
      error.name = 'Please enter your name';
    } else {
      error.name = '';
    }

    // Checking relationship
    if (!booking_for?.relationship) {
      isValid = false;
      error.relationship = 'Please select relationship';
    } else {
      error.relationship = '';
    }

    // Checking gender
    if (!booking_for?.gender) {
      isValid = false;
      error.gender = 'Please enter your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!booking_for?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }
    // Checking email
    const filter = /\S+@\S+\.\S+/;
    if (booking_for?.email?.length > 0) {
      if (!filter.test(booking_for?.email)) {
        isValid = false;
        error.email = 'Please enter the valid mail';
      } else {
        error.email = '';
      }
    } else {
      isValid = false;
      error.email = 'Please enter the mail';
    }
    // Checking mobile_no
    if (booking_for?.mobile?.length > 0) {
      if (booking_for?.mobile?.length !== 10) {
        isValid = false;
        error.mobile = 'Please enter your valid mobilenumber';
      } else {
        error.mobile = '';
      }
    } else {
      isValid = false;
      error.mobile = 'Please enter your mobilenumber';
    }
    // UPDATE ERROR
    updateBookingForError(error);
    return isValid;
  };

  // SAVE PATIENT DETAILS
  const handleSave = async () => {
    const result = isIamValidToSave();
    if (result) {
      location?.state?.prevRoute
        ? navigate(location?.state?.prevRoute)
        : navigate(clinicalRoutes.healthIssues);
    }
  };

  // CLEAR PATIENT DEATILS DATA IN BOOK APPOINTMENT STATE
  const clearForm = () => {
    clearPatientDetailsData();
  };

  const cancelPatient = () => {
    location?.state?.prevRoute
      ? navigate(location?.state?.prevRoute)
      : navigate(clinicalRoutes.selectPatient);
    if (!location?.state?.prevRoute) {
      clearForm();
    }
  };

  // GET RELATIONSHIP DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <Box
      sx={addpatient_details_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={addpatient_details_style.boxRootSx}>
        <Screenlayout
          backRequired={
            location?.state?.prevRoute
              ? location?.state?.prevRoute
              : clinicalRoutes.selectPatient
          }
          title="Book Appointment"
          childrenStyle={{
            minHeight: '60vh',
            padding: '72px 20px 112px 20px',
          }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={cancelPatient}
                buttonStyle={addpatient_details_style.buttonSx}
              >
                Cancel
              </Button>
              <Button onClick={handleSave}>Continue</Button>
            </Box>
          }
        >
          <AddPatient />
        </Screenlayout>
      </Box>
    </Box>
  );
}
