import { WeeklyCalendar } from '@atoms/weeklyCalendar';
import Box from '@mui/material/Box/Box';
import { isAfter, isBefore } from 'date-fns';
import { React } from 'react';

export function PerformanceWeeklyCalander(props) {
  const { endDate, startDate, onWeekChange, planStartDate, planEndDate } =
    props;

  return (
    <Box>
      <WeeklyCalendar
        endDate={
          isAfter(new Date(planEndDate), new Date(startDate)) &&
          isBefore(new Date(planEndDate), new Date(endDate))
            ? planEndDate
            : endDate
        }
        startDate={
          isAfter(new Date(planStartDate), new Date(startDate)) &&
          isBefore(new Date(planStartDate), new Date(endDate))
            ? planStartDate
            : startDate
        }
        onWeekChange={onWeekChange}
        leftIcon={isBefore(new Date(planStartDate), new Date(startDate))}
        rightIcon={
          isBefore(new Date(planEndDate), new Date())
            ? isAfter(new Date(planEndDate), new Date(endDate))
            : isBefore(new Date(endDate), new Date())
        }
        readOnly
      />
    </Box>
  );
}
