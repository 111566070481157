import { Box, IconButton, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { vitalMonitoringChartCalender_style } from './style';
import { BackIcon, VitalForwardIcon } from '../../../atoms';
import { LinedChart } from '../../../atoms/charts';

function VitalMonitoringChartCalender(props) {
  const {
    className = '',
    rootStyle = {},
    dates = 'Oct 23 - Oct 30',
    onBackClick = () => {},
    onForwardClick = () => {},
    legendData = [],
    isBloodPressure,
    line1Key,
    line2Key,
    data = [],
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...vitalMonitoringChartCalender_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container display="flex" justifyContent="space-between">
        <Grid item>
          <IconButton onClick={onBackClick}>
            <BackIcon id="back" fill="#E3E3E3" rootStyle={{ color: '#000' }} />
          </IconButton>
        </Grid>
        <Grid item display="flex">
          <Typography alignSelf="center" fontWeight={600} fontSize={14}>
            {dates}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onForwardClick}>
            <VitalForwardIcon
              id="forward"
              fill="#00000029"
              rootStyle={{ color: '#000' }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ bgcolor: '#fff', borderRadius: '12px', p: '12px', m: '8px' }}>
        {data?.length > 0 ? (
          <Box ml="-50px">
            <LinedChart
              data={data}
              height={265}
              chartType={isBloodPressure ? 'Blood Pressure' : ''}
              width="100%"
              legendFontSize={14}
              legendData={isBloodPressure ? legendData : []}
              line1Key={line1Key}
              line2Key={isBloodPressure && line2Key}
            />
          </Box>
        ) : (
          <Box
            sx={{ height: '37vh', display: 'flex', justifyContent: 'center' }}
          >
            <Typography alignSelf="center">No Data Found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

VitalMonitoringChartCalender.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  dates: PropTypes.string,
  onBackClick: PropTypes.func,
  onForwardClick: PropTypes.func,
  legendData: PropTypes.array,
  isBloodPressure: PropTypes.bool,
  line1Key: PropTypes.string,
  line2Key: PropTypes.string,
  data: PropTypes.array,
};

export { VitalMonitoringChartCalender };
