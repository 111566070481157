import { EditIcon } from '@atoms';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { appointmentDetails_style } from './style';

export function ReportDetails(props) {
  const {
    heading = '',
    // content = [],
    count,
    newReportCount,
    customizeReportCount,
    icon,
    className = '',
    editOnclick,
    editDownIconShown = true,
    noDataFound = '',
    reportCount,
    isDoctor = false,
    viewReportFnc,
    ...rest
  } = props;
  // const IntialList = content.slice(0, 1);
  // const [expand, setExpand] = useState(true);

  return (
    <Box
      sx={appointmentDetails_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={appointmentDetails_style.titleSx}>
          {icon}
          <Typography sx={appointmentDetails_style.headerSx}>
            {heading}
          </Typography>
        </Box>
        {!isDoctor && editDownIconShown && (
          <Box
            sx={{
              ...appointmentDetails_style.titleSx,
              gap: 2,
              cursor: 'pointer',
            }}
          >
            <EditIcon onClick={editOnclick} />
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 1 }}>
        {!isDoctor && (
          <Box>
            {count && count > 0 ? (
              <Box
                key="1"
                sx={{ ...appointmentDetails_style.labelSx, pb: '10px' }}
              >
                {`${customizeReportCount} reports are customized and ${newReportCount} are added.`}
              </Box>
            ) : (
              <Typography sx={appointmentDetails_style.noDataFound}>
                {noDataFound}
              </Typography>
            )}
          </Box>
        )}
        {isDoctor && (
          <Box>
            {isDoctor && reportCount && reportCount > 0 ? (
              <Box
                key="1"
                sx={{
                  ...appointmentDetails_style.labelSxx,
                  pb: '10px',
                  textDecoration: 'underline',
                  color: 'primary.main',
                  cursor: 'pointer',
                }}
                id="ViewReports"
                onClick={() => viewReportFnc()}
              >
                {`View ${reportCount} Reports Attached.`}
              </Box>
            ) : (
              <Typography sx={appointmentDetails_style.noDataFound}>
                {noDataFound}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

ReportDetails.propTypes = {
  heading: PropTypes.string,
  count: PropTypes.any,
  newReportCount: PropTypes.any,
  customizeReportCount: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
  editOnclick: PropTypes.any,
  editDownIconShown: PropTypes.bool,
  noDataFound: PropTypes.string,
  reportCount: PropTypes.any,
  isDoctor: PropTypes.bool,
  viewReportFnc: PropTypes.any,
};
