import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { leaderTitleCard_style } from './style';

export function LeaderTitleCard(props) {
  const { headerTextStyle = {}, className = '', ...rest } = props;

  return (
    <Box className={`${className}`} {...rest}>
      <Box
        sx={{
          mt: 2,
          mr: 0.8,
          border: '1px solid #F5F5F5',
          pl: 1,
          pr: 0.3,
          py: 1,
          borderRadius: '6px',
        }}
      >
        <Grid container>
          <Grid item xs={7} sx={leaderTitleCard_style.rankSx}>
            <Typography
              sx={{ ...leaderTitleCard_style.headerText, ...headerTextStyle }}
            >
              #
            </Typography>
            <Typography
              sx={{ mx: '27px', color: 'text.secondary', fontSize: '12px' }}
            >
              Members
            </Typography>
          </Grid>

          <Grid item xs={5} sx={leaderTitleCard_style.categorySx}>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={leaderTitleCard_style.headerText}>
                  Mind
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={leaderTitleCard_style.bodyText}>
                  Body
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={leaderTitleCard_style.foodText}>
                  Food
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

LeaderTitleCard.propTypes = {
  headerTextStyle: PropTypes.object,
  className: PropTypes.string,
};
