import { LoginImageIcon } from '@hc/ui/atoms';
import { ClinicalLogin } from '@hc/ui/components/clinical/login';
import { Box } from '@mui/material';
import { signIn_style } from './style';

export default function Login(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={signIn_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={signIn_style.imgContSx}>
        <LoginImageIcon rootStyle={signIn_style.imgSx} />
      </Box>
      <ClinicalLogin />
    </Box>
  );
}
