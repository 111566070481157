import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ThunderIcon, TimerIcon } from '../../../../atoms';
import { planHistoryFooter_style } from './style';

function PlanHistoryFooter(props) {
  const {
    className = '',
    rootStyle = {},
    onPrevious = () => {},
    onCurrent = () => {},
    planStatus,
    planCount,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...planHistoryFooter_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack flexDirection="row" justifyContent="space-around">
        <Stack
          alignItems="center"
          ml={-2}
          onClick={() => onCurrent()}
          sx={{ cursor: 'pointer' }}
        >
          <ThunderIcon
            rootStyle={{
              color: planStatus === 'current' ? ' #6552CC' : '#5C6066',
            }}
          />
          <Typography
            fontSize="14px"
            color={planStatus === 'current' ? ' #6552CC' : '#5C6066'}
          >
            Current
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          mr={-2}
          onClick={() => onPrevious()}
          sx={{ cursor: 'pointer' }}
        >
          <TimerIcon
            rootStyle={{
              color: planStatus === 'current' ? '#5C6066' : ' #6552CC',
            }}
          />
          <Typography
            fontSize="14px"
            color={planStatus === 'current' ? '#5C6066' : ' #6552CC'}
          >
            Previous
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

PlanHistoryFooter.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PlanHistoryFooter };
