import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, CustomTextArea, Drawer } from '../../../../atoms';
import { mbfChangePlanRequest_style } from './style';

function MbfChangePlanRequest(props) {
  const {
    rootStyle = {},
    addDrawerOpen,
    setAddDrawerOpen,
    makeRequestFnc,
    handleChange,
    planChangeRequestState = { description: '', error: '' },
    error,
  } = props;

  return (
    <Box>
      <Drawer
        height="auto"
        anchor="bottom"
        sx={{
          ...mbfChangePlanRequest_style.rootSx,
          ...rootStyle,
        }}
        open={addDrawerOpen}
        onCloseDrawer={() => setAddDrawerOpen()}
        footer={
          <Box>
            <Button
              sx={mbfChangePlanRequest_style.requestBtn}
              onClick={() => makeRequestFnc()}
            >
              Make Request
            </Button>
          </Box>
        }
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 600, pl: 0.5, pt: 1 }}>
          Plan Change Request
        </Typography>

        <Box py={2}>
          <Typography sx={{ fontSize: '14px', fontWeight: 400, pb: 1 }}>
            Description
          </Typography>
          <CustomTextArea
            data_testId="description"
            placeholder="Type something"
            textFieldStyle={mbfChangePlanRequest_style.customTextSx}
            rows={4}
            value={planChangeRequestState?.description}
            onChange={(e) => handleChange(e.target.value)}
          />
          <Typography
            sx={mbfChangePlanRequest_style.errorText}
            display={{
              display: planChangeRequestState?.error?.length ? 'block' : 'none',
            }}
          >
            {planChangeRequestState?.error}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}

MbfChangePlanRequest.propTypes = {
  rootStyle: PropTypes.object,
};

export { MbfChangePlanRequest };
