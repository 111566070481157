import { Avatar, Box, Typography, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import { iconText_style } from './style';

export function IconText(props) {
  const {
    invisible = false,
    name = '',
    icon,
    className = '',
    avatarinactiveStyle = {},
    rootStyle = {},
    textStyle = {},
    badgeStyle = {},
    ...rest
  } = props;
  return (
    <Box
      sx={{ ...iconText_style.rootSx, ...rootStyle }}
      {...rest}
      className={`${className}`}
      id={name.replace(/ /g, '')}
    >
      <Badge
        color="secondary"
        sx={{ ...iconText_style.badgeSx, ...badgeStyle }}
        variant="dot"
        invisible={invisible}
      >
        <Avatar
          sx={{ ...iconText_style.avatarinactiveSx, ...avatarinactiveStyle }}
        >
          {icon}
        </Avatar>
      </Badge>
      <Box>
        {name && (
          <Typography sx={{ ...iconText_style.nameInactiveSx, ...textStyle }}>
            {name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

IconText.propTypes = {
  invisible: PropTypes.bool,
  name: PropTypes.string,
  icon: PropTypes.object,
  classes: PropTypes.object,
  sx: PropTypes.object,
  className: PropTypes.string,
  avatarinactiveStyle: PropTypes.object,
  rootStyle: PropTypes.object,
  textStyle: PropTypes.object,
  badgeStyle: PropTypes.object,
};
