export const filter_toggle_button_style = {
  boxSx: {
    p: 1.25,
    border: '1px solid ',
    borderColor: 'grey.400',
    borderRadius: '8px',
    mt: 1,
    cursor: 'pointer',
    color: '#000',
    fontSize: '14px',
  },
  activeBoxSx: {
    p: 1.25,
    border: '1px solid ',
    borderColor:"orangeTints.600",
    backgroundColor: 'orangeTints.50',
    borderRadius: '8px',
    mt: 1,
    cursor: 'pointer',
    color: 'orangeTints.600',
    fontSize: '14px',
  },
};
