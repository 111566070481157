export const MedicalConditions_style = {
  boxRootSx: {
    px: 2.5,
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  mbfbuttonSx: {
    bgcolor: '#7A67D9',
    color: '#fff',
    '&:hover': {
      bgcolor: '#7A67D9',
    },
  },

  medicalCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    p: 2,
  },
  vitalWrapperSx: {
    display: 'flex',
    gap: 1.5,
  },
  vitalSx: {
    color: 'text.secondary',
    fontSize: '12px',
    fontWeight: 'medium',
    width: 52,
  },
  vitalValueSx: {
    color: 'text.primary',
    fontSize: '12px',
    fontWeight: 600,
  },
  unitSx: {
    color: 'text.hint',
    fontSize: '12px',
    marginLeft: '4px',
  },
  addBtnSx: {
    color: 'secondary.main',
    backgroundColor: 'transparent',
    p: 0,
    fontSize: '13px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  endAdornmentTextSx: {
    fontSize: '12px',
    color: 'text.disabled',
    opacity: 1,
  },
  inputWidthSx: {
    width: '100%',
    marginTop: '4px',
  },
  bmiCalculationGroupSx: {
    border: '1px solid',
    borderColor: 'secondary.main',
    backgroundColor: 'amber.50',
    borderRadius: '4px',
    padding: '10px',
    mt: 2,
  },
  bmiCalculationValueSx: {
    fontSize: 12,
    color: 'text.label',
    fontWeight: 100,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '12px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },
  listingCardSx: {
    backgroundColor: '#F8F8F8',
    border: '1.5px solid #E3E3E3',
    borderRadius: '4px',
    borderColor: 'E3E3E3',
    marginBottom: 0.7,
    py: 0.2,
  },

  worktypoSx: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      fontWeight: 400,
      p: 0,
    },
  },
  mbfVariantTextField: {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      fontWeight: 400,
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#DBE0EA',
      },
      '&:hover fieldset': {
        borderColor: '#DBE0EA',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6552CC',
      },
    },
  },
  titileSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0E1824',
    py: 1,
  },

  requiredSx: {
    fontSize: '12px',
    color: '#B7B7B7',
    py: 1,
  },
  requiredSxx: {
    fontSize: '12px',
    color: '#ea6464',
    py: 1,
  },

  cardListStyle: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#262C33',
  },
  CautionContainerSx: {
    background: '#FFFFFF',
    border: '1px solid #FDDCB2',
    borderRadius: '8px',
    display: 'flex',
    p: 2,
    gap: 2,
  },
  CautionFontSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#241F46',
  },
};
