import { CustomRadio, Dropdown, Input, Label, MobileInput } from '@atoms';
import { useBookAppointment, useRelationShip } from '@hc/store';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { addPatient_style } from './style';

export function AddPatient(props) {
  const { className = '', ...rest } = props;

  const { bookAppointmentState, handlePatientDataChange } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state?.bookAppointmentState,
      handlePatientDataChange: state.handlePatientDataChange,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { booking_for } = bookAppointmentState;

  const {
    // get,
    relationShipData,
    // loading: loader,
  } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    handlePatientDataChange(key, value);
  };

  return (
    <Box sx={addPatient_style.rootSx} className={`${className}`} {...rest}>
      <Box>
        <Typography sx={addPatient_style.PersonalTextSx}>
          Enter Patient Details
        </Typography>
      </Box>
      <Box sx={addPatient_style.boxRootSx}>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Name</Label>
          <Input
            textFieldStyle={addPatient_style.inputFieldSx}
            id="name"
            onChange={(e) => {
              handleChange('name', e.target.value, 20);
            }}
            isError={!!booking_for?.error?.name}
            errorMessage={booking_for?.error?.name}
            value={booking_for?.name}
            fullWidth
          />
        </Box>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Relationship To You</Label>
          <Dropdown
            selectOption={
              Array.isArray(relationShipData) ? relationShipData : []
            }
            id="relationship"
            value={booking_for?.relationship}
            onChange={(value) => {
              handleChange('relationship', value?.target?.value);
            }}
            isError={!!booking_for?.error?.relationship}
            helperText={booking_for?.error?.relationship}
          />
        </Box>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Choose Gender</Label>
          <CustomRadio
            id="gender"
            value={booking_for?.gender}
            onChange={(value) => {
              handleChange('gender', value);
            }}
            isError={!!booking_for?.error?.gender}
            errorMessage={booking_for?.error?.gender}
          />
        </Box>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Age</Label>
          <Input
            id="age"
            value={booking_for?.age}
            onChange={(e) => {
              handleChange('age', e.target.value, 3);
            }}
            inputProps={{ min: 1 }}
            isError={!!booking_for?.error?.age}
            errorMessage={booking_for?.error?.age}
            textFieldStyle={addPatient_style.inputFieldSx}
            endAdornment={
              <Typography sx={addPatient_style.endAdornmentTextSx}>
                Yrs
              </Typography>
            }
          />
        </Box>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Phone Number</Label>
          <MobileInput
            id="mobileNumber"
            value={{
              mobile_code: booking_for?.mobileCode ?? '+91',
              mobile: booking_for?.mobile ?? '',
            }}
            // isReadonly={true}
            isError={!!booking_for?.error?.mobile}
            helperText={booking_for?.error?.mobile}
            // isReadonlyCC={true}
            onChange={(value) => handleChange('mobileNumber', value, 10)}
            mobileInputStyle={addPatient_style.inputFieldSx}
          />
        </Box>
        <Box sx={addPatient_style.inputGroupSx}>
          <Label isRequired>Email ID</Label>
          <Input
            id="email"
            value={booking_for?.email}
            onChange={(e) => {
              handleChange('email', e.target.value, 30);
            }}
            isError={!!booking_for?.error?.email}
            errorMessage={booking_for?.error?.email}
            textFieldStyle={addPatient_style.inputFieldSx}
          />
        </Box>
      </Box>
    </Box>
  );
}
AddPatient.propTypes = {
  heading: PropTypes.string,
  onChange: PropTypes.func,
  relationShipData: PropTypes.array,
};
