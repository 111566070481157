/* eslint-disable camelcase */
export const upload_details_style = {
  inputSx: {
    mt: 1,
    mb: 2,

    borderRadius: '8px',
    '& input::placeholder': {
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-input': {
      fontWeight: '400',
    },
  },

  datePickerSx: {
    mt: 1,
    mb: 2,
  },
  dropDownSx: {
    mt: 1,
    mb: 2,
  },
  date: {
    '& .react-datepicker__month-container': {
      // backgroundColor: ' #081833',
      color: '#969eac',
      // fontSize: '1rem',
      // fontFamily: 'Mulish',

      '& .react-datepicker__month': {
        padding: '1rem 0,',
      },
      '& .react-datepicker__year-dropdown': {},
      '& .react-datepicker__month-text': {
        display: 'inline-block',
        width: '5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        padding: '0.2rem',
        '&:hover': {
          backgroundcolor: '#239179',
        },
      },
    },
  },
};
