export const health_query_chat_bot_style = {
  appBarSx: {
    // boxSizing: 'border-box',
    bgcolor: '#aeefe5',
    // bgcolor: '#0cae95',
    borderRadius: 0,
    boxShadow: 0,
    position: 'fixed',
    top: 0,
    zIndex: '10',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  previousButtonSx: {
    bgcolor: '#fff',
    boxShadow: '0px 0px 12px -4px #b7b7b7',
    position: 'fixed',
    textTransform: 'capitalize',
    height: 30,
    top: 72,
    zIndex: '1000',
    left: 0,
    right: 0,
    color: 'primary.main',
    width: '100%',
    maxWidth: {
      sm: 200,
      xs:200
    },
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '16px',
    mx: {
      sm: 'auto',
    },
    '&:hover':{
      bgcolor: '#fff',
      color: 'primary.main',
    }
  },
  chatBotBoxRootSx: {
    bgcolor: '#aeefe5',
    // bgcolor:'#0cae95',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    px: 1.5,
    pt: 3,
  },
  chatBotRootSx: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    bgcolor: '#FFFFFF',
    borderRadius: '16px',
    height: '85vh',
  },
};
