export const bookingMinDetails_style = {
  appointmentTextSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    mt: 2.36,
  },
  appointmenSubtTextSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    mt: 2.36,
  },
  appointmentSubSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    mt: 2.36,
  },
  updatesTextSx: {
    textAlign: 'center',
    color: 'text.primary',
    fontSize: '14px',
  },
  cancelRequestSx: {
    fontSize: '14px',
    cursor: 'pointer',
    opacity: 1,
    color: '#239179',
    textDecoration: 'underline',
    width: '110px',
  },
  cancelRequestBoxSx: {
    display: 'flex',
    justifyContent: 'center',
  },
  numberTextSx: {
    textAlign: 'center',
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 'medium',
    mt: 0.2,
  },
  closebuttonSx: {
    mr: 2,
    // maxWidth: '120px',
    bgcolor: '#007965',
    color: 'white',
    textTransform: 'capitalize',
    borderRadius: '8px',
    // '&:hover': {
    //   color: 'white',
    // },
    border: '2px solid #007965',
  },
};
