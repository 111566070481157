export const report_filter_style = {
  rootSx: {},
  drawerRootSx: {
    py: 1,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: 'primaryTints.300',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: 'primaryTints.300',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },

  suggestionSx: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};
