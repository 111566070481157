import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { bodyFoodText_Style } from './style';

export function BodyFoodText(props) {
  const {
    headerText = '',
    subText = '',
    headerTextStyle = {},
    subTextStyle = {},
    colorBoxStyle = {},
    subColorSTyle = {},
    cardStyle={},
  } = props;
  return (
    <Box>
      <Box sx={{...bodyFoodText_Style.cardSx, ...cardStyle}}>
        <Box sx={bodyFoodText_Style.colorWithTextSx}>
          <Box sx={{ ...bodyFoodText_Style.colorBoxSx, ...colorBoxStyle }} />
          <Box sx={{ ...bodyFoodText_Style.subColorSx, ...subColorSTyle }} />
        </Box>
        <Typography
          sx={{ ...bodyFoodText_Style.headerTextSx, ...headerTextStyle }}
        >
          {headerText}
        </Typography>
        <Typography sx={{ ...bodyFoodText_Style.subTextSx, ...subTextStyle }}>
          {subText}
        </Typography>
      </Box>
    </Box>
  );
}
BodyFoodText.propTypes={
  headerText:PropTypes.string,
  subText:PropTypes.string,
}