import { AddEditProfile } from '@hc/ui/components';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useBloodGroup, useProfile } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import isEqual from 'react-fast-compare';

export function AddFamilyProfile() {
  const [tokenData, setTokenData] = useState({});
  const { profile, handleProfileDataChange, updateMobileNumber } = useProfile(
    (state) => ({
      profile: state.profile,
      handleProfileDataChange: state.handleProfileDataChange,
      clearProfile: state.clearProfile,
      updateProfileError: state.updateProfileError,
      updateMobileNumber: state.updateMobileNumber,
      loading: state.loading,
      upsert: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    handleProfileDataChange(key, value);
  };

  useEffect(() => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    // UPDATE MOBILE NUMBER
    updateMobileNumber(data);
    const tokenData_ = localStorage.getItem(localStorageKeys.authToken);
    const parseTokenData = parseJwt(tokenData_);
    setTokenData(parseTokenData);
    getBloodGroupData();
  }, []);

  return (
    <Box>
      <AddEditProfile
        corporate={tokenData?.employee_membership_code?.length > 0}
        profile={profile}
        bloodGroupData={bloodGroupData}
        handleChange={handleChange}
      />
    </Box>
  );
}
