export const selectPatient_style = {
    rootSx: {
      display: 'grid',
      gap: 2,
      minHeight: '100vh',
    },
    boxRootSx: {
      px: 2.5,
    },
    BookingTextSx: {
      fontSize: 16,
      fontWeight: 600,
      color: 'text.primary',
      mt: 2,
    },
    myselfImageSx: {
      backgroundColor: '#FFD8BE',
      cursor: 'pointer',
      // height:'220px'
    },
    otherimageSx: {
      backgroundColor: '#CCE2CB',
      cursor: 'pointer',
      // height:'220px'
    },
    familyimageSx: {
      backgroundColor: '#CBD5E2',
      cursor: 'pointer',
      // height:'220px'
    },
  };
  