export const mbfRenewEmptyState_style = {
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#0E1824',
    textAlign: 'center',
    py: 2,
  },
  desc: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0E1824',
    textAlign: 'center',
    py: 2,
  },
};
