/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useRelationShip = create((set, get) => ({
  relationShipData: [],
  loading: null,
  error: null,
  get: async (type) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/masterRelationships/getAll',
        'get',
      ]);
      const relationShipDataCopy = [];
      if (type === 'filter') {
        relationShipDataCopy.push({
          value: 'allRelationship',
          label: 'All',
        });
      }
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          relationShipDataCopy.push({
            value: val?.id ?? '',
            label: val?.releationship ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        relationShipData: relationShipDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
