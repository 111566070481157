import { clinicalRoutes } from '@hc/routes';
import { SelectPatientAppointment } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import { selectPatient_style } from './style';

export default function SelectPatient(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={selectPatient_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={selectPatient_style.boxRootSx}>
        <Screenlayout
          notshowFooter
          backRequired={clinicalRoutes?.home}
          backRoute={clinicalRoutes?.home}
          title="Book Appointment"
        >
          <SelectPatientAppointment />
        </Screenlayout>
      </Box>
    </Box>
  );
}
