import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, localStorageKeys, parseJwt } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const useBugReports = create((set, get) => ({
  bugReport: '',
  bugReportPictures: [],
  loading: false,
  error: {
    bugReport: '',
  },
  SendbugReport: async (profile) => {
    const { bugReport, bugReportPictures, clearbugReport } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const user = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/bugsandfeedbacks/clinical/bugs/submit`,
        {
          user_id: user.user_id,
          bug_form_answer: {
            url: bugReportPictures.map((val) => val.downloadUrl),
            content: bugReport,
            name: user.name,
            mobile_no: user.mobile_no,
            country_code: user.country_code,
            email: profile.email_id,
          },
          module: 'Clinical',
        },
        true
      );
      if (data.status.code === 200) {
        toast.success(data.status.message);
        clearbugReport();
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  changeState: (value) => {
    set({ bugReport: value });
  },

  isValid: () => {
    const { bugReport, error } = get();
    let valid = true;
    const Error = structuredClone(error);
    if (!bugReport) {
      valid = false;
      Error.bugReport = 'Please enter tha valid bug';
    } else {
      Error.bugReport = '';
    }

    set({ error: { ...Error } });
    return valid;
  },
  clearbugReport: () => {
    set({
      bugReport: '',
      bugReportPictures: [],
      error: {
        bugReport: '',
      },
    });
  },

  bugReportPictureList: (data) => {
    const { bugReportPictures } = get();
    set({
      bugReportPictures: [...bugReportPictures, data],
    });
  },

  bugPicDelete: (i) => {
    const { bugReportPictures } = get();
    bugReportPictures.splice(i, 1);
    set({ bugReportPictures });
  },
}));
