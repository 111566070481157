import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useDoctorDetails = create((set) => ({
  doctorDetail: '',
  getDoctorsDetails: async (doctrorId) => {
    try {
      set({ loading: true });
      const data = await queryClient.fetchQuery([
        `/appointments/doctor-profile/get-share?doctor_user_id=${doctrorId}`,
        'get',
        {},
      ]);
      console.log(data.data);
      if (data?.status?.code) {
        set(() => ({
          doctorDetail: data?.data,
        }));
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
