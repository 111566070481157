export const contractExpiryModal_style = {
  rootSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      height: '55vh',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    // p: 2,
    overflow: 'overlay',
  },
  headerSx: { p: 2, borderBottom: '1px solid', borderColor: 'border.main' },
  childrenSx: { p: 2, overflow: 'overlay' },
  footerSx: {
    p: 3,
    bgcolor: 'common.white',
    borderTop: '1.3px dashed',
    borderColor: 'border.main',
  },
  closeSx: {
    top: '10px',
    right: '390px',
    width: '50px',
    cursor: 'pointer',
    height: '50px',
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '15px',
  },
  imgcenterSx: {
    display: 'flex',
    justifyContent: 'center',
  },
  descriptionTextSx: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    mt: 1,
  },
  infotextSx: {
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0px',
    textAlign: 'center',
    mt: 3,
    ml: 0.6,
  },
  infotextwrapSx: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
  },
  closeiconwraperSx: {
    display: 'flex',
    justifyContent: 'end',
    pr: 2,
    pt: 1,
  },
};
