export const otpVerification_style = {
  rootPaperSx: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 5,
    height: 'calc(100vh - 204px)',
    position: 'relative',
    mt: '-30px',
  },

  headingSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    marginTop: '12px',
  },

  codeSentSx: {
    textTransform: 'none',
  },

  numberSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 600,
  },

  changeSx: {
    color: 'orangeTints.600',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  verifySx: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
  },

  receiveSx: {
    color: 'text.secondary',
    fontSize: '14px',
    textAlign: 'center',
    display: 'flex',
    gap: 0.5,
  },

  resendSx: {
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
    width: 'max-content',
  },
};
