import { ClinicalOnboarding } from '@hc/ui/components/clinical/onboarding';
import { Box } from '@mui/material';
import { onboarding_style } from './style';

export default function Onboarding(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={onboarding_style.rootSx} className={`${className}`} {...rest}>
      <ClinicalOnboarding />
    </Box>
  );
}
