import {
  HealthCircleIcon,
  HealthCirclesLogo,
  MBFIcon,
  MindBodyFoodLogo,
  NotificationIcon,
  SuitCaseIcon,
  UserNameIcon
} from '@atoms/icons';
import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFMoodStats,
  useNotification,
  useProfile
} from '@hc/store';
import { localStorageKeys } from '@hc/utils/constants';
import { getFirstLetterOfName, parseJwt } from '@hc/utils/helperFunctions';
import { Badge, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContractExpiryModal } from '../contractExpiryModal';
import { layout_style } from './style';

function ClinicalLayout(props) {
  const {
    className = '',
    appBarRequired = true,
    footBarRequired = true,
    children,
    rootStyle,
    appBarStyle,
    childrenStyle,
    footerStyle,
    isMindBodyFood = false,
    isHealthCircle = true,
    ...rest
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const [tokenData, setTokenData] = useState({});
  const [addDrawerOpen, setaddDrawerOpen] = useState(false);

  const setAddDrawerOpenFunc = () => {
    setaddDrawerOpen(false);
  };

  const { getNotification, notificationState } = useNotification((state) => ({
    getNotification: state.getNotification,
    notificationState: state.notificationState,
    loading: state.loading,
  }));

  const { profile, getCorporateUserData } = useProfile(
    (state) => ({
      profile: state.profile,
      getCorporateUserData: state.getCorporateUserData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { checkPlanDataDetails, mbfState } = useMBFActivity((state) => ({
    checkPlanDataDetails: state.checkPlanDataDetails,
    mbfState: state.mbfState,
    loading: state.loading,
  }));

  const { checkPlanData } = mbfState;
  const { status } = checkPlanData;

  const { mbfMood, getTodayMoodStats } = useMBFMoodStats((state) => ({
    mbfMood: state.mbfMood,
    getTodayMoodStats: state.getTodayMoodStats,
  }));

  const { notificationData } = notificationState;

  const navItems = [
    {
      item: 'Clinical',
      path: clinicalRoutes?.home,
      icon: 'healthCircle',
    },
    {
      item: 'Mind Body Food',
      path: clinicalRoutes?.mindbodyfood,
      icon: 'mbf',
    },
    {
      item: 'Profile',
      path: clinicalRoutes?.profileView,
      icon: 'userName',
    },
  ];

  // Get Iniital Data
  const getInitialData = async () => {
    await getNotification(data?.user_id);
    if (
      typeof checkPlanData === 'object' &&
      Object.keys(checkPlanData)?.length === 0
    ) {
      // Check plan status
      const { statusCode, status } = await checkPlanDataDetails();

      if (statusCode === 200 && status === 4) {
        await getTodayMoodStats(data?.user_id, 'layout');
      }
    }
    if (status === 4 && (!mbfMood || mbfMood === null)) {
      await getTodayMoodStats(data?.user_id, 'layout');
    }

    // Check status
    // if (
    //   Object.keys(checkPlanData).length > 0 &&
    //   location.pathname === '/mindbodyfood'
    // ) {
    //   if (status !== 4) {
    //     navigate(clinicalRoutes?.getStarted);
    //   }
    // }

    // To set Token Data
    const authTokenData = localStorage.getItem(localStorageKeys.authToken);
    const parseTokenData = parseJwt(authTokenData);
    setTokenData(parseTokenData);

    // To get Corporate User Data
    if (parseTokenData?.employee_membership_code?.length > 0) {
      await getCorporateUserData(parseTokenData.user_id);
    }
  };

  const handleClick = () => {
    // if (isHealthCircle) {
    navigate('/notification');
    // }
  };

  const navIcons = {
    healthCircle: HealthCircleIcon,
    mbf: MBFIcon,
    userName: UserNameIcon,
  };

  const getStartedBtn = async (path) => {
    if (path === '/mindbodyfood') {
      await checkPlanDataDetails();
      if (!profile.contractExpired) {
        if (status === 4) {
          if (!mbfMood || mbfMood === null) {
            navigate(clinicalRoutes?.moodforyou);
          } else {
            navigate(clinicalRoutes?.mindbodyfood);
          }
        } else {
          navigate(clinicalRoutes?.getStarted);
        }
      } else {
        setaddDrawerOpen(true);
      }
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      sx={{ ...layout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      {appBarRequired && (
        <Box sx={{ ...layout_style.appBarSx, ...appBarStyle }}>
          <Box>
            {isHealthCircle && (
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <HealthCirclesLogo
                  rootStyle={{
                    width: '32px',
                    height: '32px',
                  }}
                />
                <Box sx={{ display: 'grid', ml: 1 }}>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}
                  >
                    Health Circles
                  </Typography>
                  <Box sx={layout_style.organizationSx}>
                    {tokenData?.employee_membership_code?.length > 0}
                    {tokenData?.organisation_name ?? ''}
                  </Box>
                </Box>
              </Box>
            )}
            {isMindBodyFood && <MindBodyFoodLogo />}
          </Box>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={handleClick}
            id="notification"
          >
            {notificationData?.filter((val) => val?.is_read === false)?.length >
            0 ? (
              <Badge
                color="secondary"
                overlap="circular"
                sx={layout_style.notificationSx}
                variant="dot"
              >
                <NotificationIcon rootStyle={{ width: 20, height: 20 }} />
              </Badge>
            ) : (
              <NotificationIcon rootStyle={{ width: 20, height: 20 }} />
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          ...layout_style.childrenSx,
          pt: appBarRequired && 5.5,
          ...childrenStyle,
          backgroundColor: 'primaryGreyishTints.50',
        }}
      >
        {/* {loading && (
          <Box
            sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}
          >
            <CircularProgress
              sx={{
                color: location?.pathname.includes('mindbodyfood')
                  ? 'mbf.main'
                  : 'priary.main',
              }}
            />
          </Box>
        )}

        {(location.pathname !== '/'
          ? !loading && status !== undefined
          : true) && ( */}
        <Box sx={{ backgroundColor: 'primaryGreyishTints.50' }}>{children}</Box>
        {/* )} */}
      </Box>
      {footBarRequired && (
        <Box sx={{ ...layout_style.footerNavBarSx, ...footerStyle }}>
          {navItems.map((navItem, i) => {
            const Icon = navIcons[navItem.icon];
            return tokenData?.employee_membership_code?.length > 0 &&
              navItem?.item === 'Profile' ? (
              <Box
                key={i}
                sx={layout_style.profileNavItemSx}
                onClick={() => {
                  navigate(navItem.path);
                }}
              >
                <Icon
                  rootStyle={{
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.icon === 'userName' && {
                      bgcolor:
                        navItem?.path === location?.pathname
                          ? 'primaryTints.100'
                          : 'grey.100',
                      border: '1.5px solid',
                      borderColor:
                        navItem?.path === location?.pathname
                          ? 'primary.main'
                          : 'grey.600',
                    }),
                    ...(navItem.icon === 'mbf' && {
                      color: navItem?.path.includes(location?.pathname)
                        ? 'mbf.main'
                        : 'text.secondary',
                    }),
                  }}
                  letter={getFirstLetterOfName(profile?.name)}
                />
                <SuitCaseIcon
                  rootStyle={{
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.icon === 'userName' && {
                      bgcolor:
                        navItem?.path === location?.pathname
                          ? 'primaryTints.100'
                          : 'grey.100',
                      border: '1.5px solid',
                      borderColor:
                        navItem?.path === location?.pathname
                          ? 'primary.main'
                          : 'grey.600',
                    }),
                    ...(navItem.icon === 'mbf' && {
                      color:
                        navItem?.path === location?.pathname
                          ? 'mbf.main'
                          : 'text.secondary',
                    }),
                    position: 'absolute',
                    top: '12px',
                    border: 'none',
                  }}
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    ...layout_style.navItemTextSx,
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.item === 'Mind Body Food' && {
                      color:
                        navItem?.path === location?.pathname
                          ? 'mbf.main'
                          : 'text.secondary',
                    }),
                  }}
                >
                  {navItem.item}
                </Typography>
              </Box>
            ) : (
              <Box
                key={navItem}
                sx={layout_style.navItemSx}
                onClick={() => {
                  // navigate(navItem.path);
                  getStartedBtn(navItem.path);
                }}
              >
                <Icon
                  rootStyle={{
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.icon === 'userName' && {
                      bgcolor:
                        navItem?.path === location?.pathname
                          ? 'primaryTints.100'
                          : 'grey.100',
                      border: '1.5px solid',
                      borderColor:
                        navItem?.path === location?.pathname
                          ? 'primary.main'
                          : 'grey.600',
                    }),
                    ...(navItem.icon === 'mbf' && {
                      color:
                        navItem?.path === location?.pathname
                          ? 'mbf.main'
                          : 'text.secondary',
                    }),
                  }}
                  letter={getFirstLetterOfName(profile?.name)}
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    ...layout_style.navItemTextSx,
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.item === 'Mind Body Food' && {
                      color:
                        navItem?.path === location?.pathname
                          ? 'mbf.main'
                          : 'text.secondary',
                    }),
                  }}
                >
                  {navItem.item}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
      {addDrawerOpen && (
        <ContractExpiryModal
          addDrawerOpen={addDrawerOpen}
          setAddDrawerOpen={setAddDrawerOpenFunc}
        />
      )}
    </Box>
  );
}

export { ClinicalLayout };

