import { CustomRadio } from '@atoms/customRadioButton';
import { Input } from '@atoms/input';
import { Label } from '@atoms/label';
import { MobileInput } from '@atoms/mobileInput';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BloodGroupSelection } from '../bloodGroupSelection';
import { addEditProfile_style } from './style';

export function AddEditProfile(props) {
  const {
    className = '',
    profile,
    bloodGroupData,
    handleChange = () => {},
    corporate,
    ...rest
  } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  return (
    <Box sx={addEditProfile_style.rootSx} className={`${className}`} {...rest}>
      <Box>
        {!corporate && (
          <Typography sx={addEditProfile_style.PersonalTextSx}>
            Personal Details
          </Typography>
        )}
      </Box>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Label htmlFor="name" isRequired>
            {corporate ? 'Your Name' : 'Name'}
          </Label>
          <Input
            id="name"
            textFieldStyle={addEditProfile_style.nameFieldSx}
            onChange={(e) => {
              handleChange('name', e.target.value, 50);
            }}
            isError={!!profile?.error?.name}
            errorMessage={profile?.error?.name}
            value={profile?.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Label htmlFor="gender" isRequired>
            {corporate ? 'Choose Your Gender' : 'Choose Gender'}
          </Label>
          <CustomRadio
            id="gender"
            value={profile?.gender}
            onChange={(value) => {
              handleChange('gender', value);
            }}
            isError={!!profile?.error?.gender}
            errorMessage={profile?.error?.gender}
            avataractiveStyle={{ border: '1px solid' }}
            avatarinactiveStyle={{ border: '1px solid' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Label htmlFor="age" isRequired>
            Age
          </Label>
          <Input
            id="age"
            value={profile?.age}
            onChange={(e) => {
              handleChange('age', e.target.value, 3);
            }}
            inputProps={{ min: 1 }}
            isError={!!profile?.error?.age}
            errorMessage={profile?.error?.age}
            textFieldStyle={addEditProfile_style.inputFieldSx}
            endAdornment={
              <Typography sx={addEditProfile_style.endAdornmentTextSx}>
                Yrs
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Label htmlFor="mobileNumber">Phone Number</Label>
          <MobileInput
            id="mobileNumber"
            mobileInputStyle={addEditProfile_style.inputFieldSx}
            value={{
              mobile_code: profile?.country_code
                ? profile?.country_code
                : tokenData?.country_code
                ? tokenData?.country_code
                : '+91',
              mobile: profile?.mobile_no
                ? profile?.mobile_no
                : tokenData?.mobile_no
                ? tokenData?.mobile_no
                : '',
            }}
            isReadonly
            isError={!!profile?.error?.mobile_no}
            isReadonlyCC
            onChange={(value) => handleChange('mobileNumber', value, 10)}
          />
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            value={profile?.email_id}
            onChange={(e) => {
              handleChange('email_id', e.target.value, 50);
            }}
            isError={!!profile?.error?.email_id}
            errorMessage={profile?.error?.email_id}
            textFieldStyle={addEditProfile_style.inputFieldSx}
            isReadOnly={!!corporate}
          />
        </Grid>
        <Grid item xs={12}>
          {corporate && (
            <Box>
              <Label htmlFor="employee_membership_code" isRequired>
                Membership Code (For corporate account holders)
              </Label>
              <Input
                id="employee_membership_code"
                textFieldStyle={addEditProfile_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('employee_membership_code', e.target.value);
                }}
                isError={!!profile?.error?.employee_membership_code}
                errorMessage={profile?.error?.employee_membership_code}
                value={profile?.employee_membership_code}
                fullWidth
                isReadOnly
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Label htmlFor="blood_group" isRequired>
            Blood Group{' '}
          </Label>
          <Box
            sx={{
              overflowY: 'scroll',
            }}
          >
            <BloodGroupSelection
              id="bloodgroup"
              value={profile?.bloodgroup}
              bloodGroupData={bloodGroupData}
              bloodData={profile}
              handleChange={handleChange}
              name="blood_group"
            />
          </Box>
          {profile?.error?.blood_group?.length > 0 && (
            <Typography fontSize={12} color="	#ff3333">
              {profile?.error?.blood_group}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
AddEditProfile.propTypes = {
  profile: PropTypes.object,
  handleChange: PropTypes.func,
  corporate: PropTypes.object,
  className: PropTypes.node,
  sx: PropTypes.object,
};
