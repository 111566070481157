export const Performance_Style = {
  rootSx: {
    borderRadius: '8px',
  },
  cardRootStyle: {
    borderRadius: '8px',
    boxShadow: 'none',
  },
  cardRootSx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '12px',
    borderRadius: '8px',
  },
  imageWithTextSx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    mt: 0.4,
  },
  titleSx: {
    borderColor: 'PrimaryTints.A100',
    px: 1.5,
    fontWeight: 600,
    fontSize: '16px',
    mt: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  subTitleSx: {
    fontSize: '12px',
    textAlign: 'left',
    px: 1.5,
    mt: 1,
  },
  cardContentSx: {
    px: 2,
    py: 2,
    pb: '20px!important',
  },
};
