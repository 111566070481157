import { getEndOfWeeks, getStartOfWeeks } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFMoodStatsProgress,
  useMBFWellnessJourney,
} from '@hc/store';
import { PlanHistoryFooter, PreviousMoodStats } from '@hc/ui/components';
import { BodyFoodText } from '@hc/ui/components/clinical/mindBodyFood/bodyFoodText';
import { MoodStatsProgress } from '@hc/ui/components/clinical/mindBodyFood/moodStatsProgress';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import { endOfWeek, isBefore, startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { mood_status_style } from './style';

export default function MoodStats(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const [stats, setStats] = useState(0);
  const [active, setActive] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const planStatus = searchParams.get('status');
  const navigate = useNavigate();

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const headerText = 'Be Aware of your mindset to become strong';
  const subText =
    'Manage your lifestyle choices, make informed health decisions and work towards a better quality of life.';

  const { getMoodStats, getPreviousMoodStats, planCount, startEndDateUpdate } =
    useMBFMoodStatsProgress();

  const { planEndDate, getWellnessJourney } = useMBFWellnessJourney(
    (state) => ({
      planEndDate: state.planEndDate,
      getWellnessJourney: state.getWellnessJourney,
    })
  );

  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { status } = checkPlanData;

  const onNext = async () => {
    if (planCount > stats) {
      setActive(true);
      queryClient.invalidateQueries({
        queryKey: ['PreviousMoodStats'],
      });

      const payload = {
        user_id: tokenData?.user_id ?? '',
        pageValue: stats + 1,
      };
      queryClient.invalidateQueries({
        queryKey: ['PreviousMoodStats'],
      });
      await getPreviousMoodStats(payload);
      setStats(stats + 1);
    } else {
      toast(
        `You have only ${planCount} previous ${
          planCount === 1 ? 'plan' : 'plans'
        }`,
        { icon: '⚠️' }
      );
    }
  };

  const onBack = async () => {
    if (stats >= 1) {
      if (stats - 1 !== 0) {
        setActive(false);
        queryClient.invalidateQueries({
          queryKey: ['PreviousMoodStats'],
        });
        const payload = {
          user_id: tokenData?.user_id ?? '',
          pageValue: stats - 1,
        };
        queryClient.invalidateQueries({
          queryKey: ['PreviousMoodStats'],
        });
        await getPreviousMoodStats(payload);
        setStats(stats - 1);
      } else {
        toast(
          `You have only ${planCount} previous ${
            planCount === 1 ? 'plan' : 'plans'
          }`,
          { icon: '⚠️' }
        );
      }
    }
  };

  const onPrevious = async () => {
    if (planCount > stats) {
      setSearchParams({ status: 'Previous' });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        pageValue: 1,
      };
      queryClient.invalidateQueries({
        queryKey: ['PreviousMoodStats'],
      });
      await getPreviousMoodStats(payload);
      setStats(1);
    } else {
      toast(`You have current plan only`, { icon: '⚠️' });
    }
  };

  const onCurrent = async () => {
    if (status === 4) {
      setSearchParams({ status: 'current' });
      let payload;
      payload = {
        start_date: getStartOfWeeks('YYYY-MM-DD'),
        end_date: getEndOfWeeks('YYYY-MM-DD'),
        user_id: tokenData?.user_id ?? '',
      };
      if (isBefore(new Date(planEndDate), new Date())) {
        payload = {
          start_date: startOfWeek(new Date(planEndDate)),
          end_date: endOfWeek(new Date(planEndDate)),
          user_id: tokenData?.user_id ?? '',
        };
      }

      queryClient.invalidateQueries({
        queryKey: ['moodStats'],
      });
      await getMoodStats(payload);
    } else {
      toast(`You have no current plan`, { icon: '⚠️' });
    }
  };

  const goToPrevious = async () => {
    setSearchParams({ status: 'Previous' });
    const payload = {
      user_id: tokenData?.user_id ?? '',
      pageValue: 1,
    };
    queryClient.invalidateQueries({
      queryKey: ['PreviousMoodStats'],
    });
    await getPreviousMoodStats(payload);
    setStats(1);
  };

  const initialDataCall = async () => {
    let payload;

    payload = {
      start_date: getStartOfWeeks('YYYY-MM-DD'),
      end_date: getEndOfWeeks('YYYY-MM-DD'),
      user_id: tokenData?.user_id ?? '',
    };
    if (isBefore(new Date(planEndDate), new Date())) {
      payload = {
        start_date: startOfWeek(new Date(planEndDate)),
        end_date: endOfWeek(new Date(planEndDate)),
        user_id: tokenData?.user_id ?? '',
      };
    }

    startEndDateUpdate(payload.start_date, payload.end_date);
    await getMoodStats(payload);
  };

  const initialData = async () => {
    // debugger;
    if (searchParams.get('status')) {
      setSearchParams({ status: searchParams.get('status') });
    } else if (status === 4) {
      const { end } = await getWellnessJourney();
      if (end && status === 4) {
        await initialDataCall(end);
      }
      setSearchParams({ status: 'current' });
    } else {
      await goToPrevious();
    }
  };

  useEffect(() => {
    initialData();
  }, []);


  window.addEventListener('popstate', () => {
    navigate(clinicalRoutes.mindbodyfood);
  });
  
  return (
    <Box sx={{ ...rootStyle }} className={`${className}`} {...rest}>
      <Screenlayout
        title="Mood Stats"
        footerStyle={{ borderTop: '0px', p: planCount > 0 ? 1 : 0 }}
        appBarStyle={{ ...mood_status_style.appBarSx }}
        backRequired={clinicalRoutes.mindbodyfood}
        backRoute={clinicalRoutes.mindbodyfood}
        backIconStyle={{ color: 'mbf.main' }}
        rootStyle={{ bgcolor: 'purple.50' }}
        childrenStyle={mood_status_style.childrenSx}
        footer={
          planCount > 0 && (
            <PlanHistoryFooter
              planStatus={planStatus}
              onPrevious={onPrevious}
              onCurrent={onCurrent}
            />
          )
        }
      >
        {planStatus === 'current' && (
          <Box>
            <Box px={3} py={1}>
              <MoodStatsProgress />
            </Box>
            <Box p={3} mb={2}>
              <BodyFoodText
                subTextStyle={{ pb: 2 }}
                headerText={headerText}
                subText={subText}
              />
            </Box>
          </Box>
        )}

        {planStatus === 'Previous' && (
          <Box px={3} py={1}>
            <PreviousMoodStats
              onBack={onBack}
              active={active}
              stats={stats}
              onNext={onNext}
            />
          </Box>
        )}
      </Screenlayout>
    </Box>
  );
}
