export const addpatient_details_style = {
  rootSx: {
    display: 'grid',
    gap: 1.5,
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
