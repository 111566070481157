import { DropIcon, DropUpIcon, EditIcon } from '@atoms';
import { useBookAppointment } from '@hc/store';
import { Box, Typography } from '@mui/material';
import { organizationRoutes } from '@hc/routes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { appointmentDetails_style } from './style';

export function AppointmentDetails(props) {
  const {
    heading = '',
    editDownIconShown = true,
    content = '',
    number = '',
    email = '',
    label = '',
    className = '',
    isDoctor,
    icon,
    expanded,
    myself = false,
    ...rest
  } = props;

  const { bookAppointmentState } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [expand, setExpand] = useState(expanded);
  const navigate = useNavigate();
  // EDIT FUNCTION
  const editOnclick = (route) => {
    navigate(organizationRoutes[route], {
      state: { prevRoute: organizationRoutes.appointmentSummary },
    });
  };

  return (
    <Box
      sx={appointmentDetails_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Box sx={appointmentDetails_style.titleSx}>
          {icon}
          <Typography sx={appointmentDetails_style.headerSx}>
            {heading}
          </Typography>
        </Box>
        <Box
          sx={{
            ...appointmentDetails_style.titleSx,
            gap: 2,
            cursor: 'pointer',
          }}
        >
          {editDownIconShown && (
            <>
              {!myself && (
                <EditIcon
                  onClick={() =>
                    editOnclick(
                      bookAppointmentState?.booking_method === 'others'
                        ? 'patientDetails'
                        : 'selectFamilyMember'
                    )
                  }
                />
              )}
              {expand ? (
                <DropIcon
                  onClick={() => setExpand(false)}
                  rootStyle={{ cursor: 'pointer' }}
                />
              ) : (
                <DropUpIcon
                  onClick={() => setExpand(true)}
                  rootStyle={{ cursor: 'pointer' }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {expand ? (
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#0E1824' }}
          >
            {bookAppointmentState?.booking_method === 'myself' ||
            bookAppointmentState?.booking_method === 'family'
              ? bookAppointmentState?.patientDetails?.name ?? ''
              : bookAppointmentState?.booking_method === 'others'
              ? bookAppointmentState?.booking_for?.name ?? ''
              : ''}
            {myself && (
              <Box
                as="span"
                sx={{
                  ...appointmentDetails_style.labelSx,
                  color: '#5C6266',
                  opacity: 1,
                }}
              >
                (You)
              </Box>
            )}
            {label && (
              <Box
                as="span"
                sx={{
                  ...appointmentDetails_style.labelSx,
                  color: '#5C6266',
                  background: '#F5F5F5 0% 0% no-repeat padding-box',
                  borderRadius: '4px',
                  opacity: 1,
                }}
              >
                {bookAppointmentState?.booking_method === 'myself' ||
                bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.gender &&
                    bookAppointmentState?.patientDetails?.age
                    ? `${bookAppointmentState?.patientDetails?.gender}, ${bookAppointmentState?.patientDetails?.age}` ??
                      ''
                    : ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.gender &&
                    `${bookAppointmentState?.booking_for?.age} Yrs`
                    ? `${bookAppointmentState?.booking_for?.gender}, ${bookAppointmentState?.booking_for?.age} Yrs` ??
                      ''
                    : ''
                  : ''}
              </Box>
            )}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#0E1824' }}
          >
            {content}
            {myself && (
              <Box
                as="span"
                sx={{
                  ...appointmentDetails_style.labelSx,
                  color: '#5C6266',
                  opacity: 1,
                }}
              >
                (You)
              </Box>
            )}
            {label && (
              <Box
                as="span"
                sx={{
                  ...appointmentDetails_style.labelSx,
                  color: '#5C6266',
                  background: '#F5F5F5 0% 0% no-repeat padding-box',
                  borderRadius: '4px',
                  opacity: 1,
                }}
              >
                {label}
              </Box>
            )}
          </Typography>
          <Box as="span" sx={{ display: 'flex', mt: '10px' }}>
            {number && (
              <Typography
                sx={{ fontWeight: 600, color: '#5C6266', fontSize: '12px' }}
              >
                {isDoctor
                  ? number
                  : bookAppointmentState?.booking_method === 'myself' ||
                    bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.country_code &&
                    bookAppointmentState?.patientDetails?.mobile_no
                    ? bookAppointmentState?.patientDetails?.country_code +
                        bookAppointmentState?.patientDetails?.mobile_no ?? ''
                    : ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.mobileCode &&
                    bookAppointmentState?.booking_for?.mobile
                    ? `${bookAppointmentState?.booking_for?.mobileCode} ${bookAppointmentState?.booking_for?.mobile}` ??
                      ''
                    : ''
                  : ''}
              </Typography>
            )}
            {email && (
              <>
                <span style={{ color: '#868484', fontSize: '12px', ml: 0.8 }}>
                  {' , '}
                </span>
                <Typography
                  sx={{ fontWeight: 600, color: '#5C6266', fontSize: '12px' }}
                >
                  {bookAppointmentState?.booking_method === 'myself' ||
                  bookAppointmentState?.booking_method === 'family'
                    ? bookAppointmentState?.patientDetails?.email_id
                      ? bookAppointmentState?.patientDetails?.email_id
                      : ''
                    : bookAppointmentState?.booking_method === 'others'
                    ? bookAppointmentState?.booking_for?.email
                      ? bookAppointmentState?.booking_for?.email
                      : ''
                    : ''}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

AppointmentDetails.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  number: PropTypes.string,
  email: PropTypes.string,
  label: PropTypes.string,
  editOnclick: PropTypes.func,
  myself: PropTypes.bool,
  editDownIconShown: PropTypes.bool,
  className: PropTypes.string,
  sx: PropTypes.object,
  isDoctor: PropTypes.any,
  icon: PropTypes.any,
  expanded: PropTypes.any,
};
