/* eslint-disable no-restricted-syntax */
import { clinicalRoutes } from '@hc/routes';
import { useLanguage, useSpeciality } from '@hc/store';
import { useChatConsultation } from '@hc/store/clinical/chatConsultation';
import {
  Button,
  CloseIcon,
  DateRangePicker,
  DoctorEmptyStateIllustration,
  Drawer,
  FilterIcon,
  Input,
  Label,
  NoRefferals,
  SearchIcon
} from '@hc/ui/atoms';

import {
  ClinicalChatHistoryCard,
  ClinicalChatListCard,
  DoctorFilter,
  EmptyStateComponent,
  Screenlayout,
  SkeletonCard
} from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { useSocket } from '@hc/utils/socket';
import { Badge, Box, Grid, Typography } from '@mui/material';
import { endOfDay } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { clinical_chat_list_page_style } from './style';

const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);
  return isIntersecting;
};

export default function ClinicalChatListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const socket = useSocket();

  const [tabIndex, setTabIndex] = useState('1');

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const IntersectionRef = useRef();
  const IntersectionRef1 = useRef();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const onScreen = useIntersectionObserver(IntersectionRef, { threshold: 1 });

  const [filterShow, setFilterShow] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  const {
    chatConsultationState,
    getLiveDoctorData,
    updateChatConsultationState,
    getChatHistoryDataById,
    getChatHistoryData,
    getChatCounsultationById,
    loading,
  } = useChatConsultation(
    (state) => ({
      getChatCounsultationById: state.getChatCounsultationById,
      chatConsultationState: state.chatConsultationState,
      getChatHistoryData: state.getChatHistoryData,
      getLiveDoctorData: state.getLiveDoctorData,
      getChatHistoryDataById: state.getChatHistoryDataById,
      updateChatConsultationState: state.updateChatConsultationState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getSpecialityData, specialityData } = useSpeciality(
    (state) => ({
      getSpecialityData: state.getSpecialityData,
      specialityData: state.specialityData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getLanguageData, languageData } = useLanguage(
    (state) => ({
      getLanguageData: state.getLanguageData,
      languageData: state.languageData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    languages,
    speciality,
    liveDoctorList,
    liveDoctorCount,
    chatHistoryCount,
    chatCounsultationData,
    patientChatData,
  } = chatConsultationState;

  const [showSearchBar, setShowSearch] = useState(
    (tabIndex === '1' && liveDoctorList?.length > 0) ||
      (tabIndex === '2' &&
        chatConsultationState?.chatHistoryData[0]?.doctorActiveData?.length >
          0) ||
      chatConsultationState?.chatHistoryData[1]?.doctorActiveData?.length > 0,
  );

  const chatCout = () => {
    const resArr = [];
    for (const obj of patientChatData) {
      for (const object of obj?.messageData) {
        if (object?.is_seen === false) {
          resArr?.push(object);
        }
      }
    }
    return resArr?.length;
  };

  const setTabIndexFnc = async (tabIndex) => {
    setTabIndex(tabIndex === '1' ? '2' : tabIndex === '2' ? '1' : '2');
  };

  const onSearchClear = async () => {
    setOffset(0);
    setSearch('');
    updateChatConsultationState('doctorSearch', '');
    queryClient.invalidateQueries({
      queryKey: ['getLiveDoctorData'],
    });
    await getLiveDoctorData('', 0, true);
  };

  const onOpenDrawer = async () => {
    if (tabIndex === '1') {
      // GET LANGUAGE FUNCTION
      await getLanguageData();
      // GET SPECIALITY FUNCTION
      await getSpecialityData();
      setFilterShow(true);
    } else {
      setOpenFilterDrawer(true);
    }
  };

  const onSearchFnc = async (event) => {
    setOffset(0);
    setSearch(event?.target?.value);
    updateChatConsultationState('doctorSearch', event?.target?.value);
    if (tabIndex === '1') {
      queryClient.invalidateQueries({
        queryKey: ['getLiveDoctorData'],
      });
      await getLiveDoctorData(event?.target?.value, 0, true);
    } else {
      queryClient.invalidateQueries({
        queryKey: ['chat_consultation_history'],
      });
      await getChatHistoryData(search, 0);
    }
  };

  // ONCHANGE FOR FILTER
  const onChange = (value, key) => {
    if (key === 'languages') {
      const arr = languages;
      languages?.push(value);
      return updateChatConsultationState('languages', arr);
    }
    updateChatConsultationState(key, value);
  };

  // CHIP ITEM DELETE
  const onChipDelete = (e) => {
    const result = languages?.filter((x) => x !== e);
    updateChatConsultationState('languages', result);
  };

  const onCloseDrawer = () => {
    setFilterShow(false);
  };

  const onChatClickFnc = (val) => {
    const data = {
      doctorProfileId: val?.user_profile_id ?? '',
      doctorUserId: val?.user_id ?? '',
      doctorProfilePicture: val?.profile_pic ?? '',
      doctorName: val?.first_name ?? '',
      doctorMobileNo: val?.mobile ?? '',
      doctorChatConsultationAmount: val?.chat_consultation_amount ?? 0,
      chatRequestedDate: new Date(),
      chatConsultationDuration: '15 Mins',
      patientUserId: tokenData?.user_id ?? '',
      patientProfileId: tokenData?.user_profile_id ?? '',
      patientProfilePicture: tokenData?.profile_pic ?? '',
      patientName: tokenData?.name ?? '',
      patientMobileNo: `${tokenData?.country_code} ${tokenData?.mobile_no}`,
      type: 'patientRequestToDoctor',
    };
    socket.emit('patient_request_the_chat_request_to_doctor', data);
    updateChatConsultationState('chatStatusData', data);
    navigate(clinicalRoutes?.chatStatus);
  };

  // GET HISTORY DATA PARTICULAR CHAT

  const onChatHistoryClick = async (chat_consltation_id, status, isHistory) => {
    updateChatConsultationState('chatStatus', status);
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_history_by_id'],
    });
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_data_by_id'],
    });

    const response = await getChatHistoryDataById(chat_consltation_id, status);
    const result = await getChatCounsultationById(chat_consltation_id);

    if (response?.status?.code === 200 && result?.status.code === 200) {
      if (
        response?.result[0]?.messageData?.length > 0 ||
        status === 'OnGoing'
      ) {
        navigate(clinicalRoutes?.chat, {
          state: { statusValue: status, history: isHistory },
        });
      } else {
        toast?.error('You have no data from this chat');
      }
    }
  };

  const onDateChangerClick = (event) => {
    updateChatConsultationState('chatStartDate', event?.startDate);
    updateChatConsultationState('chatEndDate', endOfDay(event?.endDate));
  };

  const onApply = async () => {
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_history'],
    });
    const response = await getChatHistoryData(search, offset);
    if (response?.code === 200) {
      setOpenFilterDrawer(false);
    }
  };

  const onClear = () => {
    updateChatConsultationState('chatStartDate', '');
    updateChatConsultationState('chatEndDate', '');
  };

  // CLEAR DOCTOR FILTER DRAWER
  const clearDrawer = async () => {
    updateChatConsultationState('languages', []);
    updateChatConsultationState('speciality', 0);
    queryClient.invalidateQueries({
      queryKey: ['getLiveDoctorData'],
    });
    await getLiveDoctorData(search, 0, true);
    setOffset(0);
  };

  // LIVE DOCTOR FILTER FUNCTION
  const liveDoctorFilter = async () => {
    if (languages?.length > 0 || speciality !== 0) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
    // GET LIVE DOCTOR LIST FUNCTION
    queryClient.invalidateQueries({
      queryKey: ['getLiveDoctorData'],
    });
    await getLiveDoctorData(search, 0, true);
    setOffset(0);
    setFilterShow(false);
  };

  // ----------------------Handle INFINITE SCROLL-----------------------

  const handleObserver = async () => {
    if (liveDoctorCount > 0 && liveDoctorCount > liveDoctorList?.length) {
      queryClient.invalidateQueries({
        queryKey: ['getLiveDoctorData'],
      });
      await getLiveDoctorData(search, offset + 1, false);
      setOffset(offset + 1);
    }
  };

  // -------------------------FETCH INITIAL STATE--------------------------------

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getLiveDoctorData'],
    });
    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_history'],
    });
    await getChatHistoryData(search, offset);
    await getLiveDoctorData(search, offset, true);
  };

  // GET APPOINTMENT LIST DATA
  useEffect(() => {
    getInitialData(search, 0);
    if (location?.state?.from === 'fromChatHistory') {
      setTabIndex('2');
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(
        'doctor_availability_status_change',
        ({ doctorProfileId, type }) => {
          if (type === 'online') {
            getInitialData();
          } else if (
            type === 'offline' &&
            Array.isArray(liveDoctorList) &&
            liveDoctorList?.length > 0
          ) {
            const result = liveDoctorList.filter(
              (val) => val?.user_profile_id !== doctorProfileId,
            );
            updateChatConsultationState('liveDoctorList', result);
          } else if (
            type === 'busy' &&
            Array.isArray(liveDoctorList) &&
            liveDoctorList?.length > 0
          ) {
            const result = [];
            // eslint-disable-next-line array-callback-return
            liveDoctorList.map((val) => {
              if (val?.user_profile_id === doctorProfileId) {
                result.push({ ...val, chat_availability_id: 2 });
              } else {
                result.push({ ...val });
              }
            });
            updateChatConsultationState('liveDoctorList', result);
          }
        },
      );
    }
    // eslint-disable-next-line
  }, [socket]);

  // -------------------------FEtch ON SCROLL------------------------------
  useEffect(() => {
    handleObserver();
  }, [onScreen]);

  useEffect(() => {
    setShowSearch(
      (tabIndex === '1' && liveDoctorList?.length > 0) ||
        (tabIndex === '2' &&
          chatConsultationState?.chatHistoryData[0]?.doctorActiveData?.length >
            0) ||
        chatConsultationState?.chatHistoryData[1]?.doctorActiveData?.length > 0,
    );
  }, [tabIndex]);

  return (
    <Screenlayout
      tabIndex={tabIndex}
      setTabIndex={() => setTabIndexFnc(tabIndex)}
      title="Chat"
      backRequired
      backRoute="/home"
      notshowFooter
      tabRequired
      tabData={[
        {
          label:
            tabIndex === '1'
              ? `Live Doctors  (${liveDoctorCount})`
              : `Live Doctors`,
          value: '1',
        },
        {
          label:
            tabIndex === '2'
              ? `Chat History  (${chatHistoryCount})`
              : `Chat History`,
          value: '2',
        },
      ]}
      masterchildStyle={{ pb: 0.5 }}
    >
      <Box px={1.5}>
        {showSearchBar && (
          <Grid container mt={2} columnSpacing={2} alignItems="center">
            <Grid item xs={10}>
              <Input
                id="search"
                placeholder="Search Doctors"
                textFieldStyle={clinical_chat_list_page_style.searchFiledSx}
                onChange={(e) => onSearchFnc(e)}
                value={search}
                startAdornment={!search?.length > 0 && <SearchIcon />}
                endAdornment={
                  search?.length > 0 && (
                    <Box
                      onClick={() => onSearchClear()}
                      sx={{ cursor: 'pointer' }}
                    >
                      <CloseIcon />
                    </Box>
                  )
                }
              />
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={clinical_chat_list_page_style.filterBoxSx}
                display="flex"
                justifyContent="center"
                onClick={() => onOpenDrawer()}
              >
                {showBadge ? (
                  <Badge color="primary" badgeContent=" " variant="dot">
                    <FilterIcon />
                  </Badge>
                ) : (
                  <FilterIcon />
                )}
              </Box>
            </Grid>
          </Grid>
        )}

        {tabIndex === '1' && (
          <>
            {loading && (
              <Box sx={{ height: '75vh', overflow: 'scroll' }}>
                {loading &&
                  Array(5)
                    .fill(1)
                    .map((val, i) => <SkeletonCard key={i} />)}
              </Box>
            )}
            {liveDoctorList?.length > 0 && (
              <Box mt={2.5}>
                {Array.isArray(liveDoctorList) && liveDoctorList.length > 0 && (
                  <Box pb={2} sx={{ height: '75vh', overflow: 'scroll' }}>
                    {Array.isArray(liveDoctorList) &&
                      liveDoctorList?.length > 0 &&
                      liveDoctorList?.map((val, i) => (
                        <Box key={i} pb={2}>
                          <ClinicalChatListCard
                            profilePicture={val?.profile_pic}
                            name={val?.first_name ?? ''}
                            speciality={val?.speciality_array ?? ''}
                            experience={val?.serving_from ?? ''}
                            status={val?.chat_availability_id}
                            chatFee={
                              val?.chat_consultation_amount > 0
                                ? `₹${val?.chat_consultation_amount}/15 Mins`
                                : ''
                            }
                            key={i}
                            language={val?.language_array ?? ''}
                            onChatClick={onChatClickFnc}
                            data={val}
                          />
                        </Box>
                      ))}
                    <div
                      ref={IntersectionRef}
                      style={{ marginTop: '-20px', opacity: 0 }}
                    >
                      intersection
                    </div>
                  </Box>
                )}
              </Box>
            )}

            {liveDoctorList?.length === 0 && !loading && (
              <EmptyStateComponent
                illustrationChild={<DoctorEmptyStateIllustration />}
                mainContent="No Doctors Found"
                subContent="As per your search no doctors found here"
              />
            )}
          </>
        )}
        {tabIndex === '2' && (
          <Box>
            {chatConsultationState?.chatHistoryData?.length > 0 &&
              chatConsultationState?.chatHistoryData.map((object, index) => (
                <Box mt={2.5} key={index}>
                  {object?.doctorActiveData?.length > 0 && (
                    <Box>
                      <Typography fontSize={14} fontWeight={500}>
                        {object?.type}
                      </Typography>
                      <Box mt={2.5}>
                        {object?.doctorActiveData?.map((obj, i) => (
                          <Box key={i} pb={2}>
                            <ClinicalChatHistoryCard
                              data={obj}
                              type={object?.type}
                              onChatHistoryClick={onChatHistoryClick}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            <div
              ref={IntersectionRef1}
              style={{ marginTop: '-20px', opacity: 0 }}
            >
              intersection
            </div>
            {chatConsultationState?.chatHistoryData[0]?.doctorActiveData
              ?.length === 0 &&
              chatConsultationState?.chatHistoryData[1]?.doctorActiveData
                ?.length === 0 &&
              !loading && (
                <EmptyStateComponent
                  illustrationChild={<NoRefferals />}
                  mainContent="No Records Found"
                  subContent="Records will appear once you start your chats"
                />
              )}
            {/* <Box sx={{ display: 'flex', justifyContent: 'end', p: 2.5 }}>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 110,
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Box
                  id="add"
                  sx={{ ...clinical_chat_list_page_style.speedDialRootSx }}
                  // onClick={() => onAddExistingClick()}
                >
                  <Box alignSelf="center">
                    <Badge
                      badgeContent={
                        chatCounsultationData?.consultation_status_name
                          ?.length > 0
                          ? chatCounsultationData?.consultation_status_name ===
                            'OnGoing'
                            ? chatCout()
                            : 0
                          : 0
                      }
                      color="secondary"
                    >
                      <WhatsAppChatIcon
                        color="#fff"
                        style={{
                          marginTop: '5px',
                          // height: '35px',
                          // width: '35px',
                        }}
                      />
                    </Badge>
                  </Box>
                </Box>
              </Box>
            </Box> */}
          </Box>
        )}
      </Box>
      {/* filter Drawer fot Doctor */}
      <DoctorFilter
        filterShow={filterShow}
        onCloseDrawer={onCloseDrawer}
        clearDrawer={clearDrawer}
        doctorFilter={liveDoctorFilter}
        filters={[
          {
            label: 'Specialty',
            value: speciality,
            component: 'Dropdown',
            onChange: (e) => onChange(e.target.value, 'speciality'),
            option:
              Array.isArray(specialityData) && specialityData?.length > 0
                ? specialityData
                : [],
          },
          {
            label: 'Language',
            value: languages,
            component: 'MultiSelect',
            onChange: (e) => onChange(e?.target?.value, 'languages'),
            onChipDelete,
            ChipText: languages,
            languageData,
            option:
              Array.isArray(languageData) && languageData?.length > 0
                ? languageData
                    ?.map((x) => (!languages?.includes(x?.value) ? x : false))
                    .filter(Boolean)
                : [],
          },
        ]}
      />
      <Drawer
        show={openFilterDrawer}
        isCloseIconRequired
        height="350px"
        drawerRightClose
        headerStyle={{ fontSize: '16px', fontWeight: 600 }}
        header="Filter"
        onCloseDrawer={() => setOpenFilterDrawer(false)}
        rootStyle={clinical_chat_list_page_style.drawerRootSx}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={onClear}
              buttonStyle={clinical_chat_list_page_style.buttonSx}
            >
              Clear
            </Button>
            <Button
              onClick={onApply}
              buttonStyle={clinical_chat_list_page_style.callButtonSx}
            >
              Apply
            </Button>
          </Box>
        }
      >
        <Grid container>
          <Grid pb={1.5} item xs={12}>
            <Label>Select Date or Date Range</Label>
            <DateRangePicker
              selectsRange
              startDate={chatConsultationState?.chatStartDate ?? new Date()}
              endDate={chatConsultationState?.chatEndDate ?? new Date()}
              onChange={(event) => onDateChangerClick(event)}
            />
          </Grid>
        </Grid>
      </Drawer>
    </Screenlayout>
  );
}
