export const forgotPassword_style = {
  imgSx: {
    width: '100%',
    height: '100%',
    pt: 2,
    pb: 4,
    borderRadius: '0%',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },
  paperSx: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 5,
    height: '100%',
    position: 'relative',
  },
  titleSx: {
    fontWeight: 600,
    p: '8px 0px',
  },
  subTitleSx: {
    fontWeight: '400',
    color: 'text.secondary',
    p: '7px 0px',
  },
  buttonStyle: {
    textTransform: 'none',
  },
  //  footerSX : {
  //   position: 'absolute',
  //   left: '0',
  //   bottom: '0',
  //   right: '0',
  //   textAlign: 'center',
  // },
  titleResetSx: {
    fontWeight: '600',
    textAlign: 'center',
  },
  resetSx: {
    textAlign: 'center',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
  imgContSx: {
    maxWidth: '90%',
    mx: 'auto',
    my: 'auto',
  },
  avatarSx: {
    width: '100%',
    maxWidth: '100px',
    height: '100px',
    margin: '7px auto',
  },
  labelStyle: {
    color: 'text.label',
    lineHeight: '2.4375em',
  },
  linkSx: {
    color: 'primary.main',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '0px 5px',
    fontSize: '14px',
    fontWeight: '600',
  },
};
