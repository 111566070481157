export const splashScreen_style = {
  rootSx: {
    display: 'grid',
    gridTemplateRows: 'minmax(auto, 34%) minmax(auto, 66%)',
    background: 'linear-gradient(172deg, #007965E3 25%, #279DAFE6 100%)',
    minHeight: '100vh',
    position: 'relative',
  },
  titleSx: {
    color: 'common.white',
    fontWeight: '600',
    pt: 2,
  },
  subtitleSx: {
    fontWeight: '400',
    textAlign: 'center',
    pt: 2.25,
    px: 9,
    color: '#F0F0F0',
  },
  resetSx: {
    textAlign: 'center',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
  avatarSx: {
    width: '100%',
    maxWidth: '100px',
    height: '100px',
    margin: '7px auto',
  },
  subrootSx: {
    width: '100%',
    minHeight: '100vh',
  },
  splashSx: {
    width: '100%',
    maxWidth: '350px',
  },
};
