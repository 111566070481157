export const getStarted_style = {
  bannerSx: {
    backgroundColor: 'purple.200',
  },
  bannerImgSx: {
    display: 'flex',
    position: 'relative',
    maxWidth: '90%',
    ml: 'auto',
    mr: 'auto',
    my: 'auto',
  },
  highlightSx: {
    color: '#6552CC',
  },
  buttonSx: {
    backgroundColor: 'mbf.main',
    '&:hover': {
      bgcolor: 'mbf.main',
    },
  },
  footerSx: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 0px 10px #efefef',
    p: 3,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
  },
  rootSx: {
    minHeight: '100vh',
    pb: 12,
  },
  mbfBannerImgSx: {
    width: '100%',
    height: '100%',
    pt: 2,
    pb: 4,
    borderRadius: '0%',
  },
  getStartedTitle: {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '600',
    pl: 1.3,
  },
  completedSx: {
    color: 'green',
  },
  BackIconSx: {
    color: '#fff',
    cursor: 'pointer',
  },
};
