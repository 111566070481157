export const questionnaire_style = {
  titleSx: {
    fontSize: '20px',
    fontWeight: 600,
    pt: 4,
  },
  paraSx: {
    fontSize: '14px',
    fontWeight: 400,
    pt: 5,
    pb: 3,
  },
  quesSx: {
    fontSize: '12px',
    fontWeight: 400,
  },
  totalSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'purple.600',
    pl: 2,
    py: 0.75,
    borderRadius: '48px',
    mb: 1.5,
  },
  totalBoxSx: {
    pt: 3,
  },
  qusAnsSx: {
    pr: 2.2,
    pl: 2.2,
  },
  linearBoxStyle: {
    paddingTop: '16px',
    position: 'fixed',
    width: '100%',
    maxWidth: '425px',
    backgroundColor: 'common.white',
  },
  checkBoxSx: {
    borderRadius: '50px',
    width: 36,
    height: 36,
    boxShadow: 'none',
    border: '1px solid',
    position: 'relative',
    borderColor: 'purple.600',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      boxShadow: 'none',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  checkSecondBoxSx: {
    backgroundColor: 'mbf.main',
    borderColor: 'purple.600',
    'input:hover ~ &': {
      backgroundColor: 'mbf.main',
    },
  },
};
