export const doctorFilter_style = {
  rootSx: { py: 1.5 },
  totalSx: {
    pb: 2,
    '&:last-child': {
      pb: 2,
    },
  },
  labelSx: {
    color: '#262C33',
  },
  componentSx: {
    pt: 1,
  },
  onClearSx: {
    bgcolor: '#FFEAEC',
    color: 'error.main',
    '&:hover': {
      bgcolor: '#FFEAEC',
    },
  },
};
