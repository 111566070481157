export const todaysActivity_Style = {
  titleTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 600,
  },
  activityTypeSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 2.5,
  },
  drawerHeaderSx: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  yogatextSx: {
    fontSize: 16,
    fontWeight: 600,
    color: 'text.primary',
  },

  indicateTextSx: {
    fontSize: 14,
    color: 'text.primary',
    fontWeight: 'medium',
  },
  progressUpdationSx: {
    mr: 2,
    mt: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  notesSx: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 500,
    color: '#1FB476',
    pt: 0.5,
  },
  dontsSx: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 500,
    color: '#FF980E',
    pt: 0.5,
  },
  refenceSx: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 500,
    color: '#1F91B4',
    pt: 0.5,
  },
};
