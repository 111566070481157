export const addEditProfile_style = {
  PersonalTextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    pb: 0.5,
  },
  vitalsTextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    mb: 1,
  },
  endAdornmentTextSx: {
    fontSize: '12px',
    color: 'text.disabled',
    opacity: 1,
  },
  inputWidthSx: {
    width: '100%',
    marginTop: '4px',
  },

  inputFieldSx: {
    marginTop: '4px',
  },
  bmiCalculationGroupSx: {
    border: '1px solid',
    borderColor: 'secondary.main',
    backgroundColor: 'amber.50',
    borderRadius: '4px',
    padding: '10px',
    mt: 2,
  },
  bmiCalculationValueSx: {
    fontSize: 12,
    color: 'text.label',
    fontWeight: 100,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  nameFieldSx: {
    marginTop: '4px',
    '& .MuiOutlinedInput-root': {
      pl: '12px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
};
