export const airtableFormRenderer_style = {
  rootSx: {
    backgroundColor: 'grey.100',
    p: 1.5,
  },
  cardSx: {
    p: 2,
    bgcolor: 'white',
    borderRadius: '8px',
  },
  textFieldSx: {
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'mbf.main',
      },
    },
  },
};
