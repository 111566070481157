export const chart_style = {
  optionRootSx: {
    display: 'grid',
    width: '70%',
    columnGap: '35px',
    rowGap: '10px',
    fontFamily: 'Poppins, sans-serif',
    margin: '24px auto 0 auto',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  optionBoxSx: {
    display: 'flex',
    alignItems: 'center',
  },
  optionColorDotSx: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    marginRight: '4px',
  },
};
