// import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useProblems } from '@hc/store/clinical/problem';
import { Button } from '@hc/ui/atoms';
import { HealthProblemsSelection } from '@hc/ui/components/clinical/appointmentBooking/selection/healthProblems';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import { useKeyPress } from 'ahooks';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { healthProblems_style } from './style';

export default function HealthProblems(props) {
  const { className = '', ...rest } = props;

  const { get } = useProblems(
    (state) => ({
      problemsData: state?.problemsData,
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  // const { clearPageData } = useBookAppointment(
  //   (state) => ({
  //     clearPageData: state.clearPageData,
  //   }),
  //   (prev, curr) => isEqual(prev, curr)
  // );
  // Gernal Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });
  // const handleClear = () => {
  // CLEAR HEALTH PROBLEM
  //   clearPageData('health_problems');
  // };
  // SKIP FUNCTION
  const onSkip = () => {
    // handleClear();
    navigate(location?.state?.prevRoute);
  };

  // GET PROBLEM DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={onSkip} buttonStyle={healthProblems_style.buttonSx}>
            Go back
          </Button>
          <Button onClick={() => navigate(location?.state?.prevRoute)}>
            Save
          </Button>
        </Box>
      }
      {...rest}
    >
      {/* Health Problems */}
      <Box>
        <Box sx={healthProblems_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <HealthProblemsSelection />
        </Box>
      </Box>
    </Screenlayout>
  );
}
