import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, localStorageKeys, parseJwt } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const UseFeedbacks = create((set, get) => ({
  feedBack: '',
  rating: null,
  feedBackPictures: [],
  loading: false,
  error: {
    rating: '',
    feedBack: '',
  },
  SendFeedback: async (profile) => {
    const { feedBack, rating, clearFeedBack, feedBackPictures } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const user = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/bugsandfeedbacks/clinical/feedback/submit`,
        {
          user_id: user.user_id,
          feedback_form_answer: {
            rating,
            content: feedBack,
            url: feedBackPictures.map((val) => val.downloadUrl),
            name: user.name,
            mobile_no: user.mobile_no,
            country_code: user.country_code,
            email: profile.email_id,
          },
          module: 'Clinical',
        },
        true
      );
      if (data.status.code === 200) {
        toast.success(data.status.message);
        clearFeedBack();
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  changeState: (value) => {
    set({ feedBack: value });
  },
  changeRating: (rate) => {
    set({ rating: rate });
  },
  isValid: () => {
    const { feedBack, rating, error } = get();
    let valid = true;
    const Error = structuredClone(error);
    if (!feedBack) {
      valid = false;
      Error.feedBack = 'Please enter tha valid feedback';
    } else {
      Error.feedBack = '';
    }
    if (rating === null) {
      valid = false;
      Error.rating = 'Please select your rating';
    } else {
      Error.rating = '';
    }
    set({ error: { ...Error } });
    return valid;
  },
  clearFeedBack: () => {
    set({
      feedBack: '',
      rating: null,
      feedBackPictures: [],
      error: {
        rating: '',
        feedBack: '',
      },
    });
  },

  feedBackPictureList: (data) => {
    const { feedBackPictures } = get();
    set({
      feedBackPictures: [...feedBackPictures, data],
    });
  },

  feedsDelete: (i) => {
    const { feedBackPictures } = get();
    const feedBackPicturesCopy = JSON.parse(JSON.stringify(feedBackPictures));
    feedBackPicturesCopy.splice(i, 1);
    set({ feedBackPictures: feedBackPicturesCopy });
  },
}));
