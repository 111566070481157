import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { appointmentCard_style } from './style';

export function AppointmentCard(props) {
  const {
    header = [],
    footer = [],
    dividerStyle = {},
    appointmentCardStyle = {},
    mainStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <Box
      sx={{ ...appointmentCard_style.rootSx, ...appointmentCardStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...appointmentCard_style.mainSx, ...mainStyle }}>
        <Box sx={appointmentCard_style.headerSx}>
          {header}
          <Divider
            sx={{ ...appointmentCard_style.dividerSx, ...dividerStyle }}
          />
        </Box>
        <Box>{footer}</Box>
        <Box sx={appointmentCard_style.arrow1Sx} />
        <Box sx={appointmentCard_style.arrow2Sx} />
      </Box>
    </Box>
  );
}

AppointmentCard.propTypes = {
  className: PropTypes.node,
  sx: PropTypes.object,
};
