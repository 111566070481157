export const Awards_style = {
  appBarSx: {
    bgcolor: 'purple.A100',
    pb: 2,
  },
  childrenSx: {
    pb: 0,
  },
  cardSx: {
    p: 3,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    boxShadow:
      'rgba(145, 158, 171, 0.2) 0px 0px 5px 0px, rgba(145, 158, 171, 0.12) 0px 20px 24px -4px',
    borderRadius: '22px',
    cursor: 'pointer',
  },
};
