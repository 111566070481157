import { CheckBox } from '@atoms';
import { GlobeIcon } from '@atoms/icons';
import { clinicalRoutes } from '@hc/routes';
import { Avatar, Box, Divider, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
import { RiArrowDownSLine, RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { familyMemberCard_style } from './style';

export function FamilyMemberCard(props) {
  const {
    name = '',
    src = '',
    relationship = '',
    gender,
    age,
    profileName = '',
    onClick = () => {},
    onClickEdit = () => {},
    // pathLink = {},
    rootStyle = {},
    is_use_my_number,
    className = '',
    confirmed = false,
    value = {},
    selectable = false,
    invited = false,
    // type = {},
    selected = false,
    key,
    onSelected = () => {},
    editId,
    ...rest
  } = props;

  const location = useLocation();

  return (
    <Box
      className={`${className}`}
      style={invited ? { opacity: ' 0.5' } : { opacity: 1 }}
      {...rest}
    >
      <Box sx={{ ...familyMemberCard_style.rootSx, ...rootStyle }}>
        <Box sx={{ position: 'relative' }}>
          {/* <img src={HalfRound} alt="" style={halfRoundSx} /> */}
          <Box sx={{ p: 1, pb: '8px!important' }}>
            <Box sx={familyMemberCard_style.appointmentCardSx}>
              <Box
                sx={
                  invited
                    ? familyMemberCard_style.linkSxx
                    : familyMemberCard_style.linkSx
                }
              >
                <Box sx={familyMemberCard_style.doctornameSx}>
                  <Avatar
                    sx={familyMemberCard_style.profileSx}
                    variant="rounded"
                    src={src}
                  >
                    {profileName}
                  </Avatar>
                  <Box sx={familyMemberCard_style.relationSX}>
                    <Typography variant="body1" sx={{ fontWeight: '600' }}>
                      {name}
                    </Typography>

                    <Box
                      sx={{ display: 'flex', gap: '1', alignItems: 'center' }}
                    >
                      <Typography sx={{ color: 'text.hint', fontSize: '12px' }}>
                        {relationship}
                      </Typography>
                      {relationship && (
                        <span
                          style={{
                            color: '#5C6266',
                            display: 'flex',
                            gap: '1',
                            alignItems: 'center',
                          }}
                        >
                          &nbsp;
                          <RiCheckboxBlankCircleFill
                            style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                          />
                          &nbsp;
                        </span>
                      )}
                      <Typography sx={{ color: 'text.hint', fontSize: '12px' }}>
                        {`${gender} / ${age}yrs`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {is_use_my_number &&
                location.pathname === clinicalRoutes.myFamily && (
                  <Box
                    onClick={() => {
                      if (is_use_my_number) {
                        onClickEdit(value);
                      }
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <MdEdit id={editId} style={{ color: '#0E1824' }} />
                  </Box>
                )}
              {confirmed && (
                <Box sx={familyMemberCard_style.gobalIconSx}>
                  <Divider
                    orientation="vertical"
                    sx={familyMemberCard_style.dividerSx}
                  />
                  <IconButton
                    disableFocusRipple
                    sx={familyMemberCard_style.iconbtnSx}
                    onClick={onClick}
                  >
                    <GlobeIcon />
                    <RiArrowDownSLine style={{ fontSize: '16px', px: 0.25 }} />
                  </IconButton>
                </Box>
              )}
              {selectable && (
                <Box sx={familyMemberCard_style.roundCheckBox}>
                  <CheckBox
                    disableRipple
                    label="CheckCircleIcon"
                    onClick={onSelected}
                    checked={selected}
                    checkStyle={{ p: 0 }}
                  />
                </Box>
              )}

              {invited && (
                <Box>
                  <Typography sx={familyMemberCard_style.invitedSx}>
                    Invited
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
FamilyMemberCard.propTypes = {
  name: PropTypes.string,
  relationship: PropTypes.string,
  gender: PropTypes.oneOf(['male', 'female', 'other']),
  profileName: PropTypes.string,
  age: PropTypes.string,
  selected: PropTypes.bool,
  confirmed: PropTypes.bool,
  invited: PropTypes.bool,
  className: PropTypes.object,
  selectable: PropTypes.bool,
  onClick: PropTypes.func,
  onClickEdit: PropTypes.func,
  editId: PropTypes.string,
};
