/* eslint-disable no-lonely-if */
import { BackIcon, Loader } from '@hc/ui/atoms';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, IconButton, Typography } from '@mui/material';
import Stories from 'react-insta-stories';
import { useLocation, useNavigate } from 'react-router-dom';
import { constructBenifitsStory } from '../awarenessSlider/sliderUtils';
import { benefitsSlider_Style } from './style';

export default function BenefitsSlider() {
  const navigate = useNavigate();
  const state = useLocation();
  const benefitSelector = state.state.benifits;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  // const val = constructStoryData(notesData);

  const onAllStoriesEndFnc = () => {
    if (data?.user_id) {
      navigate('/');
    } else {
      navigate('/');
    }
  };

  const onBackNavigate = () => {
    if (data?.user_id) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const benefitsData = [
    [
      {
        awareness_category_note_image:
          'https://storage.healthcircles.care/docs/v1/file/73104a6d-5f4c-4db6-b14f-ffc40d8b60dd.svg+xml',
        content:
          'Effortlessly monitor real-time health metrics, gain personalized insights, and make informed decisions. Think of it as having a customized health companion, guiding your unique well-being journey.',
        content_color_code: '#FFFFFF',
        gradient_left_color_code: '#79A2D1',
        gradient_right_color_code: '#376198',
        id: '397ec435-f041-4815-acbb-4eee22b9e012',
        title: 'Vital Monitoring',
        title_color_code: '#FFFFFF',
      },
    ],
    [
      {
        awareness_category_note_image:
          'https://storage.healthcircles.care/docs/v1/file/dd6640f3-fb1d-455e-b442-dce89caea842.svg+xml',
        content:
          'Track your wellness goals, receiving personalized tips for enhanced well-being along your journey. From fitness routines to nutrition insights, your path to balance commences here.',
        content_color_code: '#FFFFFF',
        gradient_left_color_code: '#7F79D1',
        gradient_right_color_code: '#377198',
        id: '397ec435-f041-4815-acbb-4eee22b9e012',
        title: 'Wellness Tracking',
        title_color_code: '#FFFFFF',
      },
    ],
    // [
    //   {
    //     awareness_category_note_image:
    //       'https://storage.healthcircles.care/docs/v1/file/e874a46d-fdb8-4cd2-9caf-1c999be46755.png',
    //     content:
    //       'The most common symptom of dengue, often sudden and high-grade, is accompanied by other signs like headache and muscle pain.',
    //     content_color_code: '#FFFFFF',
    //     gradient_left_color_code: '#00E3FD',
    //     gradient_right_color_code: '#007DFE',
    //     id: '397ec435-f041-4815-acbb-4eee22b9e012',
    //     title: 'Vital Monitoring',
    //     title_color_code: '#FFFFFF',
    //   },
    // ],
    // [
    //   {
    //     awareness_category_note_image:
    //       'https://storage.healthcircles.care/docs/v1/file/e874a46d-fdb8-4cd2-9caf-1c999be46755.png',
    //     content:
    //       'The most common symptom of dengue, often sudden and high-grade, is accompanied by other signs like headache and muscle pain.',
    //     content_color_code: '#FFFFFF',
    //     gradient_left_color_code: '#00E3FD',
    //     gradient_right_color_code: '#007DFE',
    //     id: '397ec435-f041-4815-acbb-4eee22b9e012',
    //     title: 'Vital Monitoring',
    //     title_color_code: '#FFFFFF',
    //   },
    // ],
    // [
    //   {
    //     awareness_category_note_image:
    //       'https://storage.healthcircles.care/docs/v1/file/e874a46d-fdb8-4cd2-9caf-1c999be46755.png',
    //     content:
    //       'The most common symptom of dengue, often sudden and high-grade, is accompanied by other signs like headache and muscle pain.',
    //     content_color_code: '#FFFFFF',
    //     gradient_left_color_code: '#00E3FD',
    //     gradient_right_color_code: '#007DFE',
    //     id: '397ec435-f041-4815-acbb-4eee22b9e012',
    //     title: 'Vital Monitoring',
    //     title_color_code: '#FFFFFF',
    //   },
    // ],
  ];

  return (
    <Box sx={benefitsSlider_Style.rootSx}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1000,
          top: 9,
          ml: '3px',
          cursor: 'pointer',
        }}
        display="flex"
        height="50px"
      >
        <IconButton onClick={() => onBackNavigate()}>
          <BackIcon rootStyle={{ color: '#000' }} />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            zIndex: 1000,
            position: 'fixed',
            top: 9,
            display: 'flex',
            justifyContent: 'center',
          }}
          display="flex"
          height="50px"
        >
          <Box alignSelf="center">
            {/* {categoryObjectData?.note_type === 'content' && data?.user_id?.length > 0 && } */}
            <Typography fontWeight={600} color="#fff">
              {benefitsData[benefitSelector].title}
            </Typography>
          </Box>
        </Box>
      </Box>

      {Array.isArray(benefitsData[benefitSelector]) &&
      benefitsData[benefitSelector]?.length > 0 ? (
        <Stories
          loop
          keyboardNavigation
          defaultInterval={8000}
          // isPaused
          stories={constructBenifitsStory(benefitsData[benefitSelector])}
          width="100%"
          storyContainerStyles={{ paddingTop: 0 }}
          height="100vh"
          onAllStoriesEnd={() => onAllStoriesEndFnc()}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box alignSelf="center">
            <Loader />
          </Box>
        </Box>
      )}
    </Box>
  );
}
