export const report_style = {
  addBtnSx: {
    textTransform: 'none',
    height: '40px',
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: 'primaryTints.300',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: 'primaryTints.300',
    },
  },
};
