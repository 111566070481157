export const notificationPopup_style = {
  totalBoxSx: {
    backgroundColor: 'common.white',
    p: 1,
    borderRadius: '8px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '335px',
    margin: '0px auto',
  },
  imgSx: {
    width: '60px',
    height: '60px',
    margin: '0px auto',
  },
  titleSx: {
    fontSize: '20px',
    fontWeight: 600,
    pt: 1.5,
  },
  subTitleSx: {
    fontSize: '14px',
    pb: 3,
    pt: 2,
    px: 3.25,
  },
  totalBtnSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notifyBtnSx: {
    width: '100%',
    maxWidth: '155px',
  },
  totalModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: '0px solid',
    boxShadow: 24,
    p: 4,
    outline: '0px',
  },
  notifyIconSx: {
    mt: 4,
  },
};
