/* eslint-disable no-nested-ternary */
import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity, useMBFLeaderboard, useMBFMoodStats } from '@hc/store';
import { BackIcon, Button } from '@hc/ui/atoms';
import { GetStartedBgIcon } from '@hc/ui/atoms/illustration';
import { MbfGetStartedCard, Stepper } from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStarted_style } from './style';

export default function GetStarted(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const navigate = useNavigate();
  const location = useLocation();
  const fromPlan = location?.state?.fromPlan ?? '';
  const from = location?.state?.from ?? '';

  const { mbfMood, getTodayMoodStats } = useMBFMoodStats((state) => ({
    mbfMood: state.mbfMood,
    getTodayMoodStats: state.getTodayMoodStats,
  }));

  const { getLeaderboardData, mbfLeaderboardState } = useMBFLeaderboard(
    (state) => ({
      getLeaderboardData: state.getLeaderboardData,
      mbfLeaderboardState: state.mbfLeaderboardState,
    })
  );

  const { checkPlanDataDetails, mbfState, getAnalysePlan, assignPlanLoading } =
    useMBFActivity((state) => ({
      checkPlanDataDetails: state.checkPlanDataDetails,
      mbfState: state.mbfState,
      getAnalysePlan: state.getAnalysePlan,
      assignPlanLoading: state.assignPlanLoading,
    }));

  const { checkPlanData } = mbfState;
  const { status, mbfUserPlanCount, previousPlanEndDate } = checkPlanData;

  const { member_data } = mbfLeaderboardState;

  const onBack = () => {
    if (from === 'RenewalFlow') {
      navigate(clinicalRoutes.mindbodyfood);
    } else {
      navigate(clinicalRoutes.home);
    }
  };

  const getStartedbtn = async () => {
    if (status === null || status === 0 || !status) {
      navigate(clinicalRoutes.medicalConditions, {
        state: {
          fromPlan: 'New',
        },
      });
      // navigate(clinicalRoutes.questiontypeevaluation);
    } else if (status === 1 || status === 2) {
      toast.success('You will get the notification when the plan is ready');
      onBack();
    } else if (status === 3) {
      navigate(clinicalRoutes.plansummary);
    }
  };

  const getInitialData = async () => {
    if (fromPlan === 'New') {
      navigate(clinicalRoutes.questiontypeevaluation);
    } else if (fromPlan === 'Renewal' || from === 'RenewalFlow') {
      // Check plan status
      await getAnalysePlan(true);
    }
    if (
      (typeof checkPlanData === 'object' &&
        Object.keys(checkPlanData)?.length === 0) ||
      status === 0
    ) {
      // Check plan status
      const { statusCode, status } = await checkPlanDataDetails(true);
      if (statusCode === 200 && status === 4) {
        await getTodayMoodStats(data?.user_id);
      }
    }
    if (status === 4 && (!mbfMood || mbfMood === null)) {
      await getTodayMoodStats(data?.user_id, 'layout');
    }

    // Route based on plan status
    else if (
      status &&
      (status === 1 || status === 2) &&
      !assignPlanLoading &&
      from !== 'NewFlow' &&
      (!mbfUserPlanCount || mbfUserPlanCount === 0 || mbfUserPlanCount === 1)
    ) {
      await getAnalysePlan(true);
    } else if (status === 4) {
      if (!mbfMood || mbfMood === null) {
        navigate(clinicalRoutes?.moodforyou);
      } else {
        navigate(clinicalRoutes?.mindbodyfood);
      }
    }

    if (
      ((status === null || status === 1 || status === 2) &&
        mbfUserPlanCount > 1 &&
        from !== 'RenewalFlow') ||
      (from !== 'RenewalFlow' && previousPlanEndDate?.length > 0)
    ) {
      navigate(clinicalRoutes?.mindbodyfood);
    }

    // To get leaderboard data
    if (data?.employee_membership_code) {
      await getLeaderboardData({
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: '',
        end_date: '',
      });
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  if (
    typeof checkPlanData === 'object' &&
    Object.keys(checkPlanData)?.length === 0 &&
    !mbfMood
  ) {
    return (
      <Box sx={{ display: 'grid', minHeight: '100vh', placeItems: 'center' }}>
        <CircularProgress
          sx={{
            color: 'mbf.main',
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={getStarted_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={getStarted_style.bannerSx}>
        <Box sx={getStarted_style.bannerImgSx}>
          <Box px={1} mt={1} sx={{ position: 'absolute', left: 6, top: 2 }}>
            <BackIcon
              fill="#6552CC"
              onClick={() => onBack()}
              rootStyle={getStarted_style.BackIconSx}
            />
          </Box>
          <GetStartedBgIcon rootStyle={getStarted_style.mbfBannerImgSx} />
        </Box>
      </Box>
      {/* content */}
      <Box sx={{ px: 2.5, py: 3, backgroundColor: 'purple.50' }}>
        {/* content heading */}
        <Box>
          <Typography sx={getStarted_style.getStartedTitle}>
            Strengthen your{' '}
            <span style={getStarted_style.highlightSx}>Mind</span> to nourish
            <br /> your
            <span style={getStarted_style.highlightSx}>&nbsp;Body</span> with
            healthy
            <span style={getStarted_style.highlightSx}>&nbsp;Food</span>
          </Typography>
        </Box>

        {/* steps */}
        <Stepper />
        {data?.employee_membership_code && (
          <MbfGetStartedCard data={member_data ?? []} />
        )}
      </Box>

      {/* Footer Button */}
      <Box sx={{ ...getStarted_style.footerSx }}>
        <Button
          buttonStyle={getStarted_style.buttonSx}
          onClick={() => getStartedbtn()}
        >
          {status === null || status === 0 || status === undefined
            ? 'Get Started'
            : status === 1 || status === 2
            ? 'Remind me when plan is ready'
            : status === 3
            ? 'View Plan Details'
            : 'Get Started'}
        </Button>
      </Box>
    </Box>
  );
}
