import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Slider, { SliderThumb } from '@mui/material/Slider';
import PropTypes from 'prop-types';
import * as React from 'react';
import { VitalTempIcon } from '../../../atoms';

function valuetext(value) {
  return `${value}°C`;
}

const TempSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    // height: 27,
    // width: 27,
    backgroundColor: '#fff',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <VitalTempIcon />
    </SliderThumb>
  );
}

export function VitalTemperatureSlider(props) {
  const { min, max, step, value, onChange = () => {} } = props;

  return (
    <Box sx={{ width: 300 }}>
      <TempSlider
        min={min}
        max={max}
        value={value}
        step={step}
        slots={{ thumb: AirbnbThumbComponent }}
        aria-label="Temperature"
        defaultValue={30}
        onChange={(event, value) => onChange(value)}
        getAriaValueText={valuetext}
      />
    </Box>
  );
}

VitalTemperatureSlider.propTypes = {
  min: PropTypes.any,
  max: PropTypes.any,
  step: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
