import { DropIcon, DropUpIcon, EditIcon } from '@atoms';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { appointmentDetails_style } from './style';

export function VitalDetails(props) {
  const {
    heading = '',
    content = [],
    editDownIconShown = true,
    icon,
    className = '',
    editOnclick,
    noDataFound = '',
    expanded = false,
    ...rest
  } = props;
  const IntialList = content.slice(0, 2);
  const [expand, setExpand] = useState(expanded);

  return (
    <Box
      sx={appointmentDetails_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={appointmentDetails_style.titleSx}>
          {icon}
          <Typography sx={appointmentDetails_style.headerSx}>
            {heading}
          </Typography>
        </Box>
        {editDownIconShown && (
          <Box
            sx={{
              ...appointmentDetails_style.titleSx,
              gap: 2,
              cursor: 'pointer',
            }}
          >
            <EditIcon onClick={editOnclick} />
            {expand ? (
              <DropIcon
                onClick={() => setExpand(false)}
                rootStyle={{ cursor: 'pointer' }}
              />
            ) : (
              <DropUpIcon
                onClick={() => setExpand(true)}
                rootStyle={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        )}
      </Box>
      {expand ? (
        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          {IntialList && Array.isArray(IntialList) && IntialList.length
            ? IntialList.map((val, index) =>
                val?.value && val?.value !== 0 && val?.value !== '' ? (
                  <>
                    {index > 0 ? (
                      <span
                        key={index}
                        style={appointmentDetails_style.labelSxx}
                      >
                        {', '}{' '}
                      </span>
                    ) : null}
                    <Box
                      key={index}
                      as="span"
                      sx={appointmentDetails_style.labelSx}
                    >
                      {val?.value + val?.unit}
                      <Box sx={appointmentDetails_style.labelSxx} as="span">
                        {val?.name}
                      </Box>
                    </Box>
                  </>
                ) : null
              )
            : null}
          {content.length - IntialList.length > 0 ? (
            <Box sx={appointmentDetails_style.pointsSx} as="span">
              {`+${content.length - IntialList.length}`}
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>
          {content &&
          Array.isArray(content) &&
          content.length &&
          !content?.every((v) => v?.value === 0 || v?.value === '') ? (
            content.map((val, index) =>
              val?.value && val?.value !== 0 && val?.value !== '' ? (
                <>
                  {index > 0 ? (
                    <span key={index} style={appointmentDetails_style.labelSxx}>
                      {', '}{' '}
                    </span>
                  ) : null}
                  <Box
                    key={index}
                    as="span"
                    sx={appointmentDetails_style.labelSx}
                  >
                    {val?.value + val?.unit}
                    <Box sx={appointmentDetails_style.labelSxx} as="span">
                      {val?.name}
                    </Box>
                  </Box>
                </>
              ) : null
            )
          ) : (
            <Typography sx={appointmentDetails_style.noDataFound}>
              {noDataFound}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

VitalDetails.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.array,
  editDownIconShown: PropTypes.bool,
  icon: PropTypes.any,
  className: PropTypes.string,
  editOnclick: PropTypes.any,
  noDataFound: PropTypes.string,
  expanded: PropTypes.bool,
};
