/* eslint-disable react/display-name */
import { clinicalRoutes } from '@hc/routes';
import { clinicalLoginRoutes, localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { useEffect, useState } from 'react';

import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

function PrivateRouter(props) {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    // get the authToken from local storage
    const authToken = localStorage.getItem(localStorageKeys?.authToken);
    const authTempKey = localStorage.getItem(localStorageKeys?.authTempKey);

    if (
      (!authToken || authTempKey === 'true') &&
      !clinicalLoginRoutes.some((route) => route === location?.pathname)
    ) {
      localStorage.clear();
      navigate(clinicalRoutes.login);
    }
    if (location.pathname === clinicalRoutes.onboarding) {
      localStorage.clear();
    }

    if (authToken && authTempKey !== 'true') {
      const data = parseJwt(authToken);
      // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
      if (data?.exp && moment().unix() >= data?.exp) {
        localStorage.clear();
        navigate(clinicalRoutes.login);
      } else if (
        data?.mobile_verified !== true &&
        !clinicalLoginRoutes.some((route) => route === location?.pathname)
      ) {
        localStorage.clear();
        navigate(clinicalRoutes.login);
      }
      // If have auth token and the mobile number is verified but the name is null redirect to the add profile screen & !profile screen
      else if (
        data?.mobile_no &&
        data?.mobile_verified === true &&
        data?.name === null &&
        location?.pathname !== clinicalRoutes.resetPassword &&
        location?.pathname !== clinicalRoutes.addProfile
      ) {
        // ------------ New private router line ---------------------
        if (location?.pathname !== clinicalRoutes.otpSplash) {
          navigate(clinicalRoutes.addProfile);
        }
      }
      // if the path is one of the login routes and we have auth token & mobile number verified & have the name redirect to home
      else if (
        data?.mobile_verified === true &&
        clinicalLoginRoutes.some((route) => route === location?.pathname)
      ) {
        if (data?.name === null) {
          navigate(clinicalRoutes.addProfile);
        } else if (data?.name !== null && location?.pathname === '/') {
          navigate(clinicalRoutes.home);
        }
        // ------------ New private router removed lines ---------------------
        // else {
        //   navigate(clinicalRoutes.home);
        // }
      } else if (
        location?.pathname === clinicalRoutes.addProfile &&
        data?.name !== null
      ) {
        navigate(clinicalRoutes.home);
      }
    }
    setShowComponent(true);
  }, [location]);

  if (showComponent) {
    return children;
  }
  return null;
}

export { PrivateRouter };

