import { MembersRightIcon } from '@atoms';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { selectMemberType_style } from './style';

export function SelectMemberType(props) {
  const {
    title = '',
    subTitle = '',
    rootStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...selectMemberType_style.rootSx, ...rootStyle }}>
        <Box sx={selectMemberType_style.cardContentSx}>
          <Box sx={selectMemberType_style.imageWithTextSx}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item md={11} sm={11} xs={11}>
                <Box>
                  <Typography
                    variant="body2"
                    sx={selectMemberType_style.titleSx}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={selectMemberType_style.subTitleSx}
                  >
                    {subTitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="end"
                md={1}
                sm={1}
                xs={1}
              >
                <MembersRightIcon />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

SelectMemberType.propTypes = {
  healthStatus: PropTypes.string,
  healthStatusContent: PropTypes.string,
  className: PropTypes.node,
  sx: PropTypes.object,
};
