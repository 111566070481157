import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  CheckBox,
  CloseIcon,
  DoctorMedicalRepIcon,
  NoRefferals,
  Switch,
  // Switch,
  TabpadIcon,
  UserProfileIcon,
  VitalMeterIcon,
} from '../../../atoms';
import { EmptyStateComponent } from '../../clinical';
import { medicalCard_style } from './style';

function MedicalCard(props) {
  const {
    patientName = 'Arunachalam R',
    subText = 'male',
    // age = '24',
    mobile = ' +91 9992299999',
    userPic = false,
    height = '',
    weight = '',
    temp = '',
    Pulse = '',
    bp = '',
    o2 = '',
    isShow = false,
    allergies = [],
    medications = [],
    health_problems = [],
    rootStyle = {},
    isVitalCheck,
    isMedicalConditionCheck,
    isMedicalCardSent,
    onSendVitals = () => {},
    medicalCondition = [],
    onDelete = () => {},
    onVitalChange = () => {},
    onMedicalConditionChange = () => {},
    data = {},
    isVitalShow,
    isMedicalConditionShow,
    // data = {},
  } = props;

  console.log(data, 'data');

  const [checked, setChecked] = useState(false);
  const onChecked = () => {
    setChecked(!checked);
  };

  return (
    <Box
      sx={{
        ...medicalCard_style.rootSx,
        ...rootStyle,
      }}
    >
      <Box
        sx={{
          height: '80px',
          display: 'flex',
          alignItems: 'end',
          backgroundColor: '#007965',
          borderRadius: '8px',
          justifyContent: 'space-between',
          px: 0.3,
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#FFFFFF',
            ml: 1,
            mb: 1,
          }}
        >
          {' '}
          Medical card
        </Typography>
        <Box mr={2} mb={1}>
          <TabpadIcon />
        </Box>
      </Box>

      {isShow ? (
        <Box>
          <Stack direction="row" alignItems="center" px={1} py={1}>
            <Avatar aria-label="recipe" sx={medicalCard_style.avatar} px={0.4}>
              {userPic ? (
                <img
                  src={userPic}
                  alt=""
                  style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                />
              ) : (
                <UserProfileIcon rootStyle={{ color: 'common.black' }} />
              )}
            </Avatar>

            <Typography sx={medicalCard_style.headingSx}>
              Patient details
            </Typography>
          </Stack>
          <Divider />
          <Grid container mt={1} p={1}>
            <Grid item xs={1.5}>
              <Avatar aria-label="recipe" sx={medicalCard_style.avatar}>
                {userPic ? (
                  <img
                    src={userPic}
                    alt=""
                    style={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <UserProfileIcon rootStyle={{ color: 'common.black' }} />
                )}
              </Avatar>
            </Grid>
            <Grid item xs={10.5} display="flex">
              <Grid container alignSelf="center">
                <Grid item xs={12} display="flex">
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ fontWeight: 500 }}
                  >
                    {patientName}
                  </Typography>
                  <Box
                    component="span"
                    style={{
                      ...medicalCard_style.subText,
                      background: '#F5F5F5',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    ml={1}
                  >
                    {subText}
                    {/* {age && (
                      <Box component="span" style={medicalCard_style.subText}>
                        ,{age} Yrs
                      </Box>
                    )} */}
                  </Box>
                </Grid>
                {mobile && (
                  <Grid item xs={12} display="flex" justifyContent="start">
                    <Box component="span" style={medicalCard_style.subText}>
                      {mobile}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* {isMedicalCardSent ? ( */}

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
              <VitalMeterIcon />
              <Typography sx={medicalCard_style.headingSx} ml={0.6}>
                Vitals
              </Typography>
            </Box>

            <Divider />
            {isVitalShow ? (
              <Box py={2}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    pl: 4.5,
                    '& span': { color: '#C5C5C5' },
                  }}
                >
                  {`${height} Ft`} <span>(Height) </span>,{`${weight} Kg`}
                  <span>(weight) </span>,<br />
                  {`${temp} Temp`} <span>(temp) </span>,{`${Pulse} Ft`}{' '}
                  <span>(Pulse) </span>, <br />
                  {`${bp} Ft`} <span>(BP) </span>,{`${o2} O2`}{' '}
                  <span>(O2) </span>
                </Typography>
              </Box>
            ) : (
              <Box p={1}>
                <EmptyStateComponent
                  illustrationChild={<NoRefferals />}
                  mainContent="No Records Found"
                  isChild
                />
              </Box>
            )}

            <Box mt={1}>
              <Box sx={{ display: 'flex', alignItems: 'center' }} mb={1} px={1}>
                <DoctorMedicalRepIcon />
                <Typography sx={medicalCard_style.headingSx} px={0.6}>
                  Medical Condition
                </Typography>
              </Box>

              <Divider />
              {isMedicalConditionShow ? (
                <Box py={0.3}>
                  <Typography mt={1} sx={medicalCard_style.headingSx} px={2}>
                    Allergies
                  </Typography>
                  <Box p={2} pl={3}>
                    {allergies?.map((val, i) => (
                      <Box
                        key={i}
                        sx={{
                          background: '#F5F5F5',
                          border: '1px solid #E3E3E3',
                          borderRadius: '6px',
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: '14px', fontWeight: '500' }}
                        >
                          {val}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <Typography mt={1} sx={medicalCard_style.headingSx} px={2}>
                    Medication
                  </Typography>
                  <Box p={2} pl={3}>
                    {medications?.map((val, i) => (
                      <Box
                        key={i}
                        sx={{
                          background: '#F5F5F5',
                          border: '1px solid #E3E3E3',
                          borderRadius: '6px',
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: '14px', fontWeight: '500' }}
                        >
                          {val}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <Typography mt={1} sx={medicalCard_style.headingSx} px={2}>
                    Health Problems
                  </Typography>
                  <Box p={2} pl={3}>
                    {health_problems?.map((val, i) => (
                      <Box
                        key={i}
                        sx={{
                          background: '#F5F5F5',
                          border: '1px solid #E3E3E3',
                          borderRadius: '6px',
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: '14px', fontWeight: '500' }}
                        >
                          {val}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box p={1}>
                  <EmptyStateComponent
                    illustrationChild={<NoRefferals />}
                    mainContent="No Records Found"
                    isChild
                  />
                </Box>
              )}
            </Box>
          </Box>
          {/* ) : (
            <Box height={300} display="flex" justifyContent="center">
              <Typography fontSize={14} alignSelf="center" fontWeight={600}>
                No data Found
              </Typography>
            </Box>
          )} */}
        </Box>
      ) : (
        <Box>
          <Stack direction="row" alignItems="center" px={1} py={0.4}>
            <Avatar aria-label="recipe" sx={medicalCard_style.avatar}>
              {userPic ? (
                <img src={userPic} alt="" />
              ) : (
                <UserProfileIcon rootStyle={{ color: 'common.black' }} />
              )}
            </Avatar>

            <Typography sx={medicalCard_style.headingSx}>
              Patient details
            </Typography>
          </Stack>
          <Divider />
          <Grid container mt={1} p={1}>
            <Grid item xs={1.5}>
              <Avatar aria-label="recipe" sx={medicalCard_style.avatar}>
                {userPic ? (
                  <img src={userPic} alt="" />
                ) : (
                  <UserProfileIcon rootStyle={{ color: 'common.black' }} />
                )}
              </Avatar>
            </Grid>
            <Grid item xs={10.5} display="flex">
              <Grid container alignSelf="center">
                <Grid item xs={12} display="flex">
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ fontWeight: 500 }}
                  >
                    {patientName}
                  </Typography>
                  <Box
                    component="span"
                    style={{
                      ...medicalCard_style.subText,
                      background: '#F5F5F5',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    ml={1}
                  >
                    {subText}
                    {/* {age && (
                      <Box component="span" style={medicalCard_style.subText}>
                        ,{age} Yrs
                      </Box>
                    )} */}
                  </Box>
                </Grid>
                {mobile && (
                  <Grid item xs={12} display="flex" justifyContent="start">
                    <Box component="span" style={medicalCard_style.subText}>
                      {mobile}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            p={1}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <VitalMeterIcon />
              <Typography sx={medicalCard_style.headingSx} ml={0.6}>
                Vitals
              </Typography>
            </Box>
            <Box>
              <Switch
                checked={isVitalCheck}
                onChange={(event) => onVitalChange(event?.target?.checked)}
              />
            </Box>
          </Box>

          <Divider />
          <Box p={1}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                pl: 4.5,
                '& span': { color: '#C5C5C5' },
              }}
            >
              {`${height} Ft`} <span>(Height) </span>,{`${weight} Kg`}
              <span>(weight) </span>,<br />
              {`${temp} Temp`} <span>(temp) </span>,{`${Pulse} Ft`}{' '}
              <span>(Pulse) </span>, <br />
              {`${bp} Ft`} <span>(BP) </span>,{`${o2} O2`} <span>(O2) </span>
            </Typography>
          </Box>

          <Box mt={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
              px={1}
            >
              <Box display="flex" alignItems="center">
                <DoctorMedicalRepIcon />
                <Typography sx={medicalCard_style.headingSx} px={0.6}>
                  Medical Condition
                </Typography>
              </Box>
              <Box>
                <Switch
                  checked={isMedicalConditionCheck}
                  disabled={medicalCondition?.length === 0}
                  onChange={(event) =>
                    onMedicalConditionChange(event?.target?.checked)
                  }
                />
              </Box>
            </Box>

            <Divider />

            <Box mt={1} ml={1} display="flex">
              {medicalCondition.length > 0 &&
                medicalCondition.map((val, index) => (
                  <Box key={index} as="span" sx={medicalCard_style.labelSx}>
                    <Box> {val?.value}</Box>
                    {!isMedicalCardSent && (
                      <Box
                        mt="2px"
                        ml="5px"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onDelete(val)}
                      >
                        <CloseIcon rootStyle={{ color: '#F44F5A' }} />
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>

          {/* {isMedicalCardSent ? ( */}
          <Box px={1} py={2} display="flex">
            <CheckBox checked={checked} onChange={onChecked} />
            <Typography fontSize={14} fontWeight={600}>
              Selected details will be shared with the doctor (it can be done
              only once).
            </Typography>
          </Box>
          {/* // ) : ( */}
          <Box px={1} py={2}>
            <Button
              disabled={!checked}
              onClick={() => onSendVitals()}
              buttonStyle={{ textTransform: 'capitalize' }}
            >
              Send to Doctor
            </Button>
          </Box>
          {/* )} */}
        </Box>
      )}
    </Box>
  );
}

MedicalCard.propTypes = {
  rootStyle: PropTypes.object,
  patientName: PropTypes.string,
  subText: PropTypes.string,
  age: PropTypes.string,
  mobile: PropTypes.string,
  userPic: PropTypes.string,
  height: PropTypes.string,
  weight: PropTypes.string,
  temp: PropTypes.string,
  Pulse: PropTypes.string,
  bp: PropTypes.string,
  o2: PropTypes.string,
  allergies: PropTypes.array,
  medications: PropTypes.array,
  health_problems: PropTypes.array,
  isShow: PropTypes.bool,
  onSendVitals: PropTypes.func,
  medicalCondition: PropTypes.array,
  onDelete: PropTypes.func,
  onVitalChange: PropTypes.func,
  onMedicalConditionChange: PropTypes.func,
  isMedicalCardSent: PropTypes.bool,
  isVitalCheck: PropTypes.bool,
  isMedicalConditionCheck: PropTypes.bool,
  data: PropTypes.object,
};

export { MedicalCard };
