/* eslint-disable consistent-return */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { groupBy, parseJwt } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFActivity = create((set, get) => ({
  mbfState: {
    mbfActivityStateConstructData: {},
    perDayOverAllPercentage: 0,
    tillDayCount: 0,
    overallDayCount: 0,
    planId: '',
    editData: {},
    checkPlanData: {},
  },
  loading: true,
  imageLoading: true,
  error: null,
  assignPlanLoading: false,
  activityUpsert: async (payload) => {
    try {
      set({ loading: false });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/mbf/plan/today-activity/update`,
        {
          ...payload,
        },
        true,
      );
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        // toast.success(response?.data?.status?.message);

        toast.success('Activity Progress Updated!');
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getTodayActivity: async (isLoading) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      if (isLoading) {
        set({ loading: true });
      }

      const { data } = await queryClient.fetchQuery({
        queryKey: ['getTodayActivity'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/plan/today-activity/getAll?user_id=${tokenData?.user_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });
      let groupByResponse = [];
      if (
        Array.isArray(data?.today_activities) &&
        data?.today_activities?.length > 0
      ) {
        groupByResponse = await groupBy(
          data?.today_activities,
          'activity_time_name',
        );
      }
      set({ loading: false });
      set((state) => ({
        mbfState: {
          ...state.mbfState,
          planId: data?.user_plan_id,
          mbfActivityStateConstructData: groupByResponse ?? [],
        },
      }));
      return data?.today_activities;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getOverallPlanPercentage: async (isLoading) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      if (isLoading) {
        set({ loading: true });
      }
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getOverallPlanPercentage'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/overall-plan/percentage/get?user_id=${tokenData?.user_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      return set((state) => ({
        mbfState: {
          ...state.mbfState,
          perDayOverAllPercentage: data?.overall_track_percentage ?? 0,
          tillDayCount: data?.till_date_count ?? 0,
          overallDayCount: data?.overall_day_count ?? 0,
        },
        loading: false,
      }));
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateMbfStore: (key, value) => {
    const { mbfState } = get();
    return set(() => ({
      mbfState: {
        ...mbfState,
        [key]: value,
      },
    }));
  },

  updateMbfEditDataStore: (key, value) => {
    const { mbfState } = get();
    const { editData } = mbfState;
    return set(() => ({
      mbfState: {
        ...mbfState,
        editData: {
          ...editData,
          [key]: value,
        },
      },
    }));
  },

  checkPlanDataDetails: async (initialFetch) => {
    try {
      set({ loading: true });
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      queryClient.invalidateQueries({
        queryKey: ['checkPlanDataDetails'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['checkPlanDataDetails'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/user_plan_details/get?user_id=${
              tokenData?.user_id ?? ''
            }`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        const { mbfState } = get();
        set({
          loading: false,
          mbfState: {
            ...mbfState,
            checkPlanData: {
              isFeedBackSubmitted: data?.is_feedback_submiited,
              isGoalsSubmitted: data?.is_goals_submitted,
              previousPlanEndDate: data?.previous_plan_end_date,

              mbfUserPlanCount: data?.mbf_user_plan_count ?? 0,
              status: data?.status ?? null,
            },
          },
        });

        if (initialFetch && data?.status === 4) {
          const { getOverallPlanPercentage, getTodayActivity } = get();

          // To refetch the query, which was cached
          await queryClient.invalidateQueries({
            queryKey: ['getOverallPlanPercentage'],
          });

          // To refetch the query, which was cached
          await queryClient.invalidateQueries({
            queryKey: ['getTodayActivity'],
          });
          await getOverallPlanPercentage(true);
          await getTodayActivity(true);
        }
      }
      set({ loading: false });
      return { statusCode: status?.code, status: data?.status };
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getAnalysePlan: async (showToast) => {
    try {
      set({ assignPlanLoading: true });
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      queryClient.invalidateQueries({
        queryKey: ['getAnalysePlan'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getAnalysePlan'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/user_plan_details/get?user_id=${
              tokenData?.user_id ?? ''
            }`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        const { mbfState } = get();
        if (showToast) {
          if (data?.status === 1) {
            toast(`Your Plan is yet to be Assigned!`);
          } else if (data?.status === 2) {
            toast(`Your plan is under review. Please wait!`);
          } else if (data?.status === 3) {
            toast(`Your wellness plan is ready now!`);
          }
        }
        set({
          assignPlanLoading: false,
          mbfState: {
            ...mbfState,
            checkPlanData: {
              isFeedBackSubmitted: data?.is_feedback_submiited,
              isGoalsSubmitted: data?.is_goals_submitted,
              previousPlanEndDate: data?.previous_plan_end_date,

              mbfUserPlanCount: data?.mbf_user_plan_count ?? 0,
              status: data?.status ?? null,
            },
          },
        });
      }
      set({ assignPlanLoading: false });
    } catch (error) {
      set({ assignPlanLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  levelUpdate: (statusValue) => {
    const { mbfState } = get();
    const { checkPlanData } = mbfState;
    return set({
      mbfState: {
        ...mbfState,
        checkPlanData: { ...checkPlanData, status: statusValue ?? 0 },
      },
    });
  },

  planChangeRequestUpsert: async (description) => {
    try {
      set({ loading: false });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/mbf/plan-change-request`,
        {
          description: description,
        },
        true,
      );
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        toast.success(response?.data?.status?.message);

        // toast.success('Activity Progress Updated!');
      } else {
        set({ loading: false });
        toast.error(
          response?.data?.status?.message ??
            'Something went wrong please try again!',
        );
      }
      return response?.data?.status?.code;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
