import Leaderboardillus from '@assets/Leaderboardillus.png';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MbfRankCard } from '../../mbfrankcard';
import { mbfGetStartedCard_style } from './style';

function MbfGetStartedCard(props) {
  const { className = '', rootStyle = {}, data = [], ...rest } = props;

  return (
    <Box
      sx={{
        ...mbfGetStartedCard_style.rootSx,
        backgroundImage: `url(${Leaderboardillus.svg})`,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Typography
          sx={{ fontSize: '14px', fontWeight: 600, mt: 1, textAlign: 'center' }}
        >
          Catch up with your friends!
        </Typography>
        <Typography
          sx={{ fontSize: '12px', fontWeight: 600, mt: 1, textAlign: 'center' }}
        >
          <span style={{ color: '#6552CC' }}>Get started</span> now
        </Typography>
        <MbfRankCard
          data={data}
          sx={{
            mt: 3,
            display: 'flex',
            AlignItems: 'center',
            justifyContent: 'center',
            marginLeft: '-18px',
          }}
        />
      </Box>
    </Box>
  );
}

MbfGetStartedCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MbfGetStartedCard };
