import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Performance_Style } from './style';

export function PerformanceStats(props) {
  const {
    titleText = '',
    subTitleText = '',
    icon,
    headerCircular,
    cardRootStyle,
    textStyle,
    subtextStyle,
    className = '',
    ...rest
  } = props;

  return (
    <Box className={`${className}`} {...rest} sx={Performance_Style.rootSx}>
      <Box sx={{ ...Performance_Style.cardRootSx, ...cardRootStyle }}>
        <Box sx={Performance_Style.cardContentSx}>
          <Box sx={Performance_Style.imageWithTextSx}>
            {headerCircular}
            <Box>
              <Typography
                variant="body2"
                sx={{ ...Performance_Style.titleSx, ...textStyle }}
              >
                {titleText}
                <span>
                  <img
                    src={icon}
                    alt="carousel"
                    style={{ width: '23px', height: '22px' }}
                  />
                </span>
              </Typography>
              <Typography
                variant="body2"
                sx={{ ...Performance_Style.subTitleSx, ...subtextStyle }}
              >
                {subTitleText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PerformanceStats.propTypes = {
  headerCircular: PropTypes.object,
  titleText: PropTypes.string,
  icon: PropTypes.object,
  subTitleText: PropTypes.string,
};
