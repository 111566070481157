import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@styles/animation.css';
import '@styles/global.css';

import { lightTheme, theme } from '@hc/theme';
import { queryClient } from '@lib/config/queryClient';
import {
  CssBaseline,
  ThemeProvider,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import RouterApp from '@router';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { Animate } from '@hc/ui/atoms/animate';

const themeGenerator = createTheme({
  ...theme,
  ...lightTheme,
});

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

function App() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log(`SW Registered: ${r}`);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setNeedRefresh(false);
  };

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeGenerator}>
          <CssBaseline />
          <RouterApp />
          <Animate
            show={needRefresh}
            entryAnimation="fadeInTop"
            duration="1s"
            exitAnimation="fadeOutTop"
          >
            <Box
              sx={{
                position: 'fixed',
                top: 74,
                zIndex: '1000',
                left: 0,
                right: 0,
                width: '100%',
                maxWidth: {
                  sm: 320,
                },
                mx: {
                  sm: 'auto',
                },
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                py: 1.5,
                boxSizing: 'border-box',
                bgcolor: 'common.white',
                borderColor: 'border.main',
                boxShadow: '0px -2px 23px #00000014',
                borderRadius: 2,
              }}
            >
              <Box>
                <Typography variant="body1  " sx={{ color: 'grey.700' }}>
                  New update available, click on reload to get the new changes!
                </Typography>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}
                >
                  {needRefresh && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => updateServiceWorker(true)}
                    >
                      Reload
                    </Button>
                  )}
                  <Button size="small" onClick={() => close()}>
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          </Animate>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
