export const mbfEmptyState_style = {
  rootSx: {},
  getBtn:{
    cursor: 'pointer' ,
    backgroundColor: "#F3F0FF",
    py:.6,
    px:1,
    borderRadius: "4px"
  }
};
