import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { localStorageKeys, parseJwt, queryClient, routeTo } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const usePrescription = create((set, get) => ({
  prescriptionsData: [
    {
      id: '',
      doctorName: '',
      doctorSpecialities: [],
      patientName: '',
      patientAge: '',
      patientGender: '',
      appointmentTime: '',
      appointmentDate: '',
      prescriptionData: [],
      prescriptionDownloadUrl: '',
    },
  ],
  prescriptionData: null,
  allPrescriptionsData: null,
  loading: true,
  error: null,
  getAllPrescriptionsData: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);

      set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['allPrescriptions'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/appointments/prescription-getAll`,
            { id: tokenData?.user_id ?? '' },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      return set(() => ({
        loading: false,
        allPrescriptionsData: data,
        prescriptionsData: [
          {
            id: data?.[0]?.id,
            doctorName: data?.[0]?.assigned_doctor_profile?.first_name,
            doctorSpecialities:
              data?.[0]?.assigned_doctor_profile?.doctor_specialities ?? [],
            prescriptionData:
              data?.[0]?.visit_summaries?.[0]?.visit_summary_prescriptions ??
              [],
            patientName: data?.[0]?.appointment_members?.name ?? '',
            patientAge: data?.[0]?.appointment_members?.age ?? '',
            patientGender: data?.[0]?.appointment_members?.gender ?? '',
            appointmentTime: data?.[0]?.appointment_members?.updatedAt ?? '',
            appointmentDate: data?.[0]?.appointment_members?.updatedAt ?? '',
            prescriptionDownloadUrl:
              data?.[0]?.visit_summaries?.[0]?.visit_report_url ?? '',
          },
        ],
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'something went worng please try aagin !',
      );
    }
  },
  getPrescriptionData: (id) => {
    try {
      const { allPrescriptionsData } = get();
      const allPrescriptions = allPrescriptionsData;

      const data = allPrescriptions.filter((val) => val.id.includes(id));
      set(() => ({
        loading: false,
        prescriptionData: {
          id: data?.[0]?.id,
          doctorName: data?.[0]?.assigned_doctor_profile?.first_name,
          doctorSpecialities:
            data?.[0]?.assigned_doctor_profile?.doctor_specialities ?? [],
          prescriptionData:
            data?.[0]?.visit_summaries?.[0]?.visit_summary_prescriptions ?? [],
          patientName: data?.[0]?.appointment_members?.name ?? '',
          patientAge: data?.[0]?.appointment_members?.age ?? '',
          patientGender: data?.[0]?.appointment_members?.gender ?? '',
          appointmentTime: data?.[0]?.appointment_members?.updatedAt ?? '',
          appointmentDate: data?.[0]?.appointment_members?.updatedAt ?? '',
          prescriptionDownloadUrl:
            data?.[0]?.visit_summaries?.[0]?.visit_report_url ?? '',
          visitSummaryUpdatedAt:
            data?.[0]?.visit_summaries?.[0]?.visit_summary_prescriptions?.[0]
              ?.updated_at ?? '',
        },
      }));
      return routeTo(useRouting, `/prescription/${id}`);
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'something went worng please try aagin !',
      );
    }
  },
}));
