// import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useMedication } from '@hc/store/clinical/medication';
import { Button } from '@hc/ui/atoms';
import { MedicationSelection } from '@hc/ui/components/clinical/appointmentBooking/selection/medication';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils';
import { Box } from '@mui/material';
import { useKeyPress } from 'ahooks';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { medications_style } from './style';

export default function Medications(props) {
  const { className = '', ...rest } = props;
  const referral = localStorage.getItem(localStorageKeys.isReferralAppoinment);
  const keyReferralData = JSON.parse(referral);

  const { get, loading } = useMedication(
    (state) => ({
      medicationData: state?.medicationData,
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // const { clearPageData } = useBookAppointment(
  //   (state) => ({
  //     bookAppointmentState: state?.bookAppointmentState,
  //     clearPageData: state.clearPageData,
  //   }),
  //   (prev, curr) => isEqual(prev, curr)
  // );

  // General Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });
  // CLEAR DRUG FUNCTION
  // const handleClear = () => {
  // CLEAR DRUG
  //   clearPageData('drugs');
  // };

  // SKIP FUNCTION
  const onSkip = () => {
    // handleClear();
    navigate(-1);
  };

  const onContinue = () => {
    if (location?.state?.prevRoute) {
      navigate(location?.state?.prevRoute);
    } else if (keyReferralData?.is_patient_referral) {
      navigate(location?.state?.prevRoute, {
        state: {
          doctorId: keyReferralData?.assigned_doctor_profile?.id,
        },
      });
    } else {
      navigate(location?.state?.prevRoute);
    }
  };

  // GET MEDICATION DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={onSkip} buttonStyle={medications_style.buttonSx}>
            Go back
          </Button>
          <Button loading={loading} onClick={() => onContinue()}>
            Save
          </Button>
        </Box>
      }
      {...rest}
    >
      {/* Medications */}
      <Box>
        <Box sx={medications_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <MedicationSelection />
        </Box>
      </Box>
    </Screenlayout>
  );
}
