export const existingMember_Style = {

  headerTextSx: {
    fontSize: '14px',
    color: 'text.secondary',
    pt: 2
  },
  buttonSx: {
    textTransform: 'capitalize'
  },
  commonHeightSx: {
    mt: 3
  },
  commonHeighSx: {
    mt: 0.5
  },
  noMemberSx:{
    fontSize: '16px',
    color: 'text.primary',
    fontWeight:600,
    mt: -2,
    textAlign:'center',
  }
};
