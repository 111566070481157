import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useProblems } from '@hc/store/clinical/problem';
import { SearchIcon, SearchSelect } from '@hc/ui/atoms';
import { QusAnsSelectionLisiting } from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useKeyPress } from 'ahooks';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { HealthProblemsSelection_style } from './style';

export function HealthProblemsSelection() {
  const filter = createFilterOptions();
  const { problemsData, quickProblemsData, get } = useProblems(
    (state) => ({
      problemsData: state?.problemsData,
      quickProblemsData: state.quickProblemsData,
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bookAppointmentState, clearPageData, addHealthProblems, deleteData } =
    useBookAppointment(
      (state) => ({
        bookAppointmentState: state?.bookAppointmentState,
        clearPageData: state.clearPageData,
        addHealthProblems: state.addHealthProblems,
        deleteData: state.deleteData,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const { health_problems } = bookAppointmentState;
  const [reset, setReset] = useState(null);
  const [addProblems, setAddProblems] = useState('');

  // Gernal Hooks

  // ADD HEALTH PROBLEM FUNCTION
  const handleAdd = (e, option) => {
    if (option?.value) {
      // ADD HEALTH PROBLEM
      addHealthProblems(e, option);
      // GET PROBLEM DATA
      get();
      setReset({ value: '', label: '', labelShow: '' });
      setAddProblems('');
    }
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  // GET PROBLEM DATA BASED ON SEARCH

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  // DELETE HEALTH ISSUE FUNCTION
  const handleDelete = (i) => {
    // DELETE HEALTH ISSUE
    deleteData('health_problems', i);
  };

  // CLEAR HEALTH PROBLEM FUNCTION
  const handleClear = () => {
    // CLEAR HEALTH PROBLEM
    clearPageData('health_problems');
  };

  // GET PROBLEM DATA
  useEffect(() => {
    get();
  }, []);

  return (
    <QusAnsSelectionLisiting
      QusAnsSelectionHeading="What problems do you have?"
      QusAnsListingHeading="Health Problems"
      QusAnsListingAdded="Added"
      OnClearAll={handleClear}
      quickSuggestion
      displaySelectionValues={health_problems}
      OnDelete={handleDelete}
      sugesstionClick={handleAdd}
      qsOptionViewKey="label"
      quickSuggestionRequired={quickProblemsData}
      QusAnsSelectionListing={
        <SearchSelect
          startAdornment={<SearchIcon rootStyle={{ color: 'grey.500' }} />}
          placeholder="Eg. Ulcer"
          onChangeText={(e) => setAddProblems(e?.target?.value ?? '')}
          autocompeleteStyle={{
            ...HealthProblemsSelection_style.autocompeleteSx,
          }}
          renderOption={(props, option) => {
            return (
              <Box
                sx={{ cursor: 'pointer' }}
                height="40px"
                onClick={(e) => handleAdd(e, option)}
                px={2}
                key={option.label}
                display="flex"
                {...props}
              >
                {option?.label?.includes('ADD') ? (
                  <Box display="flex" alignSelf="center">
                    <Typography
                      sx={{
                        color: '#EB6D13',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      +ADD &nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {addProblems}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignSelf="center">
                    <Typography>{option.label}</Typography>
                  </Box>
                )}
              </Box>
            );
          }}
          filterOptions={(options, params) => getFilteredadata(options, params)}
          onChange={handleAdd}
          optionViewKey="label"
          value={reset}
          options={problemsData.filter(
            (problem) =>
              !health_problems.some(
                (eachProblem) => eachProblem?.label === problem?.label
              )
          )}
        />
      }
    />
  );
}
