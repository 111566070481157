import { Crown } from '@hc/ui/atoms';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { mbfRank_style } from './style';

function MbfRank(props) {
  const {
    Rank = '',
    firstName = '',
    profilePic = {},
    avatarStyle = {},
    firstNameStyle = {},
    className = '',
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...mbfRank_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {Rank === 1 ? (
        <Crown sx={{ width: '31px', height: '23px', ml: 4 }} />
      ) : null}
      <Typography
        variant="body1"
        sx={{
          fontWeight: 'bold',
          color: 'text.secondary',

          textAlign: 'center',
        }}
      >
        {Rank === 1 ? null : Rank}
      </Typography>
      <Avatar
        src={profilePic}
        sx={{
          width: 24,
          height: 24,
          border: '4px solid ',
          borderColor: '#EAE6FF',
          zIndex: -2,
          ...avatarStyle,
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          variant="body1"
          sx={{
            display: 'block',
            fontSize: '10px',
            color: '#888888',
            textAlign: 'center',
            fontWeight: 600,
            width: '65px',
            mt: 1,
            pl: 1,
            position: 'absolute',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...firstNameStyle,
          }}
        >
          {firstName}
        </Typography>
      </Box>
    </Box>
  );
}

MbfRank.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  Rank: PropTypes.object,
  firstName: PropTypes.string,
  profilePic: PropTypes.object,
  avatarStyle: PropTypes.object,
  firstNameStyle: PropTypes.object,
};

export { MbfRank };
