import { useProfile } from '@hc/store/clinical/profile';
import { useBloodGroup } from '@hc/store/common/bloodGroup';
import { AddEditProfile } from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';

export function EditFamilyProfile() {
  const {
    get,
    profile,
    handleProfileDataChange,
    // updateProfileError,
    updateMobileNumber,
    // loading,
  } = useProfile(
    (state) => ({
      get: state.get,
      profile: state.profile,
      clearProfile: state.clearProfile,
      handleProfileDataChange: state.handleProfileDataChange,
      updateProfileError: state.updateProfileError,
      updateMobileNumber: state.updateMobileNumber,
      loading: state.loading,
      upsertProfile: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const [tokenData, setTokenData] = useState({});

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    handleProfileDataChange(key, value);
  };

  useEffect(() => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    // UPDATE MOBILE NUMBER
    updateMobileNumber(data);
    const tokenData_ = localStorage.getItem(localStorageKeys.authToken);
    const parseTokenData = parseJwt(tokenData_);
    setTokenData(parseTokenData);
    if (!profile?.name?.length > 0) {
      get();
    }
    getBloodGroupData();
  }, []);
  return (
    <Box>
      <AddEditProfile
        bloodGroupData={bloodGroupData}
        corporate={tokenData?.employee_membership_code?.length > 0}
        profile={profile}
        handleChange={handleChange}
      />
    </Box>
  );
}
