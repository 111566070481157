import { envConfig } from '@hc/config';
import { clinicalRoutes } from '@hc/routes';
import { AirtableFormRenderer } from '@hc/ui/components/airtableFormRenderer';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { feedback_style } from './style';

export default function EvaluationType(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();

  return (
    <Screenlayout
      title="Plan Feedback"
      notshowFooter
      appBarStyle={{ ...feedback_style.appBarSx }}
      backRequired
      childrenStyle={feedback_style.childrenSx}
      backIconStyle={{ color: 'mbf.main' }}
      backRoute={clinicalRoutes.mindbodyfood}
    >
      <Box sx={feedback_style.rootSx} className={`${className}`} {...rest}>
        <Box sx={feedback_style.containerSx}>
          <AirtableFormRenderer
            baseId={envConfig.airtable_app_base_id}
            tableId={envConfig.airtable_plan_feedback}
            onSubmit={async () => {
              navigate(clinicalRoutes.mindbodyfood);
            }}
          />
        </Box>
      </Box>
    </Screenlayout>
  );
}
