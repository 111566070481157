/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import { Button } from '@atoms/button';
import { useAirtable, useMBFOnboarding } from '@hc/store';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { MdEditNote } from 'react-icons/md';
import { airtableFormRenderer_style } from './style';

function AirtableFormRenderer(props) {
  const {
    className = '',
    baseType = '',
    baseId,
    tableId,
    onSubmitFnc,
    rootStyle = {},
    ...rest
  } = props;

  const [errors, setErrors] = useState({});
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const {
    getTable,
    formData,
    setFormData,
    // loadingWrite,
    error,
    table,
    loading,
  } = useAirtable((state) => ({
    getTable: state?.getTable,
    formData: state?.formData,
    setFormData: state?.setFormData,
    error: state?.error,
    loading: state?.loading,
    loadingWrite: state?.loadingWrite,
    table: state?.table,
  }));

  const { createUserRecord, formLoading } = useMBFOnboarding((state) => ({
    createUserRecord: state.createUserRecord,
    formLoading: state.formLoading,
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();

    // filtering fields to ignore the validation check
    const filteredFields = table.fields.filter((field) => {
      if (!['mobile number', 'id'].includes(field.name.toLowerCase())) {
        if (
          field.description &&
          field.description.toLowerCase().includes('option')
        ) {
          return false;
        }
        return true;
      }
    });

    // Validation check
    const errosData = filteredFields.reduce((prev, curr, index, array) => {
      // validating the other fields
      if (
        array[index - 1] &&
        (array[index - 1].type === 'singleSelect' ||
          array[index - 1].type === 'multipleSelects') &&
        array[index - 1].options.choices.some(
          (choice) => choice.name.toLowerCase() === 'other'
        )
      ) {
        // validating the other field for singleSelect
        if (
          formData[array[index - 1]?.name] &&
          array[index - 1].type === 'singleSelect' &&
          formData[array[index - 1]?.name].toLowerCase() === 'other' &&
          (!formData[curr?.name] || formData[curr?.name].length === 0)
        ) {
          return {
            ...prev,
            [curr?.id]: 'This field is required. Kindly fill it!',
          };
        }
        // validating the other field for singleSelect
        if (
          formData[array[index - 1]?.name] &&
          array[index - 1].type === 'multipleSelects' &&
          formData[array[index - 1]?.name].some(
            (item) => item.toLowerCase() === 'other'
          ) &&
          (!formData[curr?.name] || formData[curr?.name].length === 0)
        ) {
          return {
            ...prev,
            [curr?.id]: 'This field is required. Kindly fill it!',
          };
        }
        return prev;
      }
      // validating other than other fields
      if (!formData[curr?.name] || formData[curr?.name].length === 0) {
        return {
          ...prev,
          [curr?.id]: 'This field is required. Kindly fill it!',
        };
      }
      return prev;
    }, {});
    if (Object.keys(errosData).length > 0) {
      toast('Kindly fill all the required fields');
    } else {
      // submitting the form
      let payload = { ...formData };
      // including the mobile number value
      const mobileField = table.fields.filter(
        (field) => field.name.toLowerCase() === 'mobile number'
      );
      if (mobileField.length > 0) {
        const authToken = localStorage.getItem(localStorageKeys.authToken);
        const data = parseJwt(authToken);
        payload = {
          ...payload,
          [mobileField[0].name]: data?.country_code
            ? // eslint-disable-next-line prefer-template
              data?.country_code + ' ' + data?.mobile_no
            : data?.mobile_no,
        };
      }
      const createRecordPayload = {
        user_id: data?.user_id,
        baseId,
        tableId,
        recordData: payload,
      };
      queryClient.invalidateQueries({
        queryKey: ['mbfCreateUserRecord'],
      });
      const res = await createUserRecord(createRecordPayload, baseType);
      if (res?.code === 200) {
        onSubmitFnc();
      }
    }
    setErrors(errosData);
  };

  function AirtableFieldRenderer(field) {
    const { type } = field;
    switch (type) {
      case 'singleLineText':
        return (
          <TextField
            sx={{ ...airtableFormRenderer_style.textFieldSx }}
            value={formData[field.name]}
            onChange={(e) => {
              setFormData({
                ...formData,
                [field.name]: e.target.value,
              });
            }}
            placeholder={field.name}
            variant="outlined"
            fullWidth
          />
        );
      case 'multilineText':
        return (
          <TextField
            sx={{ ...airtableFormRenderer_style.textFieldSx }}
            value={formData[field.name]}
            onChange={(e) => {
              setFormData({
                ...formData,
                [field.name]: e.target.value,
              });
            }}
            placeholder={field.name}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        );
      case 'number':
        return (
          <TextField
            value={formData[field.name]}
            placeholder={field.name}
            sx={{ ...airtableFormRenderer_style.textFieldSx }}
            onChange={(e) => {
              setFormData({
                ...formData,
                [field.name]: parseInt(e.target.value, 10),
              });
            }}
            type="number"
          />
        );
      case 'multipleSelects': {
        const { choices } = field.options;
        return (
          <FormControl sx={{ display: 'grid', mt: 1, gap: 0.5 }}>
            {choices.map((choice, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    sx={{
                      color: 'mbf.main',
                      '&.Mui-checked': {
                        color: 'mbf.main',
                      },
                    }}
                    checked={
                      formData[field?.name]
                        ? formData[field.name].some(
                            (name) => name === choice.name
                          )
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        const savedResponses = formData[field?.name] ?? [];
                        savedResponses.push(choice.name);
                        setFormData({
                          ...formData,
                          [field.name]: savedResponses,
                        });
                      } else {
                        const filteredResponse = formData[field.name].filter(
                          (name) => name !== choice.name
                        );
                        setFormData({
                          ...formData,
                          [field.name]: filteredResponse,
                        });
                      }
                    }}
                  />
                }
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                  '& .MuiTypography-root': { fontSize: 14 },
                }}
                label={choice.name}
              />
            ))}
          </FormControl>
        );
      }
      case 'singleSelect': {
        const { choices } = field.options;
        return (
          <FormControl component="fieldset" margin="normal">
            <RadioGroup
              value={formData[field?.name] ?? ''}
              onChange={(event, value) => {
                setFormData({
                  ...formData,
                  [field.name]: value,
                });
              }}
              sx={{ display: 'grid', gap: 0.5 }}
            >
              {choices.map((choice,i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Radio
                      sx={{
                        color: 'mbf.main',
                        '&.Mui-checked': {
                          color: 'mbf.main',
                        },
                      }}
                    />
                  }
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 20 },
                    '& .MuiTypography-root': { fontSize: 14 },
                  }}
                  label={choice.name}
                  value={choice.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }
      case 'multipleAttachments':
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', px: '6' }}>
            <Box sx={{ width: '100px' }}>
              <Button size="large">Upload</Button>
            </Box>
          </Box>
        );

      default:
        break;
    }
  }

  const initialData = async () => {
    await getTable(baseId, tableId);
    setFormData({});
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      sx={{
        ...airtableFormRenderer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {(loading || error) && (
        <Box sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}>
          {loading && <CircularProgress sx={{ color: 'mbf.main' }} />}
          {error && !loading && (
            <Typography variant="h3" textAlign="center" color="initial">
              Unable to render the form
            </Typography>
          )}
        </Box>
      )}
      {!loading && table && Object.keys(table).length > 0 && (
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'grid', gap: '12px' }}>
            <Box
              sx={{
                ...airtableFormRenderer_style.cardSx,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <MdEditNote size={36} color="gray" />
              <Typography variant="h5" fontWeight="500" color="initial">
                {table?.name}
              </Typography>
            </Box>
            {table.fields.map((field, index) => {
              if (field.name.toLowerCase() === 'mobile number') return;
              // Not to show the field after the choices having other option
              if (
                table.fields[index - 1] &&
                (table.fields[index - 1].type === 'singleSelect' ||
                  table.fields[index - 1].type === 'multipleSelects') &&
                table.fields[index - 1].options.choices.some(
                  (choice) => choice.name.toLowerCase() === 'other'
                )
              )
                return;
              return (
                <Box key={index}>
                  <Box
                    sx={{
                      ...airtableFormRenderer_style.cardSx,
                      display: 'grid',
                      gap: 1,
                    }}
                  >
                    <Box>
                      <Typography as="span" variant="body1" fontWeight="500">
                        {field.name}
                        {!field?.description
                          ?.toLowerCase()
                          .includes('option') && (
                          <span
                            style={{
                              marginLeft: '6px',
                              color: 'red',
                            }}
                          >
                            *
                          </span>
                        )}
                      </Typography>
                    </Box>
                    {AirtableFieldRenderer(field)}

                    {/* Single select other option handling */}
                    {formData[field.name] &&
                      field.type === 'singleSelect' &&
                      formData[field.name].toLowerCase() === 'other' && (
                        <>
                          <TextField
                            value={
                              formData[table?.fields[index + 1]?.name] ?? ''
                            }
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [table?.fields[index + 1]?.name]:
                                  e.target.value,
                              });
                            }}
                            error={!!errors[table?.fields[index + 1].id]}
                            placeholder={field.name}
                            variant="outlined"
                            fullWidth
                          />
                          {errors[table?.fields[index + 1].id] && (
                            <Typography variant="body2" color="error.main">
                              {errors[field.id]}
                            </Typography>
                          )}
                        </>
                      )}
                    {/* Multiselect select other option handling */}
                    {formData[field.name] &&
                      field.type === 'multipleSelects' &&
                      formData[field.name].some(
                        (item) => item.toLowerCase() === 'other'
                      ) && (
                        <>
                          <TextField
                            value={
                              formData[table?.fields[index + 1]?.name] ?? ''
                            }
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [table?.fields[index + 1]?.name]:
                                  e.target.value,
                              });
                            }}
                            placeholder={field.name}
                            error={!!errors[table?.fields[index + 1].id]}
                            variant="outlined"
                            fullWidth
                          />
                          {errors[table?.fields[index + 1].id] && (
                            <Typography variant="body2" color="error.main">
                              {errors[field.id]}
                            </Typography>
                          )}
                        </>
                      )}
                    {errors[field.id] && (
                      <Typography variant="body2" color="error.main">
                        {errors[field.id]}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
            <Button
              buttonStyle={{
                m: 4,
                // width: 'max-content',
                mx: 'auto',
                backgroundColor: 'mbf.main',
                '&:hover': {
                  backgroundColor: 'mbf.dark',
                },
                '&:focus': {
                  backgroundColor: 'mbf.dark',
                },
                '&:active': {
                  backgroundColor: 'mbf.dark',
                },
                '& .MuiLoadingButton-loadingIndicator': {
                  color: 'mbf.main',
                },
                '&.MuiLoadingButton-loading': {
                  backgroundColor: 'mbf.light',
                },
              }}
              loading={formLoading}
              type="submit"
              variant="contained"
            >
              {baseType === 'FeedBack' ? 'Next' : 'Submit'}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
}

AirtableFormRenderer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  baseType: PropTypes.string,
};

export { AirtableFormRenderer };
