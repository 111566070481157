export const mbfStepperBanner_style = {
  container: {
    backgroundColor: '#EAE6FF ',
    borderRadius: '10px',
    mt: 1,
    height: '140px',
  },
  buttonSx: {
    mt: 0.5,
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 600,
    color: '#fff',
    backgroundColor: 'mbf.main',
    '&:hover': {
      bgcolor: 'mbf.main',
    },
  },

  descriptionSx: {
    textAlign: 'left',
    fontSize: '18px',
    wordSpacing: '1px',
    fontWeight: 700,
    P: 4,
    '& span': {
      color: '#6552CC',
    },
  },
  stepperrowSx: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
  },
  stepNumberSx: {
    borderRadius: '50%',
    color: 'mbf.main',
    fontWeight: '700',
    fontSize: '10px',
    position: 'absolute ',
    textAlign: 'center',
    marginLeft: '0.5px',
    marginBottom: ' 0.2px',
    height: '19px',
    width: '19px',
  },
  // contentWrapSx: {
  //   '&:last-child': {
  //     '&: before': {
  //       borderLeft: '0px !important',
  //       content: '""',
  //     },
  //   },
  //   display: 'grid',
  //   position: 'relative',
  // },

  contentWrapSx: {
    '&::before': {
      content: '""',
      backgroundColor: 'purple.300',
      color: 'purple.600',
      // padding:'10px',
      // fontSize:'16px',
      position: 'absolute ',
      borderLeft: '2px dashed !important',
      height: '100%',
      left: '11px',
      margin: '3px 1px',
    },
    '&:last-child': {
      '&: before': {
        borderLeft: '0px !important',
        content: '""',
      },
    },
    display: 'grid',
    position: 'relative',
  },

  titileSx: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0px',
    color: '#0E1824',
  },
  subTitileSx: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0px',
    color: '#5C6266',
  },
  imgcontent: {
    backgroundColor: '#DFD8FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
  },
};
