import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { appointmentShedule_style } from './style';

export function AppointmentShedule(props) {
  const {
    icon = [],
    dateText = '',
    yearText = '',
    timeText = '',
    timeIcon = [],
    timingClock = '',
    videoIcon = {},
    rootStyle = {},
    videoIconStyle = {},
    confirmed,
    className = '',
    ...rest
  } = props;
  return (
    <Box
      sx={{ ...appointmentShedule_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={appointmentShedule_style.generalIconSx}>{icon}</Box>
        <Box ml={1.2}>
          <Typography sx={appointmentShedule_style.dataTextSx}>
            {dateText}
          </Typography>
          <Typography sx={appointmentShedule_style.dayTextSx}>
            {yearText}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={appointmentShedule_style.generalIconSx}>{timeIcon}</Box>
        <Box ml={1.2}>
          <Typography sx={appointmentShedule_style.dataTextSx}>
            {timeText}
          </Typography>
          <Typography sx={appointmentShedule_style.dayTextSx}>
            {timingClock}
          </Typography>
        </Box>
      </Box>

      {/* Status Result */}
      {confirmed ? (
        <Box
          sx={{ ...appointmentShedule_style.videoIconSx, ...videoIconStyle }}
          ml={1.6}
        >
          {videoIcon}
        </Box>
      ) : null}
    </Box>
  );
}

AppointmentShedule.propTypes = {
  dateText: PropTypes.string,
  yearText: PropTypes.string,
  timeText: PropTypes.string,
  timingClock: PropTypes.string,
  confirmed: PropTypes.bool,
  className: PropTypes.node,
  sx: PropTypes.object,
};
