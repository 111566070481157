import { getDateFormat } from '@hc/dayjs';
import { useMBFWellnessJourney } from '@hc/store';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { React } from 'react';
import { JourneyAreaChart } from '../../../../atoms/charts/areaChart';
import { chart_card_Style } from './style';

function MbfChartCard(props) {
  const { rootStyle = {}, className = '', ...rest } = props;

  const { mbfState, planStartDate } = useMBFWellnessJourney((state) => ({
    planStartDate: state.planStartDate,
    mbfState: state.mbfState,
  }));

  return (
    <Box
      sx={{ ...chart_card_Style.rotSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box px={2.5}>
        <Box sx={chart_card_Style.cardRootSx}>
          <Box pt={1}>
            <JourneyAreaChart
              data={mbfState?.graphData}
              height={165}
              label="Your Journey"
              from={getDateFormat(planStartDate, 'DD MMM YYYY')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { MbfChartCard };

MbfChartCard.propTypes = {
  rootStyle: PropTypes.object,
  className: PropTypes.string,
};
