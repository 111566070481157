export const resetPassword_style = {
  inputGroupSx: { display: 'grid', gap: 0.5 },

  paperSx: {
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 5,
    pb: 4,
 
    
    height:"100%"
  },
  titleSx: {
    fontWeight: '600',
  },
  subTitleSx: {
    fontWeight: '400',
    color: 'text.secondary',
    pb:3
  },
  labelStyle: {
    color: 'text.label',
    lineHeight: '2.4375em',
  },
  buttonStyle: {
    mt: 2,
    backgroundColor: 'primary.main',
    color: 'common.white',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: 'common.white',
    },
  },
  eyeSx: {
    color: 'common.black',
    width: '100%',
    fontSize: '18px',
  },
};
