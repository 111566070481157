/* eslint-disable no-nested-ternary */
import { TickIcon } from '@atoms';
import { useMBFActivity } from '@hc/store/clinical/mbfActivity';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { stepper_style } from './style';

export function Stepper() {
  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { mbfUserPlanCount, status } = checkPlanData;

  const dataList =
    mbfUserPlanCount > 1
      ? [
          {
            id: 1,
            title:
              status === null || status === 0 || status === undefined
                ? 'Collect Previous & current goals'
                : status > 1 || status === 1
                ? 'Previous & current goals collected!'
                : 'Collect Previous & current goals',
            desc:
              status === null || status === 0 || status === undefined
                ? 'We will collect metrics to know more about your health and lifestyle.'
                : status > 1 || status === 1
                ? 'Collected goals will be analysed to craft your plan.'
                : 'We will collect metrics to know more about your health and lifestyle.',
            status:
              status > 1 || status === 1
                ? 'completed'
                : status === null || status === 0 || status === undefined
                ? 'inProgress'
                : 'pending',
          },
          {
            id: 2,
            title:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1
                ? 'Expert will curate it'
                : status === 2
                ? 'Analysing by expert...'
                : status > 3 || status === 3
                ? 'Analysis Completed!'
                : 'Expert will curate it',
            desc: 'Our experts will analyse your previous plan & current goals to prepare customized plan for you.',
            status:
              status > 3 || status === 3
                ? 'completed'
                : status === 1 || status === 2
                ? 'inProgress'
                : 'pending',
          },
          {
            id: 3,
            title:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1 ||
              status === 2
                ? 'Wellness plan will be shared'
                : status === 3
                ? 'Wellness plan ready!'
                : 'Wellness plan will be shared',
            desc:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1 ||
              status === 2
                ? 'Your personalized plan will be sent to you.'
                : status === 3
                ? 'Your personalized plan is ready.'
                : 'Your personalized plan will be sent to you.',
            status:
              status > 4 || status === 4
                ? 'completed'
                : status === 3
                ? 'inProgress'
                : 'pending',
          },
        ]
      : [
          {
            id: 1,
            title:
              status === null || status === 0 || status === undefined
                ? 'Collect input parameters'
                : status > 1 || status === 1
                ? 'Parameters Collected!'
                : 'Collect input parameters',
            desc:
              status === null || status === 0 || status === undefined
                ? 'We will collect metrics to know more about your health and lifestyle.'
                : status > 1 || status === 1
                ? 'Collected parameters will be analysed to craft your plan.'
                : 'We will collect metrics to know more about your health and lifestyle.',
            status:
              status > 1 || status === 1
                ? 'completed'
                : status === null || status === 0 || status === undefined
                ? 'inProgress'
                : 'pending',
          },
          {
            id: 2,
            title:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1
                ? 'Expert will analyse it'
                : status === 2
                ? 'Analysing by expert...'
                : status > 3 || status === 3
                ? 'Analysis Completed!'
                : 'Expert will analyse it',
            desc: 'Our experts will analyse your metrics to prepare customized plan for you.',
            status:
              status > 3 || status === 3
                ? 'completed'
                : status === 1 || status === 2
                ? 'inProgress'
                : 'pending',
          },
          {
            id: 3,
            title:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1 ||
              status === 2
                ? 'Wellness plan will be shared'
                : status === 3
                ? 'Wellness plan ready!'
                : 'Wellness plan will be shared',
            desc:
              status === null ||
              status === 0 ||
              status === undefined ||
              status === 1 ||
              status === 2
                ? 'Your personalized plan will be sent to you.'
                : status === 3
                ? 'Your personalized plan is ready.'
                : 'Your personalized plan will be sent to you.',
            status:
              status > 4 || status === 4
                ? 'completed'
                : status === 3
                ? 'inProgress'
                : 'pending',
          },
        ];

  return (
    <Box sx={{ pl: '10px', pr: 2.5, py: 3, mt: 1 }}>
      {dataList?.map((val, index) => (
        <Box key={index} sx={stepper_style.contentWrapSx}>
          <Box
            sx={{
              position: 'absolute',
              border: val?.status === 'inProgress' ? '2px solid #7A67D9' : '',
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              placeItems: 'center',
              display: 'grid',
              // left: val?.status === 'inProgress' ? '-0.30%' : '',
            }}
          >
            <Box
              sx={{
                ...stepper_style.stepNumberSx,
                backgroundColor:
                  val?.status === 'completed'
                    ? '#25C460'
                    : val?.status === 'pending'
                    ? '#F3F0FF'
                    : '#7A67D9',
                color:
                  val?.status === 'completed'
                    ? '#fff'
                    : val?.status === 'pending'
                    ? '#7A67D9'
                    : '#fff',
                position: 'absolute',
                width: '19px',
                height: '19px',
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                // top: 0,
                placeItems: 'center',
              }}
            >
              {val?.status === 'completed' ? (
                <TickIcon
                  rootStyle={{ color: 'common.white', stroke: 'common.white' }}
                />
              ) : (
                index + 1
              )}
            </Box>
          </Box>
          <Box sx={stepper_style.stepPointsSx}>
            <Typography
              sx={{
                ...stepper_style.stepPointsHeadingSx,
                color: val?.status === 'completed' ? '#25C460' : '#00000',
              }}
            >
              {val?.title}
            </Typography>
            <Typography sx={stepper_style.stepPointsDescSx}>
              {val?.desc}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
