export const chatBotChatListing_style = {
  rootSx: {},
  mainBox: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'end',
    width: '80%',
    gap: 1,
    pb: 1,
  },
  mainRespondBox: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'end',
    gap: 1,
    pb: 1,
  },
  clipSx: {
    mb: 0.5,
    px: 1,
    borderRadius: '8px',
    position: 'relative',
    py: 1,
    backgroundColor: 'primary.light',
    color: '#fff',
    boxShadow: '#0000000A 0px 2px 4px',
  },
  clipRespondSx: {
    position: 'relative',
    mb: 1,
    px: 1.4,
    borderRadius: '4px',
    py: 1.4,
    backgroundColor: 'grey.100',
    color: 'text.700',
    marginRight: '10%',
    display: 'inline-block',
  },

  clipMessageSx: {
    position: 'relative',
    mb: 0.5,
    marginRight: '10%',
    display: 'inline-block',
    boxShadow: '0px 2px 4px #0000000A',
    border: '1px solid',
    borderColor: 'grey.A300',
    borderRadius: '8px',
  },

  replyTestSx: {
    color: 'background.surface.100',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '12px',
  },
  respondTestSx: {
    backgroundColor: 'grey.100',
    color: 'text.700',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '12px',
  },
  initialRespondTestSx: {
    px: 1.4,
    py: 1.4,
    backgroundColor: 'grey.100',
    color: 'text.700',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '12px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  startTestSx: {
    color: 'primary.dark',
    cursor: 'pointer',
    px: 1.4,
    py: 1.4,
    backgroundColor: 'background.surface.100',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '12px',
    fontWeight: 500,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  timeSx: {
    color: 'text.500',
    display: 'flex',
    justifyContent: 'end',
    fontSize: '10px',
    mb: 0.5,
  },
  timeRespondSx: {
    color: 'text.500',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '10px',
  },
  profileBox: {
    borderRadius: '50%',
    backgroundColor: 'primary.300',
    marginBottom: '25px',
  },
  chatBotIconSx: {
    height: '25px',
    minWidth: '25px',
    borderRadius: '50%',
    backgroundColor: 'primary.300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '25px',
  },
  profileAvatarSx: {
    borderRadius: '50%',
    border: '5px solid',
    borderColor: 'primary.light',
  },
  avatarSx: {
    backgroundColor: 'primary.light',
    color: 'common.white',
  },
};
