import { getDateFormat, getEndOfWeeks, getStartOfWeeks } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFPerformance,
  useMBFWellnessJourney,
} from '@hc/store';
import {
  PerfomanceStatsMapping,
  PerformanceWeeklyCalander,
  PlanHistoryFooter,
  PreviousPerformanceStats,
} from '@hc/ui/components';
import { BodyFoodText } from '@hc/ui/components/clinical/mindBodyFood/bodyFoodText';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  addDays,
  endOfWeek,
  isBefore,
  isEqual,
  startOfWeek,
  subDays,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { performanceStats_Style } from './style';

export default function Performance(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const navigate = useNavigate();
  const [stats, setStats] = useState(0);
  const [active, setActive] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const planStatus = searchParams.get('status');
  const headerText = 'Balance your mind, body & food';
  const subText =
    'To experience a greater sense of well-being and fulfillment in your personal and professional life.';

  const { planStartDate, planEndDate, getWellnessJourney } =
    useMBFWellnessJourney((state) => ({
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
      mbfState: state.mbfState,
      getWellnessJourney: state.getWellnessJourney,
    }));

  const {
    startEndDateUpdate,
    getPerfomanceStats,
    mbfPerformanceData,
    getPreviousPerfomanceStats,
    loading,
  } = useMBFPerformance(
    (state) => ({
      getPreviousPerfomanceStats: state.getPreviousPerfomanceStats,
      getPerfomanceStats: state.getPerfomanceStats,
      startEndDateUpdate: state.startEndDateUpdate,
      mbfPerformanceData: state.mbfPerformanceData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { mindStatus, bodyStatus, foodStatus, planCount } = mbfPerformanceData;

  const { startDate, endDate } = mbfPerformanceData;

  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { status } = checkPlanData;

  const initialData = async (end) => {
    let payload;

    payload = {
      start_date: getStartOfWeeks('YYYY-MM-DD'),
      end_date: getEndOfWeeks('YYYY-MM-DD'),
      user_id: tokenData?.user_id ?? '',
    };
    if (end) {
      if (isBefore(new Date(end), new Date())) {
        payload = {
          start_date: startOfWeek(new Date(end)),
          end_date: endOfWeek(new Date(end)),
          user_id: tokenData?.user_id ?? '',
        };
      }
    }
    startEndDateUpdate(payload.start_date, payload.end_date);
    queryClient.invalidateQueries({
      queryKey: ['performanceStats'],
    });
    await getPerfomanceStats(payload);
  };

  const onWeekChange = async (type) => {
    let payload;
    if (type === 'previous') {
      payload = {
        start_date: getDateFormat(subDays(new Date(startDate), 7)),
        end_date: getDateFormat(subDays(new Date(startDate), 1)),
        user_id: tokenData?.user_id ?? '',
      };
    } else if (type === 'next') {
      payload = {
        start_date: getDateFormat(addDays(new Date(endDate), 1)),
        end_date: getDateFormat(addDays(new Date(endDate), 7)),
        user_id: tokenData?.user_id ?? '',
      };
    }
    startEndDateUpdate(payload.start_date, payload.end_date);
    queryClient.invalidateQueries({
      queryKey: ['performanceStats'],
    });
    await getPerfomanceStats(payload);
  };

  const onNext = async () => {
    if (planCount > stats) {
      setActive(true);
      queryClient.invalidateQueries({
        queryKey: ['previousPerformanceStats'],
      });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        pageValue: stats + 1,
      };
      await getPreviousPerfomanceStats(payload);
      setStats(stats + 1);
    } else {
      toast(
        `You have only ${planCount} previous ${
          planCount === 1 ? 'plan' : 'plans'
        }`,
        { icon: '⚠️' }
      );
    }
  };

  const onCurrent = async () => {
    if (status === 4) {
      setSearchParams({ status: 'current' });
      const payload = {
        start_date: getStartOfWeeks('YYYY-MM-DD'),
        end_date: getEndOfWeeks('YYYY-MM-DD'),
        user_id: tokenData?.user_id ?? '',
      };
      queryClient.invalidateQueries({
        queryKey: ['performanceStats'],
      });
      await getPerfomanceStats(payload);
    } else {
      toast(`You have no current plan`, { icon: '⚠️' });
    }
  };

  const onPrevious = async () => {
    if (planCount > 0) {
      setSearchParams({ status: 'Previous' });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        pageValue: 1,
      };
      queryClient.invalidateQueries({
        queryKey: ['previousPerformanceStats'],
      });
      await getPreviousPerfomanceStats(payload);
      setStats(1);
    } else {
      toast(`You have current plan only`, { icon: '⚠️' });
    }
  };

  const onBack = async () => {
    if (stats >= 1)
      if (stats - 1 !== 0) {
        setActive(false);
        const payload = {
          user_id: tokenData?.user_id ?? '',
          pageValue: stats - 1,
        };
        queryClient.invalidateQueries({
          queryKey: ['previousPerformanceStats'],
        });
        await getPreviousPerfomanceStats(payload);
        setStats(stats - 1);
      } else {
        toast(
          `You have only ${planCount} previous ${
            planCount === 1 ? 'plan' : 'plans'
          }`,
          { icon: '⚠️' }
        );
      }
  };

  const goToPrevious = async () => {
    setSearchParams({ status: 'Previous' });
    const payload = {
      user_id: tokenData?.user_id ?? '',
      pageValue: 1,
    };
    queryClient.invalidateQueries({
      queryKey: ['previousPerformanceStats'],
    });
    await getPreviousPerfomanceStats(payload);
    setStats(1);
  };

  const initialDataCall = async () => {
    if (searchParams.get('status')) {
      setSearchParams({ status: searchParams.get('status') });
    } else if (status === 4) {
      setSearchParams({ status: 'current' });
      const { end } = await getWellnessJourney();
      if (end && status === 4) {
        await initialData(end);
      }
    } else {
      goToPrevious();
    }
  };

  useEffect(() => {
    initialDataCall();
  }, []);

  window.addEventListener('popstate', () => {
    navigate(clinicalRoutes.mindbodyfood);
  });

  return (
    <Box className={`${className}`} {...rest}>
      <Screenlayout
        appBarStyle={performanceStats_Style.appBarStyleSx}
        backRequired
        backIconStyle={{ color: 'mbf.main' }}
        rootStyle={{ backgroundColor: '#FCFBFF' }}
        title="Performance"
        childrenStyle={performanceStats_Style.screenLayoutChildernSx}
        notshowFooter={false}
        footerStyle={{ borderTop: '0px', p: planCount > 0 ? 1 : 0 }}
        footer={
          planCount > 0 && (
            <PlanHistoryFooter
              planStatus={planStatus}
              onPrevious={onPrevious}
              onCurrent={onCurrent}
            />
          )
        }
        backRoute={clinicalRoutes?.mindbodyfood}
      >
        {planStatus === 'current' && !loading && planStartDate && (
          <Box px={3} py={2}>
            <Box sx={performanceStats_Style.statsFromSx}>
              <Typography variant="body2" sx={{ color: 'mbf.dark' }}>
                Plan started from{' '}
                <span style={{ fontWeight: 500 }}>
                  {getDateFormat(planStartDate, 'DD MMM YYYY')}
                </span>
              </Typography>
            </Box>
            <Box sx={performanceStats_Style.mindBodyRootCardSx}>
              <Box>
                <PerformanceWeeklyCalander
                  onWeekChange={onWeekChange}
                  startDate={startDate}
                  endDate={endDate}
                  planStartDate={planStartDate}
                  planEndDate={planEndDate}
                />
              </Box>
              <Box mt={2}>
                <PerfomanceStatsMapping
                  mindStatus={mindStatus}
                  bodyStatus={bodyStatus}
                  foodStatus={foodStatus}
                />
              </Box>
            </Box>
            <Box mt={2} mb={4}>
              <BodyFoodText
                subTextStyle={{ pb: 2 }}
                headerText={headerText}
                subText={subText}
              />
            </Box>
          </Box>
        )}

        {planStatus === 'Previous' && (
          <Box px={3} pb={2.5}>
            {' '}
            <PreviousPerformanceStats
              onBack={onBack}
              active={active}
              stats={stats}
              onNext={onNext}
            />
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              display: 'grid',
              minHeight: '80vh',
              placeItems: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'mbf.main' }} />
          </Box>
        )}
      </Screenlayout>
    </Box>
  );
}
