import Angry_Emoji from '@assets/angryEmoji.png';
import Tired_Emoji from '@assets/faceBandageEmoji.png';
import Stressed_Emoji from '@assets/faceWIthStreamEmoji.png';
import Neutral_Emoji from '@assets/neutralFaceEmoji.png';
import Sad_Emoji from '@assets/sleepyFaceEmoji.png';
import SmileEmoji from '@assets/smileEmoji.png';
import Energetic_Emoji from '@assets/smileWithStarEmoji.png';
import { CircularProgressWithLabel } from '@atoms/circularProgessBar';
import { getDateFormat, getEndOfWeeks, getStartOfWeeks } from '@hc/dayjs';
import { useMBFMoodStatsProgress, useMBFWellnessJourney } from '@hc/store';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  addDays,
  endOfWeek,
  isAfter,
  isBefore,
  startOfWeek,
  subDays
} from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { WeeklyCalendar } from '../../../../atoms/weeklyCalendar';
import { mood_stats_progress_style } from './style';

function MoodStatsProgress(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  // GENERAL HOOKS
  const { mbfMoodStatsData, loading, getMoodStats, startEndDateUpdate } =
    useMBFMoodStatsProgress();

  const { planStartDate, planEndDate, getWellnessJourney } =
    useMBFWellnessJourney((state) => ({
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
      mbfState: state.mbfState,
      getWellnessJourney: state.getWellnessJourney,
    }));

  const {
    happy,
    neutral,
    sad,
    tired,
    energetic,
    angry,
    stressed,
    startDate,
    endDate,
  } = mbfMoodStatsData;

  const initialData = async () => {
    let payload;

    payload = {
      start_date: getStartOfWeeks('YYYY-MM-DD'),
      end_date: getEndOfWeeks('YYYY-MM-DD'),
      user_id: tokenData?.user_id ?? '',
    };
    if (isBefore(new Date(planEndDate), new Date())) {
      payload = {
        start_date: startOfWeek(new Date(planEndDate)),
        end_date: endOfWeek(new Date(planEndDate)),
        user_id: tokenData?.user_id ?? '',
      };
    }

    startEndDateUpdate(payload.start_date, payload.end_date);
    await getMoodStats(payload);
  };

  const handleWeekChange = async (type) => {
    let payload;
    if (type === 'previous') {
      payload = {
        start_date: getDateFormat(subDays(new Date(startDate), 7)),
        end_date: getDateFormat(subDays(new Date(startDate), 1)),
        user_id: tokenData?.user_id ?? '',
        offset: 0,
        user_plan_id: '',
        activity_start_date: '',
        activity_end_date: '',
      };
    } else if (type === 'next') {
      payload = {
        start_date: getDateFormat(addDays(new Date(endDate), 1)),
        end_date: getDateFormat(addDays(new Date(endDate), 7)),
        user_id: tokenData?.user_id ?? '',
        offset: 0,
        user_plan_id: '',
        activity_start_date: '',
        activity_end_date: '',
      };
    }
    startEndDateUpdate(payload.start_date, payload.end_date);

    await getMoodStats(payload);
  };

  const data = [
    {
      value: happy,
      color: { mainColor: '#25C460', backgroundColor: '#D8F8E4' },
      moodState: 'Happy',
      image: SmileEmoji,
    },
    {
      value: neutral,
      color: { mainColor: '#2C9EE9', backgroundColor: '#DCEFFB' },
      moodState: 'Neutral',
      image: Neutral_Emoji,
    },
    {
      value: sad,
      color: { mainColor: '#FF980E', backgroundColor: '#FFEED7' },
      moodState: 'Sad',
      image: Sad_Emoji,
    },
    {
      value: tired,
      color: { mainColor: '#4F66D8', backgroundColor: '#DFE3F7' },
      moodState: 'Tired',
      image: Tired_Emoji,
    },
    {
      value: energetic,
      color: { mainColor: '#24C092', backgroundColor: '#D4F8E9' },
      moodState: 'Energetic',
      image: Energetic_Emoji,
    },
    {
      value: angry,
      color: { mainColor: '#F44F5A', backgroundColor: '#FEDDDF' },
      moodState: 'Angry',
      image: Angry_Emoji,
    },
    {
      value: stressed,
      color: { mainColor: '#FACC15', backgroundColor: '#FCF4D5' },
      moodState: 'Stressed',
      image: Stressed_Emoji,
    },
  ];

  useEffect(() => {
    getWellnessJourney();
    planEndDate && initialData();
  }, [planEndDate]);

  return (
    <Box>
      {!loading && planStartDate && (
        <>
          <Box sx={mood_stats_progress_style.statsFromSx}>
            <Typography variant="body2" sx={{ color: 'mbf.dark' }}>
              Plan started from{' '}
              <span style={{ fontWeight: 500 }}>
                {getDateFormat(planStartDate, 'DD MMM YYYY')}
              </span>
            </Typography>
          </Box>
          <Box
            sx={{ ...mood_stats_progress_style.rootSx, ...rootStyle }}
            className={`${className}`}
            {...rest}
          >
            <Box pt={1} ml={1}>
              <WeeklyCalendar
                readOnly
                endDate={
                  isAfter(new Date(planEndDate), new Date(startDate)) &&
                  isBefore(new Date(planEndDate), new Date(endDate))
                    ? planEndDate
                    : endDate
                }
                startDate={
                  isAfter(new Date(planStartDate), new Date(startDate)) &&
                  isBefore(new Date(planStartDate), new Date(endDate))
                    ? planStartDate
                    : startDate
                }
                onWeekChange={handleWeekChange}
                leftIcon={isBefore(
                  new Date(planStartDate),
                  new Date(startDate)
                )}
                rightIcon={
                  isBefore(new Date(planEndDate), new Date())
                    ? isAfter(new Date(planEndDate), new Date(endDate))
                    : isBefore(new Date(endDate), new Date())
                }
              />
            </Box>
            <Grid
              container
              sx={mood_stats_progress_style.progressContainerSx}
              justifyContent="center"
            >
              {data.map((progressData, i) => (
                <Grid
                  key={i}
                  item
                  xs={6}
                  pb={2}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgressWithLabel
                    size={100}
                    thickness={5.5}
                    value={progressData.value}
                    color={progressData.color}
                    moodState={progressData.moodState}
                    centerIcon
                    isPercenteageValue
                    isMoodState
                    image={progressData.image}
                    circularStyle={mood_stats_progress_style.circularColorSx}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      {loading && (
        <Box sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}>
          <CircularProgress sx={{ color: 'mbf.main' }} />
        </Box>
      )}
    </Box>
  );
}

// 3. Export
export { MoodStatsProgress };
MoodStatsProgress.propTypes = {
  rootStyle: PropTypes.object,
  className: PropTypes.string,
};
