export const clinicalChatListCard_style = {
  cardRootSx: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 1.5,
    cursor: 'pointer',
  },
  buttonSx: {
    height: '40px',
    borderRadius: '6px',
    border: '2px solid #007965',
    textTransform: 'capitalize',
    width: '100%',
    '&:hover': {
      height: '40px',
      borderRadius: '6px',
      border: '2px solid #007965',
      textTransform: 'capitalize',
    },
  },
};
