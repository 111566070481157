import {
  BodyCamera,
  CheckBox,
  CustomRadio,
  Dropdown,
  HeightNoBgIcon,
  Input,
  Label,
  MobileInput,
  PressureNoBgIcon,
  PulseVitalsIcon,
  SpoVitalsIcon,
  TemperatureVitalsIcon,
  WeightNoBgIcon,
} from '@atoms';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BloodGroupSelection } from '../../../bloodGroupSelection';
import { addEditFamilyMember_style } from './style';

export function AddEditFamilyMember(props) {
  const {
    className = '',
    handleCheckBox = {},
    useMyNumber = false,
    handleChange = () => {},
    familyMember,
    relationShipData = [],
    bloodGroupData = [],
    isEdit = false,
    ...rest
  } = props;

  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  return (
    <Box
      sx={addEditFamilyMember_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box mt={1}>
        <Typography sx={addEditFamilyMember_style.PersonalTextSx}>
          Family Member Details
        </Typography>
      </Box>

      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Name
          </Label>
          <Input
            textFieldStyle={addEditFamilyMember_style.inputFieldSx}
            id="name"
            onChange={(e) => {
              handleChange('name', e.target.value, 50);
            }}
            isError={!!familyMember?.error?.name}
            errorMessage={familyMember?.error?.name}
            value={familyMember?.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Relationship To You
          </Label>
          <Dropdown
            selectOption={relationShipData}
            datatestid="relationship"
            value={familyMember?.relationship}
            onChange={(value) => {
              handleChange('relationship', value?.target?.value);
            }}
            isError={!!familyMember?.error?.relationship}
            helperText={familyMember?.error?.relationship}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Choose Gender
          </Label>
          <CustomRadio
            id="gender"
            value={familyMember?.gender}
            onChange={(value) => {
              handleChange('gender', value);
            }}
            isError={!!familyMember?.error?.gender}
            errorMessage={familyMember?.error?.gender}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Age
          </Label>
          <Input
            id="age"
            value={familyMember?.age}
            onChange={(e) => {
              handleChange('age', e.target.value, 3);
            }}
            inputProps={{ min: 1 }}
            isError={!!familyMember?.error?.age}
            errorMessage={familyMember?.error?.age}
            textFieldStyle={addEditFamilyMember_style.inputFieldSx}
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                Yrs
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Phone Number
          </Label>
          <MobileInput
            id="mobileNumber"
            value={{
              mobile_code: familyMember?.country_code ?? '+91',
              mobile: familyMember?.mobile_no ?? '',
            }}
            isReadonly={useMyNumber || isEdit}
            isError={!!familyMember?.error?.mobile_no}
            helperText={familyMember?.error?.mobile_no}
            isReadonlyCC={useMyNumber || isEdit}
            onChange={(value) => handleChange('mobileNumber', value, 10)}
            mobileInputStyle={addEditFamilyMember_style.inputFieldSx}
          />
          {!isEdit && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
              onChange={(e) => handleCheckBox(e)}
              // onClick={(e) => handleCheckBox(e)}
            >
              <CheckBox id="myNumber" checked={useMyNumber} />
              <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                Use my number
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} isRequired>
            Email ID
          </Label>
          <Input
            id="email"
            value={familyMember?.email_id}
            onChange={(e) => {
              handleChange('email_id', e.target.value, 50);
            }}
            isReadOnly={isEdit}
            isError={!!familyMember?.error?.email_id}
            errorMessage={familyMember?.error?.email_id}
            textFieldStyle={addEditFamilyMember_style.inputFieldSx}
          />
        </Grid>
      </Grid>

      <Grid container mt={2} rowSpacing={1} columnSpacing={1.5}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={addEditFamilyMember_style.vitalsTextSx}>
            Vitals
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label rootStyle={{ fontWeight: 500 }}>Height</Label>
          <Input
            id="height"
            type="number"
            value={familyMember?.height}
            onChange={(e) => {
              handleChange('height', e.target.value, 3);
            }}
            inputProps={{ min: 0 }}
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <HeightNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                cm
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label rootStyle={{ fontWeight: 500 }}>Weight</Label>
          <Input
            type="number"
            value={familyMember?.weight}
            onChange={(e) => {
              handleChange('weight', e.target.value, 3);
            }}
            inputProps={{ min: 0 }}
            htmlFor="weight"
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <WeightNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                kg
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {familyMember?.bmi.length > 0 && (
            <Box sx={addEditFamilyMember_style.bmiCalculationGroupSx}>
              <Stack direction="row" spacing={1}>
                <BodyCamera />
                <Typography
                  sx={addEditFamilyMember_style.bmiCalculationValueSx}
                >
                  Your BMI is{' '}
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {familyMember?.bmi ?? ''}
                    {}
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {familyMember?.bmi
                      ? toFindBMICategory(familyMember?.bmi)
                      : 'Normal'}
                    {}
                  </span>
                </Typography>
              </Stack>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label rootStyle={{ fontWeight: 500 }}>Temperature</Label>
          <Input
            type="number"
            id="temp_in_f"
            value={familyMember?.temp_in_f}
            onChange={(e) => {
              handleChange('temp_in_f', e.target.value, 3);
            }}
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <TemperatureVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                F
              </Typography>
            }
            isError={!!familyMember?.error?.temp_in_f}
            errorMessage={familyMember?.error?.temp_in_f}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label>Pulse</Label>
          <Input
            type="number"
            id="puls_bpm"
            value={familyMember?.puls_bpm}
            onChange={(e) => {
              handleChange('puls_bpm', e.target.value, 3);
            }}
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <PulseVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                Bpm
              </Typography>
            }
            isError={!!familyMember?.error?.puls_bpm}
            errorMessage={familyMember?.error?.puls_bpm}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label>Blood Pressure</Label>
          <Input
            // type="number"
            id="blood_pressure"
            value={familyMember?.blood_pressure}
            onChange={(e) => {
              handleChange('blood_pressure', e.target.value, 7);
            }}
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <PressureNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                mmHg
              </Typography>
            }
            isError={!!familyMember?.error?.blood_pressure}
            errorMessage={familyMember?.error?.blood_pressure}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label>SPO2</Label>
          <Input
            type="number"
            id="o2_level"
            value={familyMember?.o2_level}
            onChange={(e) => {
              handleChange('o2_level', e.target.value, 3);
            }}
            textFieldStyle={addEditFamilyMember_style.inputWidthSx}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <SpoVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={addEditFamilyMember_style.endAdornmentTextSx}>
                %
              </Typography>
            }
            isError={!!familyMember?.error?.o2_level}
            errorMessage={familyMember?.error?.o2_level}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label rootStyle={{ fontWeight: 500 }} htmlFor="blood_group">
            Blood Group{' '}
          </Label>
          <Box sx={{ overflowY: 'scroll' }}>
            <BloodGroupSelection
              bloodData={familyMember}
              id="bloodgroup"
              value={familyMember?.bloodgroup}
              bloodGroupData={bloodGroupData}
              // onChange={(value) => {
              //   handleChange('blood_group', value);
              // }}
              handleChange={handleChange}
              name="blood_group"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
AddEditFamilyMember.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  relationShipData: PropTypes.array,
  isEdit: PropTypes.bool,
};
