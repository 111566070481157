/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useAllergy = create((set, get) => ({
  searchAllergy: null,
  quickAllergyData: [],
  allergyData: [],
  loading: null,
  error: null,
  get: async (search) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        search?.length > 0
          ? `/getMasterAllergies/getAll?limit=10&search=${search}`
          : '/getMasterAllergies/getAll?limit=10',
        'get',
      ]);
      const allergyDataDummy = [];

      if (Array.isArray(data?.allergyData) && data?.allergyData?.length > 0) {
        data?.allergyData.map((val) => {
          allergyDataDummy.push({
            value: val?.id ?? '',
            label: val?.allergy ?? '',
            labelShow: val?.allergy ?? '',
          });
        });
      }

      const quickSuggAllergyDataDummy = [];

      if (
        Array.isArray(data?.quick_suggestion_data) &&
        data?.quick_suggestion_data?.length > 0
      ) {
        data?.quick_suggestion_data.map((val) => {
          quickSuggAllergyDataDummy.push({
            value: val?.allergy_id ?? '',
            label: val?.allergy ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        allergyData: allergyDataDummy,
        quickAllergyData: quickSuggAllergyDataDummy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
