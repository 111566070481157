/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import { create } from 'zustand';

export const useDoctorChatConsultation = create((set, get) => ({
  doctorChatConsultationState: {
    chatStatusData: {},
    onlineTyping: 'online',
    typingMessage: '',
    messageType: '',
    messageJSON: [],
    doctorChatData: [],
    chatTimer: null,
    isChatExtendRequest: null,
    doctorChatListingData: [],
    chatCounsultationData: {},
    doctorChatListingCount: '',
    patientMedicalData: {},
    chatStatus: '',
    isScroll: false,
  },
  loading: false,
  error: null,
  updateDoctorChatConsultationState: (key, value) => {
    const { doctorChatConsultationState } = get();

    set({
      doctorChatConsultationState: {
        ...doctorChatConsultationState,
        [key]: value,
      },
    });
    if (key === 'chatStatusData') {
      localStorage.setItem('doctorChatStatusData', JSON.stringify(value));
    }
  },
  getChatHistoryDataById: async (chatConsutationId, chatEnd) => {
    const { doctorChatConsultationState } = get();
    const { chatStatusData } = doctorChatConsultationState;
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_history_by_id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatConsultation/message/get/all?chat_consultation_id=${chatConsutationId}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      let result = [];
      if (status.code === 200) {
        const resArr = [];
        const resArr2 = [];
        let date = '';
        let date1 = '';

        const isChatEnd = chatEnd === 'OnGoing' ? false : true;
        for (const object of data?.message_data) {
          if (
            new Date(data?.message_data[0]?.createdAt).getDate() ===
            new Date(object?.createdAt).getDate()
          ) {
            if (
              (object?.message_type === 1 && object?.message?.length > 0) ||
              object?.message_type !== 1
            ) {
              const obj = {
                user:
                  object?.sender_profile?.user_id === tokenData?.user_id
                    ? 'doctor'
                    : 'patient',
                type: object?.message_type ?? '',
                message: object?.message ?? '',
                message_json: object?.message_json,
                senderName: object?.sender_profile?.first_name ?? '',
                date: object?.createdAt ?? '',
                is_seen: false,
                ...object,
              };

              date = object?.createdAt ?? '';

              resArr?.push(obj);
            }
          } else {
            if (
              (object?.message_type === 1 && object?.message?.length > 0) ||
              object?.message_type !== 1
            ) {
              const obj = {
                user:
                  object?.user_id === tokenData?.user_id ? 'doctor' : 'patient',
                type: object?.message_type ?? '',
                message: object?.message ?? '',
                message_json: object?.message_json,
                senderName: object?.sender_profile?.first_name ?? '',
                date: object?.createdAt ?? '',
                is_seen: false,
                ...object,
              };

              date1 = object?.createdAt ?? '';

              resArr2?.push(obj);
            }
          }
        }

        if (resArr?.length > 0 && resArr2.length > 0) {
          result = [
            {
              date,
              isEnd: isChatEnd,
              messageData: resArr,
            },
            {
              date: date1,
              isEnd: isChatEnd,
              messageData: resArr2,
            },
          ];
        } else {
          result = [
            {
              date,
              isEnd: isChatEnd,
              messageData: resArr,
            },
          ];
        }

        const object = {
          patient_details: { ...data?.medical_card?.profile_json },
          vitals: { ...data?.medical_card?.vital_json },
          medical_condition: { ...data?.medical_card?.medical_condition_json },
        };

        set({
          loading: false,
          doctorChatConsultationState: {
            ...doctorChatConsultationState,
            doctorChatData: resArr?.length > 0 ? result : [],
            patientMedicalData: object ?? {},
          },
        });
      }
      const resData = {
        data: result,
        status,
      };
      return resData;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getChatCounsultationById: async (counsultation_id) => {
    const { doctorChatConsultationState } = get();

    try {
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_data_by_id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/chatConsultation/details/get?chat_consultation_id=${counsultation_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status.code === 200) {
        set({
          loading: false,
          doctorChatConsultationState: {
            ...doctorChatConsultationState,
            chatCounsultationData: data,
          },
        });
      }

      const result = {
        data,
        status,
      };
      return result;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // getMedicalCardDataById: async (patient_profile_id) => {
  //   const { doctorChatConsultationState } = get();

  //   try {
  //     set({ loading: true });

  //     const { data, status } = await queryClient.fetchQuery({
  //       queryKey: ['chat_medical_card_data_by_id'],
  //       queryFn: async () => {
  //         const { data } = await httpRequest(
  //           'get',
  //           `${envConfig.api_url}/chatConsultation/medical-card/get?user_id=${patient_profile_id}`,
  //           {},
  //           true,
  //         );
  //         return data;
  //       },
  //       staleTime: 120000,
  //     });
  //     if (status.code === 200) {
  //       set({
  //         doctorChatConsultationState: {
  //           ...doctorChatConsultationState,
  //           patientMedicalData: data,
  //         },
  //       });
  //     }
  //     set({ loading: false });
  //     return status;
  //   } catch (error) {
  //     set({ loading: false });
  //     log('error', error);
  //     return toast.error(
  //       error?.response?.data?.status?.message ??
  //         error?.message ??
  //         'Something went wrong please try again!',
  //     );
  //   }
  // },

  getChatHistoryData: async (searchValue, offset, initialCall) => {
    const { doctorChatConsultationState } = get();
    const { chatStatusData, doctorChatListingData } =
      doctorChatConsultationState;
    try {
      // if (initialCall) {
      set({ loading: true });
      // }

      const payload = {
        search: searchValue ?? '',
        from_date: '',
        to_date: '',
        limit: 10,
        offset: offset ?? 0,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['chat_consultation_all'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/chatConsultation/get/all`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status.code === 200) {
        if (initialCall) {
          set(() => ({
            doctorChatConsultationState: {
              ...doctorChatConsultationState,
              doctorChatListingData: Array.isArray(data?.all_chats)
                ? data?.all_chats
                : [],
              doctorChatListingCount: data?.count ?? 0,
            },
          }));
        } else {
          set(() => ({
            doctorChatConsultationState: {
              ...doctorChatConsultationState,
              doctorChatListingData: Array.isArray(data?.all_chats)
                ? [...doctorChatListingData, ...data?.all_chats]
                : [],
              doctorChatListingCount: data?.count ?? 0,
            },
          }));
        }
      }
      set({ loading: false });
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getLocalStorage: () => {
    const { doctorChatConsultationState } = get();
    const data = localStorage.getItem('doctorChatStatusData');
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        doctorChatConsultationState: {
          ...doctorChatConsultationState,
          chatStatusData: jsonData,
        },
      });
    }
  },
}));
