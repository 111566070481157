import { CertificateIcon, HospitalIcon } from '@atoms';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { doctorProfile_style } from './style';

export function DoctorProfile(props) {
  const {
    src = {},
    doctorName = '',
    profilepicStyle = {},
    pricingRequired = null,
    aboutDoctor = {},
    aboutpatient = {},
    tagStyle = {},
    apointmentCardstyle = {},
    nameStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={doctorProfile_style.rootSx}>
        <Box sx={{ p: 0, pb: '0px!important' }}>
          <Box sx={{ ...doctorProfile_style.appointmentCardSx, ...apointmentCardstyle }}>
            <Box sx={{ ...doctorProfile_style.doctornameSx, ...nameStyle }}>
              <Avatar src={src} sx={{ ...doctorProfile_style.profilepicSx, ...profilepicStyle }} />
              <Box sx={{ px: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: '600' }}>
                  {doctorName}
                </Typography>
                {Object.keys(aboutDoctor).length > 0 && (
                  <>
                    <Box sx={doctorProfile_style.doctorSx}>
                      <CertificateIcon />
                      <Typography sx={doctorProfile_style.assitantSx}>
                        {aboutDoctor.role}
                      </Typography>
                    </Box>
                    <Box sx={doctorProfile_style.doctorSx}>
                      <HospitalIcon />
                      <Typography sx={doctorProfile_style.profilepicSxassitantSx}>
                        {aboutDoctor.hospital}
                      </Typography>
                    </Box>
                  </>
                )}
                {Object.keys(aboutpatient).length > 0 && (
                  <Box sx={doctorProfile_style.patientSx}>
                    <Typography sx={doctorProfile_style.assitantSx}>
                      {aboutpatient.patientRole}
                      {aboutpatient.relationship}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* Pricing Details */}
            <Box sx={{ ...doctorProfile_style.tagSx, ...tagStyle }}>
              {pricingRequired && (
                <Box sx={doctorProfile_style.pricingBoxSx}>
                  <Typography variant="caption" sx={{ fontWeight: 500 }}>
                    {/* <span style={doctorProfile_style.pricingRequiredSx}>₹</span> */}
                    {pricingRequired}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

DoctorProfile.propTypes = {
  src: PropTypes.object,
  doctorName: PropTypes.string,
  pricingRequired: PropTypes.string,
  aboutDoctor: {
    role: PropTypes.string,
    hospital: PropTypes.string,
  },
  aboutpatient: { patientRole: PropTypes.string },
  className: PropTypes.node,
  sx: PropTypes.object,
};
