import { Box } from '@mui/material';
import {
  CalenderIcon,
  CancelAppointmentIcon,
  ConfirmAppointmentIcon,
  VideoIcon,
} from '@atoms';
import {
  BookingMinDetails,
  AppointmentShedule,
  DoctorProfile,
  AppointmentCard,
} from '@components';

import PropTypes from 'prop-types';
import { getDateFormat } from '@hc/dayjs';
import { bookingStatus_style } from './style';
import { ClockIcon } from '../../../../atoms';

export function BookingStatus(props) {
  const {
    confirmed = true,
    message = '',
    className = '',
    appointmentStatusDetails = {},
    myself = false,
    ...rest
  } = props;

  console.log(appointmentStatusDetails,"appointmentStatusDetails")

  return (
    <Box sx={bookingStatus_style.rootSx} className={`${className}`} {...rest}>
      <BookingMinDetails
        iconType={
          confirmed ? <ConfirmAppointmentIcon /> : <CancelAppointmentIcon />
        }
        appointmentType={message ?? 'Appointment Cancelled'}
        updateStyle={bookingStatus_style.smsTextSx}
        smsText={confirmed ? 'Updates will be sent over SMS to' : ''}
        mobileNumber={
          confirmed ? appointmentStatusDetails?.patientDetails?.mobile : ''
        }
      >
        <AppointmentCard
          appointmentCardStyle={bookingStatus_style.rootCardSx}
          mainStyle={bookingStatus_style.mainSx}
          header={
            <DoctorProfile
              src={appointmentStatusDetails?.doctorDetails?.profile_pic}
              doctorName={`Dr. ${appointmentStatusDetails?.doctorDetails?.doctor_name}`}
              aboutpatient={{
                patientRole: myself
                  ? `For ${appointmentStatusDetails?.patientDetails?.name} (You)`
                  : `For ${appointmentStatusDetails?.patientDetails?.name}`,
                relationship: appointmentStatusDetails?.patientDetails
                  ?.relationship
                  ? ` (${appointmentStatusDetails?.patientDetails?.relationship})`
                  : '',
              }}
              pricingRequired={
                appointmentStatusDetails?.doctorDetails?.consulation_charge
              }
              apointmentCardstyle={bookingStatus_style.cardSx}
              profilepicStyle={bookingStatus_style.profilepicSx}
              tagStyle={bookingStatus_style.priceTagSx}
            />
          }
          footer={
            <AppointmentShedule
              icon={<CalenderIcon />}
              dateText="Date"
              // yearText="09 Apr,2003"
              yearText={getDateFormat(
                appointmentStatusDetails?.appointment_date_time,
                'DD MMM, YYYY'
              )}
              timeText="Time"
              timeIcon={<ClockIcon />}
              // timingClock="09:15 AM"
              timingClock={getDateFormat(
                appointmentStatusDetails?.appointment_date_time,
                'hh:mm A'
              )}
              videoIcon={<VideoIcon />}
              confirmed={confirmed}
              rootStyle={bookingStatus_style.appointmentRootSx}
            />
          }
        />
      </BookingMinDetails>
    </Box>
  );
}
BookingStatus.propTypes = {
  confirmed: PropTypes.bool,
  appointmentStatusDetails: PropTypes.object,
  myself: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.node,
  sx: PropTypes.object,
};
