export const selectMemberType_style = {
  rootSx: {
    borderRadius: '8px',
    boxShadow: 'none',
  },

  imageWithTextSx: {
    borderRadius: '8px',
    bgcolor: '#EBFAF7',
    p: 2,
    border: '1px solid',
    borderColor: '#6EBDAB',
    cursor: 'pointer',
  },
  titleSx: {
    color: 'text.primary',
    fontWeight: 600,
    fontSize: '18px',
  },
  subTitleSx: {
    fontSize: '14px',
    mt: 1,
    color: 'text.secondary',
  },
  cardContentSx: {
    p: 2,
  },
};
