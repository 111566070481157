export const moodSelection_style = {
  totalMoodSx: {
    display: 'grid',
    gridTemplateRows: 'repeat(11,1fr)',
    gridTemplateColumns: 'repeat(12,1fr)',
    maxWidth: '435px',
    margin: '0px auto',
    position: 'relative',
  },
  emojiSx: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  imgSx: {
    // // backgroundColor: 'purple.400',
    // border: '1px solid',
    borderColor: 'purple.400',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  titleSx: {
    fontSize: '18px',
    fontWeight: 500,
  },
  avatarSx: {
    margin: 'auto',
    width: '36px',
    height: '36px',
  },
  darkCrileSx: {
    position: 'absolute',
    left: '0px',
    right: '32px',
    margin: 'auto',
    width: '48px',
    height: '48px',
  },
  darkCrile2Sx: {
    position: 'absolute',
    left: '0px',
    margin: 'auto',
    bottom: '-55px',
    right: '47px',
  },
  darkCrile3Sx: {
    position: 'absolute',
    left: '-54px',
  },
  lightpurpleSx: {
    position: 'absolute',
    bottom: '-26px',
    left: '6px',
  },
  lightpurple2Sx: {
    position: 'absolute',
    right: '-9px',
    top: '-36px',
  },
  lightpurple3Sx: {
    position: 'absolute',
    right: '-43px',
    bottom: '0px',
  },
  skipSx: {
    width: '100px',
  },
};
