export const clinical_chat_list_page_style = {
  filterBoxSx: {
    border: '1.5px solid #DBEAE8',
    cursor: 'pointer',
    borderRadius: '8px',
    py: 1.5,
  },
  speedDialRootSx: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    bgcolor: '#007965',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
        color: '#0E1824',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
  },
  buttonSx: {
    bgcolor: '#FFEAEC',
    textTransform: 'capitalize',
    color: '#F44F5A',
    '&:hover': {
      bgcolor: '#FFEAEC',
    },
  },

  callButtonSx: {
    borderRadius: 2,
    textTransform: 'capitalize',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },

  subHeadSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.secondary',
    mt: 1,
  },
};
