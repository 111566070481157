// 1. Imports - Refer coding_standard docs for the import order
import { Button } from '@atoms/button';
import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFMoodStats,
  useMBFMyWellnessPlanSlice
} from '@hc/store';
import {
  ActivityIcon,
  PlanDurationIcon,
  PlanDurationRainbowIcon
} from '@hc/ui/atoms/illustration';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { view_plan_card_style } from './style';

function ViewPlanCard(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const [getStartedLoading, setGetStartedLoading] = useState(false);

  const { planDetails, loading, getPlanDetails } = useMBFMyWellnessPlanSlice(
    (state) => ({
      planDetails: state.planDetails,
      getPlanDetails: state.getPlanDetails,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { duration, durationType, totalActivities } = planDetails;

  const dataPlan = [
    {
      cardHeaderText: `${duration} ${durationType}`,
      cardSubText: 'Plan Duration',
      image: <PlanDurationIcon />,
      effectImage: <PlanDurationRainbowIcon />,
      cardType: 'planDuration',
    },
    {
      cardHeaderText: totalActivities,
      cardSubText: 'Total Activities',
      image: <ActivityIcon />,
      effectImage: <PlanDurationRainbowIcon />,
      cardType: 'activity',
    },
  ];

  const { mbfMood, getTodayMoodStats } = useMBFMoodStats((state) => ({
    mbfMood: state.mbfMood,
    getTodayMoodStats: state.getTodayMoodStats,
  }));

  const { checkPlanDataDetails, mbfState } = useMBFActivity((state) => ({
    checkPlanDataDetails: state.checkPlanDataDetails,
    mbfState: state.mbfState,
  }));

  const { checkPlanData } = mbfState;
  const { status, mbfUserPlanCount } = checkPlanData;

  const getStartedBtn = async () => {
    setGetStartedLoading(true);

    await checkPlanDataDetails();
    if (status === 4 && (!mbfMood || mbfMood === null)) {
      await getTodayMoodStats(data?.user_id);
    }
    setGetStartedLoading(false);
    if (mbfMood) {
      navigate(clinicalRoutes.mindbodyfood);
    } else {
      navigate(clinicalRoutes.moodforyou);
    }
  };

  const getInitialData = async () => {
    // Check renewal plan flow (Yet to submit feedback & goal)
    if (status === null && mbfUserPlanCount > 0) {
      navigate(clinicalRoutes?.mindbodyfood);
    }
    await getPlanDetails();
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      sx={{ ...view_plan_card_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ overflowY: 'scroll', height: '75vh' }}>
        <Typography sx={view_plan_card_style.headerTextSx}>
          Your customized plan ready
        </Typography>
        <Typography sx={view_plan_card_style.subTextSx}>
          Your customized plan ready
        </Typography>
        {loading && (
          <Box
            sx={{ display: 'grid', minHeight: '40vh', placeItems: 'center' }}
          >
            <CircularProgress sx={{ color: 'mbf.main' }} />
          </Box>
        )}
        {!loading && planDetails && (
          <Grid container spacing={2} pt={3.1} pb={5.5}>
            {dataPlan.map((cardData, i) => (
              <Grid
                key={i}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Box sx={view_plan_card_style.cardRootSx} elevation={0}>
                  <Box p={2}>
                    <Typography sx={view_plan_card_style.cardHeaderTextSx}>
                      {cardData.cardHeaderText}
                    </Typography>
                    <Typography sx={view_plan_card_style.subTextSx}>
                      {cardData.cardSubText}
                    </Typography>
                  </Box>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Box mt="-50%">{cardData.effectImage}</Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          ...(cardData.cardType === 'planDuration' &&
                            view_plan_card_style.planDurationImgSx),
                          ...(cardData.cardType === 'activity' &&
                            view_plan_card_style.activityImgSx),
                          ...(cardData.cardType === 'evaluations' &&
                            view_plan_card_style.evaluationsImgSx),
                          ...(cardData.cardType === 'progressJourney' &&
                            view_plan_card_style.progressJourneyImgSx),
                        }}
                      >
                        {cardData.image}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Box sx={view_plan_card_style.footerSx}>
        <Button
          fullWidth
          loading={getStartedLoading}
          buttonStyle={view_plan_card_style.buttonSx}
          onClick={getStartedBtn}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
}

// 3. Export
export { ViewPlanCard };
ViewPlanCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.string,
};
