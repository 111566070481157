/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFUserPlanData = create((set, get) => ({
  mbfUserPlanState: {
    planStartDate: '',
    userPlanId: '',
    planEndDate: '',
    planNumber: '',
  },
  loading: true,
  error: null,

  getUserPlanDetails: async (offset) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['userPlanData'],
        queryFn: async () => {
          const data = await httpRequest(
            'get',
            `${envConfig.api_url}/mindBodyFood/user-plan-data/get?user_id=${tokenData?.user_id}&limit=1&offset=${offset}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      set((state) => ({
        mbfUserPlanState: {
          ...state.mbfUserPlanState,
          planStartDate: data?.data?.data?.activity_start_date ?? '',
          userPlanId: data?.data?.data?.user_plan_id ?? '',
          planEndDate: data?.data?.data?.activity_end_date ?? '',
          planNumber: data?.data?.data?.plan_no ?? '',
        },
      }));
      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
