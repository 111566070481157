/* eslint-disable no-unused-vars */
import { getDateFormat } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { useAppointment } from '@hc/store';
import { CancelIcon, ScheduleCancelIcon } from '@hc/ui/atoms';
import { ClinicalEmptyState, SkeletonCard } from '@hc/ui/components';
import { PastAppointmentCard, ViewCard } from '@hc/ui/components/clinical';
import { CancelAppointmentHome } from '@hc/ui/components/clinical/cancelAppoinmentHome';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import {
  IsBookButtonEnable,
  parseJwt,
  timeDiffCalc
} from '@hc/utils/helperFunctions';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { upcomingAppointment_style } from './style';

export default function MyAppointments(props) {
  const { className = '', ...rest } = props;

  // General Hooks
  const [tabIndex, setTabIndex] = useState('1');
  const [onSearch, setOnSearch] = useState('');
  const [search, setSearch] = useState(false);

  const [anchorElCancel, setAnchorElCancel] = useState(null);
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);

  const {
    appointmentState,
    getPastUpcomingAppointmentList,
    updateAppointments,
    updateAppointmentsData,
    loading,
  } = useAppointment(
    (state) => ({
      appointmentState: state.appointmentState,
      getPastUpcomingAppointmentList: state.getPastUpcomingAppointmentList,
      updateAppointments: state.updateAppointments,
      updateAppointmentsData: state.updateAppointmentsData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const getInitialData = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    queryClient.invalidateQueries({
      queryKey: ['appointments'],
    });
    await getPastUpcomingAppointmentList(data?.user_id, false);
  };

  const setTabIndexFnc = (tabIndex) => {
    setOnSearch('');
    setSearch(false);
    setTabIndex(tabIndex === '1' ? '2' : '1');
  };

  // APPOINTMENT SEARCH FUNCTION
  const setOnSearchFnc = (value) => {
    setOnSearch(value);
    if (tabIndex === '1') {
      if (
        Array.isArray(appointmentState?.upcoming_appointments) &&
        appointmentState?.upcoming_appointments?.length > 0
      ) {
        const upcomingAppointmentsCopy = [];
        appointmentState?.upcoming_appointments?.map((val) => {
          if (
            val?.is_self_booking
              ? val?.user_profile?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase()) ||
                val?.assigned_doctor_profile?.first_name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
              : val?.appointment_members?.[0]?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase()) ||
                val?.assigned_doctor_profile?.first_name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
          ) {
            upcomingAppointmentsCopy.push(val);
          }
        });
        updateAppointments(
          'search_upcoming_appointments',
          upcomingAppointmentsCopy,
        );
      }
    } else if (tabIndex === '2') {
      if (
        Array.isArray(appointmentState?.past_appointments) &&
        appointmentState?.past_appointments?.length > 0
      ) {
        const pastAppointmentsCopy = [];
        appointmentState?.past_appointments?.map((val) => {
          if (
            val?.is_self_booking
              ? val?.user_profile?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase()) ||
                val?.assigned_doctor_profile?.first_name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
              : val?.appointment_members?.[0]?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase()) ||
                val?.assigned_doctor_profile?.first_name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
          ) {
            pastAppointmentsCopy.push(val);
          }
        });
        updateAppointments('search_past_appointments', pastAppointmentsCopy);
      }
    }
  };

  const setSearchFnc = (val) => {
    setSearch(val);
  };

  const handleDrawerCancelWithApp = (val) => {
    setCancelDrawerOpen(val);
  };

  const handleClick = (event, index, app_id, doctor_id, event_id) => {
    if (anchorElCancel !== event.currentTarget) {
      setAnchorElCancel(event.currentTarget);
    }
    if (app_id) {
      updateAppointmentsData(index, app_id, doctor_id, event_id);
    } else {
      updateAppointmentsData();
    }
  };

  const handleCancelClose = () => {
    setAnchorElCancel(null);
  };

  // GET APPOINTMENT LIST DATA
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      sx={upcomingAppointment_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Screenlayout
        tabIndex={tabIndex}
        setTabIndex={() => setTabIndexFnc(tabIndex)}
        onSearch={onSearch}
        setOnSearch={setOnSearchFnc}
        setSearchFnc={setSearchFnc}
        search={search}
        backRequired={clinicalRoutes?.home}
        backRoute={clinicalRoutes?.home}
        tabRequired
        notshowFooter
        tabData={[
          {
            label: `Upcoming (${
              onSearch?.length > 0 && tabIndex === '1'
                ? appointmentState?.search_upcoming_appointments?.length > 0
                  ? appointmentState?.search_upcoming_appointments?.length
                  : 0
                : appointmentState?.upcoming_appointments?.length > 0
                ? appointmentState?.upcoming_appointments?.length
                : 0
            })`,
            value: '1',
          },
          {
            label: `Past (${
              onSearch?.length > 0 && tabIndex === '2'
                ? appointmentState?.search_past_appointments?.length > 0
                  ? appointmentState?.search_past_appointments?.length
                  : 0
                : appointmentState?.past_appointments?.length > 0
                ? appointmentState?.past_appointments?.length
                : 0
            })`,
            value: '2',
          },
        ]}
        searchRequired
        title="Appointments"
      >
        {/*  UpcomingAppointment Card Loaders */}
        <Box sx={upcomingAppointment_style.upcomingListSx}>
          {tabIndex === '1' &&
            loading &&
            Array(4)
              .fill(1)
              .map((i) => <SkeletonCard key={i} />)}
        </Box>
        {/*   *************************************** */}
        {/* EmptyState Design */}
        {appointmentState?.upcoming_appointments?.length === 0 &&
          tabIndex === '1' &&
          !loading && (
            <ClinicalEmptyState
              IconRequired={<ScheduleCancelIcon />}
              emptyStateStyle={{ mt: 9.25 }}
              mainHead="No Upcoming Appointments"
              subHead="Yay! You are keeping yourself healthy"
            />
          )}
        {appointmentState?.past_appointments?.length === 0 &&
          tabIndex === '2' &&
          !loading && (
            <ClinicalEmptyState
              IconRequired={<ScheduleCancelIcon />}
              emptyStateStyle={{ mt: 9.25 }}
              mainHead="No Past Appointments"
              subHead="You can view past records once you finished your 1st appointment"
            />
          )}
        {/* UpcomingAppointment Card */}
        <Box sx={upcomingAppointment_style.upcomingListSx}>
          {appointmentState?.upcoming_appointments?.length > 0 &&
            tabIndex === '1' &&
            !loading && (
              <Box>
                {onSearch?.length > 0 ? (
                  appointmentState?.search_upcoming_appointments?.length > 0 ? (
                    appointmentState?.search_upcoming_appointments?.map(
                      (val, index) => (
                        <ViewCard
                          index={index}
                          key={val?.id}
                          data={val}
                          event_id={val?.event_id ?? ''}
                          app_id={val?.id ?? ''}
                          doctor_id={
                            val?.assigned_doctor ??
                            val?.assigned_doctor_profile?.id ??
                            ''
                          }
                          doctorName={
                            val?.assigned_doctor_profile?.first_name ?? ''
                          }
                          Professional={
                            val?.assigned_doctor_profile?.doctor_specialities ??
                            []
                          }
                          src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                          paitentName={
                            val?.is_self_booking === true
                              ? val?.user_profile?.name ?? ''
                              : val?.appointment_members?.[0]?.name
                          }
                          rootStyle={
                            upcomingAppointment_style.upcomingcardStyle
                          }
                          bookingText="Join"
                          UpdateTime
                          type={val?.appointment_status}
                          type_name={
                            val?.master_appointment_status?.appointment_status
                          }
                          self={
                            val?.is_self_booking === false
                              ? val?.appointment_members?.[0]
                                  ?.master_relationship?.releationship
                              : 'Self'
                          }
                          after={
                            val?.appointment_date_time
                              ? timeDiffCalc(val?.appointment_date_time)
                              : ''
                          }
                          date={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  'DD MMM YY,',
                                )
                              : ''
                          }
                          time={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  ' hh:mm A',
                                )
                              : ''
                          }
                          disabled={
                            val?.appointment_date_time
                              ? IsBookButtonEnable(val?.appointment_date_time)
                              : true
                          }
                          // cancel appointment props
                          handleClick={handleClick}
                        />
                      ),
                    )
                  ) : (
                    <Typography sx={upcomingAppointment_style.subHeadSx}>
                      No Match Found
                    </Typography>
                  )
                ) : appointmentState?.upcoming_appointments?.length > 0 ? (
                  appointmentState?.upcoming_appointments?.map((val, index) => (
                    <ViewCard
                      index={index}
                      key={val?.id}
                      data={val}
                      event_id={val?.event_id ?? ''}
                      app_id={val?.id ?? ''}
                      doctor_id={
                        val?.assigned_doctor ??
                        val?.assigned_doctor_profile?.id ??
                        ''
                      }
                      doctorName={
                        val?.assigned_doctor_profile?.first_name ?? ''
                      }
                      Professional={
                        val?.assigned_doctor_profile?.doctor_specialities ?? []
                      }
                      src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                      paitentName={
                        val?.is_self_booking === true
                          ? val?.user_profile?.name ?? ''
                          : val?.appointment_members?.[0]?.name
                      }
                      rootStyle={upcomingAppointment_style.upcomingcardStyle}
                      bookingText="Join"
                      UpdateTime
                      type={val?.appointment_status}
                      type_name={
                        val?.master_appointment_status?.appointment_status
                      }
                      self={
                        val?.is_self_booking === false
                          ? val?.appointment_members?.[0]?.master_relationship
                              ?.releationship
                          : 'Self'
                      }
                      after={
                        val?.appointment_date_time
                          ? timeDiffCalc(val?.appointment_date_time)
                          : ''
                      }
                      date={
                        val?.appointment_date_time
                          ? getDateFormat(
                              val?.appointment_date_time,
                              'DD MMM YY,',
                            )
                          : ''
                      }
                      time={
                        val?.appointment_date_time
                          ? getDateFormat(
                              val?.appointment_date_time,
                              ' hh:mm A',
                            )
                          : ''
                      }
                      disabled={
                        val?.appointment_date_time
                          ? IsBookButtonEnable(val?.appointment_date_time)
                          : true
                      }
                      // cancel appointment props
                      handleClick={handleClick}
                    />
                  ))
                ) : (
                  <Typography sx={upcomingAppointment_style.subHeadSx}>
                    No Match Found
                  </Typography>
                )}
              </Box>
            )}
        </Box>

        {/* pastAppointment Card */}
        <Box>
          {appointmentState?.past_appointments?.length > 0 &&
            tabIndex === '2' &&
            !loading && (
              <Box>
                {appointmentState?.past_appointments?.length > 0 &&
                onSearch?.length > 0 ? (
                  appointmentState?.search_past_appointments?.length > 0 ? (
                    appointmentState?.search_past_appointments?.map(
                      (val, index) => (
                        <PastAppointmentCard
                          index={index}
                          key={index}
                          visit_report_url={
                            val?.visit_summaries?.[0]?.visit_report_url
                          }
                          doctorName={
                            val?.assigned_doctor_profile?.first_name ?? ''
                          }
                          Professional={
                            val?.assigned_doctor_profile?.doctor_specialities ??
                            []
                          }
                          rootStyle={
                            upcomingAppointment_style.pastAppointmentStyle
                          }
                          src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                          paitentName={
                            val?.is_self_booking === true
                              ? val?.user_profile?.name ?? ''
                              : val?.appointment_members?.[0]?.name
                          }
                          type={val?.appointment_status}
                          type_name={
                            val?.master_appointment_status?.appointment_status
                          }
                          self={
                            val?.is_self_booking === false
                              ? val?.appointment_members?.[0]
                                  ?.master_relationship?.releationship
                              : 'Self'
                          }
                          date={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  'DD MMM YY,',
                                )
                              : ''
                          }
                          time={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  ' hh:mm A',
                                )
                              : ''
                          }
                          viewSummary="View Summary"
                          amount={
                            val?.assigned_doctor_profile?.consulation_charge ??
                            ''
                          }
                          amountStatus={val?.amountStatus ?? ''}
                        />
                      ),
                    )
                  ) : (
                    <Typography sx={upcomingAppointment_style.subHeadSx}>
                      No Match Found
                    </Typography>
                  )
                ) : appointmentState?.past_appointments?.length > 0 ? (
                  appointmentState?.past_appointments?.map((val, index) => (
                    <PastAppointmentCard
                      index={index}
                      key={index}
                      visit_report_url={
                        val?.visit_summaries?.[0]?.visit_report_url
                      }
                      doctorName={
                        val?.assigned_doctor_profile?.first_name ?? ''
                      }
                      Professional={
                        val?.assigned_doctor_profile?.doctor_specialities ?? []
                      }
                      rootStyle={upcomingAppointment_style.pastAppointmentStyle}
                      src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                      paitentName={
                        val?.is_self_booking === true
                          ? val?.user_profile?.name ?? ''
                          : val?.appointment_members?.[0]?.name
                      }
                      type={val?.appointment_status}
                      type_name={
                        val?.master_appointment_status?.appointment_status
                      }
                      self={
                        val?.is_self_booking === false
                          ? val?.appointment_members?.[0]?.master_relationship
                              ?.releationship
                          : 'Self'
                      }
                      date={
                        val?.appointment_date_time
                          ? getDateFormat(
                              val?.appointment_date_time,
                              'DD MMM YY,',
                            )
                          : ''
                      }
                      time={
                        val?.appointment_date_time
                          ? getDateFormat(
                              val?.appointment_date_time,
                              ' hh:mm A',
                            )
                          : ''
                      }
                      viewSummary="View Summary"
                      amount={
                        val?.assigned_doctor_profile?.consulation_charge ?? ''
                      }
                      amountStatus={val?.amountStatus ?? ''}
                    />
                  ))
                ) : (
                  <Typography sx={upcomingAppointment_style.subHeadSx}>
                    No Match Found
                  </Typography>
                )}
              </Box>
            )}
        </Box>
        {/*  pastAppointment Card loader   */}
        <Box>
          {tabIndex === '2' &&
            loading &&
            Array(4)
              .fill(1)
              .map((i) => <SkeletonCard key={i} />)}
        </Box>

        {anchorElCancel && (
          <Menu
            anchorEl={anchorElCancel}
            open={Boolean(anchorElCancel)}
            onClose={handleCancelClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={upcomingAppointment_style.morelistSx}
          >
            <MenuItem
               id="cancel"
              onClick={() => {
                handleDrawerCancelWithApp(true);
                handleCancelClose();
              }}
              sx={upcomingAppointment_style.borderSx}
            >
              <CancelIcon rootStyle={{ color: 'error.main' }} />
              <Box
                component="span"
                sx={upcomingAppointment_style.cancelAppointment}
              >
                Cancel Appointment
              </Box>
            </MenuItem>
          </Menu>
        )}
        {/* CancelAppointment */}
        <CancelAppointmentHome
          setCancelDrawerOpen={setCancelDrawerOpen}
          cancelDrawerOpen={cancelDrawerOpen}
        />
        {/* Loader */}
      </Screenlayout>
    </Box>
  );
}
