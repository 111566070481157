/* eslint-disable no-unused-vars */
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useProblems = create((set, get) => ({
  searchProblems: null,
  quickProblemsData: [],
  problemsData: [],
  loading: null,
  error: null,
  get: async (search) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        search?.length > 0
          ? `/master-health-problems/get-problems?limit=3&searchString=${search}`
          : '/master-health-problems/get-problems?limit=10',
        'get',
      ]);
      const problemsDataDummy = [];
      const QuickSuggProblemsDataDummy = [];
      if (Array.isArray(data?.healthData) && data?.healthData?.length > 0) {
        data?.healthData?.map((val) => {
          problemsDataDummy.push({
            value: val?.id ?? '',
            label: val?.health_problem ?? '',
            labelShow: val?.health_problem ?? '',
          });
        });
      }
      if (
        Array.isArray(data?.quick_suggestion_data) &&
        data?.quick_suggestion_data?.length > 0
      ) {
        data?.quick_suggestion_data?.map((val) => {
          QuickSuggProblemsDataDummy.push({
            value: val?.health_problem_id ?? '',
            label: val?.health_problem ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        problemsData: problemsDataDummy,
        quickProblemsData: QuickSuggProblemsDataDummy,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
