/* eslint-disable no-unused-vars */
import { useOnboarding } from '@hc/store/clinical/onboarding';
import { Button, Input, Label } from '@hc/ui/atoms';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { resetPassword_style } from './style';

export function ClinicalResetPassword() {
  // const { className = '', ...rest } = props;

  const { user, setUser, loading, clearPassword, resetPassword } =
    useOnboarding(
      (state) => ({
        user: state.user,
        loading: state.loading,
        clearPassword: state.clearPassword,
        setUser: state.setUser,
        resetPassword: state.resetPassword,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  // General hooks
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [data, setData] = useState(user);

  const updateState = (key, value) => {
    setData({ ...data, [key]: value });
  };
  const validate = () => {
    let isValid = true;
    const error = data?.error;
    // Checking password
    if (data.password.length === 0) {
      isValid = false;
      error.password = 'New Password is required';
    } else {
      error.password = '';
    }

    if (data.password.length < 8) {
      isValid = false;
      error.password = 'Password must be at least 8 characters long';
    } else if (
      !data.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
      )
    ) {
      isValid = false;
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
    } else {
      error.password = '';
    }

    // Checking Confirm password
    if (data.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword = ' Confirm password is required';
    } else {
      error.confirmPassword = '';
    }
    if (
      data?.confirmPassword.length > 0 &&
      data.password !== data.confirmPassword
    ) {
      isValid = false;
      error.confirmPassword = 'password does not matching';
    }
    setData({ ...data, error });
    return isValid;
  };
  const SignIn = () => {
    if (validate()) {
      const error = data?.error;
      error.password = '';
      error.mobileNumber = '';
      setData({ ...data, error });
      resetPassword(data);
    }
  };

  useEffect(() => {
    if (!user?.mobileNumber) {
      navigate(clinicalRoutes.login);
    }
  }, [user]);

  useEffect(() => {
    setUser({
      ...user,
      password: '',
      confirmPassword: '',
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
    setData({
      ...user,
      password: '',
      confirmPassword: '',
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
  }, []);
  return (
    <Box>
      <Box sx={resetPassword_style.paperSx}>
        <Box sx={{ display: 'grid', gap: 1 }}>
          <Typography variant="h6" sx={resetPassword_style.titleSx}>
            Reset Password
          </Typography>
          <Typography variant="body2" sx={resetPassword_style.subTitleSx}>
            Please provide a new password for your account
          </Typography>
        </Box>
        <Box sx={{ display: 'grid', gap: 2 }}>
          {/* New Password */}
          <Box sx={resetPassword_style.inputGroupSx}>
            <Label labelStyle={resetPassword_style.labelStyle} isRequired>
              New Password
            </Label>
            <Input
              type={showpassword ? 'text' : 'password'}
              errorMessage={data?.error?.password}
              isError={data?.error?.password?.length > 0}
              value={data?.password}
              onChange={(e) => updateState('password', e.target.value)}
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  edge="end"
                >
                  {data?.password?.length > 0 ? (
                    <div>
                      {showpassword ? (
                        <MdVisibilityOff sx={resetPassword_style.eyeSx} />
                      ) : (
                        <MdVisibility sx={resetPassword_style.eyeSx} />
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </IconButton>
              }
            />
          </Box>
          {/* Confirm New Password */}
          <Box sx={resetPassword_style.inputGroupSx}>
            <Label labelStyle={resetPassword_style.labelStyle} isRequired>
              Confirm New password
            </Label>
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              isError={data?.error?.confirmPassword?.length > 0}
              errorMessage={data?.error?.confirmPassword}
              value={data?.confirmPassword}
              onChange={(e) => updateState('confirmPassword', e.target.value)}
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowConfirmPassword((prevState) => !prevState)
                  }
                  edge="end"
                >
                  {data?.confirmPassword?.length > 0 ? (
                    <div>
                      {showConfirmPassword ? (
                        <MdVisibilityOff sx={resetPassword_style.eyeSx} />
                      ) : (
                        <MdVisibility sx={resetPassword_style.eyeSx} />
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </IconButton>
              }
            />
          </Box>
        </Box>
        <Box>
          <Button
            sx={{ mt: 3, textTransform: 'none' }}
            fullWidth
            onClick={SignIn}
            loading={loading}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
