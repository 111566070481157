import { CircularProgressWithLabel } from '@atoms/circularProgessBar';
import { useMBFActivity } from '@hc/store';
import { Box } from '@mui/material';
import { isEqual } from 'date-fns';
import { React } from 'react';
import { HomeBanner } from '../../../../homeBanner';
import { mbfHomeBanner_style } from './style';

export function MBFHomeBanner(props) {
  const {
    btnDescription = '',
    description = '',
    type = '',
    onClickFnc = () => {},
  } = props;

  const { mbfState } = useMBFActivity(
    (state) => ({
      mbfState: state.mbfState,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { perDayOverAllPercentage, tillDayCount, overallDayCount } = mbfState;

  return (
    <Box>
      <HomeBanner
        rootStyleSx={mbfHomeBanner_style.mbfHomeBannerSx}
        buttonStyleSx={mbfHomeBanner_style.buttonSx}
        descStyleSx={mbfHomeBanner_style.descSx}
        desc={description}
        buttonDescription={btnDescription ?? 'View plan'}
        onClickFnc={onClickFnc}
        type={type}
        component={
          <CircularProgressWithLabel
            centerLabel={`${perDayOverAllPercentage ?? 0}%`}
            size={80}
            thickness={5}
            value={perDayOverAllPercentage ?? ''}
          />
        }
        currentDay={tillDayCount}
        Totaldays={overallDayCount}
      />
    </Box>
  );
}
