export const performanceStats_Style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  headerTextSx: {
    width: '241px',
    px: 2,
  },
  subTextSx: {
    px: 2,
    width: '340px',
  },
  cardSx: {
    width: '100%',
    height: '243px',
  },
  colorWithSx: {
    mt: 1,
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 0.1,
  },
  bodyCardStyleSx: {
    bgcolor: 'sky.50',
  },
  statsFromSx: {
    bgcolor: 'mbf.light',
    borderRadius: '10px 10px 0px 0px',
    px: 2,
    py: 1,
    mb: -0.5,
  },
  mindBodyRootCardSx: {
    px: 3,
    py: 1.5,
    border: '1px solid',
    borderColor: '#E5E0FF',
    borderRadius: '8px',
    bgcolor: 'white',
    // height: 410,
    overflow: 'scroll',
    position: 'relative',
  },
};
