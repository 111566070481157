import AngryImage from '@assets/angryEmoji.png';
import FaceBandageEmoji from '@assets/faceBandageEmoji.png';
import FaceWIthStreamEmoji from '@assets/faceWIthStreamEmoji.png';
import NeutralFaceEmoji from '@assets/neutralFaceEmoji.png';
import SleepyFaceEmoji from '@assets/sleepyFaceEmoji.png';
import SmileEmoji from '@assets/smileEmoji.png';
import SmileWithStarEmoji from '@assets/smileWithStarEmoji.png';
import { DarkpurpleIcon, LightPurpleIcon } from '@hc/ui/atoms';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { moodSelection_style } from './style';

export function MoodSelection(props) {
  const {
    hover,
    className = '',
    onHoverBtn = () => {},
    setHover,
    ...rest
  } = props;

  const mbfMoodImages = {
    happy: SmileEmoji,
    sad: SleepyFaceEmoji,
    neutral: NeutralFaceEmoji,
    Tired: FaceBandageEmoji,
    stressed: FaceWIthStreamEmoji,
    angry: AngryImage,
    energetic: SmileWithStarEmoji,
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={moodSelection_style.totalMoodSx}>
        <Box sx={moodSelection_style.darkCrileSx}>
          <DarkpurpleIcon />
        </Box>

        {/* Happy */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '4/7',
            gridColumn: '5/10',
            maxWidth: '150px',
          }}
          onClick={() => onHoverBtn('Happy')}
          id="happy"
        >
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '150px',
              backgroundColor: hover === 'Happy' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Happy' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Happy' ? 'common.white' : 'common.black',
            }}
          >
            <Box>
              <Avatar
                src={mbfMoodImages.happy}
                sx={moodSelection_style.avatarSx}
              />
              <Typography sx={moodSelection_style.titleSx}>Happy</Typography>
            </Box>
          </Box>
        </Box>

        {/* Neutral */}
        <Box
          onClick={() => onHoverBtn('Neutral')}
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '6/4',
            gridColumn: '10/13',
            maxWidth: '96px',
            position: 'relative',
          }}
          id="neutral"
        >
          <Box sx={moodSelection_style.darkCrile2Sx}>
            <DarkpurpleIcon />
          </Box>
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '96px',
              backgroundColor: hover === 'Neutral' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Neutral' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Neutral' ? 'common.white' : 'common.black',
            }}
          >
            <Box>
              <Avatar
                src={mbfMoodImages.neutral}
                sx={moodSelection_style.avatarSx}
              />
              <Typography
                sx={{ ...moodSelection_style.titleSx, fontSize: '14px' }}
              >
                Neutral
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* tired */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '10/6',
            gridColumn: '8/12',
            maxWidth: '112px',
            position: 'relative',
          }}
        >
          <Box sx={moodSelection_style.darkCrile3Sx}>
            <DarkpurpleIcon />
          </Box>
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '112px',
              backgroundColor: hover === 'Tired' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Tired' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Tired' ? 'common.white' : 'common.black',
            }}
            onClick={() => onHoverBtn('Tired')}
            id="tired"
          >
            <Box>
              <Avatar
                src={mbfMoodImages.Tired}
                sx={moodSelection_style.avatarSx}
              />
              <Typography
                sx={{ ...moodSelection_style.titleSx, fontSize: '18px' }}
              >
                Tired
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* stressed */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '10/6',
            gridColumn: '2/10',
            maxWidth: '150px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '150px',
              backgroundColor: hover === 'Stressed' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Stressed' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Stressed' ? 'common.white' : 'common.black',
            }}
            onClick={() => onHoverBtn('Stressed')}
            id="stressed"
          >
            <Box>
              <Avatar
                src={mbfMoodImages.stressed}
                sx={moodSelection_style.avatarSx}
              />
              <Typography sx={moodSelection_style.titleSx}>Stressed</Typography>
            </Box>
          </Box>
        </Box>

        {/* sad */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '4/7',
            gridColumn: '1/5',
            maxWidth: '112px',
            position: 'relative',
          }}
        >
          <Box sx={moodSelection_style.lightpurpleSx}>
            <LightPurpleIcon />
          </Box>
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '112px',
              backgroundColor: hover === 'Sad' ? 'mbf.main' : 'purple.400',
              boxShadow: hover === 'Sad' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Sad' ? 'common.white' : 'common.black',
            }}
            onClick={() => onHoverBtn('Sad')}
            id="sad"
          >
            <Box>
              <Avatar
                src={mbfMoodImages.sad}
                sx={moodSelection_style.avatarSx}
              />
              <Typography
                sx={{ ...moodSelection_style.titleSx, fontSize: '18px' }}
              >
                Sad
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Angry */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '1/5',
            gridColumn: '2/7',
            maxWidth: '156px',
          }}
        >
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              height: '156px',
              backgroundColor: hover === 'Angry' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Angry' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Angry' ? 'common.white' : 'common.black',
            }}
            onClick={() => onHoverBtn('Angry')}
            id="angry"
          >
            <Box>
              <Avatar
                src={mbfMoodImages.angry}
                sx={moodSelection_style.avatarSx}
              />
              <Typography sx={moodSelection_style.titleSx}>Angry</Typography>
            </Box>
          </Box>
        </Box>

        {/* energictic */}
        <Box
          sx={{
            ...moodSelection_style.emojiSx,
            gridRow: '1/4',
            gridColumn: '7/12',
            maxWidth: '158px',
            position: 'relative',
          }}
        >
          <Box sx={moodSelection_style.lightpurple2Sx}>
            <LightPurpleIcon />
          </Box>
          {/* <Box sx={moodSelection_style.lightpurple3Sx}>
            <LightPurpleIcon />
          </Box> */}
          <Box
            sx={{
              ...moodSelection_style.imgSx,
              backgroundColor:
                hover === 'Energetic' ? 'mbf.main' : 'purple.400',
              boxShadow:
                hover === 'Energetic' ? '0px 0px 7px 2px #4f34da8a' : 'none',
              color: hover === 'Energetic' ? 'common.white' : 'common.black',
            }}
            onClick={() => onHoverBtn('Energetic')}
            id="energictic"
          >
            <Box>
              <Avatar
                src={mbfMoodImages.energetic}
                sx={moodSelection_style.avatarSx}
              />
              <Typography sx={moodSelection_style.titleSx}>
                Energetic
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
MoodSelection.propTypes = {
  className: PropTypes.object,
  hover: PropTypes.object,
  setHover: PropTypes.func,
  onHoverBtn: PropTypes.func,
};
