export const awarenessSlider_Style = {
  rootSx: {
    // background: 'linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6)',
    height: '100vh',
  },
  subHeadSx: {
    fontSize: '16px',
    color: 'text.primary',
    p: 5.5,
    fontWeight: '25px',
  },

  titleSx: {
    fontSize: '23px',
    color: '#fff',
    p: 3,
    fontWeight: 'bold',
    position: 'absolute',
    top: 0,
  },
  backgroundImg: {},
};
