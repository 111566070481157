export const MedicationSelection_style = {
  autocompeleteSx: {
    '& .MuiOutlinedInput-root': {
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      paddingLeft: '14px !important',
      paddingRight: '14px !important',
      fontSize: '14px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
};
