import { create } from 'zustand';

export const useJitsiEmbed = create((set, get) => ({
  jitsiState: {
    roomId: '',
    isStartCall: false,
    minimizeMeetWindow: false,
    showSummary: true,

    apptId: '',
  },
  onMeetlinkUpdate: (link) => {
    const { jitsiState } = get();
    set({
      jitsiState: {
        ...jitsiState,
        roomId: link,
      },
    });
  },
  onCallUpdate: (key, value) => {
    const { jitsiState } = get();
    set({
      jitsiState: {
        ...jitsiState,
        [key]: value,
      },
    });
  },
}));
