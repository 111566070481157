export const mbfHome_activity_card_style = {
  activityTypeSx: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    gap: 1,
    mt: 2,
  },
  descSx: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  spanSx: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#6856cd',
  },
};
