import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { emptyState_style } from './style';

function ClinicalEmptyState(props) {
  const {
    className = '',
    rootStyle = {},
    emptyStateStyle = {},
    IconRequired = {},
    mainHead = '',
    subHead = '',
    subHeadStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...emptyState_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...emptyState_style.emptyStateSx, ...emptyStateStyle }}>
        <Box sx={{ p: 1.5 }}>{IconRequired}</Box>
        <Box>
          <Typography variant="body1" sx={emptyState_style.mainHeadSx}>
            {mainHead}
          </Typography>
          <Typography sx={{ ...emptyState_style.subHeadSx, ...subHeadStyle }}>
            {subHead}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export { ClinicalEmptyState };

ClinicalEmptyState.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  emptyStateStyle: PropTypes.object,
  IconRequired: PropTypes.object,
  mainHead: PropTypes.string,
  subHead: PropTypes.string,
  subHeadStyle: PropTypes.object,
};
