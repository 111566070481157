export const otpInput_style = {
  inputStyle: {
    border: '2px solid transparent',
    borderRadius: '8px',
    width: '100%',
    height: '50px',
    fontSize: '16px',
    color: '#0E1824',
    fontWeight: '600',
    backgroundColor: '#F5FBFA',
  },
};
