/* eslint-disable camelcase */
export const wellness_activity_card_Style ={
    rootSx:{

    },
    cardRootSx: {
        boxShadow:" 0 1px 12px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 3%)",  
        borderRadius: '8px',
        backgroundColor:"#fff"
      },
}