import { getDateFormat } from '@hc/dayjs';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  CalenderIcon,
  CancelAppointmentIcon,
  ClockIcon,
  ConfirmAppointmentIcon,
  VideoIcon
} from '../../../atoms';
import { AppointmentCard } from '../appointmentBooking/appointmentCard';
import { AppointmentShedule } from '../appointmentBooking/appointmentShedule';
import { BookingMinDetails } from '../appointmentBooking/bookingMinDetails';
import { DoctorProfile } from '../appointmentBooking/doctorProfile';
import { chatStatusCard_style } from './style';

function ChatStatusCard(props) {
  const {
    confirmed = true,
    noIconShow = false,
    message = '',
    subMessage = '',
    className = '',
    rootStyle = {},
    onCancelModalClick = {},
    onHomePageClick,
    makePaymentClick,
    // myself = false,
    cancelRequired = false,
    cancelButtonName = '',
    type = '',
    profilePicture = '',
    name = '',
    mobileNo = '',
    doctorChatConsultationAmount = '',
    chatRequestedDate = '',
    chatConsultationDuration = '',
    buttonLoader = false,
    buttonDisabled = false,
    hideAppointmentCard = false,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...chatStatusCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={chatStatusCard_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <BookingMinDetails
          // isIconRequired={false}
          iconType={
            noIconShow ? null : confirmed ? (
              <ConfirmAppointmentIcon />
            ) : (
              <CancelAppointmentIcon />
            )
          }
          appointmentType={message}
          updateStyle={chatStatusCard_style.smsTextSx}
          smsText={subMessage}
          cancelBtnRequired={cancelRequired}
          cancelButtonName={cancelButtonName}
          buttonLoader={buttonLoader}
          onCancelModalClick={onCancelModalClick}
          onHomePageClick={onHomePageClick}
          makePaymentClick={makePaymentClick}
          type={type}
          buttonDisabled={buttonDisabled}
        >
          {!hideAppointmentCard && (
            <AppointmentCard
              appointmentCardStyle={chatStatusCard_style.rootCardSx}
              mainStyle={chatStatusCard_style.mainSx}
              header={
                <DoctorProfile
                  src={profilePicture ?? ''}
                  doctorName={`Dr. ${name}`}
                  aboutpatient={{
                    patientRole: mobileNo ?? '',
                    relationship: '',
                  }}
                  pricingRequired={doctorChatConsultationAmount}
                  apointmentCardstyle={chatStatusCard_style.cardSx}
                  profilepicStyle={chatStatusCard_style.profilepicSx}
                  tagStyle={chatStatusCard_style.priceTagSx}
                />
              }
              footer={
                <AppointmentShedule
                  icon={<CalenderIcon />}
                  dateText="Date"
                  // yearText="09 Apr,2003"
                  yearText={getDateFormat(chatRequestedDate, 'DD MMM, YYYY')}
                  timeText="Time"
                  timeIcon={<ClockIcon />}
                  // timingClock="09:15 AM"
                  timingClock={chatConsultationDuration ?? '15 Mins'}
                  videoIcon={<VideoIcon />}
                  confirmed={confirmed}
                  rootStyle={chatStatusCard_style.appointmentRootSx}
                />
              }
            />
          )}
        </BookingMinDetails>
      </Box>
    </Box>
  );
}

ChatStatusCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  confirmed: PropTypes.bool,
  message: PropTypes.string,
  myself: PropTypes.bool,
  subMessage: PropTypes.string,
};

export { ChatStatusCard };

