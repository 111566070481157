import { CalenderSlot, DoctorAvailable } from '@components/clinical';
import { useBookAppointment } from '@hc/store';
import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { appointmentDateTimeSelection_style } from './style';

export function AppointmentDateTimeSelection(props) {
  const {
    className = '',
    bookAppointmentStateData,
    isReferral = false,
    value,
    ...rest
  } = props;

  const { bookAppointmentState } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { doctorDetail } = bookAppointmentState;

  return (
    <Box className={`${className}`} {...rest} sx={{ zoom: '95%' }}>
      <Box>
        <DoctorAvailable
          src={doctorDetail?.profile_pic ?? ''}
          doctorName={doctorDetail?.name ?? ''}
          pricingRequired={
            isReferral
              ? doctorDetail?.followup_amount_rupees
              : doctorDetail?.appointment_amount_rupees ?? ''
          }
          appointmentCard={appointmentDateTimeSelection_style.appointmentCardSx}
          role={doctorDetail?.doctor_specialities}
          hospital={doctorDetail?.working_at}
          servingFrom={value?.serving_from ?? ''}
        />
      </Box>
      <Divider
        sx={{ mt: 1.5, border: '.5px solid', borderColor: 'grey.400' }}
      />
      <CalenderSlot />
    </Box>
  );
}

AppointmentDateTimeSelection.propTypes = {
  doctorDetail: PropTypes.object,
  className: PropTypes.node,
  sx: PropTypes.object,
  bookAppointmentStateData: PropTypes.func,
  isReferral: PropTypes.bool,
  value: PropTypes.any,
};
