export const report_custmize_style = {
  rootSx: {},
  rootBoxSx: {
    backgroundColor: 'orangeTints.50',
    borderRadius: '8px',
    p: 1.5,
    mt: 2.5,
    mb: 4,
  },
  buttonRootSx: {
    textTransform: 'none',
    backgroundColor: 'orangeTints.100',
    color: 'orangeTints.600',
    fontSize: '14px',
    fontWeight: '500',
    '&:focus': {
      backgroundColor: 'orangeTints.100',
      color: 'orangeTints.600',
    },
    '&:hover': {
      backgroundColor: 'orangeTints.100',
      color: 'orangeTints.600',
    },
  },
  bodyContentSx: {
    fontSize: '14px',
    fontWeight: '500',
    color:"#000"
  },
  buttonTextSx:{
    fontSize: '14px',
    fontWeight: '500',
  },
  dataTextSx: {
    color: 'text.hint',
    fontSize: '12px',
  },
  dividerSx: { 
    border: '1.5px solid',
    borderColor:"orangeTints.100",
    backgroundColor:"orangeTints.100" ,
},
reportCountSx:{
  fontSize:15,
  fontWeight:600
}
};
