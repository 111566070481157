import { Avatar, Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BillIcon, CalenderIcon, ProfileIcon, ViewSummaryIcon } from '@atoms';
import { camelCaseToTitleCase } from '@hc/utils/helperFunctions';
import { passAppointment_style } from './style';

export function PastAppointmentCard(props) {
  const {
    src = {},
    type,
    type_name,
    time,
    date,
    doctorName = '',
    Professional = [],
    paitentName = '',
    self = '',
    viewSummary = '',
    visit_report_url,
    downloadSummary,
    className = '',
    profilepic = {},
    amount = '',
    rootStyle = {},
    amountStatus = '',
    ...rest
  } = props;

  const types = {
    // Green
    1: {
      color: 'primary.main',
      backgroundColor: 'primaryTints.100',
    },
    // orange
    2: {
      color: 'secondary.main',
      backgroundColor: 'orangeTints.100',
    },
    // Green
    3: {
      color: 'primary.main',
      backgroundColor: 'primaryTints.100',
    },
    // orange
    4: {
      color: 'secondary.main',
      backgroundColor: 'orangeTints.100',
    },
    // red
    5: {
      color: 'red.800',
      backgroundColor: 'red.50',
    },
    // red
    8: {
      color: 'red.800',
      backgroundColor: 'red.50',
    },
    // orange
    9: {
      color: 'secondary.main',
      backgroundColor: 'orangeTints.100',
    },
    // red
    10: {
      color: 'red.800',
      backgroundColor: 'red.50',
    },
    // Green
    11: {
      color: 'primary.main',
      backgroundColor: 'primaryTints.100',
    },
    // red
    12: {
      color: 'red.800',
      backgroundColor: 'red.50',
    },
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...passAppointment_style.rootSx, ...rootStyle }}>
        <Box>
          <Box sx={{ backgroundColor: '#FCFCFC ', borderRadius: '7px' }}>
            {/* Doctor & his Profession */}
            <Box sx={passAppointment_style.appointmentCardSx}>
              <Box sx={passAppointment_style.doctornameSx}>
                <Avatar
                  src={src}
                  sx={{ ...passAppointment_style.profilepicSx, ...profilepic }}
                />
                <Box sx={{ mt: 0.6, ml: 1.5 }}>
                  <Typography variant="body2" sx={{ fontWeight: '600' }}>
                    {doctorName}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', flexwrap: 'wrap', flexFlow: 'wrap' }}
                  >
                    {Array.isArray(Professional) &&
                      Professional?.length > 0 &&
                      Professional.map((val, index) => (
                        <Typography
                          key={index}
                          sx={passAppointment_style.professionalSx}
                        >
                          • {val?.master_speciality?.s ?? ''} &nbsp;
                        </Typography>
                      ))}
                    {/* {Professional} */}
                  </Box>
                </Box>
              </Box>
              <Box sx={passAppointment_style.typeSx}>
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    ...passAppointment_style.referchangingSx,
                    ...types[type],
                  }}
                >
                  {camelCaseToTitleCase(type_name)}
                </Typography>
              </Box>
            </Box>

            {/* View Summary with Self */}
            <Box sx={passAppointment_style.appointmentDocCardSx}>
              <Box sx={passAppointment_style.selfSummarySx}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ProfileIcon />
                </Box>
                <Box>
                  <Typography sx={passAppointment_style.selfSx}>
                    {paitentName}
                    &nbsp;
                    {self && <span>{`(${self})`}</span>}
                  </Typography>
                </Box>
              </Box>
              {visit_report_url && (
                <a
                  href={visit_report_url}
                  download
                  style={{ textDecoration: 'none' }}
                >
                  <Box
                    sx={{ display: 'flex', mr: 1.5, cursor: 'pointer' }}
                    // onClick={() => downloadSummary(visit_report_url)}
                  >
                    <Box>
                      <ViewSummaryIcon />
                    </Box>
                    <Box>
                      <Typography sx={passAppointment_style.viewSummaryTextSx}>
                        {viewSummary}
                      </Typography>
                    </Box>
                  </Box>
                </a>
              )}
            </Box>
            <Divider sx={passAppointment_style.dividerSx} />
          </Box>

          {/* date,time and Amount */}
          <Box sx={passAppointment_style.appointmentDateTimeSx}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={passAppointment_style.dateTimeSx}>
                <CalenderIcon />
              </Box>
              <Box>
                <Typography sx={passAppointment_style.selfSx}>
                  {date}
                  <span>{time}</span>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mr: 1.5 }}>
              <Box>
                <BillIcon />
                &nbsp;
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={passAppointment_style.amountSx}>
                  {amount}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography sx={passAppointment_style.amountTextSx}>
            {amountStatus}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
PastAppointmentCard.propTypes = {
  src: PropTypes.object,
  doctorName: PropTypes.string,
  type: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  Professional: PropTypes.string,
  paitentName: PropTypes.string,
  self: PropTypes.string,
  viewSummary: PropTypes.string,
  amount: PropTypes.string,
  amountStatus: PropTypes.string,
  className: PropTypes.node,
  sx: PropTypes.object,
};
