export const vital_monitoring_history_style = {
  rootSx: {
    px: 2,
    py: 1,
    height: '100vh',
    overflowY: 'scroll',
    bgcolor: '',
  },
  historyCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1,
    bgcolor: '#fff',
    borderRadius: '8px',
    mb:1
  },
};
