import { Box, Stack, Typography } from '@mui/material';
// import OtpTickGif from '../../assets/OtpTickGif.gif';
import OtpTickGif from '@assets/OtpTickGif.gif';
import { clinicalRoutes } from '@hc/routes';
import { useOnboarding } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { isEqual } from 'date-fns';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OtpSplash_style } from './style';

export default function OtpSplash(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();

  const { user } = useOnboarding(
    (state) => ({
      user: state.user,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const navigationFunction = () => {
    if (user?.type === 'sign-up') {
      navigate(clinicalRoutes.addProfile);
    } else if (
      user?.type === 'corporate' ||
      user?.type === 'familyMember' ||
      user?.type === 'addByDoctorOrAdmin'
    ) {
      navigate(clinicalRoutes.editProfile);
    } else if (user?.type === 'sign-in') {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const data = parseJwt(authToken);
      if (
        data?.mobile_no &&
        data?.mobile_verified === true &&
        data?.name !== null
      ) {
        navigate(clinicalRoutes.home);
      } else {
        navigate(clinicalRoutes.addProfile);
      }
    } else if (user?.type === 'forgot-password') {
      navigate(clinicalRoutes.resetPassword);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      navigationFunction();
    }, 2500);
  }, []);

  return (
    <Box sx={OtpSplash_style.rootSx} className={`${className}`} {...rest}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={OtpSplash_style.containerSx}
      >
        <Box>
          <img
            src={OtpTickGif}
            alt="Verified tick gif"
            style={OtpSplash_style.tickGifSx}
          />
        </Box>
        <Typography sx={OtpSplash_style.descTypoSx}>
          {' '}
          OTP Verified <br /> Successfully
        </Typography>
      </Stack>
    </Box>
  );
}
