import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFMoodStatsProgress = create((set, get) => ({
  mbfMoodStatsData: {
    happy: 0,
    neutral: 0,
    sad: 0,
    tired: 0,
    energetic: 0,
    angry: 0,
    stressed: 0,
    startDate: null,
    endDate: null,
  },
  planCount: '',
  planStartDate: '',
  planEndDate: '',
  loading: false,
  error: null,
  getMoodStats: async (payload) => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['moodStats', `${payload.start_date}-${payload.end_date}`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/mood-status/percentage/current-plan`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const { mbfMoodStatsData } = get();
      set({
        loading: false,
        mbfMoodStatsData: {
          ...mbfMoodStatsData,
          happy: data?.mood_percentage_response?.happy ?? '',
          neutral: data?.mood_percentage_response?.neutral ?? '',
          sad: data?.mood_percentage_response?.sad ?? '',
          tired: data?.mood_percentage_response?.tired ?? '',
          energetic: data?.mood_percentage_response?.energetic ?? '',
          angry: data?.mood_percentage_response?.angry ?? '',
          stressed: data?.mood_percentage_response?.stressed ?? '',
        },
        planCount: data?.previous_plan_count ?? '',
        planStartDate: data?.plan_details?.plan_start_date ?? '',
        planEndDate: data?.plan_details?.plan_end_date ?? '',
      });
      if (status?.code === 200) {
        set({ loading: false });
        // toast.success(status?.message);
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getPreviousMoodStats: async (payload) => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['PreviousMoodStats'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/mood-status/percentage/previous-plan?user_id=${payload?.user_id}&page=${payload?.pageValue}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const { mbfMoodStatsData } = get();
      set({
        loading: false,
        mbfMoodStatsData: {
          ...mbfMoodStatsData,
          happy: data?.mood_percentage_response?.happy ?? '',
          neutral: data?.mood_percentage_response?.neutral ?? '',
          sad: data?.mood_percentage_response?.sad ?? '',
          tired: data?.mood_percentage_response?.tired ?? '',
          energetic: data?.mood_percentage_response?.energetic ?? '',
          angry: data?.mood_percentage_response?.angry ?? '',
          stressed: data?.mood_percentage_response?.stressed ?? '',
        },
        planCount: data?.previous_plan_count ?? '',
        planStartDate: data?.plan_details?.plan_start_date ?? '',
        planEndDate: data?.plan_details?.plan_end_date ?? '',
      });
      if (status?.code === 200) {
        set({ loading: false });
        // toast.success(status?.message);
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  startEndDateUpdate: (start, end) => {
    const { mbfMoodStatsData } = get();
    set({
      mbfMoodStatsData: {
        ...mbfMoodStatsData,
        startDate: start,
        endDate: end,
      },
    });
  },
}));
