import { envConfig } from '@hc/config';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMBFWellnessJourney = create((set, get) => ({
  mbfState: {
    activities: null,
    numberOfDaysCompleted: '',
    graphData: null,
  },
  planStartDate: null,
  planEndDate: null,
  planCount: 0,
  loading: false,
  error: null,

  getWellnessJourney: async () => {
    try {
      const { mbfState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['wellnessJourney'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/wellness-journey/percentage/current-plan?user_id=${tokenData?.user_id}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          loading: false,
          mbfState: {
            ...mbfState,
            activities: data?.data?.activity_data_percentage ?? [],
            numberOfDaysCompleted: data?.data?.no_of_days_completed,
            graphData: data?.data?.graph_data,
          },
          planStartDate: data?.data?.since,
          planEndDate: data?.data?.until,
          planCount: data?.data?.previous_plan_count,
        }));
      } else {
        set(() => ({
          loading: false,
        }));
      }
      return { start: data?.data?.since, end: data?.data?.until };
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getPriviousWellnessJourney: async (pageValue) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['wellnessJourneyPrevious'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/mbf/wellness-journey/percentage/previous-plan?user_id=${tokenData?.user_id}&page=${pageValue}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      set(() => ({
        loading: false,
        mbfState: {
          activities: data?.activity_data_percentage ?? [],
          numberOfDaysCompleted: data?.no_of_days_completed,
          graphData: data?.graph_data,
        },
        planStartDate: data?.since,
        planEndDate: data?.until,
        planCount: data?.previous_plan_count,
      }));
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
