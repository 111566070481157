import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '../../../../atoms';
import { bookingMinDetails_style } from './style';

export function BookingMinDetails(props) {
  const {
    appointmentType = '',
    iconType = [],
    children,
    updateStyle = {},
    onCancelModalClick,
    onHomePageClick,
    makePaymentClick,
    smsText = '',
    mobileNumber = '',
    className = '',
    isIconRequired = true,
    isSmsTextRequired = true,
    isSubTextRequired = false,
    cancelBtnRequired = false,
    cancelButtonName = '',
    type = '',
    subText,
    buttonLoader = false,
    buttonDisabled = false,
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      {isIconRequired && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton>{iconType}</IconButton>
        </Box>
      )}
      <Box>
        <Typography sx={bookingMinDetails_style.appointmentTextSx}>
          {appointmentType}
        </Typography>
      </Box>
      {isSubTextRequired && (
        <Typography sx={bookingMinDetails_style.appointmenSubtTextSx}>
          {subText}
        </Typography>
      )}

      <Box>{children}</Box>
      <Box>
        {isSmsTextRequired && (
          <Typography
            sx={{ ...bookingMinDetails_style.updatesTextSx, ...updateStyle }}
          >
            {smsText}
          </Typography>
        )}
        <Typography sx={bookingMinDetails_style.numberTextSx}>
          {mobileNumber}
        </Typography>
      </Box>
      {cancelBtnRequired &&
      (type === 'patientRequestToDoctor' ||
        type === 'chatRequestRejectByDoctor') ? (
        <Box
          sx={bookingMinDetails_style?.cancelRequestBoxSx}
          onClick={() =>
            type === 'patientRequestToDoctor'
              ? onCancelModalClick()
              : type === 'chatRequestRejectByDoctor'
              ? onHomePageClick()
              : null
          }
        >
          <Typography sx={bookingMinDetails_style?.cancelRequestSx}>
            {cancelButtonName}
          </Typography>
        </Box>
      ) : cancelBtnRequired &&
        (type === 'chatRequestAcceptByDoctor' ||
          type === 'payment_initiated' ||
          type === 'chatPaymentInitiateByPatient' ||
          type === 'chatPaymentCompletedByPatient') ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            gap: 2,
          }}
        >
          <Button
            buttonStyle={{
              borderRadius: 2,
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.main',
              },
            }}
            loading={buttonLoader}
            onClick={() => makePaymentClick()}
            disabled={buttonDisabled}
          >
            {cancelButtonName}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

BookingMinDetails.propTypes = {
  appointmentType: PropTypes.string,
  // iconType: PropTypes.object,
  children: PropTypes.array,
  smsText: PropTypes.string,
  mobileNumber: PropTypes.string,
  className: PropTypes.node,
  sx: PropTypes.object,
};
