import { getDateValue } from '@hc/utils';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { clinicalChatHistoryCard_style } from './style';

function ClinicalChatHistoryCard(props) {
  const {
    className = '',
    data = {},
    rootStyle = {},
    onChatHistoryClick = () => {},
    type = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...clinicalChatHistoryCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
      onClick={() =>
        onChatHistoryClick(data?.chat_consltation_id, data?.status, 'isHistory')
      }
    >
      <Grid container>
        <Grid xs={2} item>
          <Avatar
            sx={{ height: '44px', width: '44px', alignSelf: 'center' }}
            src={data?.avatar ?? ''}
          >
            {data?.userName.slice(0, 1) ?? 'A'}
          </Avatar>
        </Grid>
        <Grid xs={8} alignSelf="center" item>
          <Box>
            <Typography fontSize={14} fontWeight={500}>
              {data?.userName ?? ''}
            </Typography>
            <Box display="flex">
              <Typography fontSize={12} color="#888888">
                {getDateValue(data?.chatTime)}
              </Typography>
              {type === 'active_chats' && (
                <Typography fontSize={12} color="#888888">{`• ${
                  data?.status ?? ''
                }`}</Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid xs={2} display="flex" justifyContent="end" item>
          <Box
            sx={{
              bgcolor:
                data?.status === 'OnGoing'
                  ? '#E8F4F1'
                  : data?.status === 'Waiting For Doctor'
                  ? '#FFF6F0'
                  : data?.status === 'rejected'
                  ? '#FEEDEE'
                  : data?.status === 'Cancelled'
                  ? '#FEEDEE'
                  : '#E8F4F1',
              borderRadius: '4px',
              height: '19px',
              display: 'flex',
              px: 0.5,
            }}
          >
            <Typography
              alignSelf="center"
              fontSize={10}
              sx={{ textTransform: 'capitalize' }}
              color={
                data?.status === 'OnGoing'
                  ? '#239179'
                  : data?.status === 'Waiting For Doctor'
                  ? '#EB6D13'
                  : data?.status === 'rejected'
                  ? '#F44F5A'
                  : data?.status === 'Cancelled'
                  ? '#F44F5A'
                  : '#239179'
              }
            >
              {data?.status ?? ''}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ClinicalChatHistoryCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onChatHistoryClick: PropTypes.func,
  data: PropTypes.object,
};

export { ClinicalChatHistoryCard };

