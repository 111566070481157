import { useAllergy } from '@hc/store';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { SearchIcon, SearchSelect } from '@hc/ui/atoms';
import { QusAnsSelectionLisiting } from '@hc/ui/components/qusAnsSelectionListing';
import { Box, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';

export function AllergySelection() {
  const filter = createFilterOptions();
  const { allergyData, quickAllergyData, get } = useAllergy(
    (state) => ({
      quickAllergyData: state?.quickAllergyData,
      allergyData: state?.allergyData,
      get: state?.get,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    bookAppointmentState,
    deleteData,
    addAllergies,
    clearPageData,
    // loading: loader,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state?.bookAppointmentState,
      deleteData: state.deleteData,
      addAllergies: state.addAllergies,
      clearPageData: state.clearPageData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { allergies } = bookAppointmentState;
  const [searchInput, setSearchInput] = useState(null);
  const [addAlleries, setAlleries] = useState('');

  // ADD ALLERGY DATA
  const handleAdd = (e, option) => {
    if (option?.value) {
      addAllergies(e, option);
      // GET ALLERY MASTER DATA
      get();
      setSearchInput({ value: '', label: '', labelShow: '' });
      setAlleries('');
    }
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  // DELETE ALLERGY DATA FUNCTION
  const handleDelete = (i) => {
    // DELETE ALLERGY DATA
    deleteData('allergies', i);
  };

  // CLEAR ALLERGY FUNCTION
  const handleClear = () => {
    // CLEAR ALLERGY
    clearPageData('allergies');
  };

  return (
    <QusAnsSelectionLisiting
      QusAnsSelectionHeading="What allergies do you have?"
      QusAnsListingHeading="Allergies"
      QusAnsListingAdded="Added"
      OnClearAll={handleClear}
      quickSuggestion
      displaySelectionValues={allergies}
      OnDelete={handleDelete}
      sugesstionClick={handleAdd}
      qsOptionViewKey="label"
      quickSuggestionRequired={quickAllergyData}
      QusAnsSelectionListing={
        <SearchSelect
          startAdornment={<SearchIcon rootStyle={{ color: 'grey.500' }} />}
          placeholder="Eg. Skin Allergy"
          onChangeText={(e) => setAlleries(e?.target?.value ?? '')}
          onChange={handleAdd}
          renderOption={(props, option) => {
            return (
              <Box
                sx={{ cursor: 'pointer' }}
                height="40px"
                onClick={(e) => handleAdd(e, option)}
                px={2}
                key={option.label}
                display="flex"
                {...props}
              >
                {option?.label?.includes('ADD') ? (
                  <Box display="flex" alignSelf="center">
                    <Typography
                      sx={{
                        color: '#EB6D13',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      +ADD &nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {addAlleries}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignSelf="center">
                    <Typography>{option.label}</Typography>
                  </Box>
                )}
              </Box>
            );
          }}
          filterOptions={(options, params) => getFilteredadata(options, params)}
          optionViewKey="label"
          value={searchInput}
          options={allergyData.filter(
            (allergy) =>
              !allergies.some(
                (eachAllergy) => eachAllergy?.label === allergy?.label
              )
          )}
        />
      }
    />
  );
}
