export const plan_summary_style = {
  planSummaryImageBackgroundSx: {
    backgroundColor: 'purple.200',
    height: 260,
  },
  planSummaryImageSx: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    pt: 2.6,
  },
};
