export const wellnessjourney_style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
    color: '',
  },
  statusSx: {
    textAlign: 'center',
    color: 'teal.800',
    mx: 1.4,
    fontSize: '14px',
  },
  headerSx: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'teal.50',
    borderRadius: '10px',
    px: 1.4,
    py: 1.8,
    border: '1px solid #1FB476',
  },
  childrenSx: {
    pb: 4,
  },
};
