import { clinicalRoutes } from '@hc/routes';
import { useBloodGroup, useProfile } from '@hc/store';
import {
  BodyCamera,
  Button,
  CautionIcon,
  CustomTextArea,
  HeightNoBgIcon,
  Input,
  Label,
  SquareCheckBoxIcon,
  SqureCheckedIcon,
  WeightNoBgIcon,
} from '@hc/ui/atoms';
import {
  BloodGroupSelection,
  QusAnsSelectionLisiting,
  Screenlayout,
} from '@hc/ui/components';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useKeyPress } from 'ahooks';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { MedicalConditions_style } from './style';

export default function MedicalConditions(props) {
  const { ...rest } = props;

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const {
    loading,
    profile,
    get,
    updateProfileError,
    addData,
    replaceData,
    deleteData,
    clearPageData,
    upsertMedicalCondition,
    handleProfileDataChange,
  } = useProfile(
    (state) => ({
      loading: state.loading,
      profile: state.profile,
      get: state.get,
      updateProfileError: state.updateProfileError,
      addData: state.addData,
      replaceData: state.replaceData,
      deleteData: state.deleteData,
      clearPageData: state.clearPageData,
      upsertMedicalCondition: state.upsertMedicalCondition,
      handleProfileDataChange: state.handleProfileDataChange,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // General Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const { isFromClinical } = location.state ?? false;
  const fromPlan = location?.state?.fromPlan ?? '';

  const [allergy, setAllergy] = useState('');
  const [problem, setProblem] = useState('');
  const [medication, setMedication] = useState('');

  const [allergyCheck, setAllergyCheck] = useState(false);
  const [problemCheck, setProblemCheck] = useState(false);
  const [medicationCheck, setMedicationCheck] = useState(false);

  // SKIP FUNCTION
  const onSkip = () => {
    navigate(clinicalRoutes?.profileView, {
      state: {
        fromMedicalCondition: true,
      },
    });
  };
  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  // CLEAR ALLERGY FUNCTION
  const handleClearAllergy = () => {
    // CLEAR ALLERGY
    clearPageData('medical_allergies');
  };
  // CLEAR HEALTH PROBLEM FUNCTION
  const handleClearProblem = () => {
    // CLEAR HEALTH PROBLEM
    clearPageData('medical_health_problems');
  };
  // CLEAR MEDICATION FUNCTION
  const handleClearMedication = () => {
    // CLEAR MEDICATION
    clearPageData('medical_active_medications');
  };

  // DELETE ALLERGY DATA FUNCTION
  const handleDeleteAllergy = (i) => {
    // DELETE ALLERGY DATA
    deleteData('medical_allergies', i);
  };
  // DELETE HEALTH PROBLEM DATA FUNCTION
  const handleDeleteProblem = (i) => {
    // DELETE HEALTH PROBLEM DATA
    deleteData('medical_health_problems', i);
  };
  // DELETE MEDICATION DATA FUNCTION
  const handleDeleteMedication = (i) => {
    // DELETE MEDICATION DATA
    deleteData('medical_active_medications', i);
  };

  const handleAddAllergy = () => {
    if (allergy && allergy?.length > 0) {
      // HANDLE Basic DATA CAHNGE
      addData('medical_allergies', allergy);
      // CLEAR TEXT STATE DATA
      setAllergy('');
    }
  };
  const handleAddProblem = () => {
    if (problem && problem?.length > 0) {
      // HANDLE Basic DATA CAHNGE
      addData('medical_health_problems', problem);
      // CLEAR TEXT STATE DATA
      setProblem('');
    }
  };
  const handleAddMedication = () => {
    if (medication && medication?.length > 0) {
      // HANDLE Basic DATA CAHNGE
      addData('medical_active_medications', medication);
      // CLEAR TEXT STATE DATA
      setMedication('');
    }
  };

  const handleAllergy = (e) => {
    setAllergy(e.target.value);
  };
  const handleProblem = (e) => {
    setProblem(e.target.value);
  };
  const handleMedication = (e) => {
    setMedication(e.target.value);
  };

  const handleCheckBox = (key) => {
    if (key === 'medical_allergies') {
      if (!allergyCheck) {
        replaceData(key, ['No Allergies']);
        setAllergyCheck(true);
      } else {
        replaceData(key, []);
        setAllergyCheck(false);
      }
    } else if (key === 'medical_health_problems') {
      if (!problemCheck) {
        replaceData(key, ['No Health Problems']);
        setProblemCheck(true);
      } else {
        replaceData(key, []);
        setProblemCheck(false);
      }
    } else if (key === 'medical_active_medications') {
      if (!medicationCheck) {
        replaceData(key, ['No Medications']);
        setMedicationCheck(true);
      } else {
        replaceData(key, []);
        setMedicationCheck(false);
      }
    }
  };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  const isIamValidToSave = (type) => {
    const profileCopy = JSON.parse(JSON.stringify(profile));

    let isValid = true;
    const error = profileCopy?.error;

    // Checking vitals
    if (type === 'mbf') {
      if (!profile?.height || !profile?.weight || !profile?.bmi) {
        isValid = false;
        error.vitals = 'Please enter vital details';
      } else {
        error.vitals = '';
      }
    }

    if (profile?.medical_allergies?.length === 0) {
      isValid = false;
      error.medical_allergies = 'Please enter medical allergies';
    } else {
      error.medical_allergies = '';
    }

    if (profile?.medical_health_problems?.length === 0) {
      isValid = false;
      error.medical_health_problems = 'Please enter medical health problems';
    } else {
      error.medical_health_problems = '';
    }

    if (profile?.medical_active_medications?.length === 0) {
      isValid = false;
      error.medical_active_medications =
        'Please enter medical active medications';
    } else {
      error.medical_active_medications = '';
    }

    // UPDATE PROFILE ERROR
    updateProfileError(error);
    return isValid;
  };

  const saveMedicalCondition = async (type) => {
    const result = isIamValidToSave(type);
    
    if (result) {
      const res = await upsertMedicalCondition();
      if (res === 'Successs') {
        if (type === 'profile') {
          navigate(clinicalRoutes?.profileView, {
            state: {
              fromMedicalCondition: true,
            },
          });
        } else if (type === 'mbf' && fromPlan === 'New') {
          navigate(clinicalRoutes.getStarted, {
            state: {
              fromPlan: 'New',
            },
          });
        } else if (type === 'mbf' && fromPlan === 'Renewal') {
          navigate(clinicalRoutes.mindbodyfood, {
            state: {
              fromPlan: 'Renewal',
            },
          });
        }
      }
    }
  };

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    handleProfileDataChange(key, value);
  };

  const initialData = async () => {
    // To get blood group master
    await getBloodGroupData();
    // To get profile data
    await get();
    if (profile) {
      if (profile?.medical_allergies?.[0] === 'No Allergies') {
        setAllergyCheck(true);
      }
      if (profile?.medical_health_problems?.[0] === 'No Health Problems') {
        setProblemCheck(true);
      }
      if (profile?.medical_active_medications?.[0] === 'No Medications') {
        setMedicationCheck(true);
      }
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Screenlayout
      title={
        isFromClinical
          ? 'Medical Conditions'
          : fromPlan === 'Renewal'
          ? 'Re-check Vitals'
          : 'Getting started'
      }
      backRequired
      masterchildStyle={{ pb: 10 }}
      backIconStyle={{ color: isFromClinical ? 'primaryTints.800' : '#7A67D9' }}
      appBarStyle={{
        backgroundColor: isFromClinical ? 'primaryTints.800' : '#7A67D9',
      }}
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
        backgroundColor: '#F7F7F7',
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isFromClinical && (
            <>
              {' '}
              <Button
                onClick={() => onSkip()}
                buttonStyle={MedicalConditions_style.buttonSx}
              >
                Cancel
              </Button>
              <Button onClick={() => saveMedicalCondition('profile')}>
                Save
              </Button>{' '}
            </>
          )}
          {!isFromClinical && (
            <Button
              onClick={() => saveMedicalCondition('mbf')}
              buttonStyle={MedicalConditions_style.mbfbuttonSx}
              loading={loading}
            >
              {fromPlan === 'Renewal' ? 'Submit' : "Now, Let's jump in"}
            </Button>
          )}
        </Box>
      }
      {...rest}
    >
      {/* Allergies */}
      <Box p={1} pt={2}>
        {!isFromClinical && (
          <Box sx={MedicalConditions_style.boxRootSx} mb={2}>
            <Box sx={MedicalConditions_style.CautionContainerSx}>
              <Stack sx={{ justifyContent: 'center' }}>
                <CautionIcon />
              </Stack>
              <Typography sx={MedicalConditions_style.CautionFontSx}>
                {' '}
                To bring most out of the plan we need few <br /> things from you
              </Typography>
            </Box>
          </Box>
        )}

        <Stack sx={MedicalConditions_style.boxRootSx} gap={3}>
          {/* Qus and Ans Selection Lisiting */}
          {!isFromClinical && (
            <Stack sx={MedicalConditions_style.medicalCard}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={MedicalConditions_style.titileSx}>
                  Vitals
                </Typography>
                <Typography
                  sx={
                    profile?.error?.vitals
                      ? MedicalConditions_style.requiredSxx
                      : MedicalConditions_style.requiredSx
                  }
                >
                  Required
                </Typography>
              </Stack>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Label htmlFor="height">Height</Label>
                  <Input
                    id="height"
                    type="number"
                    value={profile?.height}
                    onChange={(e) => {
                      handleChange('height', e.target.value, 3);
                    }}
                    inputProps={{ min: 1 }}
                    startAdornment={
                      <IconButton disableRipple aria-label="" edge="start">
                        <HeightNoBgIcon />
                      </IconButton>
                    }
                    endAdornment={
                      <Typography
                        sx={MedicalConditions_style.endAdornmentTextSx}
                      >
                        cm
                      </Typography>
                    }
                    textFieldStyle={MedicalConditions_style.inputWidthSx}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Label htmlFor="weight">Weight</Label>
                  <Input
                    type="number"
                    value={profile?.weight}
                    onChange={(e) => {
                      handleChange('weight', e.target.value, 3);
                    }}
                    inputProps={{ min: 1 }}
                    startAdornment={
                      <IconButton disableRipple aria-label="" edge="start">
                        <WeightNoBgIcon />
                      </IconButton>
                    }
                    endAdornment={
                      <Typography
                        sx={MedicalConditions_style.endAdornmentTextSx}
                      >
                        kg
                      </Typography>
                    }
                    htmlFor="weight"
                    textFieldStyle={MedicalConditions_style.inputWidthSx}
                  />
                </Grid>
                {profile?.bmi.length > 0 && (
                  <Grid item xs={12}>
                    <Box
                      mb={1}
                      sx={MedicalConditions_style.bmiCalculationGroupSx}
                    >
                      <Stack direction="row" spacing={1}>
                        <BodyCamera />
                        <Typography
                          sx={MedicalConditions_style.bmiCalculationValueSx}
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={500}
                            color="#184457"
                          >
                            Your BMI is
                          </Typography>

                          <span
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {profile?.bmi ?? ''}
                            {}
                          </span>
                          <span
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            {profile?.bmi
                              ? toFindBMICategory(profile?.bmi)
                              : 'Normal'}
                            {}
                          </span>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Label htmlFor="blood_group">Blood Group </Label>
                  <Box
                    sx={{
                      overflowY: 'scroll',
                    }}
                  >
                    <BloodGroupSelection
                      id="bloodgroup"
                      value={profile?.bloodgroup}
                      bloodGroupData={bloodGroupData}
                      bloodData={profile}
                      handleChange
                      name="blood_group"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          )}

          <Stack sx={MedicalConditions_style.medicalCard}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={MedicalConditions_style.titileSx}>
                Allergies
              </Typography>
              <Typography
                sx={
                  profile?.error?.medical_allergies
                    ? MedicalConditions_style.requiredSxx
                    : MedicalConditions_style.requiredSx
                }
              >
                Required
              </Typography>
            </Stack>
            <Box sx={MedicalConditions_style.inputGroupSx}>
              <div style={{ marginTop: '-26px' }}>
                <QusAnsSelectionLisiting
                  id="allergies"
                  isShowClearAll={false}
                  isShowCount={false}
                  QusAnsListingHeadStyle={MedicalConditions_style.cardListStyle}
                  qusAnsListingCardStyle={MedicalConditions_style.listingCardSx}
                  QusAnsSelectionHeading=""
                  showDivider={false}
                  QusAnsListingHeading="Allergies"
                  QusAnsListingAdded="added"
                  OnClearAll={handleClearAllergy}
                  displaySelectionValues={
                    profile?.medical_allergies?.[0] === 'No Allergies'
                      ? []
                      : profile?.medical_allergies
                  }
                  OnDelete={handleDeleteAllergy}
                  QusAnsSelectionListing={
                    <CustomTextArea
                      textFieldStyle={
                        isFromClinical
                          ? MedicalConditions_style.worktypoSx
                          : MedicalConditions_style.mbfVariantTextField
                      }
                      rows={1}
                      rowsMax={1}
                      add={handleAddAllergy}
                      onChange={handleAllergy}
                      value={allergy}
                      placeholder=""
                      enterKeyPress={useKeyPress}
                      plusButton
                      disable={allergyCheck}
                      MbfVariant={!isFromClinical}
                      data_testId="allergies"
                    />
                  }
                />
              </div>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onChange={() => handleCheckBox('medical_allergies')}
            >
              <Checkbox
                id="allergiesCheck"
                checked={allergyCheck}
                icon={<SquareCheckBoxIcon />}
                checkedIcon={
                  isFromClinical ? (
                    <SqureCheckedIcon
                      rootStyle={{ color: "'primaryTints.900' " }}
                    />
                  ) : (
                    <SqureCheckedIcon />
                  )
                }
              />
              <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                No Allergies
              </Typography>
            </Box>
          </Stack>

          <Stack sx={MedicalConditions_style.medicalCard}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={MedicalConditions_style.titileSx}>
                Health problems
              </Typography>
              <Typography
                sx={
                  profile?.error?.medical_health_problems
                    ? MedicalConditions_style.requiredSxx
                    : MedicalConditions_style.requiredSx
                }
              >
                Required
              </Typography>
            </Stack>
            <Box sx={MedicalConditions_style.inputGroupSx}>
              <div style={{ marginTop: '-26px' }}>
                <QusAnsSelectionLisiting
                  id="healthProblems"
                  isShowClearAll={false}
                  isShowCount={false}
                  QusAnsListingHeadStyle={MedicalConditions_style.cardListStyle}
                  qusAnsListingCardStyle={MedicalConditions_style.listingCardSx}
                  QusAnsSelectionHeading=""
                  showDivider={false}
                  QusAnsListingHeading="Health problems"
                  QusAnsListingAdded="added"
                  OnClearAll={handleClearProblem}
                  displaySelectionValues={
                    profile?.medical_health_problems?.[0] ===
                    'No Health Problems'
                      ? []
                      : profile?.medical_health_problems
                  }
                  OnDelete={handleDeleteProblem}
                  QusAnsSelectionListing={
                    <CustomTextArea
                      data_testId="healthProblems"
                      textFieldStyle={
                        isFromClinical
                          ? MedicalConditions_style.worktypoSx
                          : MedicalConditions_style.mbfVariantTextField
                      }
                      rows={1}
                      rowsMax={1}
                      add={handleAddProblem}
                      onChange={handleProblem}
                      value={problem}
                      placeholder=""
                      enterKeyPress={useKeyPress}
                      plusButton
                      disable={problemCheck}
                      MbfVariant={!isFromClinical}
                    />
                  }
                />
              </div>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onChange={() => handleCheckBox('medical_health_problems')}
            >
              <Checkbox
                id="healthProblemsCheck"
                checked={problemCheck}
                icon={<SquareCheckBoxIcon />}
                checkedIcon={
                  isFromClinical ? (
                    <SqureCheckedIcon
                      rootStyle={{ color: "'primaryTints.900' " }}
                    />
                  ) : (
                    <SqureCheckedIcon />
                  )
                }
              />

              <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                No Health Problems
              </Typography>
            </Box>
          </Stack>

          <Stack sx={MedicalConditions_style.medicalCard}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={MedicalConditions_style.titileSx}>
                Active Medications
              </Typography>
              <Typography
                sx={
                  profile?.error?.medical_active_medications
                    ? MedicalConditions_style.requiredSxx
                    : MedicalConditions_style.requiredSx
                }
              >
                Required
              </Typography>
            </Stack>
            <Box sx={MedicalConditions_style.inputGroupSx}>
              <div style={{ marginTop: '-26px' }}>
                <QusAnsSelectionLisiting
                  id="activeMedications"
                  isShowClearAll={false}
                  isShowCount={false}
                  QusAnsListingHeadStyle={MedicalConditions_style.cardListStyle}
                  qusAnsListingCardStyle={MedicalConditions_style.listingCardSx}
                  QusAnsSelectionHeading=""
                  showDivider={false}
                  QusAnsListingHeading="Active Medications"
                  QusAnsListingAdded="added"
                  OnClearAll={handleClearMedication}
                  displaySelectionValues={
                    profile?.medical_active_medications?.[0] ===
                    'No Medications'
                      ? []
                      : profile?.medical_active_medications
                  }
                  OnDelete={handleDeleteMedication}
                  QusAnsSelectionListing={
                    <CustomTextArea
                      data_testId="activeMedications"
                      textFieldStyle={
                        isFromClinical
                          ? MedicalConditions_style.worktypoSx
                          : MedicalConditions_style.mbfVariantTextField
                      }
                      rows={1}
                      rowsMax={1}
                      add={handleAddMedication}
                      onChange={handleMedication}
                      value={medication}
                      placeholder=""
                      enterKeyPress={useKeyPress}
                      plusButton
                      disable={medicationCheck}
                      MbfVariant={!isFromClinical}
                    />
                  }
                />
              </div>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onChange={() => handleCheckBox('medical_active_medications')}
            >
              <Checkbox
                id="activeMedicationsCheck"
                checked={medicationCheck}
                icon={<SquareCheckBoxIcon />}
                checkedIcon={
                  isFromClinical ? (
                    <SqureCheckedIcon
                      rootStyle={{ color: "'primaryTints.900' " }}
                    />
                  ) : (
                    <SqureCheckedIcon />
                  )
                }
              />

              <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                No Medications
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Screenlayout>
  );
}
