/* eslint-disable no-lonely-if */
import { clinicalRoutes } from '@hc/routes';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { Button } from '@hc/ui/atoms';
import { FreeConsultation, HealthIssuesSelection } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { localStorageKeys, parseJwt } from '@hc/utils';
import { doctorAvailablePayload, localStorageKeys, parseJwt } from '@hc/utils';

import { useProfile } from '@hc/store';
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';

import isEqual from 'react-fast-compare';
import { healthIssues_style } from './style';

export default function HealthIssues(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const [addDrawerOpen, setAddDrawerOpen] = useState(false);

  const { getDoctorData, setStartEndBetween, updateProfileData } =
    useBookAppointment((state) => ({
      updateProfileData: state.updateProfileData,
      getDoctorData: state.getDoctorData,
      setStartEndBetween: state.setStartEndBetween,
      bookAppointmentState: state?.bookAppointmentState,
      clearPageData: state.clearPageData,
    }));

  const { getProfileById, profile } = useProfile(
    (state) => ({
      profile: state.profile,
      getProfileById: state.getProfileById,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // const referral = localStorage.getItem(localStorageKeys.isReferralAppoinment);
  // const keyReferralData = JSON.parse(referral);

  // Gernal Hooks
  const navigate = useNavigate();
  const location = useLocation();
  // CLEAR HEALTH ISSUE FUNCTION
  // const handleClear = () => {
  // CLEAR HEALTH ISSUE
  // clearPageData('health_issues');

  const getInitialData = async (payload, start, end, days) => {
    await getDoctorData(payload);
    await setStartEndBetween(start, end, days);
    const profileData = await getProfileById(data?.user_id);
    // UPDATE PROFILE DATA
    updateProfileData('myself', data, profileData, 'myself');
  };

  const viewDoctorsClick = () => {
    if (location?.state?.prevRoute?.length > 0) {
      navigate(location?.state?.prevRoute);
    } else {
      if (data?.employee_membership_code) {
        if (profile.remaining_free_consultion > 0) {
          setAddDrawerOpen(true);
        } else {
          navigate(clinicalRoutes.doctorAvailable, {
            state: {
              val: location?.state?.val ?? {},
              keyData: data ?? {},
              doctorId: location?.state?.doctorId ?? '',
            },
          });
        }
      } else {
        localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
        navigate(clinicalRoutes.doctorAvailable, {
          state: {
            val: location?.state?.val ?? {},
            keyData: data ?? {},
            doctorId: location?.state?.doctorId ?? '',
          },
        });
      }
    }
  };

  const paidConsultationFun = () => {
    localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
    navigate(clinicalRoutes.doctorAvailable, {
      state: {
        val: location?.state?.val ?? {},
        keyData: data ?? {},
        doctorId: location?.state?.doctorId ?? '',
      },
    });
  };
  const FreeConsultationNavFun = () => {
    localStorage.setItem(localStorageKeys.appoinmentType, 'free');
    navigate(clinicalRoutes.doctorAvailable, {
      state: {
        val: location?.state?.val ?? {},
        keyData: data ?? {},
        doctorId: location?.state?.doctorId ?? '',
      },
    });
  };

  useEffect(() => {
    const referral = localStorage.getItem(
      localStorageKeys.isReferralAppoinment,
    );
    const keyReferralData = JSON.parse(referral);
    if (keyReferralData) {
      const start = startOfWeek(new Date());
      const end = endOfWeek(new Date());
      const days = eachDayOfInterval({
        start,
        end,
      });
      const payload = {
        ...doctorAvailablePayload(start),
        search: '',
        languages: [],
        speciality: '',
        id: keyReferralData?.assigned_doctor_profile?.id,
      };
      getInitialData(payload, start, end, days);
    }
  }, []);

  return (
    <Screenlayout
      title="Book Appointment"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={() => viewDoctorsClick()}>
            {location?.state?.prevRoute?.length > 0
              ? 'Summary'
              : 'View Doctors'}
          </Button>
        </Box>
      }
      {...rest}
    >
      {/* healthIssues */}
      <Box>
        <Box sx={healthIssues_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <HealthIssuesSelection />
        </Box>
      </Box>
      <FreeConsultation
        addDrawerOpen={addDrawerOpen}
        setAddDrawerOpen={setAddDrawerOpen}
        PaidConsultant={() => paidConsultationFun()}
        FreeConsultant={() => FreeConsultationNavFun()}
      />
    </Screenlayout>
  );
}
